export const WORDS = [
  'xuyao',
  'sushe',
  'zheli',
  'duyao',
  'tigao',
  'wendu',
  'yachi',
  'xuezi',
  'dajia',
  'haipa',
  'yindu',
  'xuexi',
  'laohu',
  'ruguo',
  'haizi',
  'anpai',
  'juede',
  'rizhi',
  'huang',
  'nuofu',
  'naxie',
  'chazi',
  'teshu',
  'zuofa',
  'nüren',
  'gaosu',
  'ditie',
  'muban',
  'wuhui',
  'hushi',
  'women',
  'shuzi',
  'xiawu',
  'feiji',
  'zenme',
  'yihou',
  'xiang',
  'chang',
  'dushu',
  'wenti',
  'chuan',
  'suoyi',
]

export const HANZI = [
  'xūyào 需要',
  'sùshè 宿舍',
  'zhèlǐ 这里',
  'dúyào 毒药',
  'tígāo 提高',
  'wēndù 温度',
  'yáchǐ 牙齿',
  'xuēzi 靴子',
  'dàjiā 大家',
  'hàipà 害怕',
  'yìndù 印度',
  'xuéxí 学习',
  'lǎohǔ 老虎',
  'rúguǒ 如果',
  'háizi 孩子',
  'ānpái 安排',
  'juédé 觉得',
  'rìzhì 日志',
  'huáng 黄',
  'nuòfū 懦夫',
  'nàxiē 那些',
  'chāzi 叉子',
  'tèshū 特殊',
  'zuòfǎ 作法',
  'nǚrén 女人',
  'gàosù 告訴',
  'dìtiě 地铁',
  'mùbǎn 木板',
  'wǔhuì 舞会',
  'hùshì 护士',
  'wǒmen 我们',
  'shùzì 数字',
  'xiàwǔ 下午',
  'fēijī 飞机',
  'zěnme 怎么',
  'yǐhòu 以后',
  'xiǎng 想',
  'cháng 常',
  'dúshū 读书',
  'wèntí 问题',
  'chuán 船',
  'suǒyǐ 所以',
]
