export const VALID_GUESSES = [
'zhiri',
'zhizi',
'zhici',
'zhisi',
'zhiba',
'zhipa',
'zhima',
'zhifa',
'zhida',
'zhita',
'zhina',
'zhila',
'zhiga',
'zhika',
'zhiha',
'zhiza',
'zhica',
'zhisa',
'zhibo',
'zhipo',
'zhimo',
'zhifo',
'zhilo',
'zhime',
'zhide',
'zhite',
'zhine',
'zhile',
'zhige',
'zhike',
'zhihe',
'zhire',
'zhize',
'zhice',
'zhise',
'zhiai',
'zhiei',
'zhiao',
'zhiou',
'zhian',
'zhien',
'zhier',
'zhiyi',
'zhibi',
'zhipi',
'zhimi',
'zhidi',
'zhiti',
'zhini',
'zhili',
'zhiji',
'zhiqi',
'zhixi',
'zhiya',
'zhiyo',
'zhiye',
'zhiwu',
'zhibu',
'zhipu',
'zhimu',
'zhifu',
'zhidu',
'zhitu',
'zhinu',
'zhilu',
'zhigu',
'zhiku',
'zhihu',
'zhiru',
'zhizu',
'zhicu',
'zhisu',
'zhiwa',
'zhiwo',
'zhiyu',
'zhinü',
'zhilü',
'zhiju',
'zhiqu',
'zhixu',
'chiri',
'chizi',
'chici',
'chisi',
'chiba',
'chipa',
'chima',
'chifa',
'chida',
'chita',
'china',
'chila',
'chiga',
'chika',
'chiha',
'chiza',
'chica',
'chisa',
'chibo',
'chipo',
'chimo',
'chifo',
'chilo',
'chime',
'chide',
'chite',
'chine',
'chile',
'chige',
'chike',
'chihe',
'chire',
'chize',
'chice',
'chise',
'chiai',
'chiei',
'chiao',
'chiou',
'chian',
'chien',
'chier',
'chiyi',
'chibi',
'chipi',
'chimi',
'chidi',
'chiti',
'chini',
'chili',
'chiji',
'chiqi',
'chixi',
'chiya',
'chiyo',
'chiye',
'chiwu',
'chibu',
'chipu',
'chimu',
'chifu',
'chidu',
'chitu',
'chinu',
'chilu',
'chigu',
'chiku',
'chihu',
'chiru',
'chizu',
'chicu',
'chisu',
'chiwa',
'chiwo',
'chiyu',
'chinü',
'chilü',
'chiju',
'chiqu',
'chixu',
'shiri',
'shizi',
'shici',
'shisi',
'shiba',
'shipa',
'shima',
'shifa',
'shida',
'shita',
'shina',
'shila',
'shiga',
'shika',
'shiha',
'shiza',
'shica',
'shisa',
'shibo',
'shipo',
'shimo',
'shifo',
'shilo',
'shime',
'shide',
'shite',
'shine',
'shile',
'shige',
'shike',
'shihe',
'shire',
'shize',
'shice',
'shise',
'shiai',
'shiei',
'shiao',
'shiou',
'shian',
'shien',
'shier',
'shiyi',
'shibi',
'shipi',
'shimi',
'shidi',
'shiti',
'shini',
'shili',
'shiji',
'shiqi',
'shixi',
'shiya',
'shiyo',
'shiye',
'shiwu',
'shibu',
'shipu',
'shimu',
'shifu',
'shidu',
'shitu',
'shinu',
'shilu',
'shigu',
'shiku',
'shihu',
'shiru',
'shizu',
'shicu',
'shisu',
'shiwa',
'shiwo',
'shiyu',
'shinü',
'shilü',
'shiju',
'shiqu',
'shixu',
'rizhi',
'richi',
'rishi',
'rizha',
'richa',
'risha',
'rizhe',
'riche',
'rishe',
'ribai',
'ripai',
'rimai',
'ridai',
'ritai',
'rinai',
'rilai',
'rigai',
'rikai',
'rihai',
'rizai',
'ricai',
'risai',
'ribei',
'ripei',
'rimei',
'rifei',
'ridei',
'ritei',
'rinei',
'rilei',
'rigei',
'rikei',
'rihei',
'rizei',
'risei',
'ribao',
'ripao',
'rimao',
'ridao',
'ritao',
'rinao',
'rilao',
'rigao',
'rikao',
'rihao',
'rirao',
'rizao',
'ricao',
'risao',
'ripou',
'rimou',
'rifou',
'ridou',
'ritou',
'rinou',
'rilou',
'rigou',
'rikou',
'rihou',
'rirou',
'rizou',
'ricou',
'risou',
'riban',
'ripan',
'riman',
'rifan',
'ridan',
'ritan',
'rinan',
'rilan',
'rigan',
'rikan',
'rihan',
'riran',
'rizan',
'rican',
'risan',
'riben',
'ripen',
'rimen',
'rifen',
'riden',
'rinen',
'rigen',
'riken',
'rihen',
'riren',
'rizen',
'ricen',
'risen',
'riang',
'rieng',
'ridia',
'rinia',
'rilia',
'rijia',
'riqia',
'rixia',
'ribie',
'ripie',
'rimie',
'ridie',
'ritie',
'rinie',
'rilie',
'rijie',
'riqie',
'rixie',
'riyai',
'riyao',
'riyou',
'rimiu',
'ridiu',
'riniu',
'riliu',
'rijiu',
'riqiu',
'rixiu',
'riyan',
'riyin',
'ribin',
'ripin',
'rimin',
'ridin',
'rinin',
'rilin',
'rijin',
'riqin',
'rixin',
'rizhu',
'richu',
'rishu',
'rigua',
'rikua',
'rihua',
'rirua',
'riduo',
'rituo',
'rinuo',
'riluo',
'riguo',
'rikuo',
'rihuo',
'riruo',
'rizuo',
'ricuo',
'risuo',
'riwai',
'riwei',
'ridui',
'ritui',
'rigui',
'rikui',
'rihui',
'rirui',
'rizui',
'ricui',
'risui',
'riwan',
'riwen',
'ridun',
'ritun',
'rinun',
'rilun',
'rigun',
'rikun',
'rihun',
'rirun',
'rizun',
'ricun',
'risun',
'riyue',
'rinüe',
'rilüe',
'rijue',
'rique',
'rixue',
'riyun',
'rilün',
'rijun',
'riqun',
'rixun',
'zizhi',
'zichi',
'zishi',
'zizha',
'zicha',
'zisha',
'zizhe',
'ziche',
'zishe',
'zibai',
'zipai',
'zimai',
'zidai',
'zitai',
'zinai',
'zilai',
'zigai',
'zikai',
'zihai',
'zizai',
'zicai',
'zisai',
'zibei',
'zipei',
'zimei',
'zifei',
'zidei',
'zitei',
'zinei',
'zilei',
'zigei',
'zikei',
'zihei',
'zizei',
'zisei',
'zibao',
'zipao',
'zimao',
'zidao',
'zitao',
'zinao',
'zilao',
'zigao',
'zikao',
'zihao',
'zirao',
'zizao',
'zicao',
'zisao',
'zipou',
'zimou',
'zifou',
'zidou',
'zitou',
'zinou',
'zilou',
'zigou',
'zikou',
'zihou',
'zirou',
'zizou',
'zicou',
'zisou',
'ziban',
'zipan',
'ziman',
'zifan',
'zidan',
'zitan',
'zinan',
'zilan',
'zigan',
'zikan',
'zihan',
'ziran',
'zizan',
'zican',
'zisan',
'ziben',
'zipen',
'zimen',
'zifen',
'ziden',
'zinen',
'zigen',
'ziken',
'zihen',
'ziren',
'zizen',
'zicen',
'zisen',
'ziang',
'zieng',
'zidia',
'zinia',
'zilia',
'zijia',
'ziqia',
'zixia',
'zibie',
'zipie',
'zimie',
'zidie',
'zitie',
'zinie',
'zilie',
'zijie',
'ziqie',
'zixie',
'ziyai',
'ziyao',
'ziyou',
'zimiu',
'zidiu',
'ziniu',
'ziliu',
'zijiu',
'ziqiu',
'zixiu',
'ziyan',
'ziyin',
'zibin',
'zipin',
'zimin',
'zidin',
'zinin',
'zilin',
'zijin',
'ziqin',
'zixin',
'zizhu',
'zichu',
'zishu',
'zigua',
'zikua',
'zihua',
'zirua',
'ziduo',
'zituo',
'zinuo',
'ziluo',
'ziguo',
'zikuo',
'zihuo',
'ziruo',
'zizuo',
'zicuo',
'zisuo',
'ziwai',
'ziwei',
'zidui',
'zitui',
'zigui',
'zikui',
'zihui',
'zirui',
'zizui',
'zicui',
'zisui',
'ziwan',
'ziwen',
'zidun',
'zitun',
'zinun',
'zilun',
'zigun',
'zikun',
'zihun',
'zirun',
'zizun',
'zicun',
'zisun',
'ziyue',
'zinüe',
'zilüe',
'zijue',
'zique',
'zixue',
'ziyun',
'zilün',
'zijun',
'ziqun',
'zixun',
'cizhi',
'cichi',
'cishi',
'cizha',
'cicha',
'cisha',
'cizhe',
'ciche',
'cishe',
'cibai',
'cipai',
'cimai',
'cidai',
'citai',
'cinai',
'cilai',
'cigai',
'cikai',
'cihai',
'cizai',
'cicai',
'cisai',
'cibei',
'cipei',
'cimei',
'cifei',
'cidei',
'citei',
'cinei',
'cilei',
'cigei',
'cikei',
'cihei',
'cizei',
'cisei',
'cibao',
'cipao',
'cimao',
'cidao',
'citao',
'cinao',
'cilao',
'cigao',
'cikao',
'cihao',
'cirao',
'cizao',
'cicao',
'cisao',
'cipou',
'cimou',
'cifou',
'cidou',
'citou',
'cinou',
'cilou',
'cigou',
'cikou',
'cihou',
'cirou',
'cizou',
'cicou',
'cisou',
'ciban',
'cipan',
'ciman',
'cifan',
'cidan',
'citan',
'cinan',
'cilan',
'cigan',
'cikan',
'cihan',
'ciran',
'cizan',
'cican',
'cisan',
'ciben',
'cipen',
'cimen',
'cifen',
'ciden',
'cinen',
'cigen',
'ciken',
'cihen',
'ciren',
'cizen',
'cicen',
'cisen',
'ciang',
'cieng',
'cidia',
'cinia',
'cilia',
'cijia',
'ciqia',
'cixia',
'cibie',
'cipie',
'cimie',
'cidie',
'citie',
'cinie',
'cilie',
'cijie',
'ciqie',
'cixie',
'ciyai',
'ciyao',
'ciyou',
'cimiu',
'cidiu',
'ciniu',
'ciliu',
'cijiu',
'ciqiu',
'cixiu',
'ciyan',
'ciyin',
'cibin',
'cipin',
'cimin',
'cidin',
'cinin',
'cilin',
'cijin',
'ciqin',
'cixin',
'cizhu',
'cichu',
'cishu',
'cigua',
'cikua',
'cihua',
'cirua',
'ciduo',
'cituo',
'cinuo',
'ciluo',
'ciguo',
'cikuo',
'cihuo',
'ciruo',
'cizuo',
'cicuo',
'cisuo',
'ciwai',
'ciwei',
'cidui',
'citui',
'cigui',
'cikui',
'cihui',
'cirui',
'cizui',
'cicui',
'cisui',
'ciwan',
'ciwen',
'cidun',
'citun',
'cinun',
'cilun',
'cigun',
'cikun',
'cihun',
'cirun',
'cizun',
'cicun',
'cisun',
'ciyue',
'cinüe',
'cilüe',
'cijue',
'cique',
'cixue',
'ciyun',
'cilün',
'cijun',
'ciqun',
'cixun',
'sizhi',
'sichi',
'sishi',
'sizha',
'sicha',
'sisha',
'sizhe',
'siche',
'sishe',
'sibai',
'sipai',
'simai',
'sidai',
'sitai',
'sinai',
'silai',
'sigai',
'sikai',
'sihai',
'sizai',
'sicai',
'sisai',
'sibei',
'sipei',
'simei',
'sifei',
'sidei',
'sitei',
'sinei',
'silei',
'sigei',
'sikei',
'sihei',
'sizei',
'sisei',
'sibao',
'sipao',
'simao',
'sidao',
'sitao',
'sinao',
'silao',
'sigao',
'sikao',
'sihao',
'sirao',
'sizao',
'sicao',
'sisao',
'sipou',
'simou',
'sifou',
'sidou',
'sitou',
'sinou',
'silou',
'sigou',
'sikou',
'sihou',
'sirou',
'sizou',
'sicou',
'sisou',
'siban',
'sipan',
'siman',
'sifan',
'sidan',
'sitan',
'sinan',
'silan',
'sigan',
'sikan',
'sihan',
'siran',
'sizan',
'sican',
'sisan',
'siben',
'sipen',
'simen',
'sifen',
'siden',
'sinen',
'sigen',
'siken',
'sihen',
'siren',
'sizen',
'sicen',
'sisen',
'siang',
'sieng',
'sidia',
'sinia',
'silia',
'sijia',
'siqia',
'sixia',
'sibie',
'sipie',
'simie',
'sidie',
'sitie',
'sinie',
'silie',
'sijie',
'siqie',
'sixie',
'siyai',
'siyao',
'siyou',
'simiu',
'sidiu',
'siniu',
'siliu',
'sijiu',
'siqiu',
'sixiu',
'siyan',
'siyin',
'sibin',
'sipin',
'simin',
'sidin',
'sinin',
'silin',
'sijin',
'siqin',
'sixin',
'sizhu',
'sichu',
'sishu',
'sigua',
'sikua',
'sihua',
'sirua',
'siduo',
'situo',
'sinuo',
'siluo',
'siguo',
'sikuo',
'sihuo',
'siruo',
'sizuo',
'sicuo',
'sisuo',
'siwai',
'siwei',
'sidui',
'situi',
'sigui',
'sikui',
'sihui',
'sirui',
'sizui',
'sicui',
'sisui',
'siwan',
'siwen',
'sidun',
'situn',
'sinun',
'silun',
'sigun',
'sikun',
'sihun',
'sirun',
'sizun',
'sicun',
'sisun',
'siyue',
'sinüe',
'silüe',
'sijue',
'sique',
'sixue',
'siyun',
'silün',
'sijun',
'siqun',
'sixun',
'bazhi',
'bachi',
'bashi',
'bazha',
'bacha',
'basha',
'bazhe',
'bache',
'bashe',
'babai',
'bapai',
'bamai',
'badai',
'batai',
'banai',
'balai',
'bagai',
'bakai',
'bahai',
'bazai',
'bacai',
'basai',
'babei',
'bapei',
'bamei',
'bafei',
'badei',
'batei',
'banei',
'balei',
'bagei',
'bakei',
'bahei',
'bazei',
'basei',
'babao',
'bapao',
'bamao',
'badao',
'batao',
'banao',
'balao',
'bagao',
'bakao',
'bahao',
'barao',
'bazao',
'bacao',
'basao',
'bapou',
'bamou',
'bafou',
'badou',
'batou',
'banou',
'balou',
'bagou',
'bakou',
'bahou',
'barou',
'bazou',
'bacou',
'basou',
'baban',
'bapan',
'baman',
'bafan',
'badan',
'batan',
'banan',
'balan',
'bagan',
'bakan',
'bahan',
'baran',
'bazan',
'bacan',
'basan',
'baben',
'bapen',
'bamen',
'bafen',
'baden',
'banen',
'bagen',
'baken',
'bahen',
'baren',
'bazen',
'bacen',
'basen',
'baang',
'baeng',
'badia',
'bania',
'balia',
'bajia',
'baqia',
'baxia',
'babie',
'bapie',
'bamie',
'badie',
'batie',
'banie',
'balie',
'bajie',
'baqie',
'baxie',
'bayai',
'bayao',
'bayou',
'bamiu',
'badiu',
'baniu',
'baliu',
'bajiu',
'baqiu',
'baxiu',
'bayan',
'bayin',
'babin',
'bapin',
'bamin',
'badin',
'banin',
'balin',
'bajin',
'baqin',
'baxin',
'bazhu',
'bachu',
'bashu',
'bagua',
'bakua',
'bahua',
'barua',
'baduo',
'batuo',
'banuo',
'baluo',
'baguo',
'bakuo',
'bahuo',
'baruo',
'bazuo',
'bacuo',
'basuo',
'bawai',
'bawei',
'badui',
'batui',
'bagui',
'bakui',
'bahui',
'barui',
'bazui',
'bacui',
'basui',
'bawan',
'bawen',
'badun',
'batun',
'banun',
'balun',
'bagun',
'bakun',
'bahun',
'barun',
'bazun',
'bacun',
'basun',
'bayue',
'banüe',
'balüe',
'bajue',
'baque',
'baxue',
'bayun',
'balün',
'bajun',
'baqun',
'baxun',
'pazhi',
'pachi',
'pashi',
'pazha',
'pacha',
'pasha',
'pazhe',
'pache',
'pashe',
'pabai',
'papai',
'pamai',
'padai',
'patai',
'panai',
'palai',
'pagai',
'pakai',
'pahai',
'pazai',
'pacai',
'pasai',
'pabei',
'papei',
'pamei',
'pafei',
'padei',
'patei',
'panei',
'palei',
'pagei',
'pakei',
'pahei',
'pazei',
'pasei',
'pabao',
'papao',
'pamao',
'padao',
'patao',
'panao',
'palao',
'pagao',
'pakao',
'pahao',
'parao',
'pazao',
'pacao',
'pasao',
'papou',
'pamou',
'pafou',
'padou',
'patou',
'panou',
'palou',
'pagou',
'pakou',
'pahou',
'parou',
'pazou',
'pacou',
'pasou',
'paban',
'papan',
'paman',
'pafan',
'padan',
'patan',
'panan',
'palan',
'pagan',
'pakan',
'pahan',
'paran',
'pazan',
'pacan',
'pasan',
'paben',
'papen',
'pamen',
'pafen',
'paden',
'panen',
'pagen',
'paken',
'pahen',
'paren',
'pazen',
'pacen',
'pasen',
'paang',
'paeng',
'padia',
'pania',
'palia',
'pajia',
'paqia',
'paxia',
'pabie',
'papie',
'pamie',
'padie',
'patie',
'panie',
'palie',
'pajie',
'paqie',
'paxie',
'payai',
'payao',
'payou',
'pamiu',
'padiu',
'paniu',
'paliu',
'pajiu',
'paqiu',
'paxiu',
'payan',
'payin',
'pabin',
'papin',
'pamin',
'padin',
'panin',
'palin',
'pajin',
'paqin',
'paxin',
'pazhu',
'pachu',
'pashu',
'pagua',
'pakua',
'pahua',
'parua',
'paduo',
'patuo',
'panuo',
'paluo',
'paguo',
'pakuo',
'pahuo',
'paruo',
'pazuo',
'pacuo',
'pasuo',
'pawai',
'pawei',
'padui',
'patui',
'pagui',
'pakui',
'pahui',
'parui',
'pazui',
'pacui',
'pasui',
'pawan',
'pawen',
'padun',
'patun',
'panun',
'palun',
'pagun',
'pakun',
'pahun',
'parun',
'pazun',
'pacun',
'pasun',
'payue',
'panüe',
'palüe',
'pajue',
'paque',
'paxue',
'payun',
'palün',
'pajun',
'paqun',
'paxun',
'mazhi',
'machi',
'mashi',
'mazha',
'macha',
'masha',
'mazhe',
'mache',
'mashe',
'mabai',
'mapai',
'mamai',
'madai',
'matai',
'manai',
'malai',
'magai',
'makai',
'mahai',
'mazai',
'macai',
'masai',
'mabei',
'mapei',
'mamei',
'mafei',
'madei',
'matei',
'manei',
'malei',
'magei',
'makei',
'mahei',
'mazei',
'masei',
'mabao',
'mapao',
'mamao',
'madao',
'matao',
'manao',
'malao',
'magao',
'makao',
'mahao',
'marao',
'mazao',
'macao',
'masao',
'mapou',
'mamou',
'mafou',
'madou',
'matou',
'manou',
'malou',
'magou',
'makou',
'mahou',
'marou',
'mazou',
'macou',
'masou',
'maban',
'mapan',
'maman',
'mafan',
'madan',
'matan',
'manan',
'malan',
'magan',
'makan',
'mahan',
'maran',
'mazan',
'macan',
'masan',
'maben',
'mapen',
'mamen',
'mafen',
'maden',
'manen',
'magen',
'maken',
'mahen',
'maren',
'mazen',
'macen',
'masen',
'maang',
'maeng',
'madia',
'mania',
'malia',
'majia',
'maqia',
'maxia',
'mabie',
'mapie',
'mamie',
'madie',
'matie',
'manie',
'malie',
'majie',
'maqie',
'maxie',
'mayai',
'mayao',
'mayou',
'mamiu',
'madiu',
'maniu',
'maliu',
'majiu',
'maqiu',
'maxiu',
'mayan',
'mayin',
'mabin',
'mapin',
'mamin',
'madin',
'manin',
'malin',
'majin',
'maqin',
'maxin',
'mazhu',
'machu',
'mashu',
'magua',
'makua',
'mahua',
'marua',
'maduo',
'matuo',
'manuo',
'maluo',
'maguo',
'makuo',
'mahuo',
'maruo',
'mazuo',
'macuo',
'masuo',
'mawai',
'mawei',
'madui',
'matui',
'magui',
'makui',
'mahui',
'marui',
'mazui',
'macui',
'masui',
'mawan',
'mawen',
'madun',
'matun',
'manun',
'malun',
'magun',
'makun',
'mahun',
'marun',
'mazun',
'macun',
'masun',
'mayue',
'manüe',
'malüe',
'majue',
'maque',
'maxue',
'mayun',
'malün',
'majun',
'maqun',
'maxun',
'fazhi',
'fachi',
'fashi',
'fazha',
'facha',
'fasha',
'fazhe',
'fache',
'fashe',
'fabai',
'fapai',
'famai',
'fadai',
'fatai',
'fanai',
'falai',
'fagai',
'fakai',
'fahai',
'fazai',
'facai',
'fasai',
'fabei',
'fapei',
'famei',
'fafei',
'fadei',
'fatei',
'fanei',
'falei',
'fagei',
'fakei',
'fahei',
'fazei',
'fasei',
'fabao',
'fapao',
'famao',
'fadao',
'fatao',
'fanao',
'falao',
'fagao',
'fakao',
'fahao',
'farao',
'fazao',
'facao',
'fasao',
'fapou',
'famou',
'fafou',
'fadou',
'fatou',
'fanou',
'falou',
'fagou',
'fakou',
'fahou',
'farou',
'fazou',
'facou',
'fasou',
'faban',
'fapan',
'faman',
'fafan',
'fadan',
'fatan',
'fanan',
'falan',
'fagan',
'fakan',
'fahan',
'faran',
'fazan',
'facan',
'fasan',
'faben',
'fapen',
'famen',
'fafen',
'faden',
'fanen',
'fagen',
'faken',
'fahen',
'faren',
'fazen',
'facen',
'fasen',
'faang',
'faeng',
'fadia',
'fania',
'falia',
'fajia',
'faqia',
'faxia',
'fabie',
'fapie',
'famie',
'fadie',
'fatie',
'fanie',
'falie',
'fajie',
'faqie',
'faxie',
'fayai',
'fayao',
'fayou',
'famiu',
'fadiu',
'faniu',
'faliu',
'fajiu',
'faqiu',
'faxiu',
'fayan',
'fayin',
'fabin',
'fapin',
'famin',
'fadin',
'fanin',
'falin',
'fajin',
'faqin',
'faxin',
'fazhu',
'fachu',
'fashu',
'fagua',
'fakua',
'fahua',
'farua',
'faduo',
'fatuo',
'fanuo',
'faluo',
'faguo',
'fakuo',
'fahuo',
'faruo',
'fazuo',
'facuo',
'fasuo',
'fawai',
'fawei',
'fadui',
'fatui',
'fagui',
'fakui',
'fahui',
'farui',
'fazui',
'facui',
'fasui',
'fawan',
'fawen',
'fadun',
'fatun',
'fanun',
'falun',
'fagun',
'fakun',
'fahun',
'farun',
'fazun',
'facun',
'fasun',
'fayue',
'fanüe',
'falüe',
'fajue',
'faque',
'faxue',
'fayun',
'falün',
'fajun',
'faqun',
'faxun',
'dazhi',
'dachi',
'dashi',
'dazha',
'dacha',
'dasha',
'dazhe',
'dache',
'dashe',
'dabai',
'dapai',
'damai',
'dadai',
'datai',
'danai',
'dalai',
'dagai',
'dakai',
'dahai',
'dazai',
'dacai',
'dasai',
'dabei',
'dapei',
'damei',
'dafei',
'dadei',
'datei',
'danei',
'dalei',
'dagei',
'dakei',
'dahei',
'dazei',
'dasei',
'dabao',
'dapao',
'damao',
'dadao',
'datao',
'danao',
'dalao',
'dagao',
'dakao',
'dahao',
'darao',
'dazao',
'dacao',
'dasao',
'dapou',
'damou',
'dafou',
'dadou',
'datou',
'danou',
'dalou',
'dagou',
'dakou',
'dahou',
'darou',
'dazou',
'dacou',
'dasou',
'daban',
'dapan',
'daman',
'dafan',
'dadan',
'datan',
'danan',
'dalan',
'dagan',
'dakan',
'dahan',
'daran',
'dazan',
'dacan',
'dasan',
'daben',
'dapen',
'damen',
'dafen',
'daden',
'danen',
'dagen',
'daken',
'dahen',
'daren',
'dazen',
'dacen',
'dasen',
'daang',
'daeng',
'dadia',
'dania',
'dalia',
'dajia',
'daqia',
'daxia',
'dabie',
'dapie',
'damie',
'dadie',
'datie',
'danie',
'dalie',
'dajie',
'daqie',
'daxie',
'dayai',
'dayao',
'dayou',
'damiu',
'dadiu',
'daniu',
'daliu',
'dajiu',
'daqiu',
'daxiu',
'dayan',
'dayin',
'dabin',
'dapin',
'damin',
'dadin',
'danin',
'dalin',
'dajin',
'daqin',
'daxin',
'dazhu',
'dachu',
'dashu',
'dagua',
'dakua',
'dahua',
'darua',
'daduo',
'datuo',
'danuo',
'daluo',
'daguo',
'dakuo',
'dahuo',
'daruo',
'dazuo',
'dacuo',
'dasuo',
'dawai',
'dawei',
'dadui',
'datui',
'dagui',
'dakui',
'dahui',
'darui',
'dazui',
'dacui',
'dasui',
'dawan',
'dawen',
'dadun',
'datun',
'danun',
'dalun',
'dagun',
'dakun',
'dahun',
'darun',
'dazun',
'dacun',
'dasun',
'dayue',
'danüe',
'dalüe',
'dajue',
'daque',
'daxue',
'dayun',
'dalün',
'dajun',
'daqun',
'daxun',
'tazhi',
'tachi',
'tashi',
'tazha',
'tacha',
'tasha',
'tazhe',
'tache',
'tashe',
'tabai',
'tapai',
'tamai',
'tadai',
'tatai',
'tanai',
'talai',
'tagai',
'takai',
'tahai',
'tazai',
'tacai',
'tasai',
'tabei',
'tapei',
'tamei',
'tafei',
'tadei',
'tatei',
'tanei',
'talei',
'tagei',
'takei',
'tahei',
'tazei',
'tasei',
'tabao',
'tapao',
'tamao',
'tadao',
'tatao',
'tanao',
'talao',
'tagao',
'takao',
'tahao',
'tarao',
'tazao',
'tacao',
'tasao',
'tapou',
'tamou',
'tafou',
'tadou',
'tatou',
'tanou',
'talou',
'tagou',
'takou',
'tahou',
'tarou',
'tazou',
'tacou',
'tasou',
'taban',
'tapan',
'taman',
'tafan',
'tadan',
'tatan',
'tanan',
'talan',
'tagan',
'takan',
'tahan',
'taran',
'tazan',
'tacan',
'tasan',
'taben',
'tapen',
'tamen',
'tafen',
'taden',
'tanen',
'tagen',
'taken',
'tahen',
'taren',
'tazen',
'tacen',
'tasen',
'taang',
'taeng',
'tadia',
'tania',
'talia',
'tajia',
'taqia',
'taxia',
'tabie',
'tapie',
'tamie',
'tadie',
'tatie',
'tanie',
'talie',
'tajie',
'taqie',
'taxie',
'tayai',
'tayao',
'tayou',
'tamiu',
'tadiu',
'taniu',
'taliu',
'tajiu',
'taqiu',
'taxiu',
'tayan',
'tayin',
'tabin',
'tapin',
'tamin',
'tadin',
'tanin',
'talin',
'tajin',
'taqin',
'taxin',
'tazhu',
'tachu',
'tashu',
'tagua',
'takua',
'tahua',
'tarua',
'taduo',
'tatuo',
'tanuo',
'taluo',
'taguo',
'takuo',
'tahuo',
'taruo',
'tazuo',
'tacuo',
'tasuo',
'tawai',
'tawei',
'tadui',
'tatui',
'tagui',
'takui',
'tahui',
'tarui',
'tazui',
'tacui',
'tasui',
'tawan',
'tawen',
'tadun',
'tatun',
'tanun',
'talun',
'tagun',
'takun',
'tahun',
'tarun',
'tazun',
'tacun',
'tasun',
'tayue',
'tanüe',
'talüe',
'tajue',
'taque',
'taxue',
'tayun',
'talün',
'tajun',
'taqun',
'taxun',
'nazhi',
'nachi',
'nashi',
'nazha',
'nacha',
'nasha',
'nazhe',
'nache',
'nashe',
'nabai',
'napai',
'namai',
'nadai',
'natai',
'nanai',
'nalai',
'nagai',
'nakai',
'nahai',
'nazai',
'nacai',
'nasai',
'nabei',
'napei',
'namei',
'nafei',
'nadei',
'natei',
'nanei',
'nalei',
'nagei',
'nakei',
'nahei',
'nazei',
'nasei',
'nabao',
'napao',
'namao',
'nadao',
'natao',
'nanao',
'nalao',
'nagao',
'nakao',
'nahao',
'narao',
'nazao',
'nacao',
'nasao',
'napou',
'namou',
'nafou',
'nadou',
'natou',
'nanou',
'nalou',
'nagou',
'nakou',
'nahou',
'narou',
'nazou',
'nacou',
'nasou',
'naban',
'napan',
'naman',
'nafan',
'nadan',
'natan',
'nanan',
'nalan',
'nagan',
'nakan',
'nahan',
'naran',
'nazan',
'nacan',
'nasan',
'naben',
'napen',
'namen',
'nafen',
'naden',
'nanen',
'nagen',
'naken',
'nahen',
'naren',
'nazen',
'nacen',
'nasen',
'naang',
'naeng',
'nadia',
'nania',
'nalia',
'najia',
'naqia',
'naxia',
'nabie',
'napie',
'namie',
'nadie',
'natie',
'nanie',
'nalie',
'najie',
'naqie',
'naxie',
'nayai',
'nayao',
'nayou',
'namiu',
'nadiu',
'naniu',
'naliu',
'najiu',
'naqiu',
'naxiu',
'nayan',
'nayin',
'nabin',
'napin',
'namin',
'nadin',
'nanin',
'nalin',
'najin',
'naqin',
'naxin',
'nazhu',
'nachu',
'nashu',
'nagua',
'nakua',
'nahua',
'narua',
'naduo',
'natuo',
'nanuo',
'naluo',
'naguo',
'nakuo',
'nahuo',
'naruo',
'nazuo',
'nacuo',
'nasuo',
'nawai',
'nawei',
'nadui',
'natui',
'nagui',
'nakui',
'nahui',
'narui',
'nazui',
'nacui',
'nasui',
'nawan',
'nawen',
'nadun',
'natun',
'nanun',
'nalun',
'nagun',
'nakun',
'nahun',
'narun',
'nazun',
'nacun',
'nasun',
'nayue',
'nanüe',
'nalüe',
'najue',
'naque',
'naxue',
'nayun',
'nalün',
'najun',
'naqun',
'naxun',
'lazhi',
'lachi',
'lashi',
'lazha',
'lacha',
'lasha',
'lazhe',
'lache',
'lashe',
'labai',
'lapai',
'lamai',
'ladai',
'latai',
'lanai',
'lalai',
'lagai',
'lakai',
'lahai',
'lazai',
'lacai',
'lasai',
'labei',
'lapei',
'lamei',
'lafei',
'ladei',
'latei',
'lanei',
'lalei',
'lagei',
'lakei',
'lahei',
'lazei',
'lasei',
'labao',
'lapao',
'lamao',
'ladao',
'latao',
'lanao',
'lalao',
'lagao',
'lakao',
'lahao',
'larao',
'lazao',
'lacao',
'lasao',
'lapou',
'lamou',
'lafou',
'ladou',
'latou',
'lanou',
'lalou',
'lagou',
'lakou',
'lahou',
'larou',
'lazou',
'lacou',
'lasou',
'laban',
'lapan',
'laman',
'lafan',
'ladan',
'latan',
'lanan',
'lalan',
'lagan',
'lakan',
'lahan',
'laran',
'lazan',
'lacan',
'lasan',
'laben',
'lapen',
'lamen',
'lafen',
'laden',
'lanen',
'lagen',
'laken',
'lahen',
'laren',
'lazen',
'lacen',
'lasen',
'laang',
'laeng',
'ladia',
'lania',
'lalia',
'lajia',
'laqia',
'laxia',
'labie',
'lapie',
'lamie',
'ladie',
'latie',
'lanie',
'lalie',
'lajie',
'laqie',
'laxie',
'layai',
'layao',
'layou',
'lamiu',
'ladiu',
'laniu',
'laliu',
'lajiu',
'laqiu',
'laxiu',
'layan',
'layin',
'labin',
'lapin',
'lamin',
'ladin',
'lanin',
'lalin',
'lajin',
'laqin',
'laxin',
'lazhu',
'lachu',
'lashu',
'lagua',
'lakua',
'lahua',
'larua',
'laduo',
'latuo',
'lanuo',
'laluo',
'laguo',
'lakuo',
'lahuo',
'laruo',
'lazuo',
'lacuo',
'lasuo',
'lawai',
'lawei',
'ladui',
'latui',
'lagui',
'lakui',
'lahui',
'larui',
'lazui',
'lacui',
'lasui',
'lawan',
'lawen',
'ladun',
'latun',
'lanun',
'lalun',
'lagun',
'lakun',
'lahun',
'larun',
'lazun',
'lacun',
'lasun',
'layue',
'lanüe',
'lalüe',
'lajue',
'laque',
'laxue',
'layun',
'lalün',
'lajun',
'laqun',
'laxun',
'gazhi',
'gachi',
'gashi',
'gazha',
'gacha',
'gasha',
'gazhe',
'gache',
'gashe',
'gabai',
'gapai',
'gamai',
'gadai',
'gatai',
'ganai',
'galai',
'gagai',
'gakai',
'gahai',
'gazai',
'gacai',
'gasai',
'gabei',
'gapei',
'gamei',
'gafei',
'gadei',
'gatei',
'ganei',
'galei',
'gagei',
'gakei',
'gahei',
'gazei',
'gasei',
'gabao',
'gapao',
'gamao',
'gadao',
'gatao',
'ganao',
'galao',
'gagao',
'gakao',
'gahao',
'garao',
'gazao',
'gacao',
'gasao',
'gapou',
'gamou',
'gafou',
'gadou',
'gatou',
'ganou',
'galou',
'gagou',
'gakou',
'gahou',
'garou',
'gazou',
'gacou',
'gasou',
'gaban',
'gapan',
'gaman',
'gafan',
'gadan',
'gatan',
'ganan',
'galan',
'gagan',
'gakan',
'gahan',
'garan',
'gazan',
'gacan',
'gasan',
'gaben',
'gapen',
'gamen',
'gafen',
'gaden',
'ganen',
'gagen',
'gaken',
'gahen',
'garen',
'gazen',
'gacen',
'gasen',
'gaang',
'gaeng',
'gadia',
'gania',
'galia',
'gajia',
'gaqia',
'gaxia',
'gabie',
'gapie',
'gamie',
'gadie',
'gatie',
'ganie',
'galie',
'gajie',
'gaqie',
'gaxie',
'gayai',
'gayao',
'gayou',
'gamiu',
'gadiu',
'ganiu',
'galiu',
'gajiu',
'gaqiu',
'gaxiu',
'gayan',
'gayin',
'gabin',
'gapin',
'gamin',
'gadin',
'ganin',
'galin',
'gajin',
'gaqin',
'gaxin',
'gazhu',
'gachu',
'gashu',
'gagua',
'gakua',
'gahua',
'garua',
'gaduo',
'gatuo',
'ganuo',
'galuo',
'gaguo',
'gakuo',
'gahuo',
'garuo',
'gazuo',
'gacuo',
'gasuo',
'gawai',
'gawei',
'gadui',
'gatui',
'gagui',
'gakui',
'gahui',
'garui',
'gazui',
'gacui',
'gasui',
'gawan',
'gawen',
'gadun',
'gatun',
'ganun',
'galun',
'gagun',
'gakun',
'gahun',
'garun',
'gazun',
'gacun',
'gasun',
'gayue',
'ganüe',
'galüe',
'gajue',
'gaque',
'gaxue',
'gayun',
'galün',
'gajun',
'gaqun',
'gaxun',
'kazhi',
'kachi',
'kashi',
'kazha',
'kacha',
'kasha',
'kazhe',
'kache',
'kashe',
'kabai',
'kapai',
'kamai',
'kadai',
'katai',
'kanai',
'kalai',
'kagai',
'kakai',
'kahai',
'kazai',
'kacai',
'kasai',
'kabei',
'kapei',
'kamei',
'kafei',
'kadei',
'katei',
'kanei',
'kalei',
'kagei',
'kakei',
'kahei',
'kazei',
'kasei',
'kabao',
'kapao',
'kamao',
'kadao',
'katao',
'kanao',
'kalao',
'kagao',
'kakao',
'kahao',
'karao',
'kazao',
'kacao',
'kasao',
'kapou',
'kamou',
'kafou',
'kadou',
'katou',
'kanou',
'kalou',
'kagou',
'kakou',
'kahou',
'karou',
'kazou',
'kacou',
'kasou',
'kaban',
'kapan',
'kaman',
'kafan',
'kadan',
'katan',
'kanan',
'kalan',
'kagan',
'kakan',
'kahan',
'karan',
'kazan',
'kacan',
'kasan',
'kaben',
'kapen',
'kamen',
'kafen',
'kaden',
'kanen',
'kagen',
'kaken',
'kahen',
'karen',
'kazen',
'kacen',
'kasen',
'kaang',
'kaeng',
'kadia',
'kania',
'kalia',
'kajia',
'kaqia',
'kaxia',
'kabie',
'kapie',
'kamie',
'kadie',
'katie',
'kanie',
'kalie',
'kajie',
'kaqie',
'kaxie',
'kayai',
'kayao',
'kayou',
'kamiu',
'kadiu',
'kaniu',
'kaliu',
'kajiu',
'kaqiu',
'kaxiu',
'kayan',
'kayin',
'kabin',
'kapin',
'kamin',
'kadin',
'kanin',
'kalin',
'kajin',
'kaqin',
'kaxin',
'kazhu',
'kachu',
'kashu',
'kagua',
'kakua',
'kahua',
'karua',
'kaduo',
'katuo',
'kanuo',
'kaluo',
'kaguo',
'kakuo',
'kahuo',
'karuo',
'kazuo',
'kacuo',
'kasuo',
'kawai',
'kawei',
'kadui',
'katui',
'kagui',
'kakui',
'kahui',
'karui',
'kazui',
'kacui',
'kasui',
'kawan',
'kawen',
'kadun',
'katun',
'kanun',
'kalun',
'kagun',
'kakun',
'kahun',
'karun',
'kazun',
'kacun',
'kasun',
'kayue',
'kanüe',
'kalüe',
'kajue',
'kaque',
'kaxue',
'kayun',
'kalün',
'kajun',
'kaqun',
'kaxun',
'hazhi',
'hachi',
'hashi',
'hazha',
'hacha',
'hasha',
'hazhe',
'hache',
'hashe',
'habai',
'hapai',
'hamai',
'hadai',
'hatai',
'hanai',
'halai',
'hagai',
'hakai',
'hahai',
'hazai',
'hacai',
'hasai',
'habei',
'hapei',
'hamei',
'hafei',
'hadei',
'hatei',
'hanei',
'halei',
'hagei',
'hakei',
'hahei',
'hazei',
'hasei',
'habao',
'hapao',
'hamao',
'hadao',
'hatao',
'hanao',
'halao',
'hagao',
'hakao',
'hahao',
'harao',
'hazao',
'hacao',
'hasao',
'hapou',
'hamou',
'hafou',
'hadou',
'hatou',
'hanou',
'halou',
'hagou',
'hakou',
'hahou',
'harou',
'hazou',
'hacou',
'hasou',
'haban',
'hapan',
'haman',
'hafan',
'hadan',
'hatan',
'hanan',
'halan',
'hagan',
'hakan',
'hahan',
'haran',
'hazan',
'hacan',
'hasan',
'haben',
'hapen',
'hamen',
'hafen',
'haden',
'hanen',
'hagen',
'haken',
'hahen',
'haren',
'hazen',
'hacen',
'hasen',
'haang',
'haeng',
'hadia',
'hania',
'halia',
'hajia',
'haqia',
'haxia',
'habie',
'hapie',
'hamie',
'hadie',
'hatie',
'hanie',
'halie',
'hajie',
'haqie',
'haxie',
'hayai',
'hayao',
'hayou',
'hamiu',
'hadiu',
'haniu',
'haliu',
'hajiu',
'haqiu',
'haxiu',
'hayan',
'hayin',
'habin',
'hapin',
'hamin',
'hadin',
'hanin',
'halin',
'hajin',
'haqin',
'haxin',
'hazhu',
'hachu',
'hashu',
'hagua',
'hakua',
'hahua',
'harua',
'haduo',
'hatuo',
'hanuo',
'haluo',
'haguo',
'hakuo',
'hahuo',
'haruo',
'hazuo',
'hacuo',
'hasuo',
'hawai',
'hawei',
'hadui',
'hatui',
'hagui',
'hakui',
'hahui',
'harui',
'hazui',
'hacui',
'hasui',
'hawan',
'hawen',
'hadun',
'hatun',
'hanun',
'halun',
'hagun',
'hakun',
'hahun',
'harun',
'hazun',
'hacun',
'hasun',
'hayue',
'hanüe',
'halüe',
'hajue',
'haque',
'haxue',
'hayun',
'halün',
'hajun',
'haqun',
'haxun',
'zhari',
'zhazi',
'zhaci',
'zhasi',
'zhaba',
'zhapa',
'zhama',
'zhafa',
'zhada',
'zhata',
'zhana',
'zhala',
'zhaga',
'zhaka',
'zhaha',
'zhaza',
'zhaca',
'zhasa',
'zhabo',
'zhapo',
'zhamo',
'zhafo',
'zhalo',
'zhame',
'zhade',
'zhate',
'zhane',
'zhale',
'zhage',
'zhake',
'zhahe',
'zhare',
'zhaze',
'zhace',
'zhase',
'zhaai',
'zhaei',
'zhaao',
'zhaou',
'zhaan',
'zhaen',
'zhaer',
'zhayi',
'zhabi',
'zhapi',
'zhami',
'zhadi',
'zhati',
'zhani',
'zhali',
'zhaji',
'zhaqi',
'zhaxi',
'zhaya',
'zhayo',
'zhaye',
'zhawu',
'zhabu',
'zhapu',
'zhamu',
'zhafu',
'zhadu',
'zhatu',
'zhanu',
'zhalu',
'zhagu',
'zhaku',
'zhahu',
'zharu',
'zhazu',
'zhacu',
'zhasu',
'zhawa',
'zhawo',
'zhayu',
'zhanü',
'zhalü',
'zhaju',
'zhaqu',
'zhaxu',
'chari',
'chazi',
'chaci',
'chasi',
'chaba',
'chapa',
'chama',
'chafa',
'chada',
'chata',
'chana',
'chala',
'chaga',
'chaka',
'chaha',
'chaza',
'chaca',
'chasa',
'chabo',
'chapo',
'chamo',
'chafo',
'chalo',
'chame',
'chade',
'chate',
'chane',
'chale',
'chage',
'chake',
'chahe',
'chare',
'chaze',
'chace',
'chase',
'chaai',
'chaei',
'chaao',
'chaou',
'chaan',
'chaen',
'chaer',
'chayi',
'chabi',
'chapi',
'chami',
'chadi',
'chati',
'chani',
'chali',
'chaji',
'chaqi',
'chaxi',
'chaya',
'chayo',
'chaye',
'chawu',
'chabu',
'chapu',
'chamu',
'chafu',
'chadu',
'chatu',
'chanu',
'chalu',
'chagu',
'chaku',
'chahu',
'charu',
'chazu',
'chacu',
'chasu',
'chawa',
'chawo',
'chayu',
'chanü',
'chalü',
'chaju',
'chaqu',
'chaxu',
'shari',
'shazi',
'shaci',
'shasi',
'shaba',
'shapa',
'shama',
'shafa',
'shada',
'shata',
'shana',
'shala',
'shaga',
'shaka',
'shaha',
'shaza',
'shaca',
'shasa',
'shabo',
'shapo',
'shamo',
'shafo',
'shalo',
'shame',
'shade',
'shate',
'shane',
'shale',
'shage',
'shake',
'shahe',
'share',
'shaze',
'shace',
'shase',
'shaai',
'shaei',
'shaao',
'shaou',
'shaan',
'shaen',
'shaer',
'shayi',
'shabi',
'shapi',
'shami',
'shadi',
'shati',
'shani',
'shali',
'shaji',
'shaqi',
'shaxi',
'shaya',
'shayo',
'shaye',
'shawu',
'shabu',
'shapu',
'shamu',
'shafu',
'shadu',
'shatu',
'shanu',
'shalu',
'shagu',
'shaku',
'shahu',
'sharu',
'shazu',
'shacu',
'shasu',
'shawa',
'shawo',
'shayu',
'shanü',
'shalü',
'shaju',
'shaqu',
'shaxu',
'zazhi',
'zachi',
'zashi',
'zazha',
'zacha',
'zasha',
'zazhe',
'zache',
'zashe',
'zabai',
'zapai',
'zamai',
'zadai',
'zatai',
'zanai',
'zalai',
'zagai',
'zakai',
'zahai',
'zazai',
'zacai',
'zasai',
'zabei',
'zapei',
'zamei',
'zafei',
'zadei',
'zatei',
'zanei',
'zalei',
'zagei',
'zakei',
'zahei',
'zazei',
'zasei',
'zabao',
'zapao',
'zamao',
'zadao',
'zatao',
'zanao',
'zalao',
'zagao',
'zakao',
'zahao',
'zarao',
'zazao',
'zacao',
'zasao',
'zapou',
'zamou',
'zafou',
'zadou',
'zatou',
'zanou',
'zalou',
'zagou',
'zakou',
'zahou',
'zarou',
'zazou',
'zacou',
'zasou',
'zaban',
'zapan',
'zaman',
'zafan',
'zadan',
'zatan',
'zanan',
'zalan',
'zagan',
'zakan',
'zahan',
'zaran',
'zazan',
'zacan',
'zasan',
'zaben',
'zapen',
'zamen',
'zafen',
'zaden',
'zanen',
'zagen',
'zaken',
'zahen',
'zaren',
'zazen',
'zacen',
'zasen',
'zaang',
'zaeng',
'zadia',
'zania',
'zalia',
'zajia',
'zaqia',
'zaxia',
'zabie',
'zapie',
'zamie',
'zadie',
'zatie',
'zanie',
'zalie',
'zajie',
'zaqie',
'zaxie',
'zayai',
'zayao',
'zayou',
'zamiu',
'zadiu',
'zaniu',
'zaliu',
'zajiu',
'zaqiu',
'zaxiu',
'zayan',
'zayin',
'zabin',
'zapin',
'zamin',
'zadin',
'zanin',
'zalin',
'zajin',
'zaqin',
'zaxin',
'zazhu',
'zachu',
'zashu',
'zagua',
'zakua',
'zahua',
'zarua',
'zaduo',
'zatuo',
'zanuo',
'zaluo',
'zaguo',
'zakuo',
'zahuo',
'zaruo',
'zazuo',
'zacuo',
'zasuo',
'zawai',
'zawei',
'zadui',
'zatui',
'zagui',
'zakui',
'zahui',
'zarui',
'zazui',
'zacui',
'zasui',
'zawan',
'zawen',
'zadun',
'zatun',
'zanun',
'zalun',
'zagun',
'zakun',
'zahun',
'zarun',
'zazun',
'zacun',
'zasun',
'zayue',
'zanüe',
'zalüe',
'zajue',
'zaque',
'zaxue',
'zayun',
'zalün',
'zajun',
'zaqun',
'zaxun',
'cazhi',
'cachi',
'cashi',
'cazha',
'cacha',
'casha',
'cazhe',
'cache',
'cashe',
'cabai',
'capai',
'camai',
'cadai',
'catai',
'canai',
'calai',
'cagai',
'cakai',
'cahai',
'cazai',
'cacai',
'casai',
'cabei',
'capei',
'camei',
'cafei',
'cadei',
'catei',
'canei',
'calei',
'cagei',
'cakei',
'cahei',
'cazei',
'casei',
'cabao',
'capao',
'camao',
'cadao',
'catao',
'canao',
'calao',
'cagao',
'cakao',
'cahao',
'carao',
'cazao',
'cacao',
'casao',
'capou',
'camou',
'cafou',
'cadou',
'catou',
'canou',
'calou',
'cagou',
'cakou',
'cahou',
'carou',
'cazou',
'cacou',
'casou',
'caban',
'capan',
'caman',
'cafan',
'cadan',
'catan',
'canan',
'calan',
'cagan',
'cakan',
'cahan',
'caran',
'cazan',
'cacan',
'casan',
'caben',
'capen',
'camen',
'cafen',
'caden',
'canen',
'cagen',
'caken',
'cahen',
'caren',
'cazen',
'cacen',
'casen',
'caang',
'caeng',
'cadia',
'cania',
'calia',
'cajia',
'caqia',
'caxia',
'cabie',
'capie',
'camie',
'cadie',
'catie',
'canie',
'calie',
'cajie',
'caqie',
'caxie',
'cayai',
'cayao',
'cayou',
'camiu',
'cadiu',
'caniu',
'caliu',
'cajiu',
'caqiu',
'caxiu',
'cayan',
'cayin',
'cabin',
'capin',
'camin',
'cadin',
'canin',
'calin',
'cajin',
'caqin',
'caxin',
'cazhu',
'cachu',
'cashu',
'cagua',
'cakua',
'cahua',
'carua',
'caduo',
'catuo',
'canuo',
'caluo',
'caguo',
'cakuo',
'cahuo',
'caruo',
'cazuo',
'cacuo',
'casuo',
'cawai',
'cawei',
'cadui',
'catui',
'cagui',
'cakui',
'cahui',
'carui',
'cazui',
'cacui',
'casui',
'cawan',
'cawen',
'cadun',
'catun',
'canun',
'calun',
'cagun',
'cakun',
'cahun',
'carun',
'cazun',
'cacun',
'casun',
'cayue',
'canüe',
'calüe',
'cajue',
'caque',
'caxue',
'cayun',
'calün',
'cajun',
'caqun',
'caxun',
'sazhi',
'sachi',
'sashi',
'sazha',
'sacha',
'sasha',
'sazhe',
'sache',
'sashe',
'sabai',
'sapai',
'samai',
'sadai',
'satai',
'sanai',
'salai',
'sagai',
'sakai',
'sahai',
'sazai',
'sacai',
'sasai',
'sabei',
'sapei',
'samei',
'safei',
'sadei',
'satei',
'sanei',
'salei',
'sagei',
'sakei',
'sahei',
'sazei',
'sasei',
'sabao',
'sapao',
'samao',
'sadao',
'satao',
'sanao',
'salao',
'sagao',
'sakao',
'sahao',
'sarao',
'sazao',
'sacao',
'sasao',
'sapou',
'samou',
'safou',
'sadou',
'satou',
'sanou',
'salou',
'sagou',
'sakou',
'sahou',
'sarou',
'sazou',
'sacou',
'sasou',
'saban',
'sapan',
'saman',
'safan',
'sadan',
'satan',
'sanan',
'salan',
'sagan',
'sakan',
'sahan',
'saran',
'sazan',
'sacan',
'sasan',
'saben',
'sapen',
'samen',
'safen',
'saden',
'sanen',
'sagen',
'saken',
'sahen',
'saren',
'sazen',
'sacen',
'sasen',
'saang',
'saeng',
'sadia',
'sania',
'salia',
'sajia',
'saqia',
'saxia',
'sabie',
'sapie',
'samie',
'sadie',
'satie',
'sanie',
'salie',
'sajie',
'saqie',
'saxie',
'sayai',
'sayao',
'sayou',
'samiu',
'sadiu',
'saniu',
'saliu',
'sajiu',
'saqiu',
'saxiu',
'sayan',
'sayin',
'sabin',
'sapin',
'samin',
'sadin',
'sanin',
'salin',
'sajin',
'saqin',
'saxin',
'sazhu',
'sachu',
'sashu',
'sagua',
'sakua',
'sahua',
'sarua',
'saduo',
'satuo',
'sanuo',
'saluo',
'saguo',
'sakuo',
'sahuo',
'saruo',
'sazuo',
'sacuo',
'sasuo',
'sawai',
'sawei',
'sadui',
'satui',
'sagui',
'sakui',
'sahui',
'sarui',
'sazui',
'sacui',
'sasui',
'sawan',
'sawen',
'sadun',
'satun',
'sanun',
'salun',
'sagun',
'sakun',
'sahun',
'sarun',
'sazun',
'sacun',
'sasun',
'sayue',
'sanüe',
'salüe',
'sajue',
'saque',
'saxue',
'sayun',
'salün',
'sajun',
'saqun',
'saxun',
'bozhi',
'bochi',
'boshi',
'bozha',
'bocha',
'bosha',
'bozhe',
'boche',
'boshe',
'bobai',
'bopai',
'bomai',
'bodai',
'botai',
'bonai',
'bolai',
'bogai',
'bokai',
'bohai',
'bozai',
'bocai',
'bosai',
'bobei',
'bopei',
'bomei',
'bofei',
'bodei',
'botei',
'bonei',
'bolei',
'bogei',
'bokei',
'bohei',
'bozei',
'bosei',
'bobao',
'bopao',
'bomao',
'bodao',
'botao',
'bonao',
'bolao',
'bogao',
'bokao',
'bohao',
'borao',
'bozao',
'bocao',
'bosao',
'bopou',
'bomou',
'bofou',
'bodou',
'botou',
'bonou',
'bolou',
'bogou',
'bokou',
'bohou',
'borou',
'bozou',
'bocou',
'bosou',
'boban',
'bopan',
'boman',
'bofan',
'bodan',
'botan',
'bonan',
'bolan',
'bogan',
'bokan',
'bohan',
'boran',
'bozan',
'bocan',
'bosan',
'boben',
'bopen',
'bomen',
'bofen',
'boden',
'bonen',
'bogen',
'boken',
'bohen',
'boren',
'bozen',
'bocen',
'bosen',
'boang',
'boeng',
'bodia',
'bonia',
'bolia',
'bojia',
'boqia',
'boxia',
'bobie',
'bopie',
'bomie',
'bodie',
'botie',
'bonie',
'bolie',
'bojie',
'boqie',
'boxie',
'boyai',
'boyao',
'boyou',
'bomiu',
'bodiu',
'boniu',
'boliu',
'bojiu',
'boqiu',
'boxiu',
'boyan',
'boyin',
'bobin',
'bopin',
'bomin',
'bodin',
'bonin',
'bolin',
'bojin',
'boqin',
'boxin',
'bozhu',
'bochu',
'boshu',
'bogua',
'bokua',
'bohua',
'borua',
'boduo',
'botuo',
'bonuo',
'boluo',
'boguo',
'bokuo',
'bohuo',
'boruo',
'bozuo',
'bocuo',
'bosuo',
'bowai',
'bowei',
'bodui',
'botui',
'bogui',
'bokui',
'bohui',
'borui',
'bozui',
'bocui',
'bosui',
'bowan',
'bowen',
'bodun',
'botun',
'bonun',
'bolun',
'bogun',
'bokun',
'bohun',
'borun',
'bozun',
'bocun',
'bosun',
'boyue',
'bonüe',
'bolüe',
'bojue',
'boque',
'boxue',
'boyun',
'bolün',
'bojun',
'boqun',
'boxun',
'pozhi',
'pochi',
'poshi',
'pozha',
'pocha',
'posha',
'pozhe',
'poche',
'poshe',
'pobai',
'popai',
'pomai',
'podai',
'potai',
'ponai',
'polai',
'pogai',
'pokai',
'pohai',
'pozai',
'pocai',
'posai',
'pobei',
'popei',
'pomei',
'pofei',
'podei',
'potei',
'ponei',
'polei',
'pogei',
'pokei',
'pohei',
'pozei',
'posei',
'pobao',
'popao',
'pomao',
'podao',
'potao',
'ponao',
'polao',
'pogao',
'pokao',
'pohao',
'porao',
'pozao',
'pocao',
'posao',
'popou',
'pomou',
'pofou',
'podou',
'potou',
'ponou',
'polou',
'pogou',
'pokou',
'pohou',
'porou',
'pozou',
'pocou',
'posou',
'poban',
'popan',
'poman',
'pofan',
'podan',
'potan',
'ponan',
'polan',
'pogan',
'pokan',
'pohan',
'poran',
'pozan',
'pocan',
'posan',
'poben',
'popen',
'pomen',
'pofen',
'poden',
'ponen',
'pogen',
'poken',
'pohen',
'poren',
'pozen',
'pocen',
'posen',
'poang',
'poeng',
'podia',
'ponia',
'polia',
'pojia',
'poqia',
'poxia',
'pobie',
'popie',
'pomie',
'podie',
'potie',
'ponie',
'polie',
'pojie',
'poqie',
'poxie',
'poyai',
'poyao',
'poyou',
'pomiu',
'podiu',
'poniu',
'poliu',
'pojiu',
'poqiu',
'poxiu',
'poyan',
'poyin',
'pobin',
'popin',
'pomin',
'podin',
'ponin',
'polin',
'pojin',
'poqin',
'poxin',
'pozhu',
'pochu',
'poshu',
'pogua',
'pokua',
'pohua',
'porua',
'poduo',
'potuo',
'ponuo',
'poluo',
'poguo',
'pokuo',
'pohuo',
'poruo',
'pozuo',
'pocuo',
'posuo',
'powai',
'powei',
'podui',
'potui',
'pogui',
'pokui',
'pohui',
'porui',
'pozui',
'pocui',
'posui',
'powan',
'powen',
'podun',
'potun',
'ponun',
'polun',
'pogun',
'pokun',
'pohun',
'porun',
'pozun',
'pocun',
'posun',
'poyue',
'ponüe',
'polüe',
'pojue',
'poque',
'poxue',
'poyun',
'polün',
'pojun',
'poqun',
'poxun',
'mozhi',
'mochi',
'moshi',
'mozha',
'mocha',
'mosha',
'mozhe',
'moche',
'moshe',
'mobai',
'mopai',
'momai',
'modai',
'motai',
'monai',
'molai',
'mogai',
'mokai',
'mohai',
'mozai',
'mocai',
'mosai',
'mobei',
'mopei',
'momei',
'mofei',
'modei',
'motei',
'monei',
'molei',
'mogei',
'mokei',
'mohei',
'mozei',
'mosei',
'mobao',
'mopao',
'momao',
'modao',
'motao',
'monao',
'molao',
'mogao',
'mokao',
'mohao',
'morao',
'mozao',
'mocao',
'mosao',
'mopou',
'momou',
'mofou',
'modou',
'motou',
'monou',
'molou',
'mogou',
'mokou',
'mohou',
'morou',
'mozou',
'mocou',
'mosou',
'moban',
'mopan',
'moman',
'mofan',
'modan',
'motan',
'monan',
'molan',
'mogan',
'mokan',
'mohan',
'moran',
'mozan',
'mocan',
'mosan',
'moben',
'mopen',
'momen',
'mofen',
'moden',
'monen',
'mogen',
'moken',
'mohen',
'moren',
'mozen',
'mocen',
'mosen',
'moang',
'moeng',
'modia',
'monia',
'molia',
'mojia',
'moqia',
'moxia',
'mobie',
'mopie',
'momie',
'modie',
'motie',
'monie',
'molie',
'mojie',
'moqie',
'moxie',
'moyai',
'moyao',
'moyou',
'momiu',
'modiu',
'moniu',
'moliu',
'mojiu',
'moqiu',
'moxiu',
'moyan',
'moyin',
'mobin',
'mopin',
'momin',
'modin',
'monin',
'molin',
'mojin',
'moqin',
'moxin',
'mozhu',
'mochu',
'moshu',
'mogua',
'mokua',
'mohua',
'morua',
'moduo',
'motuo',
'monuo',
'moluo',
'moguo',
'mokuo',
'mohuo',
'moruo',
'mozuo',
'mocuo',
'mosuo',
'mowai',
'mowei',
'modui',
'motui',
'mogui',
'mokui',
'mohui',
'morui',
'mozui',
'mocui',
'mosui',
'mowan',
'mowen',
'modun',
'motun',
'monun',
'molun',
'mogun',
'mokun',
'mohun',
'morun',
'mozun',
'mocun',
'mosun',
'moyue',
'monüe',
'molüe',
'mojue',
'moque',
'moxue',
'moyun',
'molün',
'mojun',
'moqun',
'moxun',
'fozhi',
'fochi',
'foshi',
'fozha',
'focha',
'fosha',
'fozhe',
'foche',
'foshe',
'fobai',
'fopai',
'fomai',
'fodai',
'fotai',
'fonai',
'folai',
'fogai',
'fokai',
'fohai',
'fozai',
'focai',
'fosai',
'fobei',
'fopei',
'fomei',
'fofei',
'fodei',
'fotei',
'fonei',
'folei',
'fogei',
'fokei',
'fohei',
'fozei',
'fosei',
'fobao',
'fopao',
'fomao',
'fodao',
'fotao',
'fonao',
'folao',
'fogao',
'fokao',
'fohao',
'forao',
'fozao',
'focao',
'fosao',
'fopou',
'fomou',
'fofou',
'fodou',
'fotou',
'fonou',
'folou',
'fogou',
'fokou',
'fohou',
'forou',
'fozou',
'focou',
'fosou',
'foban',
'fopan',
'foman',
'fofan',
'fodan',
'fotan',
'fonan',
'folan',
'fogan',
'fokan',
'fohan',
'foran',
'fozan',
'focan',
'fosan',
'foben',
'fopen',
'fomen',
'fofen',
'foden',
'fonen',
'fogen',
'foken',
'fohen',
'foren',
'fozen',
'focen',
'fosen',
'foang',
'foeng',
'fodia',
'fonia',
'folia',
'fojia',
'foqia',
'foxia',
'fobie',
'fopie',
'fomie',
'fodie',
'fotie',
'fonie',
'folie',
'fojie',
'foqie',
'foxie',
'foyai',
'foyao',
'foyou',
'fomiu',
'fodiu',
'foniu',
'foliu',
'fojiu',
'foqiu',
'foxiu',
'foyan',
'foyin',
'fobin',
'fopin',
'fomin',
'fodin',
'fonin',
'folin',
'fojin',
'foqin',
'foxin',
'fozhu',
'fochu',
'foshu',
'fogua',
'fokua',
'fohua',
'forua',
'foduo',
'fotuo',
'fonuo',
'foluo',
'foguo',
'fokuo',
'fohuo',
'foruo',
'fozuo',
'focuo',
'fosuo',
'fowai',
'fowei',
'fodui',
'fotui',
'fogui',
'fokui',
'fohui',
'forui',
'fozui',
'focui',
'fosui',
'fowan',
'fowen',
'fodun',
'fotun',
'fonun',
'folun',
'fogun',
'fokun',
'fohun',
'forun',
'fozun',
'focun',
'fosun',
'foyue',
'fonüe',
'folüe',
'fojue',
'foque',
'foxue',
'foyun',
'folün',
'fojun',
'foqun',
'foxun',
'lozhi',
'lochi',
'loshi',
'lozha',
'locha',
'losha',
'lozhe',
'loche',
'loshe',
'lobai',
'lopai',
'lomai',
'lodai',
'lotai',
'lonai',
'lolai',
'logai',
'lokai',
'lohai',
'lozai',
'locai',
'losai',
'lobei',
'lopei',
'lomei',
'lofei',
'lodei',
'lotei',
'lonei',
'lolei',
'logei',
'lokei',
'lohei',
'lozei',
'losei',
'lobao',
'lopao',
'lomao',
'lodao',
'lotao',
'lonao',
'lolao',
'logao',
'lokao',
'lohao',
'lorao',
'lozao',
'locao',
'losao',
'lopou',
'lomou',
'lofou',
'lodou',
'lotou',
'lonou',
'lolou',
'logou',
'lokou',
'lohou',
'lorou',
'lozou',
'locou',
'losou',
'loban',
'lopan',
'loman',
'lofan',
'lodan',
'lotan',
'lonan',
'lolan',
'logan',
'lokan',
'lohan',
'loran',
'lozan',
'locan',
'losan',
'loben',
'lopen',
'lomen',
'lofen',
'loden',
'lonen',
'logen',
'loken',
'lohen',
'loren',
'lozen',
'locen',
'losen',
'loang',
'loeng',
'lodia',
'lonia',
'lolia',
'lojia',
'loqia',
'loxia',
'lobie',
'lopie',
'lomie',
'lodie',
'lotie',
'lonie',
'lolie',
'lojie',
'loqie',
'loxie',
'loyai',
'loyao',
'loyou',
'lomiu',
'lodiu',
'loniu',
'loliu',
'lojiu',
'loqiu',
'loxiu',
'loyan',
'loyin',
'lobin',
'lopin',
'lomin',
'lodin',
'lonin',
'lolin',
'lojin',
'loqin',
'loxin',
'lozhu',
'lochu',
'loshu',
'logua',
'lokua',
'lohua',
'lorua',
'loduo',
'lotuo',
'lonuo',
'loluo',
'loguo',
'lokuo',
'lohuo',
'loruo',
'lozuo',
'locuo',
'losuo',
'lowai',
'lowei',
'lodui',
'lotui',
'logui',
'lokui',
'lohui',
'lorui',
'lozui',
'locui',
'losui',
'lowan',
'lowen',
'lodun',
'lotun',
'lonun',
'lolun',
'logun',
'lokun',
'lohun',
'lorun',
'lozun',
'locun',
'losun',
'loyue',
'lonüe',
'lolüe',
'lojue',
'loque',
'loxue',
'loyun',
'lolün',
'lojun',
'loqun',
'loxun',
'mezhi',
'mechi',
'meshi',
'mezha',
'mecha',
'mesha',
'mezhe',
'meche',
'meshe',
'mebai',
'mepai',
'memai',
'medai',
'metai',
'menai',
'melai',
'megai',
'mekai',
'mehai',
'mezai',
'mecai',
'mesai',
'mebei',
'mepei',
'memei',
'mefei',
'medei',
'metei',
'menei',
'melei',
'megei',
'mekei',
'mehei',
'mezei',
'mesei',
'mebao',
'mepao',
'memao',
'medao',
'metao',
'menao',
'melao',
'megao',
'mekao',
'mehao',
'merao',
'mezao',
'mecao',
'mesao',
'mepou',
'memou',
'mefou',
'medou',
'metou',
'menou',
'melou',
'megou',
'mekou',
'mehou',
'merou',
'mezou',
'mecou',
'mesou',
'meban',
'mepan',
'meman',
'mefan',
'medan',
'metan',
'menan',
'melan',
'megan',
'mekan',
'mehan',
'meran',
'mezan',
'mecan',
'mesan',
'meben',
'mepen',
'memen',
'mefen',
'meden',
'menen',
'megen',
'meken',
'mehen',
'meren',
'mezen',
'mecen',
'mesen',
'meang',
'meeng',
'media',
'menia',
'melia',
'mejia',
'meqia',
'mexia',
'mebie',
'mepie',
'memie',
'medie',
'metie',
'menie',
'melie',
'mejie',
'meqie',
'mexie',
'meyai',
'meyao',
'meyou',
'memiu',
'mediu',
'meniu',
'meliu',
'mejiu',
'meqiu',
'mexiu',
'meyan',
'meyin',
'mebin',
'mepin',
'memin',
'medin',
'menin',
'melin',
'mejin',
'meqin',
'mexin',
'mezhu',
'mechu',
'meshu',
'megua',
'mekua',
'mehua',
'merua',
'meduo',
'metuo',
'menuo',
'meluo',
'meguo',
'mekuo',
'mehuo',
'meruo',
'mezuo',
'mecuo',
'mesuo',
'mewai',
'mewei',
'medui',
'metui',
'megui',
'mekui',
'mehui',
'merui',
'mezui',
'mecui',
'mesui',
'mewan',
'mewen',
'medun',
'metun',
'menun',
'melun',
'megun',
'mekun',
'mehun',
'merun',
'mezun',
'mecun',
'mesun',
'meyue',
'menüe',
'melüe',
'mejue',
'meque',
'mexue',
'meyun',
'melün',
'mejun',
'mequn',
'mexun',
'dezhi',
'dechi',
'deshi',
'dezha',
'decha',
'desha',
'dezhe',
'deche',
'deshe',
'debai',
'depai',
'demai',
'dedai',
'detai',
'denai',
'delai',
'degai',
'dekai',
'dehai',
'dezai',
'decai',
'desai',
'debei',
'depei',
'demei',
'defei',
'dedei',
'detei',
'denei',
'delei',
'degei',
'dekei',
'dehei',
'dezei',
'desei',
'debao',
'depao',
'demao',
'dedao',
'detao',
'denao',
'delao',
'degao',
'dekao',
'dehao',
'derao',
'dezao',
'decao',
'desao',
'depou',
'demou',
'defou',
'dedou',
'detou',
'denou',
'delou',
'degou',
'dekou',
'dehou',
'derou',
'dezou',
'decou',
'desou',
'deban',
'depan',
'deman',
'defan',
'dedan',
'detan',
'denan',
'delan',
'degan',
'dekan',
'dehan',
'deran',
'dezan',
'decan',
'desan',
'deben',
'depen',
'demen',
'defen',
'deden',
'denen',
'degen',
'deken',
'dehen',
'deren',
'dezen',
'decen',
'desen',
'deang',
'deeng',
'dedia',
'denia',
'delia',
'dejia',
'deqia',
'dexia',
'debie',
'depie',
'demie',
'dedie',
'detie',
'denie',
'delie',
'dejie',
'deqie',
'dexie',
'deyai',
'deyao',
'deyou',
'demiu',
'dediu',
'deniu',
'deliu',
'dejiu',
'deqiu',
'dexiu',
'deyan',
'deyin',
'debin',
'depin',
'demin',
'dedin',
'denin',
'delin',
'dejin',
'deqin',
'dexin',
'dezhu',
'dechu',
'deshu',
'degua',
'dekua',
'dehua',
'derua',
'deduo',
'detuo',
'denuo',
'deluo',
'deguo',
'dekuo',
'dehuo',
'deruo',
'dezuo',
'decuo',
'desuo',
'dewai',
'dewei',
'dedui',
'detui',
'degui',
'dekui',
'dehui',
'derui',
'dezui',
'decui',
'desui',
'dewan',
'dewen',
'dedun',
'detun',
'denun',
'delun',
'degun',
'dekun',
'dehun',
'derun',
'dezun',
'decun',
'desun',
'deyue',
'denüe',
'delüe',
'dejue',
'deque',
'dexue',
'deyun',
'delün',
'dejun',
'dequn',
'dexun',
'tezhi',
'techi',
'teshi',
'tezha',
'techa',
'tesha',
'tezhe',
'teche',
'teshe',
'tebai',
'tepai',
'temai',
'tedai',
'tetai',
'tenai',
'telai',
'tegai',
'tekai',
'tehai',
'tezai',
'tecai',
'tesai',
'tebei',
'tepei',
'temei',
'tefei',
'tedei',
'tetei',
'tenei',
'telei',
'tegei',
'tekei',
'tehei',
'tezei',
'tesei',
'tebao',
'tepao',
'temao',
'tedao',
'tetao',
'tenao',
'telao',
'tegao',
'tekao',
'tehao',
'terao',
'tezao',
'tecao',
'tesao',
'tepou',
'temou',
'tefou',
'tedou',
'tetou',
'tenou',
'telou',
'tegou',
'tekou',
'tehou',
'terou',
'tezou',
'tecou',
'tesou',
'teban',
'tepan',
'teman',
'tefan',
'tedan',
'tetan',
'tenan',
'telan',
'tegan',
'tekan',
'tehan',
'teran',
'tezan',
'tecan',
'tesan',
'teben',
'tepen',
'temen',
'tefen',
'teden',
'tenen',
'tegen',
'teken',
'tehen',
'teren',
'tezen',
'tecen',
'tesen',
'teang',
'teeng',
'tedia',
'tenia',
'telia',
'tejia',
'teqia',
'texia',
'tebie',
'tepie',
'temie',
'tedie',
'tetie',
'tenie',
'telie',
'tejie',
'teqie',
'texie',
'teyai',
'teyao',
'teyou',
'temiu',
'tediu',
'teniu',
'teliu',
'tejiu',
'teqiu',
'texiu',
'teyan',
'teyin',
'tebin',
'tepin',
'temin',
'tedin',
'tenin',
'telin',
'tejin',
'teqin',
'texin',
'tezhu',
'techu',
'teshu',
'tegua',
'tekua',
'tehua',
'terua',
'teduo',
'tetuo',
'tenuo',
'teluo',
'teguo',
'tekuo',
'tehuo',
'teruo',
'tezuo',
'tecuo',
'tesuo',
'tewai',
'tewei',
'tedui',
'tetui',
'tegui',
'tekui',
'tehui',
'terui',
'tezui',
'tecui',
'tesui',
'tewan',
'tewen',
'tedun',
'tetun',
'tenun',
'telun',
'tegun',
'tekun',
'tehun',
'terun',
'tezun',
'tecun',
'tesun',
'teyue',
'tenüe',
'telüe',
'tejue',
'teque',
'texue',
'teyun',
'telün',
'tejun',
'tequn',
'texun',
'nezhi',
'nechi',
'neshi',
'nezha',
'necha',
'nesha',
'nezhe',
'neche',
'neshe',
'nebai',
'nepai',
'nemai',
'nedai',
'netai',
'nenai',
'nelai',
'negai',
'nekai',
'nehai',
'nezai',
'necai',
'nesai',
'nebei',
'nepei',
'nemei',
'nefei',
'nedei',
'netei',
'nenei',
'nelei',
'negei',
'nekei',
'nehei',
'nezei',
'nesei',
'nebao',
'nepao',
'nemao',
'nedao',
'netao',
'nenao',
'nelao',
'negao',
'nekao',
'nehao',
'nerao',
'nezao',
'necao',
'nesao',
'nepou',
'nemou',
'nefou',
'nedou',
'netou',
'nenou',
'nelou',
'negou',
'nekou',
'nehou',
'nerou',
'nezou',
'necou',
'nesou',
'neban',
'nepan',
'neman',
'nefan',
'nedan',
'netan',
'nenan',
'nelan',
'negan',
'nekan',
'nehan',
'neran',
'nezan',
'necan',
'nesan',
'neben',
'nepen',
'nemen',
'nefen',
'neden',
'nenen',
'negen',
'neken',
'nehen',
'neren',
'nezen',
'necen',
'nesen',
'neang',
'neeng',
'nedia',
'nenia',
'nelia',
'nejia',
'neqia',
'nexia',
'nebie',
'nepie',
'nemie',
'nedie',
'netie',
'nenie',
'nelie',
'nejie',
'neqie',
'nexie',
'neyai',
'neyao',
'neyou',
'nemiu',
'nediu',
'neniu',
'neliu',
'nejiu',
'neqiu',
'nexiu',
'neyan',
'neyin',
'nebin',
'nepin',
'nemin',
'nedin',
'nenin',
'nelin',
'nejin',
'neqin',
'nexin',
'nezhu',
'nechu',
'neshu',
'negua',
'nekua',
'nehua',
'nerua',
'neduo',
'netuo',
'nenuo',
'neluo',
'neguo',
'nekuo',
'nehuo',
'neruo',
'nezuo',
'necuo',
'nesuo',
'newai',
'newei',
'nedui',
'netui',
'negui',
'nekui',
'nehui',
'nerui',
'nezui',
'necui',
'nesui',
'newan',
'newen',
'nedun',
'netun',
'nenun',
'nelun',
'negun',
'nekun',
'nehun',
'nerun',
'nezun',
'necun',
'nesun',
'neyue',
'nenüe',
'nelüe',
'nejue',
'neque',
'nexue',
'neyun',
'nelün',
'nejun',
'nequn',
'nexun',
'lezhi',
'lechi',
'leshi',
'lezha',
'lecha',
'lesha',
'lezhe',
'leche',
'leshe',
'lebai',
'lepai',
'lemai',
'ledai',
'letai',
'lenai',
'lelai',
'legai',
'lekai',
'lehai',
'lezai',
'lecai',
'lesai',
'lebei',
'lepei',
'lemei',
'lefei',
'ledei',
'letei',
'lenei',
'lelei',
'legei',
'lekei',
'lehei',
'lezei',
'lesei',
'lebao',
'lepao',
'lemao',
'ledao',
'letao',
'lenao',
'lelao',
'legao',
'lekao',
'lehao',
'lerao',
'lezao',
'lecao',
'lesao',
'lepou',
'lemou',
'lefou',
'ledou',
'letou',
'lenou',
'lelou',
'legou',
'lekou',
'lehou',
'lerou',
'lezou',
'lecou',
'lesou',
'leban',
'lepan',
'leman',
'lefan',
'ledan',
'letan',
'lenan',
'lelan',
'legan',
'lekan',
'lehan',
'leran',
'lezan',
'lecan',
'lesan',
'leben',
'lepen',
'lemen',
'lefen',
'leden',
'lenen',
'legen',
'leken',
'lehen',
'leren',
'lezen',
'lecen',
'lesen',
'leang',
'leeng',
'ledia',
'lenia',
'lelia',
'lejia',
'leqia',
'lexia',
'lebie',
'lepie',
'lemie',
'ledie',
'letie',
'lenie',
'lelie',
'lejie',
'leqie',
'lexie',
'leyai',
'leyao',
'leyou',
'lemiu',
'lediu',
'leniu',
'leliu',
'lejiu',
'leqiu',
'lexiu',
'leyan',
'leyin',
'lebin',
'lepin',
'lemin',
'ledin',
'lenin',
'lelin',
'lejin',
'leqin',
'lexin',
'lezhu',
'lechu',
'leshu',
'legua',
'lekua',
'lehua',
'lerua',
'leduo',
'letuo',
'lenuo',
'leluo',
'leguo',
'lekuo',
'lehuo',
'leruo',
'lezuo',
'lecuo',
'lesuo',
'lewai',
'lewei',
'ledui',
'letui',
'legui',
'lekui',
'lehui',
'lerui',
'lezui',
'lecui',
'lesui',
'lewan',
'lewen',
'ledun',
'letun',
'lenun',
'lelun',
'legun',
'lekun',
'lehun',
'lerun',
'lezun',
'lecun',
'lesun',
'leyue',
'lenüe',
'lelüe',
'lejue',
'leque',
'lexue',
'leyun',
'lelün',
'lejun',
'lequn',
'lexun',
'gezhi',
'gechi',
'geshi',
'gezha',
'gecha',
'gesha',
'gezhe',
'geche',
'geshe',
'gebai',
'gepai',
'gemai',
'gedai',
'getai',
'genai',
'gelai',
'gegai',
'gekai',
'gehai',
'gezai',
'gecai',
'gesai',
'gebei',
'gepei',
'gemei',
'gefei',
'gedei',
'getei',
'genei',
'gelei',
'gegei',
'gekei',
'gehei',
'gezei',
'gesei',
'gebao',
'gepao',
'gemao',
'gedao',
'getao',
'genao',
'gelao',
'gegao',
'gekao',
'gehao',
'gerao',
'gezao',
'gecao',
'gesao',
'gepou',
'gemou',
'gefou',
'gedou',
'getou',
'genou',
'gelou',
'gegou',
'gekou',
'gehou',
'gerou',
'gezou',
'gecou',
'gesou',
'geban',
'gepan',
'geman',
'gefan',
'gedan',
'getan',
'genan',
'gelan',
'gegan',
'gekan',
'gehan',
'geran',
'gezan',
'gecan',
'gesan',
'geben',
'gepen',
'gemen',
'gefen',
'geden',
'genen',
'gegen',
'geken',
'gehen',
'geren',
'gezen',
'gecen',
'gesen',
'geang',
'geeng',
'gedia',
'genia',
'gelia',
'gejia',
'geqia',
'gexia',
'gebie',
'gepie',
'gemie',
'gedie',
'getie',
'genie',
'gelie',
'gejie',
'geqie',
'gexie',
'geyai',
'geyao',
'geyou',
'gemiu',
'gediu',
'geniu',
'geliu',
'gejiu',
'geqiu',
'gexiu',
'geyan',
'geyin',
'gebin',
'gepin',
'gemin',
'gedin',
'genin',
'gelin',
'gejin',
'geqin',
'gexin',
'gezhu',
'gechu',
'geshu',
'gegua',
'gekua',
'gehua',
'gerua',
'geduo',
'getuo',
'genuo',
'geluo',
'geguo',
'gekuo',
'gehuo',
'geruo',
'gezuo',
'gecuo',
'gesuo',
'gewai',
'gewei',
'gedui',
'getui',
'gegui',
'gekui',
'gehui',
'gerui',
'gezui',
'gecui',
'gesui',
'gewan',
'gewen',
'gedun',
'getun',
'genun',
'gelun',
'gegun',
'gekun',
'gehun',
'gerun',
'gezun',
'gecun',
'gesun',
'geyue',
'genüe',
'gelüe',
'gejue',
'geque',
'gexue',
'geyun',
'gelün',
'gejun',
'gequn',
'gexun',
'kezhi',
'kechi',
'keshi',
'kezha',
'kecha',
'kesha',
'kezhe',
'keche',
'keshe',
'kebai',
'kepai',
'kemai',
'kedai',
'ketai',
'kenai',
'kelai',
'kegai',
'kekai',
'kehai',
'kezai',
'kecai',
'kesai',
'kebei',
'kepei',
'kemei',
'kefei',
'kedei',
'ketei',
'kenei',
'kelei',
'kegei',
'kekei',
'kehei',
'kezei',
'kesei',
'kebao',
'kepao',
'kemao',
'kedao',
'ketao',
'kenao',
'kelao',
'kegao',
'kekao',
'kehao',
'kerao',
'kezao',
'kecao',
'kesao',
'kepou',
'kemou',
'kefou',
'kedou',
'ketou',
'kenou',
'kelou',
'kegou',
'kekou',
'kehou',
'kerou',
'kezou',
'kecou',
'kesou',
'keban',
'kepan',
'keman',
'kefan',
'kedan',
'ketan',
'kenan',
'kelan',
'kegan',
'kekan',
'kehan',
'keran',
'kezan',
'kecan',
'kesan',
'keben',
'kepen',
'kemen',
'kefen',
'keden',
'kenen',
'kegen',
'keken',
'kehen',
'keren',
'kezen',
'kecen',
'kesen',
'keang',
'keeng',
'kedia',
'kenia',
'kelia',
'kejia',
'keqia',
'kexia',
'kebie',
'kepie',
'kemie',
'kedie',
'ketie',
'kenie',
'kelie',
'kejie',
'keqie',
'kexie',
'keyai',
'keyao',
'keyou',
'kemiu',
'kediu',
'keniu',
'keliu',
'kejiu',
'keqiu',
'kexiu',
'keyan',
'keyin',
'kebin',
'kepin',
'kemin',
'kedin',
'kenin',
'kelin',
'kejin',
'keqin',
'kexin',
'kezhu',
'kechu',
'keshu',
'kegua',
'kekua',
'kehua',
'kerua',
'keduo',
'ketuo',
'kenuo',
'keluo',
'keguo',
'kekuo',
'kehuo',
'keruo',
'kezuo',
'kecuo',
'kesuo',
'kewai',
'kewei',
'kedui',
'ketui',
'kegui',
'kekui',
'kehui',
'kerui',
'kezui',
'kecui',
'kesui',
'kewan',
'kewen',
'kedun',
'ketun',
'kenun',
'kelun',
'kegun',
'kekun',
'kehun',
'kerun',
'kezun',
'kecun',
'kesun',
'keyue',
'kenüe',
'kelüe',
'kejue',
'keque',
'kexue',
'keyun',
'kelün',
'kejun',
'kequn',
'kexun',
'hezhi',
'hechi',
'heshi',
'hezha',
'hecha',
'hesha',
'hezhe',
'heche',
'heshe',
'hebai',
'hepai',
'hemai',
'hedai',
'hetai',
'henai',
'helai',
'hegai',
'hekai',
'hehai',
'hezai',
'hecai',
'hesai',
'hebei',
'hepei',
'hemei',
'hefei',
'hedei',
'hetei',
'henei',
'helei',
'hegei',
'hekei',
'hehei',
'hezei',
'hesei',
'hebao',
'hepao',
'hemao',
'hedao',
'hetao',
'henao',
'helao',
'hegao',
'hekao',
'hehao',
'herao',
'hezao',
'hecao',
'hesao',
'hepou',
'hemou',
'hefou',
'hedou',
'hetou',
'henou',
'helou',
'hegou',
'hekou',
'hehou',
'herou',
'hezou',
'hecou',
'hesou',
'heban',
'hepan',
'heman',
'hefan',
'hedan',
'hetan',
'henan',
'helan',
'hegan',
'hekan',
'hehan',
'heran',
'hezan',
'hecan',
'hesan',
'heben',
'hepen',
'hemen',
'hefen',
'heden',
'henen',
'hegen',
'heken',
'hehen',
'heren',
'hezen',
'hecen',
'hesen',
'heang',
'heeng',
'hedia',
'henia',
'helia',
'hejia',
'heqia',
'hexia',
'hebie',
'hepie',
'hemie',
'hedie',
'hetie',
'henie',
'helie',
'hejie',
'heqie',
'hexie',
'heyai',
'heyao',
'heyou',
'hemiu',
'hediu',
'heniu',
'heliu',
'hejiu',
'heqiu',
'hexiu',
'heyan',
'heyin',
'hebin',
'hepin',
'hemin',
'hedin',
'henin',
'helin',
'hejin',
'heqin',
'hexin',
'hezhu',
'hechu',
'heshu',
'hegua',
'hekua',
'hehua',
'herua',
'heduo',
'hetuo',
'henuo',
'heluo',
'heguo',
'hekuo',
'hehuo',
'heruo',
'hezuo',
'hecuo',
'hesuo',
'hewai',
'hewei',
'hedui',
'hetui',
'hegui',
'hekui',
'hehui',
'herui',
'hezui',
'hecui',
'hesui',
'hewan',
'hewen',
'hedun',
'hetun',
'henun',
'helun',
'hegun',
'hekun',
'hehun',
'herun',
'hezun',
'hecun',
'hesun',
'heyue',
'henüe',
'helüe',
'hejue',
'heque',
'hexue',
'heyun',
'helün',
'hejun',
'hequn',
'hexun',
'zheri',
'zhezi',
'zheci',
'zhesi',
'zheba',
'zhepa',
'zhema',
'zhefa',
'zheda',
'zheta',
'zhena',
'zhela',
'zhega',
'zheka',
'zheha',
'zheza',
'zheca',
'zhesa',
'zhebo',
'zhepo',
'zhemo',
'zhefo',
'zhelo',
'zheme',
'zhede',
'zhete',
'zhene',
'zhele',
'zhege',
'zheke',
'zhehe',
'zhere',
'zheze',
'zhece',
'zhese',
'zheai',
'zheei',
'zheao',
'zheou',
'zhean',
'zheen',
'zheer',
'zheyi',
'zhebi',
'zhepi',
'zhemi',
'zhedi',
'zheti',
'zheni',
'zheli',
'zheji',
'zheqi',
'zhexi',
'zheya',
'zheyo',
'zheye',
'zhewu',
'zhebu',
'zhepu',
'zhemu',
'zhefu',
'zhedu',
'zhetu',
'zhenu',
'zhelu',
'zhegu',
'zheku',
'zhehu',
'zheru',
'zhezu',
'zhecu',
'zhesu',
'zhewa',
'zhewo',
'zheyu',
'zhenü',
'zhelü',
'zheju',
'zhequ',
'zhexu',
'cheri',
'chezi',
'checi',
'chesi',
'cheba',
'chepa',
'chema',
'chefa',
'cheda',
'cheta',
'chena',
'chela',
'chega',
'cheka',
'cheha',
'cheza',
'checa',
'chesa',
'chebo',
'chepo',
'chemo',
'chefo',
'chelo',
'cheme',
'chede',
'chete',
'chene',
'chele',
'chege',
'cheke',
'chehe',
'chere',
'cheze',
'chece',
'chese',
'cheai',
'cheei',
'cheao',
'cheou',
'chean',
'cheen',
'cheer',
'cheyi',
'chebi',
'chepi',
'chemi',
'chedi',
'cheti',
'cheni',
'cheli',
'cheji',
'cheqi',
'chexi',
'cheya',
'cheyo',
'cheye',
'chewu',
'chebu',
'chepu',
'chemu',
'chefu',
'chedu',
'chetu',
'chenu',
'chelu',
'chegu',
'cheku',
'chehu',
'cheru',
'chezu',
'checu',
'chesu',
'chewa',
'chewo',
'cheyu',
'chenü',
'chelü',
'cheju',
'chequ',
'chexu',
'sheri',
'shezi',
'sheci',
'shesi',
'sheba',
'shepa',
'shema',
'shefa',
'sheda',
'sheta',
'shena',
'shela',
'shega',
'sheka',
'sheha',
'sheza',
'sheca',
'shesa',
'shebo',
'shepo',
'shemo',
'shefo',
'shelo',
'sheme',
'shede',
'shete',
'shene',
'shele',
'shege',
'sheke',
'shehe',
'shere',
'sheze',
'shece',
'shese',
'sheai',
'sheei',
'sheao',
'sheou',
'shean',
'sheen',
'sheer',
'sheyi',
'shebi',
'shepi',
'shemi',
'shedi',
'sheti',
'sheni',
'sheli',
'sheji',
'sheqi',
'shexi',
'sheya',
'sheyo',
'sheye',
'shewu',
'shebu',
'shepu',
'shemu',
'shefu',
'shedu',
'shetu',
'shenu',
'shelu',
'shegu',
'sheku',
'shehu',
'sheru',
'shezu',
'shecu',
'shesu',
'shewa',
'shewo',
'sheyu',
'shenü',
'shelü',
'sheju',
'shequ',
'shexu',
'rezhi',
'rechi',
'reshi',
'rezha',
'recha',
'resha',
'rezhe',
'reche',
'reshe',
'rebai',
'repai',
'remai',
'redai',
'retai',
'renai',
'relai',
'regai',
'rekai',
'rehai',
'rezai',
'recai',
'resai',
'rebei',
'repei',
'remei',
'refei',
'redei',
'retei',
'renei',
'relei',
'regei',
'rekei',
'rehei',
'rezei',
'resei',
'rebao',
'repao',
'remao',
'redao',
'retao',
'renao',
'relao',
'regao',
'rekao',
'rehao',
'rerao',
'rezao',
'recao',
'resao',
'repou',
'remou',
'refou',
'redou',
'retou',
'renou',
'relou',
'regou',
'rekou',
'rehou',
'rerou',
'rezou',
'recou',
'resou',
'reban',
'repan',
'reman',
'refan',
'redan',
'retan',
'renan',
'relan',
'regan',
'rekan',
'rehan',
'reran',
'rezan',
'recan',
'resan',
'reben',
'repen',
'remen',
'refen',
'reden',
'renen',
'regen',
'reken',
'rehen',
'reren',
'rezen',
'recen',
'resen',
'reang',
'reeng',
'redia',
'renia',
'relia',
'rejia',
'reqia',
'rexia',
'rebie',
'repie',
'remie',
'redie',
'retie',
'renie',
'relie',
'rejie',
'reqie',
'rexie',
'reyai',
'reyao',
'reyou',
'remiu',
'rediu',
'reniu',
'reliu',
'rejiu',
'reqiu',
'rexiu',
'reyan',
'reyin',
'rebin',
'repin',
'remin',
'redin',
'renin',
'relin',
'rejin',
'reqin',
'rexin',
'rezhu',
'rechu',
'reshu',
'regua',
'rekua',
'rehua',
'rerua',
'reduo',
'retuo',
'renuo',
'reluo',
'reguo',
'rekuo',
'rehuo',
'reruo',
'rezuo',
'recuo',
'resuo',
'rewai',
'rewei',
'redui',
'retui',
'regui',
'rekui',
'rehui',
'rerui',
'rezui',
'recui',
'resui',
'rewan',
'rewen',
'redun',
'retun',
'renun',
'relun',
'regun',
'rekun',
'rehun',
'rerun',
'rezun',
'recun',
'resun',
'reyue',
'renüe',
'relüe',
'rejue',
'reque',
'rexue',
'reyun',
'relün',
'rejun',
'requn',
'rexun',
'zezhi',
'zechi',
'zeshi',
'zezha',
'zecha',
'zesha',
'zezhe',
'zeche',
'zeshe',
'zebai',
'zepai',
'zemai',
'zedai',
'zetai',
'zenai',
'zelai',
'zegai',
'zekai',
'zehai',
'zezai',
'zecai',
'zesai',
'zebei',
'zepei',
'zemei',
'zefei',
'zedei',
'zetei',
'zenei',
'zelei',
'zegei',
'zekei',
'zehei',
'zezei',
'zesei',
'zebao',
'zepao',
'zemao',
'zedao',
'zetao',
'zenao',
'zelao',
'zegao',
'zekao',
'zehao',
'zerao',
'zezao',
'zecao',
'zesao',
'zepou',
'zemou',
'zefou',
'zedou',
'zetou',
'zenou',
'zelou',
'zegou',
'zekou',
'zehou',
'zerou',
'zezou',
'zecou',
'zesou',
'zeban',
'zepan',
'zeman',
'zefan',
'zedan',
'zetan',
'zenan',
'zelan',
'zegan',
'zekan',
'zehan',
'zeran',
'zezan',
'zecan',
'zesan',
'zeben',
'zepen',
'zemen',
'zefen',
'zeden',
'zenen',
'zegen',
'zeken',
'zehen',
'zeren',
'zezen',
'zecen',
'zesen',
'zeang',
'zeeng',
'zedia',
'zenia',
'zelia',
'zejia',
'zeqia',
'zexia',
'zebie',
'zepie',
'zemie',
'zedie',
'zetie',
'zenie',
'zelie',
'zejie',
'zeqie',
'zexie',
'zeyai',
'zeyao',
'zeyou',
'zemiu',
'zediu',
'zeniu',
'zeliu',
'zejiu',
'zeqiu',
'zexiu',
'zeyan',
'zeyin',
'zebin',
'zepin',
'zemin',
'zedin',
'zenin',
'zelin',
'zejin',
'zeqin',
'zexin',
'zezhu',
'zechu',
'zeshu',
'zegua',
'zekua',
'zehua',
'zerua',
'zeduo',
'zetuo',
'zenuo',
'zeluo',
'zeguo',
'zekuo',
'zehuo',
'zeruo',
'zezuo',
'zecuo',
'zesuo',
'zewai',
'zewei',
'zedui',
'zetui',
'zegui',
'zekui',
'zehui',
'zerui',
'zezui',
'zecui',
'zesui',
'zewan',
'zewen',
'zedun',
'zetun',
'zenun',
'zelun',
'zegun',
'zekun',
'zehun',
'zerun',
'zezun',
'zecun',
'zesun',
'zeyue',
'zenüe',
'zelüe',
'zejue',
'zeque',
'zexue',
'zeyun',
'zelün',
'zejun',
'zequn',
'zexun',
'cezhi',
'cechi',
'ceshi',
'cezha',
'cecha',
'cesha',
'cezhe',
'ceche',
'ceshe',
'cebai',
'cepai',
'cemai',
'cedai',
'cetai',
'cenai',
'celai',
'cegai',
'cekai',
'cehai',
'cezai',
'cecai',
'cesai',
'cebei',
'cepei',
'cemei',
'cefei',
'cedei',
'cetei',
'cenei',
'celei',
'cegei',
'cekei',
'cehei',
'cezei',
'cesei',
'cebao',
'cepao',
'cemao',
'cedao',
'cetao',
'cenao',
'celao',
'cegao',
'cekao',
'cehao',
'cerao',
'cezao',
'cecao',
'cesao',
'cepou',
'cemou',
'cefou',
'cedou',
'cetou',
'cenou',
'celou',
'cegou',
'cekou',
'cehou',
'cerou',
'cezou',
'cecou',
'cesou',
'ceban',
'cepan',
'ceman',
'cefan',
'cedan',
'cetan',
'cenan',
'celan',
'cegan',
'cekan',
'cehan',
'ceran',
'cezan',
'cecan',
'cesan',
'ceben',
'cepen',
'cemen',
'cefen',
'ceden',
'cenen',
'cegen',
'ceken',
'cehen',
'ceren',
'cezen',
'cecen',
'cesen',
'ceang',
'ceeng',
'cedia',
'cenia',
'celia',
'cejia',
'ceqia',
'cexia',
'cebie',
'cepie',
'cemie',
'cedie',
'cetie',
'cenie',
'celie',
'cejie',
'ceqie',
'cexie',
'ceyai',
'ceyao',
'ceyou',
'cemiu',
'cediu',
'ceniu',
'celiu',
'cejiu',
'ceqiu',
'cexiu',
'ceyan',
'ceyin',
'cebin',
'cepin',
'cemin',
'cedin',
'cenin',
'celin',
'cejin',
'ceqin',
'cexin',
'cezhu',
'cechu',
'ceshu',
'cegua',
'cekua',
'cehua',
'cerua',
'ceduo',
'cetuo',
'cenuo',
'celuo',
'ceguo',
'cekuo',
'cehuo',
'ceruo',
'cezuo',
'cecuo',
'cesuo',
'cewai',
'cewei',
'cedui',
'cetui',
'cegui',
'cekui',
'cehui',
'cerui',
'cezui',
'cecui',
'cesui',
'cewan',
'cewen',
'cedun',
'cetun',
'cenun',
'celun',
'cegun',
'cekun',
'cehun',
'cerun',
'cezun',
'cecun',
'cesun',
'ceyue',
'cenüe',
'celüe',
'cejue',
'ceque',
'cexue',
'ceyun',
'celün',
'cejun',
'cequn',
'cexun',
'sezhi',
'sechi',
'seshi',
'sezha',
'secha',
'sesha',
'sezhe',
'seche',
'seshe',
'sebai',
'sepai',
'semai',
'sedai',
'setai',
'senai',
'selai',
'segai',
'sekai',
'sehai',
'sezai',
'secai',
'sesai',
'sebei',
'sepei',
'semei',
'sefei',
'sedei',
'setei',
'senei',
'selei',
'segei',
'sekei',
'sehei',
'sezei',
'sesei',
'sebao',
'sepao',
'semao',
'sedao',
'setao',
'senao',
'selao',
'segao',
'sekao',
'sehao',
'serao',
'sezao',
'secao',
'sesao',
'sepou',
'semou',
'sefou',
'sedou',
'setou',
'senou',
'selou',
'segou',
'sekou',
'sehou',
'serou',
'sezou',
'secou',
'sesou',
'seban',
'sepan',
'seman',
'sefan',
'sedan',
'setan',
'senan',
'selan',
'segan',
'sekan',
'sehan',
'seran',
'sezan',
'secan',
'sesan',
'seben',
'sepen',
'semen',
'sefen',
'seden',
'senen',
'segen',
'seken',
'sehen',
'seren',
'sezen',
'secen',
'sesen',
'seang',
'seeng',
'sedia',
'senia',
'selia',
'sejia',
'seqia',
'sexia',
'sebie',
'sepie',
'semie',
'sedie',
'setie',
'senie',
'selie',
'sejie',
'seqie',
'sexie',
'seyai',
'seyao',
'seyou',
'semiu',
'sediu',
'seniu',
'seliu',
'sejiu',
'seqiu',
'sexiu',
'seyan',
'seyin',
'sebin',
'sepin',
'semin',
'sedin',
'senin',
'selin',
'sejin',
'seqin',
'sexin',
'sezhu',
'sechu',
'seshu',
'segua',
'sekua',
'sehua',
'serua',
'seduo',
'setuo',
'senuo',
'seluo',
'seguo',
'sekuo',
'sehuo',
'seruo',
'sezuo',
'secuo',
'sesuo',
'sewai',
'sewei',
'sedui',
'setui',
'segui',
'sekui',
'sehui',
'serui',
'sezui',
'secui',
'sesui',
'sewan',
'sewen',
'sedun',
'setun',
'senun',
'selun',
'segun',
'sekun',
'sehun',
'serun',
'sezun',
'secun',
'sesun',
'seyue',
'senüe',
'selüe',
'sejue',
'seque',
'sexue',
'seyun',
'selün',
'sejun',
'sequn',
'sexun',
'aizhi',
'aichi',
'aishi',
'aizha',
'aicha',
'aisha',
'aizhe',
'aiche',
'aishe',
'aibai',
'aipai',
'aimai',
'aidai',
'aitai',
'ainai',
'ailai',
'aigai',
'aikai',
'aihai',
'aizai',
'aicai',
'aisai',
'aibei',
'aipei',
'aimei',
'aifei',
'aidei',
'aitei',
'ainei',
'ailei',
'aigei',
'aikei',
'aihei',
'aizei',
'aisei',
'aibao',
'aipao',
'aimao',
'aidao',
'aitao',
'ainao',
'ailao',
'aigao',
'aikao',
'aihao',
'airao',
'aizao',
'aicao',
'aisao',
'aipou',
'aimou',
'aifou',
'aidou',
'aitou',
'ainou',
'ailou',
'aigou',
'aikou',
'aihou',
'airou',
'aizou',
'aicou',
'aisou',
'aiban',
'aipan',
'aiman',
'aifan',
'aidan',
'aitan',
'ainan',
'ailan',
'aigan',
'aikan',
'aihan',
'airan',
'aizan',
'aican',
'aisan',
'aiben',
'aipen',
'aimen',
'aifen',
'aiden',
'ainen',
'aigen',
'aiken',
'aihen',
'airen',
'aizen',
'aicen',
'aisen',
'aiang',
'aieng',
'aidia',
'ainia',
'ailia',
'aijia',
'aiqia',
'aixia',
'aibie',
'aipie',
'aimie',
'aidie',
'aitie',
'ainie',
'ailie',
'aijie',
'aiqie',
'aixie',
'aiyai',
'aiyao',
'aiyou',
'aimiu',
'aidiu',
'ainiu',
'ailiu',
'aijiu',
'aiqiu',
'aixiu',
'aiyan',
'aiyin',
'aibin',
'aipin',
'aimin',
'aidin',
'ainin',
'ailin',
'aijin',
'aiqin',
'aixin',
'aizhu',
'aichu',
'aishu',
'aigua',
'aikua',
'aihua',
'airua',
'aiduo',
'aituo',
'ainuo',
'ailuo',
'aiguo',
'aikuo',
'aihuo',
'airuo',
'aizuo',
'aicuo',
'aisuo',
'aiwai',
'aiwei',
'aidui',
'aitui',
'aigui',
'aikui',
'aihui',
'airui',
'aizui',
'aicui',
'aisui',
'aiwan',
'aiwen',
'aidun',
'aitun',
'ainun',
'ailun',
'aigun',
'aikun',
'aihun',
'airun',
'aizun',
'aicun',
'aisun',
'aiyue',
'ainüe',
'ailüe',
'aijue',
'aique',
'aixue',
'aiyun',
'ailün',
'aijun',
'aiqun',
'aixun',
'bairi',
'baizi',
'baici',
'baisi',
'baiba',
'baipa',
'baima',
'baifa',
'baida',
'baita',
'baina',
'baila',
'baiga',
'baika',
'baiha',
'baiza',
'baica',
'baisa',
'baibo',
'baipo',
'baimo',
'baifo',
'bailo',
'baime',
'baide',
'baite',
'baine',
'baile',
'baige',
'baike',
'baihe',
'baire',
'baize',
'baice',
'baise',
'baiai',
'baiei',
'baiao',
'baiou',
'baian',
'baien',
'baier',
'baiyi',
'baibi',
'baipi',
'baimi',
'baidi',
'baiti',
'baini',
'baili',
'baiji',
'baiqi',
'baixi',
'baiya',
'baiyo',
'baiye',
'baiwu',
'baibu',
'baipu',
'baimu',
'baifu',
'baidu',
'baitu',
'bainu',
'bailu',
'baigu',
'baiku',
'baihu',
'bairu',
'baizu',
'baicu',
'baisu',
'baiwa',
'baiwo',
'baiyu',
'bainü',
'bailü',
'baiju',
'baiqu',
'baixu',
'pairi',
'paizi',
'paici',
'paisi',
'paiba',
'paipa',
'paima',
'paifa',
'paida',
'paita',
'paina',
'paila',
'paiga',
'paika',
'paiha',
'paiza',
'paica',
'paisa',
'paibo',
'paipo',
'paimo',
'paifo',
'pailo',
'paime',
'paide',
'paite',
'paine',
'paile',
'paige',
'paike',
'paihe',
'paire',
'paize',
'paice',
'paise',
'paiai',
'paiei',
'paiao',
'paiou',
'paian',
'paien',
'paier',
'paiyi',
'paibi',
'paipi',
'paimi',
'paidi',
'paiti',
'paini',
'paili',
'paiji',
'paiqi',
'paixi',
'paiya',
'paiyo',
'paiye',
'paiwu',
'paibu',
'paipu',
'paimu',
'paifu',
'paidu',
'paitu',
'painu',
'pailu',
'paigu',
'paiku',
'paihu',
'pairu',
'paizu',
'paicu',
'paisu',
'paiwa',
'paiwo',
'paiyu',
'painü',
'pailü',
'paiju',
'paiqu',
'paixu',
'mairi',
'maizi',
'maici',
'maisi',
'maiba',
'maipa',
'maima',
'maifa',
'maida',
'maita',
'maina',
'maila',
'maiga',
'maika',
'maiha',
'maiza',
'maica',
'maisa',
'maibo',
'maipo',
'maimo',
'maifo',
'mailo',
'maime',
'maide',
'maite',
'maine',
'maile',
'maige',
'maike',
'maihe',
'maire',
'maize',
'maice',
'maise',
'maiai',
'maiei',
'maiao',
'maiou',
'maian',
'maien',
'maier',
'maiyi',
'maibi',
'maipi',
'maimi',
'maidi',
'maiti',
'maini',
'maili',
'maiji',
'maiqi',
'maixi',
'maiya',
'maiyo',
'maiye',
'maiwu',
'maibu',
'maipu',
'maimu',
'maifu',
'maidu',
'maitu',
'mainu',
'mailu',
'maigu',
'maiku',
'maihu',
'mairu',
'maizu',
'maicu',
'maisu',
'maiwa',
'maiwo',
'maiyu',
'mainü',
'mailü',
'maiju',
'maiqu',
'maixu',
'dairi',
'daizi',
'daici',
'daisi',
'daiba',
'daipa',
'daima',
'daifa',
'daida',
'daita',
'daina',
'daila',
'daiga',
'daika',
'daiha',
'daiza',
'daica',
'daisa',
'daibo',
'daipo',
'daimo',
'daifo',
'dailo',
'daime',
'daide',
'daite',
'daine',
'daile',
'daige',
'daike',
'daihe',
'daire',
'daize',
'daice',
'daise',
'daiai',
'daiei',
'daiao',
'daiou',
'daian',
'daien',
'daier',
'daiyi',
'daibi',
'daipi',
'daimi',
'daidi',
'daiti',
'daini',
'daili',
'daiji',
'daiqi',
'daixi',
'daiya',
'daiyo',
'daiye',
'daiwu',
'daibu',
'daipu',
'daimu',
'daifu',
'daidu',
'daitu',
'dainu',
'dailu',
'daigu',
'daiku',
'daihu',
'dairu',
'daizu',
'daicu',
'daisu',
'daiwa',
'daiwo',
'daiyu',
'dainü',
'dailü',
'daiju',
'daiqu',
'daixu',
'tairi',
'taizi',
'taici',
'taisi',
'taiba',
'taipa',
'taima',
'taifa',
'taida',
'taita',
'taina',
'taila',
'taiga',
'taika',
'taiha',
'taiza',
'taica',
'taisa',
'taibo',
'taipo',
'taimo',
'taifo',
'tailo',
'taime',
'taide',
'taite',
'taine',
'taile',
'taige',
'taike',
'taihe',
'taire',
'taize',
'taice',
'taise',
'taiai',
'taiei',
'taiao',
'taiou',
'taian',
'taien',
'taier',
'taiyi',
'taibi',
'taipi',
'taimi',
'taidi',
'taiti',
'taini',
'taili',
'taiji',
'taiqi',
'taixi',
'taiya',
'taiyo',
'taiye',
'taiwu',
'taibu',
'taipu',
'taimu',
'taifu',
'taidu',
'taitu',
'tainu',
'tailu',
'taigu',
'taiku',
'taihu',
'tairu',
'taizu',
'taicu',
'taisu',
'taiwa',
'taiwo',
'taiyu',
'tainü',
'tailü',
'taiju',
'taiqu',
'taixu',
'nairi',
'naizi',
'naici',
'naisi',
'naiba',
'naipa',
'naima',
'naifa',
'naida',
'naita',
'naina',
'naila',
'naiga',
'naika',
'naiha',
'naiza',
'naica',
'naisa',
'naibo',
'naipo',
'naimo',
'naifo',
'nailo',
'naime',
'naide',
'naite',
'naine',
'naile',
'naige',
'naike',
'naihe',
'naire',
'naize',
'naice',
'naise',
'naiai',
'naiei',
'naiao',
'naiou',
'naian',
'naien',
'naier',
'naiyi',
'naibi',
'naipi',
'naimi',
'naidi',
'naiti',
'naini',
'naili',
'naiji',
'naiqi',
'naixi',
'naiya',
'naiyo',
'naiye',
'naiwu',
'naibu',
'naipu',
'naimu',
'naifu',
'naidu',
'naitu',
'nainu',
'nailu',
'naigu',
'naiku',
'naihu',
'nairu',
'naizu',
'naicu',
'naisu',
'naiwa',
'naiwo',
'naiyu',
'nainü',
'nailü',
'naiju',
'naiqu',
'naixu',
'lairi',
'laizi',
'laici',
'laisi',
'laiba',
'laipa',
'laima',
'laifa',
'laida',
'laita',
'laina',
'laila',
'laiga',
'laika',
'laiha',
'laiza',
'laica',
'laisa',
'laibo',
'laipo',
'laimo',
'laifo',
'lailo',
'laime',
'laide',
'laite',
'laine',
'laile',
'laige',
'laike',
'laihe',
'laire',
'laize',
'laice',
'laise',
'laiai',
'laiei',
'laiao',
'laiou',
'laian',
'laien',
'laier',
'laiyi',
'laibi',
'laipi',
'laimi',
'laidi',
'laiti',
'laini',
'laili',
'laiji',
'laiqi',
'laixi',
'laiya',
'laiyo',
'laiye',
'laiwu',
'laibu',
'laipu',
'laimu',
'laifu',
'laidu',
'laitu',
'lainu',
'lailu',
'laigu',
'laiku',
'laihu',
'lairu',
'laizu',
'laicu',
'laisu',
'laiwa',
'laiwo',
'laiyu',
'lainü',
'lailü',
'laiju',
'laiqu',
'laixu',
'gairi',
'gaizi',
'gaici',
'gaisi',
'gaiba',
'gaipa',
'gaima',
'gaifa',
'gaida',
'gaita',
'gaina',
'gaila',
'gaiga',
'gaika',
'gaiha',
'gaiza',
'gaica',
'gaisa',
'gaibo',
'gaipo',
'gaimo',
'gaifo',
'gailo',
'gaime',
'gaide',
'gaite',
'gaine',
'gaile',
'gaige',
'gaike',
'gaihe',
'gaire',
'gaize',
'gaice',
'gaise',
'gaiai',
'gaiei',
'gaiao',
'gaiou',
'gaian',
'gaien',
'gaier',
'gaiyi',
'gaibi',
'gaipi',
'gaimi',
'gaidi',
'gaiti',
'gaini',
'gaili',
'gaiji',
'gaiqi',
'gaixi',
'gaiya',
'gaiyo',
'gaiye',
'gaiwu',
'gaibu',
'gaipu',
'gaimu',
'gaifu',
'gaidu',
'gaitu',
'gainu',
'gailu',
'gaigu',
'gaiku',
'gaihu',
'gairu',
'gaizu',
'gaicu',
'gaisu',
'gaiwa',
'gaiwo',
'gaiyu',
'gainü',
'gailü',
'gaiju',
'gaiqu',
'gaixu',
'kairi',
'kaizi',
'kaici',
'kaisi',
'kaiba',
'kaipa',
'kaima',
'kaifa',
'kaida',
'kaita',
'kaina',
'kaila',
'kaiga',
'kaika',
'kaiha',
'kaiza',
'kaica',
'kaisa',
'kaibo',
'kaipo',
'kaimo',
'kaifo',
'kailo',
'kaime',
'kaide',
'kaite',
'kaine',
'kaile',
'kaige',
'kaike',
'kaihe',
'kaire',
'kaize',
'kaice',
'kaise',
'kaiai',
'kaiei',
'kaiao',
'kaiou',
'kaian',
'kaien',
'kaier',
'kaiyi',
'kaibi',
'kaipi',
'kaimi',
'kaidi',
'kaiti',
'kaini',
'kaili',
'kaiji',
'kaiqi',
'kaixi',
'kaiya',
'kaiyo',
'kaiye',
'kaiwu',
'kaibu',
'kaipu',
'kaimu',
'kaifu',
'kaidu',
'kaitu',
'kainu',
'kailu',
'kaigu',
'kaiku',
'kaihu',
'kairu',
'kaizu',
'kaicu',
'kaisu',
'kaiwa',
'kaiwo',
'kaiyu',
'kainü',
'kailü',
'kaiju',
'kaiqu',
'kaixu',
'hairi',
'haizi',
'haici',
'haisi',
'haiba',
'haipa',
'haima',
'haifa',
'haida',
'haita',
'haina',
'haila',
'haiga',
'haika',
'haiha',
'haiza',
'haica',
'haisa',
'haibo',
'haipo',
'haimo',
'haifo',
'hailo',
'haime',
'haide',
'haite',
'haine',
'haile',
'haige',
'haike',
'haihe',
'haire',
'haize',
'haice',
'haise',
'haiai',
'haiei',
'haiao',
'haiou',
'haian',
'haien',
'haier',
'haiyi',
'haibi',
'haipi',
'haimi',
'haidi',
'haiti',
'haini',
'haili',
'haiji',
'haiqi',
'haixi',
'haiya',
'haiyo',
'haiye',
'haiwu',
'haibu',
'haipu',
'haimu',
'haifu',
'haidu',
'haitu',
'hainu',
'hailu',
'haigu',
'haiku',
'haihu',
'hairu',
'haizu',
'haicu',
'haisu',
'haiwa',
'haiwo',
'haiyu',
'hainü',
'hailü',
'haiju',
'haiqu',
'haixu',
'zairi',
'zaizi',
'zaici',
'zaisi',
'zaiba',
'zaipa',
'zaima',
'zaifa',
'zaida',
'zaita',
'zaina',
'zaila',
'zaiga',
'zaika',
'zaiha',
'zaiza',
'zaica',
'zaisa',
'zaibo',
'zaipo',
'zaimo',
'zaifo',
'zailo',
'zaime',
'zaide',
'zaite',
'zaine',
'zaile',
'zaige',
'zaike',
'zaihe',
'zaire',
'zaize',
'zaice',
'zaise',
'zaiai',
'zaiei',
'zaiao',
'zaiou',
'zaian',
'zaien',
'zaier',
'zaiyi',
'zaibi',
'zaipi',
'zaimi',
'zaidi',
'zaiti',
'zaini',
'zaili',
'zaiji',
'zaiqi',
'zaixi',
'zaiya',
'zaiyo',
'zaiye',
'zaiwu',
'zaibu',
'zaipu',
'zaimu',
'zaifu',
'zaidu',
'zaitu',
'zainu',
'zailu',
'zaigu',
'zaiku',
'zaihu',
'zairu',
'zaizu',
'zaicu',
'zaisu',
'zaiwa',
'zaiwo',
'zaiyu',
'zainü',
'zailü',
'zaiju',
'zaiqu',
'zaixu',
'cairi',
'caizi',
'caici',
'caisi',
'caiba',
'caipa',
'caima',
'caifa',
'caida',
'caita',
'caina',
'caila',
'caiga',
'caika',
'caiha',
'caiza',
'caica',
'caisa',
'caibo',
'caipo',
'caimo',
'caifo',
'cailo',
'caime',
'caide',
'caite',
'caine',
'caile',
'caige',
'caike',
'caihe',
'caire',
'caize',
'caice',
'caise',
'caiai',
'caiei',
'caiao',
'caiou',
'caian',
'caien',
'caier',
'caiyi',
'caibi',
'caipi',
'caimi',
'caidi',
'caiti',
'caini',
'caili',
'caiji',
'caiqi',
'caixi',
'caiya',
'caiyo',
'caiye',
'caiwu',
'caibu',
'caipu',
'caimu',
'caifu',
'caidu',
'caitu',
'cainu',
'cailu',
'caigu',
'caiku',
'caihu',
'cairu',
'caizu',
'caicu',
'caisu',
'caiwa',
'caiwo',
'caiyu',
'cainü',
'cailü',
'caiju',
'caiqu',
'caixu',
'sairi',
'saizi',
'saici',
'saisi',
'saiba',
'saipa',
'saima',
'saifa',
'saida',
'saita',
'saina',
'saila',
'saiga',
'saika',
'saiha',
'saiza',
'saica',
'saisa',
'saibo',
'saipo',
'saimo',
'saifo',
'sailo',
'saime',
'saide',
'saite',
'saine',
'saile',
'saige',
'saike',
'saihe',
'saire',
'saize',
'saice',
'saise',
'saiai',
'saiei',
'saiao',
'saiou',
'saian',
'saien',
'saier',
'saiyi',
'saibi',
'saipi',
'saimi',
'saidi',
'saiti',
'saini',
'saili',
'saiji',
'saiqi',
'saixi',
'saiya',
'saiyo',
'saiye',
'saiwu',
'saibu',
'saipu',
'saimu',
'saifu',
'saidu',
'saitu',
'sainu',
'sailu',
'saigu',
'saiku',
'saihu',
'sairu',
'saizu',
'saicu',
'saisu',
'saiwa',
'saiwo',
'saiyu',
'sainü',
'sailü',
'saiju',
'saiqu',
'saixu',
'eizhi',
'eichi',
'eishi',
'eizha',
'eicha',
'eisha',
'eizhe',
'eiche',
'eishe',
'eibai',
'eipai',
'eimai',
'eidai',
'eitai',
'einai',
'eilai',
'eigai',
'eikai',
'eihai',
'eizai',
'eicai',
'eisai',
'eibei',
'eipei',
'eimei',
'eifei',
'eidei',
'eitei',
'einei',
'eilei',
'eigei',
'eikei',
'eihei',
'eizei',
'eisei',
'eibao',
'eipao',
'eimao',
'eidao',
'eitao',
'einao',
'eilao',
'eigao',
'eikao',
'eihao',
'eirao',
'eizao',
'eicao',
'eisao',
'eipou',
'eimou',
'eifou',
'eidou',
'eitou',
'einou',
'eilou',
'eigou',
'eikou',
'eihou',
'eirou',
'eizou',
'eicou',
'eisou',
'eiban',
'eipan',
'eiman',
'eifan',
'eidan',
'eitan',
'einan',
'eilan',
'eigan',
'eikan',
'eihan',
'eiran',
'eizan',
'eican',
'eisan',
'eiben',
'eipen',
'eimen',
'eifen',
'eiden',
'einen',
'eigen',
'eiken',
'eihen',
'eiren',
'eizen',
'eicen',
'eisen',
'eiang',
'eieng',
'eidia',
'einia',
'eilia',
'eijia',
'eiqia',
'eixia',
'eibie',
'eipie',
'eimie',
'eidie',
'eitie',
'einie',
'eilie',
'eijie',
'eiqie',
'eixie',
'eiyai',
'eiyao',
'eiyou',
'eimiu',
'eidiu',
'einiu',
'eiliu',
'eijiu',
'eiqiu',
'eixiu',
'eiyan',
'eiyin',
'eibin',
'eipin',
'eimin',
'eidin',
'einin',
'eilin',
'eijin',
'eiqin',
'eixin',
'eizhu',
'eichu',
'eishu',
'eigua',
'eikua',
'eihua',
'eirua',
'eiduo',
'eituo',
'einuo',
'eiluo',
'eiguo',
'eikuo',
'eihuo',
'eiruo',
'eizuo',
'eicuo',
'eisuo',
'eiwai',
'eiwei',
'eidui',
'eitui',
'eigui',
'eikui',
'eihui',
'eirui',
'eizui',
'eicui',
'eisui',
'eiwan',
'eiwen',
'eidun',
'eitun',
'einun',
'eilun',
'eigun',
'eikun',
'eihun',
'eirun',
'eizun',
'eicun',
'eisun',
'eiyue',
'einüe',
'eilüe',
'eijue',
'eique',
'eixue',
'eiyun',
'eilün',
'eijun',
'eiqun',
'eixun',
'beiri',
'beizi',
'beici',
'beisi',
'beiba',
'beipa',
'beima',
'beifa',
'beida',
'beita',
'beina',
'beila',
'beiga',
'beika',
'beiha',
'beiza',
'beica',
'beisa',
'beibo',
'beipo',
'beimo',
'beifo',
'beilo',
'beime',
'beide',
'beite',
'beine',
'beile',
'beige',
'beike',
'beihe',
'beire',
'beize',
'beice',
'beise',
'beiai',
'beiei',
'beiao',
'beiou',
'beian',
'beien',
'beier',
'beiyi',
'beibi',
'beipi',
'beimi',
'beidi',
'beiti',
'beini',
'beili',
'beiji',
'beiqi',
'beixi',
'beiya',
'beiyo',
'beiye',
'beiwu',
'beibu',
'beipu',
'beimu',
'beifu',
'beidu',
'beitu',
'beinu',
'beilu',
'beigu',
'beiku',
'beihu',
'beiru',
'beizu',
'beicu',
'beisu',
'beiwa',
'beiwo',
'beiyu',
'beinü',
'beilü',
'beiju',
'beiqu',
'beixu',
'peiri',
'peizi',
'peici',
'peisi',
'peiba',
'peipa',
'peima',
'peifa',
'peida',
'peita',
'peina',
'peila',
'peiga',
'peika',
'peiha',
'peiza',
'peica',
'peisa',
'peibo',
'peipo',
'peimo',
'peifo',
'peilo',
'peime',
'peide',
'peite',
'peine',
'peile',
'peige',
'peike',
'peihe',
'peire',
'peize',
'peice',
'peise',
'peiai',
'peiei',
'peiao',
'peiou',
'peian',
'peien',
'peier',
'peiyi',
'peibi',
'peipi',
'peimi',
'peidi',
'peiti',
'peini',
'peili',
'peiji',
'peiqi',
'peixi',
'peiya',
'peiyo',
'peiye',
'peiwu',
'peibu',
'peipu',
'peimu',
'peifu',
'peidu',
'peitu',
'peinu',
'peilu',
'peigu',
'peiku',
'peihu',
'peiru',
'peizu',
'peicu',
'peisu',
'peiwa',
'peiwo',
'peiyu',
'peinü',
'peilü',
'peiju',
'peiqu',
'peixu',
'meiri',
'meizi',
'meici',
'meisi',
'meiba',
'meipa',
'meima',
'meifa',
'meida',
'meita',
'meina',
'meila',
'meiga',
'meika',
'meiha',
'meiza',
'meica',
'meisa',
'meibo',
'meipo',
'meimo',
'meifo',
'meilo',
'meime',
'meide',
'meite',
'meine',
'meile',
'meige',
'meike',
'meihe',
'meire',
'meize',
'meice',
'meise',
'meiai',
'meiei',
'meiao',
'meiou',
'meian',
'meien',
'meier',
'meiyi',
'meibi',
'meipi',
'meimi',
'meidi',
'meiti',
'meini',
'meili',
'meiji',
'meiqi',
'meixi',
'meiya',
'meiyo',
'meiye',
'meiwu',
'meibu',
'meipu',
'meimu',
'meifu',
'meidu',
'meitu',
'meinu',
'meilu',
'meigu',
'meiku',
'meihu',
'meiru',
'meizu',
'meicu',
'meisu',
'meiwa',
'meiwo',
'meiyu',
'meinü',
'meilü',
'meiju',
'meiqu',
'meixu',
'feiri',
'feizi',
'feici',
'feisi',
'feiba',
'feipa',
'feima',
'feifa',
'feida',
'feita',
'feina',
'feila',
'feiga',
'feika',
'feiha',
'feiza',
'feica',
'feisa',
'feibo',
'feipo',
'feimo',
'feifo',
'feilo',
'feime',
'feide',
'feite',
'feine',
'feile',
'feige',
'feike',
'feihe',
'feire',
'feize',
'feice',
'feise',
'feiai',
'feiei',
'feiao',
'feiou',
'feian',
'feien',
'feier',
'feiyi',
'feibi',
'feipi',
'feimi',
'feidi',
'feiti',
'feini',
'feili',
'feiji',
'feiqi',
'feixi',
'feiya',
'feiyo',
'feiye',
'feiwu',
'feibu',
'feipu',
'feimu',
'feifu',
'feidu',
'feitu',
'feinu',
'feilu',
'feigu',
'feiku',
'feihu',
'feiru',
'feizu',
'feicu',
'feisu',
'feiwa',
'feiwo',
'feiyu',
'feinü',
'feilü',
'feiju',
'feiqu',
'feixu',
'deiri',
'deizi',
'deici',
'deisi',
'deiba',
'deipa',
'deima',
'deifa',
'deida',
'deita',
'deina',
'deila',
'deiga',
'deika',
'deiha',
'deiza',
'deica',
'deisa',
'deibo',
'deipo',
'deimo',
'deifo',
'deilo',
'deime',
'deide',
'deite',
'deine',
'deile',
'deige',
'deike',
'deihe',
'deire',
'deize',
'deice',
'deise',
'deiai',
'deiei',
'deiao',
'deiou',
'deian',
'deien',
'deier',
'deiyi',
'deibi',
'deipi',
'deimi',
'deidi',
'deiti',
'deini',
'deili',
'deiji',
'deiqi',
'deixi',
'deiya',
'deiyo',
'deiye',
'deiwu',
'deibu',
'deipu',
'deimu',
'deifu',
'deidu',
'deitu',
'deinu',
'deilu',
'deigu',
'deiku',
'deihu',
'deiru',
'deizu',
'deicu',
'deisu',
'deiwa',
'deiwo',
'deiyu',
'deinü',
'deilü',
'deiju',
'deiqu',
'deixu',
'teiri',
'teizi',
'teici',
'teisi',
'teiba',
'teipa',
'teima',
'teifa',
'teida',
'teita',
'teina',
'teila',
'teiga',
'teika',
'teiha',
'teiza',
'teica',
'teisa',
'teibo',
'teipo',
'teimo',
'teifo',
'teilo',
'teime',
'teide',
'teite',
'teine',
'teile',
'teige',
'teike',
'teihe',
'teire',
'teize',
'teice',
'teise',
'teiai',
'teiei',
'teiao',
'teiou',
'teian',
'teien',
'teier',
'teiyi',
'teibi',
'teipi',
'teimi',
'teidi',
'teiti',
'teini',
'teili',
'teiji',
'teiqi',
'teixi',
'teiya',
'teiyo',
'teiye',
'teiwu',
'teibu',
'teipu',
'teimu',
'teifu',
'teidu',
'teitu',
'teinu',
'teilu',
'teigu',
'teiku',
'teihu',
'teiru',
'teizu',
'teicu',
'teisu',
'teiwa',
'teiwo',
'teiyu',
'teinü',
'teilü',
'teiju',
'teiqu',
'teixu',
'neiri',
'neizi',
'neici',
'neisi',
'neiba',
'neipa',
'neima',
'neifa',
'neida',
'neita',
'neina',
'neila',
'neiga',
'neika',
'neiha',
'neiza',
'neica',
'neisa',
'neibo',
'neipo',
'neimo',
'neifo',
'neilo',
'neime',
'neide',
'neite',
'neine',
'neile',
'neige',
'neike',
'neihe',
'neire',
'neize',
'neice',
'neise',
'neiai',
'neiei',
'neiao',
'neiou',
'neian',
'neien',
'neier',
'neiyi',
'neibi',
'neipi',
'neimi',
'neidi',
'neiti',
'neini',
'neili',
'neiji',
'neiqi',
'neixi',
'neiya',
'neiyo',
'neiye',
'neiwu',
'neibu',
'neipu',
'neimu',
'neifu',
'neidu',
'neitu',
'neinu',
'neilu',
'neigu',
'neiku',
'neihu',
'neiru',
'neizu',
'neicu',
'neisu',
'neiwa',
'neiwo',
'neiyu',
'neinü',
'neilü',
'neiju',
'neiqu',
'neixu',
'leiri',
'leizi',
'leici',
'leisi',
'leiba',
'leipa',
'leima',
'leifa',
'leida',
'leita',
'leina',
'leila',
'leiga',
'leika',
'leiha',
'leiza',
'leica',
'leisa',
'leibo',
'leipo',
'leimo',
'leifo',
'leilo',
'leime',
'leide',
'leite',
'leine',
'leile',
'leige',
'leike',
'leihe',
'leire',
'leize',
'leice',
'leise',
'leiai',
'leiei',
'leiao',
'leiou',
'leian',
'leien',
'leier',
'leiyi',
'leibi',
'leipi',
'leimi',
'leidi',
'leiti',
'leini',
'leili',
'leiji',
'leiqi',
'leixi',
'leiya',
'leiyo',
'leiye',
'leiwu',
'leibu',
'leipu',
'leimu',
'leifu',
'leidu',
'leitu',
'leinu',
'leilu',
'leigu',
'leiku',
'leihu',
'leiru',
'leizu',
'leicu',
'leisu',
'leiwa',
'leiwo',
'leiyu',
'leinü',
'leilü',
'leiju',
'leiqu',
'leixu',
'geiri',
'geizi',
'geici',
'geisi',
'geiba',
'geipa',
'geima',
'geifa',
'geida',
'geita',
'geina',
'geila',
'geiga',
'geika',
'geiha',
'geiza',
'geica',
'geisa',
'geibo',
'geipo',
'geimo',
'geifo',
'geilo',
'geime',
'geide',
'geite',
'geine',
'geile',
'geige',
'geike',
'geihe',
'geire',
'geize',
'geice',
'geise',
'geiai',
'geiei',
'geiao',
'geiou',
'geian',
'geien',
'geier',
'geiyi',
'geibi',
'geipi',
'geimi',
'geidi',
'geiti',
'geini',
'geili',
'geiji',
'geiqi',
'geixi',
'geiya',
'geiyo',
'geiye',
'geiwu',
'geibu',
'geipu',
'geimu',
'geifu',
'geidu',
'geitu',
'geinu',
'geilu',
'geigu',
'geiku',
'geihu',
'geiru',
'geizu',
'geicu',
'geisu',
'geiwa',
'geiwo',
'geiyu',
'geinü',
'geilü',
'geiju',
'geiqu',
'geixu',
'keiri',
'keizi',
'keici',
'keisi',
'keiba',
'keipa',
'keima',
'keifa',
'keida',
'keita',
'keina',
'keila',
'keiga',
'keika',
'keiha',
'keiza',
'keica',
'keisa',
'keibo',
'keipo',
'keimo',
'keifo',
'keilo',
'keime',
'keide',
'keite',
'keine',
'keile',
'keige',
'keike',
'keihe',
'keire',
'keize',
'keice',
'keise',
'keiai',
'keiei',
'keiao',
'keiou',
'keian',
'keien',
'keier',
'keiyi',
'keibi',
'keipi',
'keimi',
'keidi',
'keiti',
'keini',
'keili',
'keiji',
'keiqi',
'keixi',
'keiya',
'keiyo',
'keiye',
'keiwu',
'keibu',
'keipu',
'keimu',
'keifu',
'keidu',
'keitu',
'keinu',
'keilu',
'keigu',
'keiku',
'keihu',
'keiru',
'keizu',
'keicu',
'keisu',
'keiwa',
'keiwo',
'keiyu',
'keinü',
'keilü',
'keiju',
'keiqu',
'keixu',
'heiri',
'heizi',
'heici',
'heisi',
'heiba',
'heipa',
'heima',
'heifa',
'heida',
'heita',
'heina',
'heila',
'heiga',
'heika',
'heiha',
'heiza',
'heica',
'heisa',
'heibo',
'heipo',
'heimo',
'heifo',
'heilo',
'heime',
'heide',
'heite',
'heine',
'heile',
'heige',
'heike',
'heihe',
'heire',
'heize',
'heice',
'heise',
'heiai',
'heiei',
'heiao',
'heiou',
'heian',
'heien',
'heier',
'heiyi',
'heibi',
'heipi',
'heimi',
'heidi',
'heiti',
'heini',
'heili',
'heiji',
'heiqi',
'heixi',
'heiya',
'heiyo',
'heiye',
'heiwu',
'heibu',
'heipu',
'heimu',
'heifu',
'heidu',
'heitu',
'heinu',
'heilu',
'heigu',
'heiku',
'heihu',
'heiru',
'heizu',
'heicu',
'heisu',
'heiwa',
'heiwo',
'heiyu',
'heinü',
'heilü',
'heiju',
'heiqu',
'heixu',
'zeiri',
'zeizi',
'zeici',
'zeisi',
'zeiba',
'zeipa',
'zeima',
'zeifa',
'zeida',
'zeita',
'zeina',
'zeila',
'zeiga',
'zeika',
'zeiha',
'zeiza',
'zeica',
'zeisa',
'zeibo',
'zeipo',
'zeimo',
'zeifo',
'zeilo',
'zeime',
'zeide',
'zeite',
'zeine',
'zeile',
'zeige',
'zeike',
'zeihe',
'zeire',
'zeize',
'zeice',
'zeise',
'zeiai',
'zeiei',
'zeiao',
'zeiou',
'zeian',
'zeien',
'zeier',
'zeiyi',
'zeibi',
'zeipi',
'zeimi',
'zeidi',
'zeiti',
'zeini',
'zeili',
'zeiji',
'zeiqi',
'zeixi',
'zeiya',
'zeiyo',
'zeiye',
'zeiwu',
'zeibu',
'zeipu',
'zeimu',
'zeifu',
'zeidu',
'zeitu',
'zeinu',
'zeilu',
'zeigu',
'zeiku',
'zeihu',
'zeiru',
'zeizu',
'zeicu',
'zeisu',
'zeiwa',
'zeiwo',
'zeiyu',
'zeinü',
'zeilü',
'zeiju',
'zeiqu',
'zeixu',
'seiri',
'seizi',
'seici',
'seisi',
'seiba',
'seipa',
'seima',
'seifa',
'seida',
'seita',
'seina',
'seila',
'seiga',
'seika',
'seiha',
'seiza',
'seica',
'seisa',
'seibo',
'seipo',
'seimo',
'seifo',
'seilo',
'seime',
'seide',
'seite',
'seine',
'seile',
'seige',
'seike',
'seihe',
'seire',
'seize',
'seice',
'seise',
'seiai',
'seiei',
'seiao',
'seiou',
'seian',
'seien',
'seier',
'seiyi',
'seibi',
'seipi',
'seimi',
'seidi',
'seiti',
'seini',
'seili',
'seiji',
'seiqi',
'seixi',
'seiya',
'seiyo',
'seiye',
'seiwu',
'seibu',
'seipu',
'seimu',
'seifu',
'seidu',
'seitu',
'seinu',
'seilu',
'seigu',
'seiku',
'seihu',
'seiru',
'seizu',
'seicu',
'seisu',
'seiwa',
'seiwo',
'seiyu',
'seinü',
'seilü',
'seiju',
'seiqu',
'seixu',
'aozhi',
'aochi',
'aoshi',
'aozha',
'aocha',
'aosha',
'aozhe',
'aoche',
'aoshe',
'aobai',
'aopai',
'aomai',
'aodai',
'aotai',
'aonai',
'aolai',
'aogai',
'aokai',
'aohai',
'aozai',
'aocai',
'aosai',
'aobei',
'aopei',
'aomei',
'aofei',
'aodei',
'aotei',
'aonei',
'aolei',
'aogei',
'aokei',
'aohei',
'aozei',
'aosei',
'aobao',
'aopao',
'aomao',
'aodao',
'aotao',
'aonao',
'aolao',
'aogao',
'aokao',
'aohao',
'aorao',
'aozao',
'aocao',
'aosao',
'aopou',
'aomou',
'aofou',
'aodou',
'aotou',
'aonou',
'aolou',
'aogou',
'aokou',
'aohou',
'aorou',
'aozou',
'aocou',
'aosou',
'aoban',
'aopan',
'aoman',
'aofan',
'aodan',
'aotan',
'aonan',
'aolan',
'aogan',
'aokan',
'aohan',
'aoran',
'aozan',
'aocan',
'aosan',
'aoben',
'aopen',
'aomen',
'aofen',
'aoden',
'aonen',
'aogen',
'aoken',
'aohen',
'aoren',
'aozen',
'aocen',
'aosen',
'aoang',
'aoeng',
'aodia',
'aonia',
'aolia',
'aojia',
'aoqia',
'aoxia',
'aobie',
'aopie',
'aomie',
'aodie',
'aotie',
'aonie',
'aolie',
'aojie',
'aoqie',
'aoxie',
'aoyai',
'aoyao',
'aoyou',
'aomiu',
'aodiu',
'aoniu',
'aoliu',
'aojiu',
'aoqiu',
'aoxiu',
'aoyan',
'aoyin',
'aobin',
'aopin',
'aomin',
'aodin',
'aonin',
'aolin',
'aojin',
'aoqin',
'aoxin',
'aozhu',
'aochu',
'aoshu',
'aogua',
'aokua',
'aohua',
'aorua',
'aoduo',
'aotuo',
'aonuo',
'aoluo',
'aoguo',
'aokuo',
'aohuo',
'aoruo',
'aozuo',
'aocuo',
'aosuo',
'aowai',
'aowei',
'aodui',
'aotui',
'aogui',
'aokui',
'aohui',
'aorui',
'aozui',
'aocui',
'aosui',
'aowan',
'aowen',
'aodun',
'aotun',
'aonun',
'aolun',
'aogun',
'aokun',
'aohun',
'aorun',
'aozun',
'aocun',
'aosun',
'aoyue',
'aonüe',
'aolüe',
'aojue',
'aoque',
'aoxue',
'aoyun',
'aolün',
'aojun',
'aoqun',
'aoxun',
'baori',
'baozi',
'baoci',
'baosi',
'baoba',
'baopa',
'baoma',
'baofa',
'baoda',
'baota',
'baona',
'baola',
'baoga',
'baoka',
'baoha',
'baoza',
'baoca',
'baosa',
'baobo',
'baopo',
'baomo',
'baofo',
'baolo',
'baome',
'baode',
'baote',
'baone',
'baole',
'baoge',
'baoke',
'baohe',
'baore',
'baoze',
'baoce',
'baose',
'baoai',
'baoei',
'baoao',
'baoou',
'baoan',
'baoen',
'baoer',
'baoyi',
'baobi',
'baopi',
'baomi',
'baodi',
'baoti',
'baoni',
'baoli',
'baoji',
'baoqi',
'baoxi',
'baoya',
'baoyo',
'baoye',
'baowu',
'baobu',
'baopu',
'baomu',
'baofu',
'baodu',
'baotu',
'baonu',
'baolu',
'baogu',
'baoku',
'baohu',
'baoru',
'baozu',
'baocu',
'baosu',
'baowa',
'baowo',
'baoyu',
'baonü',
'baolü',
'baoju',
'baoqu',
'baoxu',
'paori',
'paozi',
'paoci',
'paosi',
'paoba',
'paopa',
'paoma',
'paofa',
'paoda',
'paota',
'paona',
'paola',
'paoga',
'paoka',
'paoha',
'paoza',
'paoca',
'paosa',
'paobo',
'paopo',
'paomo',
'paofo',
'paolo',
'paome',
'paode',
'paote',
'paone',
'paole',
'paoge',
'paoke',
'paohe',
'paore',
'paoze',
'paoce',
'paose',
'paoai',
'paoei',
'paoao',
'paoou',
'paoan',
'paoen',
'paoer',
'paoyi',
'paobi',
'paopi',
'paomi',
'paodi',
'paoti',
'paoni',
'paoli',
'paoji',
'paoqi',
'paoxi',
'paoya',
'paoyo',
'paoye',
'paowu',
'paobu',
'paopu',
'paomu',
'paofu',
'paodu',
'paotu',
'paonu',
'paolu',
'paogu',
'paoku',
'paohu',
'paoru',
'paozu',
'paocu',
'paosu',
'paowa',
'paowo',
'paoyu',
'paonü',
'paolü',
'paoju',
'paoqu',
'paoxu',
'maori',
'maozi',
'maoci',
'maosi',
'maoba',
'maopa',
'maoma',
'maofa',
'maoda',
'maota',
'maona',
'maola',
'maoga',
'maoka',
'maoha',
'maoza',
'maoca',
'maosa',
'maobo',
'maopo',
'maomo',
'maofo',
'maolo',
'maome',
'maode',
'maote',
'maone',
'maole',
'maoge',
'maoke',
'maohe',
'maore',
'maoze',
'maoce',
'maose',
'maoai',
'maoei',
'maoao',
'maoou',
'maoan',
'maoen',
'maoer',
'maoyi',
'maobi',
'maopi',
'maomi',
'maodi',
'maoti',
'maoni',
'maoli',
'maoji',
'maoqi',
'maoxi',
'maoya',
'maoyo',
'maoye',
'maowu',
'maobu',
'maopu',
'maomu',
'maofu',
'maodu',
'maotu',
'maonu',
'maolu',
'maogu',
'maoku',
'maohu',
'maoru',
'maozu',
'maocu',
'maosu',
'maowa',
'maowo',
'maoyu',
'maonü',
'maolü',
'maoju',
'maoqu',
'maoxu',
'daori',
'daozi',
'daoci',
'daosi',
'daoba',
'daopa',
'daoma',
'daofa',
'daoda',
'daota',
'daona',
'daola',
'daoga',
'daoka',
'daoha',
'daoza',
'daoca',
'daosa',
'daobo',
'daopo',
'daomo',
'daofo',
'daolo',
'daome',
'daode',
'daote',
'daone',
'daole',
'daoge',
'daoke',
'daohe',
'daore',
'daoze',
'daoce',
'daose',
'daoai',
'daoei',
'daoao',
'daoou',
'daoan',
'daoen',
'daoer',
'daoyi',
'daobi',
'daopi',
'daomi',
'daodi',
'daoti',
'daoni',
'daoli',
'daoji',
'daoqi',
'daoxi',
'daoya',
'daoyo',
'daoye',
'daowu',
'daobu',
'daopu',
'daomu',
'daofu',
'daodu',
'daotu',
'daonu',
'daolu',
'daogu',
'daoku',
'daohu',
'daoru',
'daozu',
'daocu',
'daosu',
'daowa',
'daowo',
'daoyu',
'daonü',
'daolü',
'daoju',
'daoqu',
'daoxu',
'taori',
'taozi',
'taoci',
'taosi',
'taoba',
'taopa',
'taoma',
'taofa',
'taoda',
'taota',
'taona',
'taola',
'taoga',
'taoka',
'taoha',
'taoza',
'taoca',
'taosa',
'taobo',
'taopo',
'taomo',
'taofo',
'taolo',
'taome',
'taode',
'taote',
'taone',
'taole',
'taoge',
'taoke',
'taohe',
'taore',
'taoze',
'taoce',
'taose',
'taoai',
'taoei',
'taoao',
'taoou',
'taoan',
'taoen',
'taoer',
'taoyi',
'taobi',
'taopi',
'taomi',
'taodi',
'taoti',
'taoni',
'taoli',
'taoji',
'taoqi',
'taoxi',
'taoya',
'taoyo',
'taoye',
'taowu',
'taobu',
'taopu',
'taomu',
'taofu',
'taodu',
'taotu',
'taonu',
'taolu',
'taogu',
'taoku',
'taohu',
'taoru',
'taozu',
'taocu',
'taosu',
'taowa',
'taowo',
'taoyu',
'taonü',
'taolü',
'taoju',
'taoqu',
'taoxu',
'naori',
'naozi',
'naoci',
'naosi',
'naoba',
'naopa',
'naoma',
'naofa',
'naoda',
'naota',
'naona',
'naola',
'naoga',
'naoka',
'naoha',
'naoza',
'naoca',
'naosa',
'naobo',
'naopo',
'naomo',
'naofo',
'naolo',
'naome',
'naode',
'naote',
'naone',
'naole',
'naoge',
'naoke',
'naohe',
'naore',
'naoze',
'naoce',
'naose',
'naoai',
'naoei',
'naoao',
'naoou',
'naoan',
'naoen',
'naoer',
'naoyi',
'naobi',
'naopi',
'naomi',
'naodi',
'naoti',
'naoni',
'naoli',
'naoji',
'naoqi',
'naoxi',
'naoya',
'naoyo',
'naoye',
'naowu',
'naobu',
'naopu',
'naomu',
'naofu',
'naodu',
'naotu',
'naonu',
'naolu',
'naogu',
'naoku',
'naohu',
'naoru',
'naozu',
'naocu',
'naosu',
'naowa',
'naowo',
'naoyu',
'naonü',
'naolü',
'naoju',
'naoqu',
'naoxu',
'laori',
'laozi',
'laoci',
'laosi',
'laoba',
'laopa',
'laoma',
'laofa',
'laoda',
'laota',
'laona',
'laola',
'laoga',
'laoka',
'laoha',
'laoza',
'laoca',
'laosa',
'laobo',
'laopo',
'laomo',
'laofo',
'laolo',
'laome',
'laode',
'laote',
'laone',
'laole',
'laoge',
'laoke',
'laohe',
'laore',
'laoze',
'laoce',
'laose',
'laoai',
'laoei',
'laoao',
'laoou',
'laoan',
'laoen',
'laoer',
'laoyi',
'laobi',
'laopi',
'laomi',
'laodi',
'laoti',
'laoni',
'laoli',
'laoji',
'laoqi',
'laoxi',
'laoya',
'laoyo',
'laoye',
'laowu',
'laobu',
'laopu',
'laomu',
'laofu',
'laodu',
'laotu',
'laonu',
'laolu',
'laogu',
'laoku',
'laohu',
'laoru',
'laozu',
'laocu',
'laosu',
'laowa',
'laowo',
'laoyu',
'laonü',
'laolü',
'laoju',
'laoqu',
'laoxu',
'gaori',
'gaozi',
'gaoci',
'gaosi',
'gaoba',
'gaopa',
'gaoma',
'gaofa',
'gaoda',
'gaota',
'gaona',
'gaola',
'gaoga',
'gaoka',
'gaoha',
'gaoza',
'gaoca',
'gaosa',
'gaobo',
'gaopo',
'gaomo',
'gaofo',
'gaolo',
'gaome',
'gaode',
'gaote',
'gaone',
'gaole',
'gaoge',
'gaoke',
'gaohe',
'gaore',
'gaoze',
'gaoce',
'gaose',
'gaoai',
'gaoei',
'gaoao',
'gaoou',
'gaoan',
'gaoen',
'gaoer',
'gaoyi',
'gaobi',
'gaopi',
'gaomi',
'gaodi',
'gaoti',
'gaoni',
'gaoli',
'gaoji',
'gaoqi',
'gaoxi',
'gaoya',
'gaoyo',
'gaoye',
'gaowu',
'gaobu',
'gaopu',
'gaomu',
'gaofu',
'gaodu',
'gaotu',
'gaonu',
'gaolu',
'gaogu',
'gaoku',
'gaohu',
'gaoru',
'gaozu',
'gaocu',
'gaosu',
'gaowa',
'gaowo',
'gaoyu',
'gaonü',
'gaolü',
'gaoju',
'gaoqu',
'gaoxu',
'kaori',
'kaozi',
'kaoci',
'kaosi',
'kaoba',
'kaopa',
'kaoma',
'kaofa',
'kaoda',
'kaota',
'kaona',
'kaola',
'kaoga',
'kaoka',
'kaoha',
'kaoza',
'kaoca',
'kaosa',
'kaobo',
'kaopo',
'kaomo',
'kaofo',
'kaolo',
'kaome',
'kaode',
'kaote',
'kaone',
'kaole',
'kaoge',
'kaoke',
'kaohe',
'kaore',
'kaoze',
'kaoce',
'kaose',
'kaoai',
'kaoei',
'kaoao',
'kaoou',
'kaoan',
'kaoen',
'kaoer',
'kaoyi',
'kaobi',
'kaopi',
'kaomi',
'kaodi',
'kaoti',
'kaoni',
'kaoli',
'kaoji',
'kaoqi',
'kaoxi',
'kaoya',
'kaoyo',
'kaoye',
'kaowu',
'kaobu',
'kaopu',
'kaomu',
'kaofu',
'kaodu',
'kaotu',
'kaonu',
'kaolu',
'kaogu',
'kaoku',
'kaohu',
'kaoru',
'kaozu',
'kaocu',
'kaosu',
'kaowa',
'kaowo',
'kaoyu',
'kaonü',
'kaolü',
'kaoju',
'kaoqu',
'kaoxu',
'haori',
'haozi',
'haoci',
'haosi',
'haoba',
'haopa',
'haoma',
'haofa',
'haoda',
'haota',
'haona',
'haola',
'haoga',
'haoka',
'haoha',
'haoza',
'haoca',
'haosa',
'haobo',
'haopo',
'haomo',
'haofo',
'haolo',
'haome',
'haode',
'haote',
'haone',
'haole',
'haoge',
'haoke',
'haohe',
'haore',
'haoze',
'haoce',
'haose',
'haoai',
'haoei',
'haoao',
'haoou',
'haoan',
'haoen',
'haoer',
'haoyi',
'haobi',
'haopi',
'haomi',
'haodi',
'haoti',
'haoni',
'haoli',
'haoji',
'haoqi',
'haoxi',
'haoya',
'haoyo',
'haoye',
'haowu',
'haobu',
'haopu',
'haomu',
'haofu',
'haodu',
'haotu',
'haonu',
'haolu',
'haogu',
'haoku',
'haohu',
'haoru',
'haozu',
'haocu',
'haosu',
'haowa',
'haowo',
'haoyu',
'haonü',
'haolü',
'haoju',
'haoqu',
'haoxu',
'raori',
'raozi',
'raoci',
'raosi',
'raoba',
'raopa',
'raoma',
'raofa',
'raoda',
'raota',
'raona',
'raola',
'raoga',
'raoka',
'raoha',
'raoza',
'raoca',
'raosa',
'raobo',
'raopo',
'raomo',
'raofo',
'raolo',
'raome',
'raode',
'raote',
'raone',
'raole',
'raoge',
'raoke',
'raohe',
'raore',
'raoze',
'raoce',
'raose',
'raoai',
'raoei',
'raoao',
'raoou',
'raoan',
'raoen',
'raoer',
'raoyi',
'raobi',
'raopi',
'raomi',
'raodi',
'raoti',
'raoni',
'raoli',
'raoji',
'raoqi',
'raoxi',
'raoya',
'raoyo',
'raoye',
'raowu',
'raobu',
'raopu',
'raomu',
'raofu',
'raodu',
'raotu',
'raonu',
'raolu',
'raogu',
'raoku',
'raohu',
'raoru',
'raozu',
'raocu',
'raosu',
'raowa',
'raowo',
'raoyu',
'raonü',
'raolü',
'raoju',
'raoqu',
'raoxu',
'zaori',
'zaozi',
'zaoci',
'zaosi',
'zaoba',
'zaopa',
'zaoma',
'zaofa',
'zaoda',
'zaota',
'zaona',
'zaola',
'zaoga',
'zaoka',
'zaoha',
'zaoza',
'zaoca',
'zaosa',
'zaobo',
'zaopo',
'zaomo',
'zaofo',
'zaolo',
'zaome',
'zaode',
'zaote',
'zaone',
'zaole',
'zaoge',
'zaoke',
'zaohe',
'zaore',
'zaoze',
'zaoce',
'zaose',
'zaoai',
'zaoei',
'zaoao',
'zaoou',
'zaoan',
'zaoen',
'zaoer',
'zaoyi',
'zaobi',
'zaopi',
'zaomi',
'zaodi',
'zaoti',
'zaoni',
'zaoli',
'zaoji',
'zaoqi',
'zaoxi',
'zaoya',
'zaoyo',
'zaoye',
'zaowu',
'zaobu',
'zaopu',
'zaomu',
'zaofu',
'zaodu',
'zaotu',
'zaonu',
'zaolu',
'zaogu',
'zaoku',
'zaohu',
'zaoru',
'zaozu',
'zaocu',
'zaosu',
'zaowa',
'zaowo',
'zaoyu',
'zaonü',
'zaolü',
'zaoju',
'zaoqu',
'zaoxu',
'caori',
'caozi',
'caoci',
'caosi',
'caoba',
'caopa',
'caoma',
'caofa',
'caoda',
'caota',
'caona',
'caola',
'caoga',
'caoka',
'caoha',
'caoza',
'caoca',
'caosa',
'caobo',
'caopo',
'caomo',
'caofo',
'caolo',
'caome',
'caode',
'caote',
'caone',
'caole',
'caoge',
'caoke',
'caohe',
'caore',
'caoze',
'caoce',
'caose',
'caoai',
'caoei',
'caoao',
'caoou',
'caoan',
'caoen',
'caoer',
'caoyi',
'caobi',
'caopi',
'caomi',
'caodi',
'caoti',
'caoni',
'caoli',
'caoji',
'caoqi',
'caoxi',
'caoya',
'caoyo',
'caoye',
'caowu',
'caobu',
'caopu',
'caomu',
'caofu',
'caodu',
'caotu',
'caonu',
'caolu',
'caogu',
'caoku',
'caohu',
'caoru',
'caozu',
'caocu',
'caosu',
'caowa',
'caowo',
'caoyu',
'caonü',
'caolü',
'caoju',
'caoqu',
'caoxu',
'saori',
'saozi',
'saoci',
'saosi',
'saoba',
'saopa',
'saoma',
'saofa',
'saoda',
'saota',
'saona',
'saola',
'saoga',
'saoka',
'saoha',
'saoza',
'saoca',
'saosa',
'saobo',
'saopo',
'saomo',
'saofo',
'saolo',
'saome',
'saode',
'saote',
'saone',
'saole',
'saoge',
'saoke',
'saohe',
'saore',
'saoze',
'saoce',
'saose',
'saoai',
'saoei',
'saoao',
'saoou',
'saoan',
'saoen',
'saoer',
'saoyi',
'saobi',
'saopi',
'saomi',
'saodi',
'saoti',
'saoni',
'saoli',
'saoji',
'saoqi',
'saoxi',
'saoya',
'saoyo',
'saoye',
'saowu',
'saobu',
'saopu',
'saomu',
'saofu',
'saodu',
'saotu',
'saonu',
'saolu',
'saogu',
'saoku',
'saohu',
'saoru',
'saozu',
'saocu',
'saosu',
'saowa',
'saowo',
'saoyu',
'saonü',
'saolü',
'saoju',
'saoqu',
'saoxu',
'ouzhi',
'ouchi',
'oushi',
'ouzha',
'oucha',
'ousha',
'ouzhe',
'ouche',
'oushe',
'oubai',
'oupai',
'oumai',
'oudai',
'outai',
'ounai',
'oulai',
'ougai',
'oukai',
'ouhai',
'ouzai',
'oucai',
'ousai',
'oubei',
'oupei',
'oumei',
'oufei',
'oudei',
'outei',
'ounei',
'oulei',
'ougei',
'oukei',
'ouhei',
'ouzei',
'ousei',
'oubao',
'oupao',
'oumao',
'oudao',
'outao',
'ounao',
'oulao',
'ougao',
'oukao',
'ouhao',
'ourao',
'ouzao',
'oucao',
'ousao',
'oupou',
'oumou',
'oufou',
'oudou',
'outou',
'ounou',
'oulou',
'ougou',
'oukou',
'ouhou',
'ourou',
'ouzou',
'oucou',
'ousou',
'ouban',
'oupan',
'ouman',
'oufan',
'oudan',
'outan',
'ounan',
'oulan',
'ougan',
'oukan',
'ouhan',
'ouran',
'ouzan',
'oucan',
'ousan',
'ouben',
'oupen',
'oumen',
'oufen',
'ouden',
'ounen',
'ougen',
'ouken',
'ouhen',
'ouren',
'ouzen',
'oucen',
'ousen',
'ouang',
'oueng',
'oudia',
'ounia',
'oulia',
'oujia',
'ouqia',
'ouxia',
'oubie',
'oupie',
'oumie',
'oudie',
'outie',
'ounie',
'oulie',
'oujie',
'ouqie',
'ouxie',
'ouyai',
'ouyao',
'ouyou',
'oumiu',
'oudiu',
'ouniu',
'ouliu',
'oujiu',
'ouqiu',
'ouxiu',
'ouyan',
'ouyin',
'oubin',
'oupin',
'oumin',
'oudin',
'ounin',
'oulin',
'oujin',
'ouqin',
'ouxin',
'ouzhu',
'ouchu',
'oushu',
'ougua',
'oukua',
'ouhua',
'ourua',
'ouduo',
'outuo',
'ounuo',
'ouluo',
'ouguo',
'oukuo',
'ouhuo',
'ouruo',
'ouzuo',
'oucuo',
'ousuo',
'ouwai',
'ouwei',
'oudui',
'outui',
'ougui',
'oukui',
'ouhui',
'ourui',
'ouzui',
'oucui',
'ousui',
'ouwan',
'ouwen',
'oudun',
'outun',
'ounun',
'oulun',
'ougun',
'oukun',
'ouhun',
'ourun',
'ouzun',
'oucun',
'ousun',
'ouyue',
'ounüe',
'oulüe',
'oujue',
'ouque',
'ouxue',
'ouyun',
'oulün',
'oujun',
'ouqun',
'ouxun',
'pouri',
'pouzi',
'pouci',
'pousi',
'pouba',
'poupa',
'pouma',
'poufa',
'pouda',
'pouta',
'pouna',
'poula',
'pouga',
'pouka',
'pouha',
'pouza',
'pouca',
'pousa',
'poubo',
'poupo',
'poumo',
'poufo',
'poulo',
'poume',
'poude',
'poute',
'poune',
'poule',
'pouge',
'pouke',
'pouhe',
'poure',
'pouze',
'pouce',
'pouse',
'pouai',
'pouei',
'pouao',
'pouou',
'pouan',
'pouen',
'pouer',
'pouyi',
'poubi',
'poupi',
'poumi',
'poudi',
'pouti',
'pouni',
'pouli',
'pouji',
'pouqi',
'pouxi',
'pouya',
'pouyo',
'pouye',
'pouwu',
'poubu',
'poupu',
'poumu',
'poufu',
'poudu',
'poutu',
'pounu',
'poulu',
'pougu',
'pouku',
'pouhu',
'pouru',
'pouzu',
'poucu',
'pousu',
'pouwa',
'pouwo',
'pouyu',
'pounü',
'poulü',
'pouju',
'pouqu',
'pouxu',
'mouri',
'mouzi',
'mouci',
'mousi',
'mouba',
'moupa',
'mouma',
'moufa',
'mouda',
'mouta',
'mouna',
'moula',
'mouga',
'mouka',
'mouha',
'mouza',
'mouca',
'mousa',
'moubo',
'moupo',
'moumo',
'moufo',
'moulo',
'moume',
'moude',
'moute',
'moune',
'moule',
'mouge',
'mouke',
'mouhe',
'moure',
'mouze',
'mouce',
'mouse',
'mouai',
'mouei',
'mouao',
'mouou',
'mouan',
'mouen',
'mouer',
'mouyi',
'moubi',
'moupi',
'moumi',
'moudi',
'mouti',
'mouni',
'mouli',
'mouji',
'mouqi',
'mouxi',
'mouya',
'mouyo',
'mouye',
'mouwu',
'moubu',
'moupu',
'moumu',
'moufu',
'moudu',
'moutu',
'mounu',
'moulu',
'mougu',
'mouku',
'mouhu',
'mouru',
'mouzu',
'moucu',
'mousu',
'mouwa',
'mouwo',
'mouyu',
'mounü',
'moulü',
'mouju',
'mouqu',
'mouxu',
'fouri',
'fouzi',
'fouci',
'fousi',
'fouba',
'foupa',
'fouma',
'foufa',
'fouda',
'fouta',
'founa',
'foula',
'fouga',
'fouka',
'fouha',
'fouza',
'fouca',
'fousa',
'foubo',
'foupo',
'foumo',
'foufo',
'foulo',
'foume',
'foude',
'foute',
'foune',
'foule',
'fouge',
'fouke',
'fouhe',
'foure',
'fouze',
'fouce',
'fouse',
'fouai',
'fouei',
'fouao',
'fouou',
'fouan',
'fouen',
'fouer',
'fouyi',
'foubi',
'foupi',
'foumi',
'foudi',
'fouti',
'founi',
'fouli',
'fouji',
'fouqi',
'fouxi',
'fouya',
'fouyo',
'fouye',
'fouwu',
'foubu',
'foupu',
'foumu',
'foufu',
'foudu',
'foutu',
'founu',
'foulu',
'fougu',
'fouku',
'fouhu',
'fouru',
'fouzu',
'foucu',
'fousu',
'fouwa',
'fouwo',
'fouyu',
'founü',
'foulü',
'fouju',
'fouqu',
'fouxu',
'douri',
'douzi',
'douci',
'dousi',
'douba',
'doupa',
'douma',
'doufa',
'douda',
'douta',
'douna',
'doula',
'douga',
'douka',
'douha',
'douza',
'douca',
'dousa',
'doubo',
'doupo',
'doumo',
'doufo',
'doulo',
'doume',
'doude',
'doute',
'doune',
'doule',
'douge',
'douke',
'douhe',
'doure',
'douze',
'douce',
'douse',
'douai',
'douei',
'douao',
'douou',
'douan',
'douen',
'douer',
'douyi',
'doubi',
'doupi',
'doumi',
'doudi',
'douti',
'douni',
'douli',
'douji',
'douqi',
'douxi',
'douya',
'douyo',
'douye',
'douwu',
'doubu',
'doupu',
'doumu',
'doufu',
'doudu',
'doutu',
'dounu',
'doulu',
'dougu',
'douku',
'douhu',
'douru',
'douzu',
'doucu',
'dousu',
'douwa',
'douwo',
'douyu',
'dounü',
'doulü',
'douju',
'douqu',
'douxu',
'touri',
'touzi',
'touci',
'tousi',
'touba',
'toupa',
'touma',
'toufa',
'touda',
'touta',
'touna',
'toula',
'touga',
'touka',
'touha',
'touza',
'touca',
'tousa',
'toubo',
'toupo',
'toumo',
'toufo',
'toulo',
'toume',
'toude',
'toute',
'toune',
'toule',
'touge',
'touke',
'touhe',
'toure',
'touze',
'touce',
'touse',
'touai',
'touei',
'touao',
'touou',
'touan',
'touen',
'touer',
'touyi',
'toubi',
'toupi',
'toumi',
'toudi',
'touti',
'touni',
'touli',
'touji',
'touqi',
'touxi',
'touya',
'touyo',
'touye',
'touwu',
'toubu',
'toupu',
'toumu',
'toufu',
'toudu',
'toutu',
'tounu',
'toulu',
'tougu',
'touku',
'touhu',
'touru',
'touzu',
'toucu',
'tousu',
'touwa',
'touwo',
'touyu',
'tounü',
'toulü',
'touju',
'touqu',
'touxu',
'nouri',
'nouzi',
'nouci',
'nousi',
'nouba',
'noupa',
'nouma',
'noufa',
'nouda',
'nouta',
'nouna',
'noula',
'nouga',
'nouka',
'nouha',
'nouza',
'nouca',
'nousa',
'noubo',
'noupo',
'noumo',
'noufo',
'noulo',
'noume',
'noude',
'noute',
'noune',
'noule',
'nouge',
'nouke',
'nouhe',
'noure',
'nouze',
'nouce',
'nouse',
'nouai',
'nouei',
'nouao',
'nouou',
'nouan',
'nouen',
'nouer',
'nouyi',
'noubi',
'noupi',
'noumi',
'noudi',
'nouti',
'nouni',
'nouli',
'nouji',
'nouqi',
'nouxi',
'nouya',
'nouyo',
'nouye',
'nouwu',
'noubu',
'noupu',
'noumu',
'noufu',
'noudu',
'noutu',
'nounu',
'noulu',
'nougu',
'nouku',
'nouhu',
'nouru',
'nouzu',
'noucu',
'nousu',
'nouwa',
'nouwo',
'nouyu',
'nounü',
'noulü',
'nouju',
'nouqu',
'nouxu',
'louri',
'louzi',
'louci',
'lousi',
'louba',
'loupa',
'louma',
'loufa',
'louda',
'louta',
'louna',
'loula',
'louga',
'louka',
'louha',
'louza',
'louca',
'lousa',
'loubo',
'loupo',
'loumo',
'loufo',
'loulo',
'loume',
'loude',
'loute',
'loune',
'loule',
'louge',
'louke',
'louhe',
'loure',
'louze',
'louce',
'louse',
'louai',
'louei',
'louao',
'louou',
'louan',
'louen',
'louer',
'louyi',
'loubi',
'loupi',
'loumi',
'loudi',
'louti',
'louni',
'louli',
'louji',
'louqi',
'louxi',
'louya',
'louyo',
'louye',
'louwu',
'loubu',
'loupu',
'loumu',
'loufu',
'loudu',
'loutu',
'lounu',
'loulu',
'lougu',
'louku',
'louhu',
'louru',
'louzu',
'loucu',
'lousu',
'louwa',
'louwo',
'louyu',
'lounü',
'loulü',
'louju',
'louqu',
'louxu',
'gouri',
'gouzi',
'gouci',
'gousi',
'gouba',
'goupa',
'gouma',
'goufa',
'gouda',
'gouta',
'gouna',
'goula',
'gouga',
'gouka',
'gouha',
'gouza',
'gouca',
'gousa',
'goubo',
'goupo',
'goumo',
'goufo',
'goulo',
'goume',
'goude',
'goute',
'goune',
'goule',
'gouge',
'gouke',
'gouhe',
'goure',
'gouze',
'gouce',
'gouse',
'gouai',
'gouei',
'gouao',
'gouou',
'gouan',
'gouen',
'gouer',
'gouyi',
'goubi',
'goupi',
'goumi',
'goudi',
'gouti',
'gouni',
'gouli',
'gouji',
'gouqi',
'gouxi',
'gouya',
'gouyo',
'gouye',
'gouwu',
'goubu',
'goupu',
'goumu',
'goufu',
'goudu',
'goutu',
'gounu',
'goulu',
'gougu',
'gouku',
'gouhu',
'gouru',
'gouzu',
'goucu',
'gousu',
'gouwa',
'gouwo',
'gouyu',
'gounü',
'goulü',
'gouju',
'gouqu',
'gouxu',
'kouri',
'kouzi',
'kouci',
'kousi',
'kouba',
'koupa',
'kouma',
'koufa',
'kouda',
'kouta',
'kouna',
'koula',
'kouga',
'kouka',
'kouha',
'kouza',
'kouca',
'kousa',
'koubo',
'koupo',
'koumo',
'koufo',
'koulo',
'koume',
'koude',
'koute',
'koune',
'koule',
'kouge',
'kouke',
'kouhe',
'koure',
'kouze',
'kouce',
'kouse',
'kouai',
'kouei',
'kouao',
'kouou',
'kouan',
'kouen',
'kouer',
'kouyi',
'koubi',
'koupi',
'koumi',
'koudi',
'kouti',
'kouni',
'kouli',
'kouji',
'kouqi',
'kouxi',
'kouya',
'kouyo',
'kouye',
'kouwu',
'koubu',
'koupu',
'koumu',
'koufu',
'koudu',
'koutu',
'kounu',
'koulu',
'kougu',
'kouku',
'kouhu',
'kouru',
'kouzu',
'koucu',
'kousu',
'kouwa',
'kouwo',
'kouyu',
'kounü',
'koulü',
'kouju',
'kouqu',
'kouxu',
'houri',
'houzi',
'houci',
'housi',
'houba',
'houpa',
'houma',
'houfa',
'houda',
'houta',
'houna',
'houla',
'houga',
'houka',
'houha',
'houza',
'houca',
'housa',
'houbo',
'houpo',
'houmo',
'houfo',
'houlo',
'houme',
'houde',
'houte',
'houne',
'houle',
'houge',
'houke',
'houhe',
'houre',
'houze',
'houce',
'house',
'houai',
'houei',
'houao',
'houou',
'houan',
'houen',
'houer',
'houyi',
'houbi',
'houpi',
'houmi',
'houdi',
'houti',
'houni',
'houli',
'houji',
'houqi',
'houxi',
'houya',
'houyo',
'houye',
'houwu',
'houbu',
'houpu',
'houmu',
'houfu',
'houdu',
'houtu',
'hounu',
'houlu',
'hougu',
'houku',
'houhu',
'houru',
'houzu',
'houcu',
'housu',
'houwa',
'houwo',
'houyu',
'hounü',
'houlü',
'houju',
'houqu',
'houxu',
'rouri',
'rouzi',
'rouci',
'rousi',
'rouba',
'roupa',
'rouma',
'roufa',
'rouda',
'routa',
'rouna',
'roula',
'rouga',
'rouka',
'rouha',
'rouza',
'rouca',
'rousa',
'roubo',
'roupo',
'roumo',
'roufo',
'roulo',
'roume',
'roude',
'route',
'roune',
'roule',
'rouge',
'rouke',
'rouhe',
'roure',
'rouze',
'rouce',
'rouse',
'rouai',
'rouei',
'rouao',
'rouou',
'rouan',
'rouen',
'rouer',
'rouyi',
'roubi',
'roupi',
'roumi',
'roudi',
'routi',
'rouni',
'rouli',
'rouji',
'rouqi',
'rouxi',
'rouya',
'rouyo',
'rouye',
'rouwu',
'roubu',
'roupu',
'roumu',
'roufu',
'roudu',
'routu',
'rounu',
'roulu',
'rougu',
'rouku',
'rouhu',
'rouru',
'rouzu',
'roucu',
'rousu',
'rouwa',
'rouwo',
'rouyu',
'rounü',
'roulü',
'rouju',
'rouqu',
'rouxu',
'zouri',
'zouzi',
'zouci',
'zousi',
'zouba',
'zoupa',
'zouma',
'zoufa',
'zouda',
'zouta',
'zouna',
'zoula',
'zouga',
'zouka',
'zouha',
'zouza',
'zouca',
'zousa',
'zoubo',
'zoupo',
'zoumo',
'zoufo',
'zoulo',
'zoume',
'zoude',
'zoute',
'zoune',
'zoule',
'zouge',
'zouke',
'zouhe',
'zoure',
'zouze',
'zouce',
'zouse',
'zouai',
'zouei',
'zouao',
'zouou',
'zouan',
'zouen',
'zouer',
'zouyi',
'zoubi',
'zoupi',
'zoumi',
'zoudi',
'zouti',
'zouni',
'zouli',
'zouji',
'zouqi',
'zouxi',
'zouya',
'zouyo',
'zouye',
'zouwu',
'zoubu',
'zoupu',
'zoumu',
'zoufu',
'zoudu',
'zoutu',
'zounu',
'zoulu',
'zougu',
'zouku',
'zouhu',
'zouru',
'zouzu',
'zoucu',
'zousu',
'zouwa',
'zouwo',
'zouyu',
'zounü',
'zoulü',
'zouju',
'zouqu',
'zouxu',
'couri',
'couzi',
'couci',
'cousi',
'couba',
'coupa',
'couma',
'coufa',
'couda',
'couta',
'couna',
'coula',
'couga',
'couka',
'couha',
'couza',
'couca',
'cousa',
'coubo',
'coupo',
'coumo',
'coufo',
'coulo',
'coume',
'coude',
'coute',
'coune',
'coule',
'couge',
'couke',
'couhe',
'coure',
'couze',
'couce',
'couse',
'couai',
'couei',
'couao',
'couou',
'couan',
'couen',
'couer',
'couyi',
'coubi',
'coupi',
'coumi',
'coudi',
'couti',
'couni',
'couli',
'couji',
'couqi',
'couxi',
'couya',
'couyo',
'couye',
'couwu',
'coubu',
'coupu',
'coumu',
'coufu',
'coudu',
'coutu',
'counu',
'coulu',
'cougu',
'couku',
'couhu',
'couru',
'couzu',
'coucu',
'cousu',
'couwa',
'couwo',
'couyu',
'counü',
'coulü',
'couju',
'couqu',
'couxu',
'souri',
'souzi',
'souci',
'sousi',
'souba',
'soupa',
'souma',
'soufa',
'souda',
'souta',
'souna',
'soula',
'souga',
'souka',
'souha',
'souza',
'souca',
'sousa',
'soubo',
'soupo',
'soumo',
'soufo',
'soulo',
'soume',
'soude',
'soute',
'soune',
'soule',
'souge',
'souke',
'souhe',
'soure',
'souze',
'souce',
'souse',
'souai',
'souei',
'souao',
'souou',
'souan',
'souen',
'souer',
'souyi',
'soubi',
'soupi',
'soumi',
'soudi',
'souti',
'souni',
'souli',
'souji',
'souqi',
'souxi',
'souya',
'souyo',
'souye',
'souwu',
'soubu',
'soupu',
'soumu',
'soufu',
'soudu',
'soutu',
'sounu',
'soulu',
'sougu',
'souku',
'souhu',
'souru',
'souzu',
'soucu',
'sousu',
'souwa',
'souwo',
'souyu',
'sounü',
'soulü',
'souju',
'souqu',
'souxu',
'anzhi',
'anchi',
'anshi',
'anzha',
'ancha',
'ansha',
'anzhe',
'anche',
'anshe',
'anbai',
'anpai',
'anmai',
'andai',
'antai',
'annai',
'anlai',
'angai',
'ankai',
'anhai',
'anzai',
'ancai',
'ansai',
'anbei',
'anpei',
'anmei',
'anfei',
'andei',
'antei',
'annei',
'anlei',
'angei',
'ankei',
'anhei',
'anzei',
'ansei',
'anbao',
'anpao',
'anmao',
'andao',
'antao',
'annao',
'anlao',
'angao',
'ankao',
'anhao',
'anrao',
'anzao',
'ancao',
'ansao',
'anpou',
'anmou',
'anfou',
'andou',
'antou',
'annou',
'anlou',
'angou',
'ankou',
'anhou',
'anrou',
'anzou',
'ancou',
'ansou',
'anban',
'anpan',
'anman',
'anfan',
'andan',
'antan',
'annan',
'anlan',
'angan',
'ankan',
'anhan',
'anran',
'anzan',
'ancan',
'ansan',
'anben',
'anpen',
'anmen',
'anfen',
'anden',
'annen',
'angen',
'anken',
'anhen',
'anren',
'anzen',
'ancen',
'ansen',
'anang',
'aneng',
'andia',
'annia',
'anlia',
'anjia',
'anqia',
'anxia',
'anbie',
'anpie',
'anmie',
'andie',
'antie',
'annie',
'anlie',
'anjie',
'anqie',
'anxie',
'anyai',
'anyao',
'anyou',
'anmiu',
'andiu',
'anniu',
'anliu',
'anjiu',
'anqiu',
'anxiu',
'anyan',
'anyin',
'anbin',
'anpin',
'anmin',
'andin',
'annin',
'anlin',
'anjin',
'anqin',
'anxin',
'anzhu',
'anchu',
'anshu',
'angua',
'ankua',
'anhua',
'anrua',
'anduo',
'antuo',
'annuo',
'anluo',
'anguo',
'ankuo',
'anhuo',
'anruo',
'anzuo',
'ancuo',
'ansuo',
'anwai',
'anwei',
'andui',
'antui',
'angui',
'ankui',
'anhui',
'anrui',
'anzui',
'ancui',
'ansui',
'anwan',
'anwen',
'andun',
'antun',
'annun',
'anlun',
'angun',
'ankun',
'anhun',
'anrun',
'anzun',
'ancun',
'ansun',
'anyue',
'annüe',
'anlüe',
'anjue',
'anque',
'anxue',
'anyun',
'anlün',
'anjun',
'anqun',
'anxun',
'banri',
'banzi',
'banci',
'bansi',
'banba',
'banpa',
'banma',
'banfa',
'banda',
'banta',
'banna',
'banla',
'banga',
'banka',
'banha',
'banza',
'banca',
'bansa',
'banbo',
'banpo',
'banmo',
'banfo',
'banlo',
'banme',
'bande',
'bante',
'banne',
'banle',
'bange',
'banke',
'banhe',
'banre',
'banze',
'bance',
'banse',
'banai',
'banei',
'banao',
'banou',
'banan',
'banen',
'baner',
'banyi',
'banbi',
'banpi',
'banmi',
'bandi',
'banti',
'banni',
'banli',
'banji',
'banqi',
'banxi',
'banya',
'banyo',
'banye',
'banwu',
'banbu',
'banpu',
'banmu',
'banfu',
'bandu',
'bantu',
'bannu',
'banlu',
'bangu',
'banku',
'banhu',
'banru',
'banzu',
'bancu',
'bansu',
'banwa',
'banwo',
'banyu',
'bannü',
'banlü',
'banju',
'banqu',
'banxu',
'panri',
'panzi',
'panci',
'pansi',
'panba',
'panpa',
'panma',
'panfa',
'panda',
'panta',
'panna',
'panla',
'panga',
'panka',
'panha',
'panza',
'panca',
'pansa',
'panbo',
'panpo',
'panmo',
'panfo',
'panlo',
'panme',
'pande',
'pante',
'panne',
'panle',
'pange',
'panke',
'panhe',
'panre',
'panze',
'pance',
'panse',
'panai',
'panei',
'panao',
'panou',
'panan',
'panen',
'paner',
'panyi',
'panbi',
'panpi',
'panmi',
'pandi',
'panti',
'panni',
'panli',
'panji',
'panqi',
'panxi',
'panya',
'panyo',
'panye',
'panwu',
'panbu',
'panpu',
'panmu',
'panfu',
'pandu',
'pantu',
'pannu',
'panlu',
'pangu',
'panku',
'panhu',
'panru',
'panzu',
'pancu',
'pansu',
'panwa',
'panwo',
'panyu',
'pannü',
'panlü',
'panju',
'panqu',
'panxu',
'manri',
'manzi',
'manci',
'mansi',
'manba',
'manpa',
'manma',
'manfa',
'manda',
'manta',
'manna',
'manla',
'manga',
'manka',
'manha',
'manza',
'manca',
'mansa',
'manbo',
'manpo',
'manmo',
'manfo',
'manlo',
'manme',
'mande',
'mante',
'manne',
'manle',
'mange',
'manke',
'manhe',
'manre',
'manze',
'mance',
'manse',
'manai',
'manei',
'manao',
'manou',
'manan',
'manen',
'maner',
'manyi',
'manbi',
'manpi',
'manmi',
'mandi',
'manti',
'manni',
'manli',
'manji',
'manqi',
'manxi',
'manya',
'manyo',
'manye',
'manwu',
'manbu',
'manpu',
'manmu',
'manfu',
'mandu',
'mantu',
'mannu',
'manlu',
'mangu',
'manku',
'manhu',
'manru',
'manzu',
'mancu',
'mansu',
'manwa',
'manwo',
'manyu',
'mannü',
'manlü',
'manju',
'manqu',
'manxu',
'fanri',
'fanzi',
'fanci',
'fansi',
'fanba',
'fanpa',
'fanma',
'fanfa',
'fanda',
'fanta',
'fanna',
'fanla',
'fanga',
'fanka',
'fanha',
'fanza',
'fanca',
'fansa',
'fanbo',
'fanpo',
'fanmo',
'fanfo',
'fanlo',
'fanme',
'fande',
'fante',
'fanne',
'fanle',
'fange',
'fanke',
'fanhe',
'fanre',
'fanze',
'fance',
'fanse',
'fanai',
'fanei',
'fanao',
'fanou',
'fanan',
'fanen',
'faner',
'fanyi',
'fanbi',
'fanpi',
'fanmi',
'fandi',
'fanti',
'fanni',
'fanli',
'fanji',
'fanqi',
'fanxi',
'fanya',
'fanyo',
'fanye',
'fanwu',
'fanbu',
'fanpu',
'fanmu',
'fanfu',
'fandu',
'fantu',
'fannu',
'fanlu',
'fangu',
'fanku',
'fanhu',
'fanru',
'fanzu',
'fancu',
'fansu',
'fanwa',
'fanwo',
'fanyu',
'fannü',
'fanlü',
'fanju',
'fanqu',
'fanxu',
'danri',
'danzi',
'danci',
'dansi',
'danba',
'danpa',
'danma',
'danfa',
'danda',
'danta',
'danna',
'danla',
'danga',
'danka',
'danha',
'danza',
'danca',
'dansa',
'danbo',
'danpo',
'danmo',
'danfo',
'danlo',
'danme',
'dande',
'dante',
'danne',
'danle',
'dange',
'danke',
'danhe',
'danre',
'danze',
'dance',
'danse',
'danai',
'danei',
'danao',
'danou',
'danan',
'danen',
'daner',
'danyi',
'danbi',
'danpi',
'danmi',
'dandi',
'danti',
'danni',
'danli',
'danji',
'danqi',
'danxi',
'danya',
'danyo',
'danye',
'danwu',
'danbu',
'danpu',
'danmu',
'danfu',
'dandu',
'dantu',
'dannu',
'danlu',
'dangu',
'danku',
'danhu',
'danru',
'danzu',
'dancu',
'dansu',
'danwa',
'danwo',
'danyu',
'dannü',
'danlü',
'danju',
'danqu',
'danxu',
'tanri',
'tanzi',
'tanci',
'tansi',
'tanba',
'tanpa',
'tanma',
'tanfa',
'tanda',
'tanta',
'tanna',
'tanla',
'tanga',
'tanka',
'tanha',
'tanza',
'tanca',
'tansa',
'tanbo',
'tanpo',
'tanmo',
'tanfo',
'tanlo',
'tanme',
'tande',
'tante',
'tanne',
'tanle',
'tange',
'tanke',
'tanhe',
'tanre',
'tanze',
'tance',
'tanse',
'tanai',
'tanei',
'tanao',
'tanou',
'tanan',
'tanen',
'taner',
'tanyi',
'tanbi',
'tanpi',
'tanmi',
'tandi',
'tanti',
'tanni',
'tanli',
'tanji',
'tanqi',
'tanxi',
'tanya',
'tanyo',
'tanye',
'tanwu',
'tanbu',
'tanpu',
'tanmu',
'tanfu',
'tandu',
'tantu',
'tannu',
'tanlu',
'tangu',
'tanku',
'tanhu',
'tanru',
'tanzu',
'tancu',
'tansu',
'tanwa',
'tanwo',
'tanyu',
'tannü',
'tanlü',
'tanju',
'tanqu',
'tanxu',
'nanri',
'nanzi',
'nanci',
'nansi',
'nanba',
'nanpa',
'nanma',
'nanfa',
'nanda',
'nanta',
'nanna',
'nanla',
'nanga',
'nanka',
'nanha',
'nanza',
'nanca',
'nansa',
'nanbo',
'nanpo',
'nanmo',
'nanfo',
'nanlo',
'nanme',
'nande',
'nante',
'nanne',
'nanle',
'nange',
'nanke',
'nanhe',
'nanre',
'nanze',
'nance',
'nanse',
'nanai',
'nanei',
'nanao',
'nanou',
'nanan',
'nanen',
'naner',
'nanyi',
'nanbi',
'nanpi',
'nanmi',
'nandi',
'nanti',
'nanni',
'nanli',
'nanji',
'nanqi',
'nanxi',
'nanya',
'nanyo',
'nanye',
'nanwu',
'nanbu',
'nanpu',
'nanmu',
'nanfu',
'nandu',
'nantu',
'nannu',
'nanlu',
'nangu',
'nanku',
'nanhu',
'nanru',
'nanzu',
'nancu',
'nansu',
'nanwa',
'nanwo',
'nanyu',
'nannü',
'nanlü',
'nanju',
'nanqu',
'nanxu',
'lanri',
'lanzi',
'lanci',
'lansi',
'lanba',
'lanpa',
'lanma',
'lanfa',
'landa',
'lanta',
'lanna',
'lanla',
'langa',
'lanka',
'lanha',
'lanza',
'lanca',
'lansa',
'lanbo',
'lanpo',
'lanmo',
'lanfo',
'lanlo',
'lanme',
'lande',
'lante',
'lanne',
'lanle',
'lange',
'lanke',
'lanhe',
'lanre',
'lanze',
'lance',
'lanse',
'lanai',
'lanei',
'lanao',
'lanou',
'lanan',
'lanen',
'laner',
'lanyi',
'lanbi',
'lanpi',
'lanmi',
'landi',
'lanti',
'lanni',
'lanli',
'lanji',
'lanqi',
'lanxi',
'lanya',
'lanyo',
'lanye',
'lanwu',
'lanbu',
'lanpu',
'lanmu',
'lanfu',
'landu',
'lantu',
'lannu',
'lanlu',
'langu',
'lanku',
'lanhu',
'lanru',
'lanzu',
'lancu',
'lansu',
'lanwa',
'lanwo',
'lanyu',
'lannü',
'lanlü',
'lanju',
'lanqu',
'lanxu',
'ganri',
'ganzi',
'ganci',
'gansi',
'ganba',
'ganpa',
'ganma',
'ganfa',
'ganda',
'ganta',
'ganna',
'ganla',
'ganga',
'ganka',
'ganha',
'ganza',
'ganca',
'gansa',
'ganbo',
'ganpo',
'ganmo',
'ganfo',
'ganlo',
'ganme',
'gande',
'gante',
'ganne',
'ganle',
'gange',
'ganke',
'ganhe',
'ganre',
'ganze',
'gance',
'ganse',
'ganai',
'ganei',
'ganao',
'ganou',
'ganan',
'ganen',
'ganer',
'ganyi',
'ganbi',
'ganpi',
'ganmi',
'gandi',
'ganti',
'ganni',
'ganli',
'ganji',
'ganqi',
'ganxi',
'ganya',
'ganyo',
'ganye',
'ganwu',
'ganbu',
'ganpu',
'ganmu',
'ganfu',
'gandu',
'gantu',
'gannu',
'ganlu',
'gangu',
'ganku',
'ganhu',
'ganru',
'ganzu',
'gancu',
'gansu',
'ganwa',
'ganwo',
'ganyu',
'gannü',
'ganlü',
'ganju',
'ganqu',
'ganxu',
'kanri',
'kanzi',
'kanci',
'kansi',
'kanba',
'kanpa',
'kanma',
'kanfa',
'kanda',
'kanta',
'kanna',
'kanla',
'kanga',
'kanka',
'kanha',
'kanza',
'kanca',
'kansa',
'kanbo',
'kanpo',
'kanmo',
'kanfo',
'kanlo',
'kanme',
'kande',
'kante',
'kanne',
'kanle',
'kange',
'kanke',
'kanhe',
'kanre',
'kanze',
'kance',
'kanse',
'kanai',
'kanei',
'kanao',
'kanou',
'kanan',
'kanen',
'kaner',
'kanyi',
'kanbi',
'kanpi',
'kanmi',
'kandi',
'kanti',
'kanni',
'kanli',
'kanji',
'kanqi',
'kanxi',
'kanya',
'kanyo',
'kanye',
'kanwu',
'kanbu',
'kanpu',
'kanmu',
'kanfu',
'kandu',
'kantu',
'kannu',
'kanlu',
'kangu',
'kanku',
'kanhu',
'kanru',
'kanzu',
'kancu',
'kansu',
'kanwa',
'kanwo',
'kanyu',
'kannü',
'kanlü',
'kanju',
'kanqu',
'kanxu',
'hanri',
'hanzi',
'hanci',
'hansi',
'hanba',
'hanpa',
'hanma',
'hanfa',
'handa',
'hanta',
'hanna',
'hanla',
'hanga',
'hanka',
'hanha',
'hanza',
'hanca',
'hansa',
'hanbo',
'hanpo',
'hanmo',
'hanfo',
'hanlo',
'hanme',
'hande',
'hante',
'hanne',
'hanle',
'hange',
'hanke',
'hanhe',
'hanre',
'hanze',
'hance',
'hanse',
'hanai',
'hanei',
'hanao',
'hanou',
'hanan',
'hanen',
'haner',
'hanyi',
'hanbi',
'hanpi',
'hanmi',
'handi',
'hanti',
'hanni',
'hanli',
'hanji',
'hanqi',
'hanxi',
'hanya',
'hanyo',
'hanye',
'hanwu',
'hanbu',
'hanpu',
'hanmu',
'hanfu',
'handu',
'hantu',
'hannu',
'hanlu',
'hangu',
'hanku',
'hanhu',
'hanru',
'hanzu',
'hancu',
'hansu',
'hanwa',
'hanwo',
'hanyu',
'hannü',
'hanlü',
'hanju',
'hanqu',
'hanxu',
'ranri',
'ranzi',
'ranci',
'ransi',
'ranba',
'ranpa',
'ranma',
'ranfa',
'randa',
'ranta',
'ranna',
'ranla',
'ranga',
'ranka',
'ranha',
'ranza',
'ranca',
'ransa',
'ranbo',
'ranpo',
'ranmo',
'ranfo',
'ranlo',
'ranme',
'rande',
'rante',
'ranne',
'ranle',
'range',
'ranke',
'ranhe',
'ranre',
'ranze',
'rance',
'ranse',
'ranai',
'ranei',
'ranao',
'ranou',
'ranan',
'ranen',
'raner',
'ranyi',
'ranbi',
'ranpi',
'ranmi',
'randi',
'ranti',
'ranni',
'ranli',
'ranji',
'ranqi',
'ranxi',
'ranya',
'ranyo',
'ranye',
'ranwu',
'ranbu',
'ranpu',
'ranmu',
'ranfu',
'randu',
'rantu',
'rannu',
'ranlu',
'rangu',
'ranku',
'ranhu',
'ranru',
'ranzu',
'rancu',
'ransu',
'ranwa',
'ranwo',
'ranyu',
'rannü',
'ranlü',
'ranju',
'ranqu',
'ranxu',
'zanri',
'zanzi',
'zanci',
'zansi',
'zanba',
'zanpa',
'zanma',
'zanfa',
'zanda',
'zanta',
'zanna',
'zanla',
'zanga',
'zanka',
'zanha',
'zanza',
'zanca',
'zansa',
'zanbo',
'zanpo',
'zanmo',
'zanfo',
'zanlo',
'zanme',
'zande',
'zante',
'zanne',
'zanle',
'zange',
'zanke',
'zanhe',
'zanre',
'zanze',
'zance',
'zanse',
'zanai',
'zanei',
'zanao',
'zanou',
'zanan',
'zanen',
'zaner',
'zanyi',
'zanbi',
'zanpi',
'zanmi',
'zandi',
'zanti',
'zanni',
'zanli',
'zanji',
'zanqi',
'zanxi',
'zanya',
'zanyo',
'zanye',
'zanwu',
'zanbu',
'zanpu',
'zanmu',
'zanfu',
'zandu',
'zantu',
'zannu',
'zanlu',
'zangu',
'zanku',
'zanhu',
'zanru',
'zanzu',
'zancu',
'zansu',
'zanwa',
'zanwo',
'zanyu',
'zannü',
'zanlü',
'zanju',
'zanqu',
'zanxu',
'canri',
'canzi',
'canci',
'cansi',
'canba',
'canpa',
'canma',
'canfa',
'canda',
'canta',
'canna',
'canla',
'canga',
'canka',
'canha',
'canza',
'canca',
'cansa',
'canbo',
'canpo',
'canmo',
'canfo',
'canlo',
'canme',
'cande',
'cante',
'canne',
'canle',
'cange',
'canke',
'canhe',
'canre',
'canze',
'cance',
'canse',
'canai',
'canei',
'canao',
'canou',
'canan',
'canen',
'caner',
'canyi',
'canbi',
'canpi',
'canmi',
'candi',
'canti',
'canni',
'canli',
'canji',
'canqi',
'canxi',
'canya',
'canyo',
'canye',
'canwu',
'canbu',
'canpu',
'canmu',
'canfu',
'candu',
'cantu',
'cannu',
'canlu',
'cangu',
'canku',
'canhu',
'canru',
'canzu',
'cancu',
'cansu',
'canwa',
'canwo',
'canyu',
'cannü',
'canlü',
'canju',
'canqu',
'canxu',
'sanri',
'sanzi',
'sanci',
'sansi',
'sanba',
'sanpa',
'sanma',
'sanfa',
'sanda',
'santa',
'sanna',
'sanla',
'sanga',
'sanka',
'sanha',
'sanza',
'sanca',
'sansa',
'sanbo',
'sanpo',
'sanmo',
'sanfo',
'sanlo',
'sanme',
'sande',
'sante',
'sanne',
'sanle',
'sange',
'sanke',
'sanhe',
'sanre',
'sanze',
'sance',
'sanse',
'sanai',
'sanei',
'sanao',
'sanou',
'sanan',
'sanen',
'saner',
'sanyi',
'sanbi',
'sanpi',
'sanmi',
'sandi',
'santi',
'sanni',
'sanli',
'sanji',
'sanqi',
'sanxi',
'sanya',
'sanyo',
'sanye',
'sanwu',
'sanbu',
'sanpu',
'sanmu',
'sanfu',
'sandu',
'santu',
'sannu',
'sanlu',
'sangu',
'sanku',
'sanhu',
'sanru',
'sanzu',
'sancu',
'sansu',
'sanwa',
'sanwo',
'sanyu',
'sannü',
'sanlü',
'sanju',
'sanqu',
'sanxu',
'enzhi',
'enchi',
'enshi',
'enzha',
'encha',
'ensha',
'enzhe',
'enche',
'enshe',
'enbai',
'enpai',
'enmai',
'endai',
'entai',
'ennai',
'enlai',
'engai',
'enkai',
'enhai',
'enzai',
'encai',
'ensai',
'enbei',
'enpei',
'enmei',
'enfei',
'endei',
'entei',
'ennei',
'enlei',
'engei',
'enkei',
'enhei',
'enzei',
'ensei',
'enbao',
'enpao',
'enmao',
'endao',
'entao',
'ennao',
'enlao',
'engao',
'enkao',
'enhao',
'enrao',
'enzao',
'encao',
'ensao',
'enpou',
'enmou',
'enfou',
'endou',
'entou',
'ennou',
'enlou',
'engou',
'enkou',
'enhou',
'enrou',
'enzou',
'encou',
'ensou',
'enban',
'enpan',
'enman',
'enfan',
'endan',
'entan',
'ennan',
'enlan',
'engan',
'enkan',
'enhan',
'enran',
'enzan',
'encan',
'ensan',
'enben',
'enpen',
'enmen',
'enfen',
'enden',
'ennen',
'engen',
'enken',
'enhen',
'enren',
'enzen',
'encen',
'ensen',
'enang',
'eneng',
'endia',
'ennia',
'enlia',
'enjia',
'enqia',
'enxia',
'enbie',
'enpie',
'enmie',
'endie',
'entie',
'ennie',
'enlie',
'enjie',
'enqie',
'enxie',
'enyai',
'enyao',
'enyou',
'enmiu',
'endiu',
'enniu',
'enliu',
'enjiu',
'enqiu',
'enxiu',
'enyan',
'enyin',
'enbin',
'enpin',
'enmin',
'endin',
'ennin',
'enlin',
'enjin',
'enqin',
'enxin',
'enzhu',
'enchu',
'enshu',
'engua',
'enkua',
'enhua',
'enrua',
'enduo',
'entuo',
'ennuo',
'enluo',
'enguo',
'enkuo',
'enhuo',
'enruo',
'enzuo',
'encuo',
'ensuo',
'enwai',
'enwei',
'endui',
'entui',
'engui',
'enkui',
'enhui',
'enrui',
'enzui',
'encui',
'ensui',
'enwan',
'enwen',
'endun',
'entun',
'ennun',
'enlun',
'engun',
'enkun',
'enhun',
'enrun',
'enzun',
'encun',
'ensun',
'enyue',
'ennüe',
'enlüe',
'enjue',
'enque',
'enxue',
'enyun',
'enlün',
'enjun',
'enqun',
'enxun',
'benri',
'benzi',
'benci',
'bensi',
'benba',
'benpa',
'benma',
'benfa',
'benda',
'benta',
'benna',
'benla',
'benga',
'benka',
'benha',
'benza',
'benca',
'bensa',
'benbo',
'benpo',
'benmo',
'benfo',
'benlo',
'benme',
'bende',
'bente',
'benne',
'benle',
'benge',
'benke',
'benhe',
'benre',
'benze',
'bence',
'bense',
'benai',
'benei',
'benao',
'benou',
'benan',
'benen',
'bener',
'benyi',
'benbi',
'benpi',
'benmi',
'bendi',
'benti',
'benni',
'benli',
'benji',
'benqi',
'benxi',
'benya',
'benyo',
'benye',
'benwu',
'benbu',
'benpu',
'benmu',
'benfu',
'bendu',
'bentu',
'bennu',
'benlu',
'bengu',
'benku',
'benhu',
'benru',
'benzu',
'bencu',
'bensu',
'benwa',
'benwo',
'benyu',
'bennü',
'benlü',
'benju',
'benqu',
'benxu',
'penri',
'penzi',
'penci',
'pensi',
'penba',
'penpa',
'penma',
'penfa',
'penda',
'penta',
'penna',
'penla',
'penga',
'penka',
'penha',
'penza',
'penca',
'pensa',
'penbo',
'penpo',
'penmo',
'penfo',
'penlo',
'penme',
'pende',
'pente',
'penne',
'penle',
'penge',
'penke',
'penhe',
'penre',
'penze',
'pence',
'pense',
'penai',
'penei',
'penao',
'penou',
'penan',
'penen',
'pener',
'penyi',
'penbi',
'penpi',
'penmi',
'pendi',
'penti',
'penni',
'penli',
'penji',
'penqi',
'penxi',
'penya',
'penyo',
'penye',
'penwu',
'penbu',
'penpu',
'penmu',
'penfu',
'pendu',
'pentu',
'pennu',
'penlu',
'pengu',
'penku',
'penhu',
'penru',
'penzu',
'pencu',
'pensu',
'penwa',
'penwo',
'penyu',
'pennü',
'penlü',
'penju',
'penqu',
'penxu',
'menri',
'menzi',
'menci',
'mensi',
'menba',
'menpa',
'menma',
'menfa',
'menda',
'menta',
'menna',
'menla',
'menga',
'menka',
'menha',
'menza',
'menca',
'mensa',
'menbo',
'menpo',
'menmo',
'menfo',
'menlo',
'menme',
'mende',
'mente',
'menne',
'menle',
'menge',
'menke',
'menhe',
'menre',
'menze',
'mence',
'mense',
'menai',
'menei',
'menao',
'menou',
'menan',
'menen',
'mener',
'menyi',
'menbi',
'menpi',
'menmi',
'mendi',
'menti',
'menni',
'menli',
'menji',
'menqi',
'menxi',
'menya',
'menyo',
'menye',
'menwu',
'menbu',
'menpu',
'menmu',
'menfu',
'mendu',
'mentu',
'mennu',
'menlu',
'mengu',
'menku',
'menhu',
'menru',
'menzu',
'mencu',
'mensu',
'menwa',
'menwo',
'menyu',
'mennü',
'menlü',
'menju',
'menqu',
'menxu',
'fenri',
'fenzi',
'fenci',
'fensi',
'fenba',
'fenpa',
'fenma',
'fenfa',
'fenda',
'fenta',
'fenna',
'fenla',
'fenga',
'fenka',
'fenha',
'fenza',
'fenca',
'fensa',
'fenbo',
'fenpo',
'fenmo',
'fenfo',
'fenlo',
'fenme',
'fende',
'fente',
'fenne',
'fenle',
'fenge',
'fenke',
'fenhe',
'fenre',
'fenze',
'fence',
'fense',
'fenai',
'fenei',
'fenao',
'fenou',
'fenan',
'fenen',
'fener',
'fenyi',
'fenbi',
'fenpi',
'fenmi',
'fendi',
'fenti',
'fenni',
'fenli',
'fenji',
'fenqi',
'fenxi',
'fenya',
'fenyo',
'fenye',
'fenwu',
'fenbu',
'fenpu',
'fenmu',
'fenfu',
'fendu',
'fentu',
'fennu',
'fenlu',
'fengu',
'fenku',
'fenhu',
'fenru',
'fenzu',
'fencu',
'fensu',
'fenwa',
'fenwo',
'fenyu',
'fennü',
'fenlü',
'fenju',
'fenqu',
'fenxu',
'denri',
'denzi',
'denci',
'densi',
'denba',
'denpa',
'denma',
'denfa',
'denda',
'denta',
'denna',
'denla',
'denga',
'denka',
'denha',
'denza',
'denca',
'densa',
'denbo',
'denpo',
'denmo',
'denfo',
'denlo',
'denme',
'dende',
'dente',
'denne',
'denle',
'denge',
'denke',
'denhe',
'denre',
'denze',
'dence',
'dense',
'denai',
'denei',
'denao',
'denou',
'denan',
'denen',
'dener',
'denyi',
'denbi',
'denpi',
'denmi',
'dendi',
'denti',
'denni',
'denli',
'denji',
'denqi',
'denxi',
'denya',
'denyo',
'denye',
'denwu',
'denbu',
'denpu',
'denmu',
'denfu',
'dendu',
'dentu',
'dennu',
'denlu',
'dengu',
'denku',
'denhu',
'denru',
'denzu',
'dencu',
'densu',
'denwa',
'denwo',
'denyu',
'dennü',
'denlü',
'denju',
'denqu',
'denxu',
'nenri',
'nenzi',
'nenci',
'nensi',
'nenba',
'nenpa',
'nenma',
'nenfa',
'nenda',
'nenta',
'nenna',
'nenla',
'nenga',
'nenka',
'nenha',
'nenza',
'nenca',
'nensa',
'nenbo',
'nenpo',
'nenmo',
'nenfo',
'nenlo',
'nenme',
'nende',
'nente',
'nenne',
'nenle',
'nenge',
'nenke',
'nenhe',
'nenre',
'nenze',
'nence',
'nense',
'nenai',
'nenei',
'nenao',
'nenou',
'nenan',
'nenen',
'nener',
'nenyi',
'nenbi',
'nenpi',
'nenmi',
'nendi',
'nenti',
'nenni',
'nenli',
'nenji',
'nenqi',
'nenxi',
'nenya',
'nenyo',
'nenye',
'nenwu',
'nenbu',
'nenpu',
'nenmu',
'nenfu',
'nendu',
'nentu',
'nennu',
'nenlu',
'nengu',
'nenku',
'nenhu',
'nenru',
'nenzu',
'nencu',
'nensu',
'nenwa',
'nenwo',
'nenyu',
'nennü',
'nenlü',
'nenju',
'nenqu',
'nenxu',
'genri',
'genzi',
'genci',
'gensi',
'genba',
'genpa',
'genma',
'genfa',
'genda',
'genta',
'genna',
'genla',
'genga',
'genka',
'genha',
'genza',
'genca',
'gensa',
'genbo',
'genpo',
'genmo',
'genfo',
'genlo',
'genme',
'gende',
'gente',
'genne',
'genle',
'genge',
'genke',
'genhe',
'genre',
'genze',
'gence',
'gense',
'genai',
'genei',
'genao',
'genou',
'genan',
'genen',
'gener',
'genyi',
'genbi',
'genpi',
'genmi',
'gendi',
'genti',
'genni',
'genli',
'genji',
'genqi',
'genxi',
'genya',
'genyo',
'genye',
'genwu',
'genbu',
'genpu',
'genmu',
'genfu',
'gendu',
'gentu',
'gennu',
'genlu',
'gengu',
'genku',
'genhu',
'genru',
'genzu',
'gencu',
'gensu',
'genwa',
'genwo',
'genyu',
'gennü',
'genlü',
'genju',
'genqu',
'genxu',
'kenri',
'kenzi',
'kenci',
'kensi',
'kenba',
'kenpa',
'kenma',
'kenfa',
'kenda',
'kenta',
'kenna',
'kenla',
'kenga',
'kenka',
'kenha',
'kenza',
'kenca',
'kensa',
'kenbo',
'kenpo',
'kenmo',
'kenfo',
'kenlo',
'kenme',
'kende',
'kente',
'kenne',
'kenle',
'kenge',
'kenke',
'kenhe',
'kenre',
'kenze',
'kence',
'kense',
'kenai',
'kenei',
'kenao',
'kenou',
'kenan',
'kenen',
'kener',
'kenyi',
'kenbi',
'kenpi',
'kenmi',
'kendi',
'kenti',
'kenni',
'kenli',
'kenji',
'kenqi',
'kenxi',
'kenya',
'kenyo',
'kenye',
'kenwu',
'kenbu',
'kenpu',
'kenmu',
'kenfu',
'kendu',
'kentu',
'kennu',
'kenlu',
'kengu',
'kenku',
'kenhu',
'kenru',
'kenzu',
'kencu',
'kensu',
'kenwa',
'kenwo',
'kenyu',
'kennü',
'kenlü',
'kenju',
'kenqu',
'kenxu',
'henri',
'henzi',
'henci',
'hensi',
'henba',
'henpa',
'henma',
'henfa',
'henda',
'henta',
'henna',
'henla',
'henga',
'henka',
'henha',
'henza',
'henca',
'hensa',
'henbo',
'henpo',
'henmo',
'henfo',
'henlo',
'henme',
'hende',
'hente',
'henne',
'henle',
'henge',
'henke',
'henhe',
'henre',
'henze',
'hence',
'hense',
'henai',
'henei',
'henao',
'henou',
'henan',
'henen',
'hener',
'henyi',
'henbi',
'henpi',
'henmi',
'hendi',
'henti',
'henni',
'henli',
'henji',
'henqi',
'henxi',
'henya',
'henyo',
'henye',
'henwu',
'henbu',
'henpu',
'henmu',
'henfu',
'hendu',
'hentu',
'hennu',
'henlu',
'hengu',
'henku',
'henhu',
'henru',
'henzu',
'hencu',
'hensu',
'henwa',
'henwo',
'henyu',
'hennü',
'henlü',
'henju',
'henqu',
'henxu',
'renri',
'renzi',
'renci',
'rensi',
'renba',
'renpa',
'renma',
'renfa',
'renda',
'renta',
'renna',
'renla',
'renga',
'renka',
'renha',
'renza',
'renca',
'rensa',
'renbo',
'renpo',
'renmo',
'renfo',
'renlo',
'renme',
'rende',
'rente',
'renne',
'renle',
'renge',
'renke',
'renhe',
'renre',
'renze',
'rence',
'rense',
'renai',
'renei',
'renao',
'renou',
'renan',
'renen',
'rener',
'renyi',
'renbi',
'renpi',
'renmi',
'rendi',
'renti',
'renni',
'renli',
'renji',
'renqi',
'renxi',
'renya',
'renyo',
'renye',
'renwu',
'renbu',
'renpu',
'renmu',
'renfu',
'rendu',
'rentu',
'rennu',
'renlu',
'rengu',
'renku',
'renhu',
'renru',
'renzu',
'rencu',
'rensu',
'renwa',
'renwo',
'renyu',
'rennü',
'renlü',
'renju',
'renqu',
'renxu',
'zenri',
'zenzi',
'zenci',
'zensi',
'zenba',
'zenpa',
'zenma',
'zenfa',
'zenda',
'zenta',
'zenna',
'zenla',
'zenga',
'zenka',
'zenha',
'zenza',
'zenca',
'zensa',
'zenbo',
'zenpo',
'zenmo',
'zenfo',
'zenlo',
'zenme',
'zende',
'zente',
'zenne',
'zenle',
'zenge',
'zenke',
'zenhe',
'zenre',
'zenze',
'zence',
'zense',
'zenai',
'zenei',
'zenao',
'zenou',
'zenan',
'zenen',
'zener',
'zenyi',
'zenbi',
'zenpi',
'zenmi',
'zendi',
'zenti',
'zenni',
'zenli',
'zenji',
'zenqi',
'zenxi',
'zenya',
'zenyo',
'zenye',
'zenwu',
'zenbu',
'zenpu',
'zenmu',
'zenfu',
'zendu',
'zentu',
'zennu',
'zenlu',
'zengu',
'zenku',
'zenhu',
'zenru',
'zenzu',
'zencu',
'zensu',
'zenwa',
'zenwo',
'zenyu',
'zennü',
'zenlü',
'zenju',
'zenqu',
'zenxu',
'cenri',
'cenzi',
'cenci',
'censi',
'cenba',
'cenpa',
'cenma',
'cenfa',
'cenda',
'centa',
'cenna',
'cenla',
'cenga',
'cenka',
'cenha',
'cenza',
'cenca',
'censa',
'cenbo',
'cenpo',
'cenmo',
'cenfo',
'cenlo',
'cenme',
'cende',
'cente',
'cenne',
'cenle',
'cenge',
'cenke',
'cenhe',
'cenre',
'cenze',
'cence',
'cense',
'cenai',
'cenei',
'cenao',
'cenou',
'cenan',
'cenen',
'cener',
'cenyi',
'cenbi',
'cenpi',
'cenmi',
'cendi',
'centi',
'cenni',
'cenli',
'cenji',
'cenqi',
'cenxi',
'cenya',
'cenyo',
'cenye',
'cenwu',
'cenbu',
'cenpu',
'cenmu',
'cenfu',
'cendu',
'centu',
'cennu',
'cenlu',
'cengu',
'cenku',
'cenhu',
'cenru',
'cenzu',
'cencu',
'censu',
'cenwa',
'cenwo',
'cenyu',
'cennü',
'cenlü',
'cenju',
'cenqu',
'cenxu',
'senri',
'senzi',
'senci',
'sensi',
'senba',
'senpa',
'senma',
'senfa',
'senda',
'senta',
'senna',
'senla',
'senga',
'senka',
'senha',
'senza',
'senca',
'sensa',
'senbo',
'senpo',
'senmo',
'senfo',
'senlo',
'senme',
'sende',
'sente',
'senne',
'senle',
'senge',
'senke',
'senhe',
'senre',
'senze',
'sence',
'sense',
'senai',
'senei',
'senao',
'senou',
'senan',
'senen',
'sener',
'senyi',
'senbi',
'senpi',
'senmi',
'sendi',
'senti',
'senni',
'senli',
'senji',
'senqi',
'senxi',
'senya',
'senyo',
'senye',
'senwu',
'senbu',
'senpu',
'senmu',
'senfu',
'sendu',
'sentu',
'sennu',
'senlu',
'sengu',
'senku',
'senhu',
'senru',
'senzu',
'sencu',
'sensu',
'senwa',
'senwo',
'senyu',
'sennü',
'senlü',
'senju',
'senqu',
'senxu',
'angri',
'angzi',
'angci',
'angsi',
'angba',
'angpa',
'angma',
'angfa',
'angda',
'angta',
'angna',
'angla',
'angga',
'angka',
'angha',
'angza',
'angca',
'angsa',
'angbo',
'angpo',
'angmo',
'angfo',
'anglo',
'angme',
'angde',
'angte',
'angne',
'angle',
'angge',
'angke',
'anghe',
'angre',
'angze',
'angce',
'angse',
'angai',
'angei',
'angao',
'angou',
'angan',
'angen',
'anger',
'angyi',
'angbi',
'angpi',
'angmi',
'angdi',
'angti',
'angni',
'angli',
'angji',
'angqi',
'angxi',
'angya',
'angyo',
'angye',
'angwu',
'angbu',
'angpu',
'angmu',
'angfu',
'angdu',
'angtu',
'angnu',
'anglu',
'anggu',
'angku',
'anghu',
'angru',
'angzu',
'angcu',
'angsu',
'angwa',
'angwo',
'angyu',
'angnü',
'anglü',
'angju',
'angqu',
'angxu',
'zhang',
'chang',
'shang',
'engri',
'engzi',
'engci',
'engsi',
'engba',
'engpa',
'engma',
'engfa',
'engda',
'engta',
'engna',
'engla',
'engga',
'engka',
'engha',
'engza',
'engca',
'engsa',
'engbo',
'engpo',
'engmo',
'engfo',
'englo',
'engme',
'engde',
'engte',
'engne',
'engle',
'engge',
'engke',
'enghe',
'engre',
'engze',
'engce',
'engse',
'engai',
'engei',
'engao',
'engou',
'engan',
'engen',
'enger',
'engyi',
'engbi',
'engpi',
'engmi',
'engdi',
'engti',
'engni',
'engli',
'engji',
'engqi',
'engxi',
'engya',
'engyo',
'engye',
'engwu',
'engbu',
'engpu',
'engmu',
'engfu',
'engdu',
'engtu',
'engnu',
'englu',
'enggu',
'engku',
'enghu',
'engru',
'engzu',
'engcu',
'engsu',
'engwa',
'engwo',
'engyu',
'engnü',
'englü',
'engju',
'engqu',
'engxu',
'zheng',
'cheng',
'sheng',
'zhong',
'chong',
'shong',
'erzhi',
'erchi',
'ershi',
'erzha',
'ercha',
'ersha',
'erzhe',
'erche',
'ershe',
'erbai',
'erpai',
'ermai',
'erdai',
'ertai',
'ernai',
'erlai',
'ergai',
'erkai',
'erhai',
'erzai',
'ercai',
'ersai',
'erbei',
'erpei',
'ermei',
'erfei',
'erdei',
'ertei',
'ernei',
'erlei',
'ergei',
'erkei',
'erhei',
'erzei',
'ersei',
'erbao',
'erpao',
'ermao',
'erdao',
'ertao',
'ernao',
'erlao',
'ergao',
'erkao',
'erhao',
'errao',
'erzao',
'ercao',
'ersao',
'erpou',
'ermou',
'erfou',
'erdou',
'ertou',
'ernou',
'erlou',
'ergou',
'erkou',
'erhou',
'errou',
'erzou',
'ercou',
'ersou',
'erban',
'erpan',
'erman',
'erfan',
'erdan',
'ertan',
'ernan',
'erlan',
'ergan',
'erkan',
'erhan',
'erran',
'erzan',
'ercan',
'ersan',
'erben',
'erpen',
'ermen',
'erfen',
'erden',
'ernen',
'ergen',
'erken',
'erhen',
'erren',
'erzen',
'ercen',
'ersen',
'erang',
'ereng',
'erdia',
'ernia',
'erlia',
'erjia',
'erqia',
'erxia',
'erbie',
'erpie',
'ermie',
'erdie',
'ertie',
'ernie',
'erlie',
'erjie',
'erqie',
'erxie',
'eryai',
'eryao',
'eryou',
'ermiu',
'erdiu',
'erniu',
'erliu',
'erjiu',
'erqiu',
'erxiu',
'eryan',
'eryin',
'erbin',
'erpin',
'ermin',
'erdin',
'ernin',
'erlin',
'erjin',
'erqin',
'erxin',
'erzhu',
'erchu',
'ershu',
'ergua',
'erkua',
'erhua',
'errua',
'erduo',
'ertuo',
'ernuo',
'erluo',
'erguo',
'erkuo',
'erhuo',
'erruo',
'erzuo',
'ercuo',
'ersuo',
'erwai',
'erwei',
'erdui',
'ertui',
'ergui',
'erkui',
'erhui',
'errui',
'erzui',
'ercui',
'ersui',
'erwan',
'erwen',
'erdun',
'ertun',
'ernun',
'erlun',
'ergun',
'erkun',
'erhun',
'errun',
'erzun',
'ercun',
'ersun',
'eryue',
'ernüe',
'erlüe',
'erjue',
'erque',
'erxue',
'eryun',
'erlün',
'erjun',
'erqun',
'erxun',
'yizhi',
'yichi',
'yishi',
'yizha',
'yicha',
'yisha',
'yizhe',
'yiche',
'yishe',
'yibai',
'yipai',
'yimai',
'yidai',
'yitai',
'yinai',
'yilai',
'yigai',
'yikai',
'yihai',
'yizai',
'yicai',
'yisai',
'yibei',
'yipei',
'yimei',
'yifei',
'yidei',
'yitei',
'yinei',
'yilei',
'yigei',
'yikei',
'yihei',
'yizei',
'yisei',
'yibao',
'yipao',
'yimao',
'yidao',
'yitao',
'yinao',
'yilao',
'yigao',
'yikao',
'yihao',
'yirao',
'yizao',
'yicao',
'yisao',
'yipou',
'yimou',
'yifou',
'yidou',
'yitou',
'yinou',
'yilou',
'yigou',
'yikou',
'yihou',
'yirou',
'yizou',
'yicou',
'yisou',
'yiban',
'yipan',
'yiman',
'yifan',
'yidan',
'yitan',
'yinan',
'yilan',
'yigan',
'yikan',
'yihan',
'yiran',
'yizan',
'yican',
'yisan',
'yiben',
'yipen',
'yimen',
'yifen',
'yiden',
'yinen',
'yigen',
'yiken',
'yihen',
'yiren',
'yizen',
'yicen',
'yisen',
'yiang',
'yieng',
'yidia',
'yinia',
'yilia',
'yijia',
'yiqia',
'yixia',
'yibie',
'yipie',
'yimie',
'yidie',
'yitie',
'yinie',
'yilie',
'yijie',
'yiqie',
'yixie',
'yiyai',
'yiyao',
'yiyou',
'yimiu',
'yidiu',
'yiniu',
'yiliu',
'yijiu',
'yiqiu',
'yixiu',
'yiyan',
'yiyin',
'yibin',
'yipin',
'yimin',
'yidin',
'yinin',
'yilin',
'yijin',
'yiqin',
'yixin',
'yizhu',
'yichu',
'yishu',
'yigua',
'yikua',
'yihua',
'yirua',
'yiduo',
'yituo',
'yinuo',
'yiluo',
'yiguo',
'yikuo',
'yihuo',
'yiruo',
'yizuo',
'yicuo',
'yisuo',
'yiwai',
'yiwei',
'yidui',
'yitui',
'yigui',
'yikui',
'yihui',
'yirui',
'yizui',
'yicui',
'yisui',
'yiwan',
'yiwen',
'yidun',
'yitun',
'yinun',
'yilun',
'yigun',
'yikun',
'yihun',
'yirun',
'yizun',
'yicun',
'yisun',
'yiyue',
'yinüe',
'yilüe',
'yijue',
'yique',
'yixue',
'yiyun',
'yilün',
'yijun',
'yiqun',
'yixun',
'bizhi',
'bichi',
'bishi',
'bizha',
'bicha',
'bisha',
'bizhe',
'biche',
'bishe',
'bibai',
'bipai',
'bimai',
'bidai',
'bitai',
'binai',
'bilai',
'bigai',
'bikai',
'bihai',
'bizai',
'bicai',
'bisai',
'bibei',
'bipei',
'bimei',
'bifei',
'bidei',
'bitei',
'binei',
'bilei',
'bigei',
'bikei',
'bihei',
'bizei',
'bisei',
'bibao',
'bipao',
'bimao',
'bidao',
'bitao',
'binao',
'bilao',
'bigao',
'bikao',
'bihao',
'birao',
'bizao',
'bicao',
'bisao',
'bipou',
'bimou',
'bifou',
'bidou',
'bitou',
'binou',
'bilou',
'bigou',
'bikou',
'bihou',
'birou',
'bizou',
'bicou',
'bisou',
'biban',
'bipan',
'biman',
'bifan',
'bidan',
'bitan',
'binan',
'bilan',
'bigan',
'bikan',
'bihan',
'biran',
'bizan',
'bican',
'bisan',
'biben',
'bipen',
'bimen',
'bifen',
'biden',
'binen',
'bigen',
'biken',
'bihen',
'biren',
'bizen',
'bicen',
'bisen',
'biang',
'bieng',
'bidia',
'binia',
'bilia',
'bijia',
'biqia',
'bixia',
'bibie',
'bipie',
'bimie',
'bidie',
'bitie',
'binie',
'bilie',
'bijie',
'biqie',
'bixie',
'biyai',
'biyao',
'biyou',
'bimiu',
'bidiu',
'biniu',
'biliu',
'bijiu',
'biqiu',
'bixiu',
'biyan',
'biyin',
'bibin',
'bipin',
'bimin',
'bidin',
'binin',
'bilin',
'bijin',
'biqin',
'bixin',
'bizhu',
'bichu',
'bishu',
'bigua',
'bikua',
'bihua',
'birua',
'biduo',
'bituo',
'binuo',
'biluo',
'biguo',
'bikuo',
'bihuo',
'biruo',
'bizuo',
'bicuo',
'bisuo',
'biwai',
'biwei',
'bidui',
'bitui',
'bigui',
'bikui',
'bihui',
'birui',
'bizui',
'bicui',
'bisui',
'biwan',
'biwen',
'bidun',
'bitun',
'binun',
'bilun',
'bigun',
'bikun',
'bihun',
'birun',
'bizun',
'bicun',
'bisun',
'biyue',
'binüe',
'bilüe',
'bijue',
'bique',
'bixue',
'biyun',
'bilün',
'bijun',
'biqun',
'bixun',
'pizhi',
'pichi',
'pishi',
'pizha',
'picha',
'pisha',
'pizhe',
'piche',
'pishe',
'pibai',
'pipai',
'pimai',
'pidai',
'pitai',
'pinai',
'pilai',
'pigai',
'pikai',
'pihai',
'pizai',
'picai',
'pisai',
'pibei',
'pipei',
'pimei',
'pifei',
'pidei',
'pitei',
'pinei',
'pilei',
'pigei',
'pikei',
'pihei',
'pizei',
'pisei',
'pibao',
'pipao',
'pimao',
'pidao',
'pitao',
'pinao',
'pilao',
'pigao',
'pikao',
'pihao',
'pirao',
'pizao',
'picao',
'pisao',
'pipou',
'pimou',
'pifou',
'pidou',
'pitou',
'pinou',
'pilou',
'pigou',
'pikou',
'pihou',
'pirou',
'pizou',
'picou',
'pisou',
'piban',
'pipan',
'piman',
'pifan',
'pidan',
'pitan',
'pinan',
'pilan',
'pigan',
'pikan',
'pihan',
'piran',
'pizan',
'pican',
'pisan',
'piben',
'pipen',
'pimen',
'pifen',
'piden',
'pinen',
'pigen',
'piken',
'pihen',
'piren',
'pizen',
'picen',
'pisen',
'piang',
'pieng',
'pidia',
'pinia',
'pilia',
'pijia',
'piqia',
'pixia',
'pibie',
'pipie',
'pimie',
'pidie',
'pitie',
'pinie',
'pilie',
'pijie',
'piqie',
'pixie',
'piyai',
'piyao',
'piyou',
'pimiu',
'pidiu',
'piniu',
'piliu',
'pijiu',
'piqiu',
'pixiu',
'piyan',
'piyin',
'pibin',
'pipin',
'pimin',
'pidin',
'pinin',
'pilin',
'pijin',
'piqin',
'pixin',
'pizhu',
'pichu',
'pishu',
'pigua',
'pikua',
'pihua',
'pirua',
'piduo',
'pituo',
'pinuo',
'piluo',
'piguo',
'pikuo',
'pihuo',
'piruo',
'pizuo',
'picuo',
'pisuo',
'piwai',
'piwei',
'pidui',
'pitui',
'pigui',
'pikui',
'pihui',
'pirui',
'pizui',
'picui',
'pisui',
'piwan',
'piwen',
'pidun',
'pitun',
'pinun',
'pilun',
'pigun',
'pikun',
'pihun',
'pirun',
'pizun',
'picun',
'pisun',
'piyue',
'pinüe',
'pilüe',
'pijue',
'pique',
'pixue',
'piyun',
'pilün',
'pijun',
'piqun',
'pixun',
'mizhi',
'michi',
'mishi',
'mizha',
'micha',
'misha',
'mizhe',
'miche',
'mishe',
'mibai',
'mipai',
'mimai',
'midai',
'mitai',
'minai',
'milai',
'migai',
'mikai',
'mihai',
'mizai',
'micai',
'misai',
'mibei',
'mipei',
'mimei',
'mifei',
'midei',
'mitei',
'minei',
'milei',
'migei',
'mikei',
'mihei',
'mizei',
'misei',
'mibao',
'mipao',
'mimao',
'midao',
'mitao',
'minao',
'milao',
'migao',
'mikao',
'mihao',
'mirao',
'mizao',
'micao',
'misao',
'mipou',
'mimou',
'mifou',
'midou',
'mitou',
'minou',
'milou',
'migou',
'mikou',
'mihou',
'mirou',
'mizou',
'micou',
'misou',
'miban',
'mipan',
'miman',
'mifan',
'midan',
'mitan',
'minan',
'milan',
'migan',
'mikan',
'mihan',
'miran',
'mizan',
'mican',
'misan',
'miben',
'mipen',
'mimen',
'mifen',
'miden',
'minen',
'migen',
'miken',
'mihen',
'miren',
'mizen',
'micen',
'misen',
'miang',
'mieng',
'midia',
'minia',
'milia',
'mijia',
'miqia',
'mixia',
'mibie',
'mipie',
'mimie',
'midie',
'mitie',
'minie',
'milie',
'mijie',
'miqie',
'mixie',
'miyai',
'miyao',
'miyou',
'mimiu',
'midiu',
'miniu',
'miliu',
'mijiu',
'miqiu',
'mixiu',
'miyan',
'miyin',
'mibin',
'mipin',
'mimin',
'midin',
'minin',
'milin',
'mijin',
'miqin',
'mixin',
'mizhu',
'michu',
'mishu',
'migua',
'mikua',
'mihua',
'mirua',
'miduo',
'mituo',
'minuo',
'miluo',
'miguo',
'mikuo',
'mihuo',
'miruo',
'mizuo',
'micuo',
'misuo',
'miwai',
'miwei',
'midui',
'mitui',
'migui',
'mikui',
'mihui',
'mirui',
'mizui',
'micui',
'misui',
'miwan',
'miwen',
'midun',
'mitun',
'minun',
'milun',
'migun',
'mikun',
'mihun',
'mirun',
'mizun',
'micun',
'misun',
'miyue',
'minüe',
'milüe',
'mijue',
'mique',
'mixue',
'miyun',
'milün',
'mijun',
'miqun',
'mixun',
'dizhi',
'dichi',
'dishi',
'dizha',
'dicha',
'disha',
'dizhe',
'diche',
'dishe',
'dibai',
'dipai',
'dimai',
'didai',
'ditai',
'dinai',
'dilai',
'digai',
'dikai',
'dihai',
'dizai',
'dicai',
'disai',
'dibei',
'dipei',
'dimei',
'difei',
'didei',
'ditei',
'dinei',
'dilei',
'digei',
'dikei',
'dihei',
'dizei',
'disei',
'dibao',
'dipao',
'dimao',
'didao',
'ditao',
'dinao',
'dilao',
'digao',
'dikao',
'dihao',
'dirao',
'dizao',
'dicao',
'disao',
'dipou',
'dimou',
'difou',
'didou',
'ditou',
'dinou',
'dilou',
'digou',
'dikou',
'dihou',
'dirou',
'dizou',
'dicou',
'disou',
'diban',
'dipan',
'diman',
'difan',
'didan',
'ditan',
'dinan',
'dilan',
'digan',
'dikan',
'dihan',
'diran',
'dizan',
'dican',
'disan',
'diben',
'dipen',
'dimen',
'difen',
'diden',
'dinen',
'digen',
'diken',
'dihen',
'diren',
'dizen',
'dicen',
'disen',
'diang',
'dieng',
'didia',
'dinia',
'dilia',
'dijia',
'diqia',
'dixia',
'dibie',
'dipie',
'dimie',
'didie',
'ditie',
'dinie',
'dilie',
'dijie',
'diqie',
'dixie',
'diyai',
'diyao',
'diyou',
'dimiu',
'didiu',
'diniu',
'diliu',
'dijiu',
'diqiu',
'dixiu',
'diyan',
'diyin',
'dibin',
'dipin',
'dimin',
'didin',
'dinin',
'dilin',
'dijin',
'diqin',
'dixin',
'dizhu',
'dichu',
'dishu',
'digua',
'dikua',
'dihua',
'dirua',
'diduo',
'dituo',
'dinuo',
'diluo',
'diguo',
'dikuo',
'dihuo',
'diruo',
'dizuo',
'dicuo',
'disuo',
'diwai',
'diwei',
'didui',
'ditui',
'digui',
'dikui',
'dihui',
'dirui',
'dizui',
'dicui',
'disui',
'diwan',
'diwen',
'didun',
'ditun',
'dinun',
'dilun',
'digun',
'dikun',
'dihun',
'dirun',
'dizun',
'dicun',
'disun',
'diyue',
'dinüe',
'dilüe',
'dijue',
'dique',
'dixue',
'diyun',
'dilün',
'dijun',
'diqun',
'dixun',
'tizhi',
'tichi',
'tishi',
'tizha',
'ticha',
'tisha',
'tizhe',
'tiche',
'tishe',
'tibai',
'tipai',
'timai',
'tidai',
'titai',
'tinai',
'tilai',
'tigai',
'tikai',
'tihai',
'tizai',
'ticai',
'tisai',
'tibei',
'tipei',
'timei',
'tifei',
'tidei',
'titei',
'tinei',
'tilei',
'tigei',
'tikei',
'tihei',
'tizei',
'tisei',
'tibao',
'tipao',
'timao',
'tidao',
'titao',
'tinao',
'tilao',
'tigao',
'tikao',
'tihao',
'tirao',
'tizao',
'ticao',
'tisao',
'tipou',
'timou',
'tifou',
'tidou',
'titou',
'tinou',
'tilou',
'tigou',
'tikou',
'tihou',
'tirou',
'tizou',
'ticou',
'tisou',
'tiban',
'tipan',
'timan',
'tifan',
'tidan',
'titan',
'tinan',
'tilan',
'tigan',
'tikan',
'tihan',
'tiran',
'tizan',
'tican',
'tisan',
'tiben',
'tipen',
'timen',
'tifen',
'tiden',
'tinen',
'tigen',
'tiken',
'tihen',
'tiren',
'tizen',
'ticen',
'tisen',
'tiang',
'tieng',
'tidia',
'tinia',
'tilia',
'tijia',
'tiqia',
'tixia',
'tibie',
'tipie',
'timie',
'tidie',
'titie',
'tinie',
'tilie',
'tijie',
'tiqie',
'tixie',
'tiyai',
'tiyao',
'tiyou',
'timiu',
'tidiu',
'tiniu',
'tiliu',
'tijiu',
'tiqiu',
'tixiu',
'tiyan',
'tiyin',
'tibin',
'tipin',
'timin',
'tidin',
'tinin',
'tilin',
'tijin',
'tiqin',
'tixin',
'tizhu',
'tichu',
'tishu',
'tigua',
'tikua',
'tihua',
'tirua',
'tiduo',
'tituo',
'tinuo',
'tiluo',
'tiguo',
'tikuo',
'tihuo',
'tiruo',
'tizuo',
'ticuo',
'tisuo',
'tiwai',
'tiwei',
'tidui',
'titui',
'tigui',
'tikui',
'tihui',
'tirui',
'tizui',
'ticui',
'tisui',
'tiwan',
'tiwen',
'tidun',
'titun',
'tinun',
'tilun',
'tigun',
'tikun',
'tihun',
'tirun',
'tizun',
'ticun',
'tisun',
'tiyue',
'tinüe',
'tilüe',
'tijue',
'tique',
'tixue',
'tiyun',
'tilün',
'tijun',
'tiqun',
'tixun',
'nizhi',
'nichi',
'nishi',
'nizha',
'nicha',
'nisha',
'nizhe',
'niche',
'nishe',
'nibai',
'nipai',
'nimai',
'nidai',
'nitai',
'ninai',
'nilai',
'nigai',
'nikai',
'nihai',
'nizai',
'nicai',
'nisai',
'nibei',
'nipei',
'nimei',
'nifei',
'nidei',
'nitei',
'ninei',
'nilei',
'nigei',
'nikei',
'nihei',
'nizei',
'nisei',
'nibao',
'nipao',
'nimao',
'nidao',
'nitao',
'ninao',
'nilao',
'nigao',
'nikao',
'nihao',
'nirao',
'nizao',
'nicao',
'nisao',
'nipou',
'nimou',
'nifou',
'nidou',
'nitou',
'ninou',
'nilou',
'nigou',
'nikou',
'nihou',
'nirou',
'nizou',
'nicou',
'nisou',
'niban',
'nipan',
'niman',
'nifan',
'nidan',
'nitan',
'ninan',
'nilan',
'nigan',
'nikan',
'nihan',
'niran',
'nizan',
'nican',
'nisan',
'niben',
'nipen',
'nimen',
'nifen',
'niden',
'ninen',
'nigen',
'niken',
'nihen',
'niren',
'nizen',
'nicen',
'nisen',
'niang',
'nieng',
'nidia',
'ninia',
'nilia',
'nijia',
'niqia',
'nixia',
'nibie',
'nipie',
'nimie',
'nidie',
'nitie',
'ninie',
'nilie',
'nijie',
'niqie',
'nixie',
'niyai',
'niyao',
'niyou',
'nimiu',
'nidiu',
'niniu',
'niliu',
'nijiu',
'niqiu',
'nixiu',
'niyan',
'niyin',
'nibin',
'nipin',
'nimin',
'nidin',
'ninin',
'nilin',
'nijin',
'niqin',
'nixin',
'nizhu',
'nichu',
'nishu',
'nigua',
'nikua',
'nihua',
'nirua',
'niduo',
'nituo',
'ninuo',
'niluo',
'niguo',
'nikuo',
'nihuo',
'niruo',
'nizuo',
'nicuo',
'nisuo',
'niwai',
'niwei',
'nidui',
'nitui',
'nigui',
'nikui',
'nihui',
'nirui',
'nizui',
'nicui',
'nisui',
'niwan',
'niwen',
'nidun',
'nitun',
'ninun',
'nilun',
'nigun',
'nikun',
'nihun',
'nirun',
'nizun',
'nicun',
'nisun',
'niyue',
'ninüe',
'nilüe',
'nijue',
'nique',
'nixue',
'niyun',
'nilün',
'nijun',
'niqun',
'nixun',
'lizhi',
'lichi',
'lishi',
'lizha',
'licha',
'lisha',
'lizhe',
'liche',
'lishe',
'libai',
'lipai',
'limai',
'lidai',
'litai',
'linai',
'lilai',
'ligai',
'likai',
'lihai',
'lizai',
'licai',
'lisai',
'libei',
'lipei',
'limei',
'lifei',
'lidei',
'litei',
'linei',
'lilei',
'ligei',
'likei',
'lihei',
'lizei',
'lisei',
'libao',
'lipao',
'limao',
'lidao',
'litao',
'linao',
'lilao',
'ligao',
'likao',
'lihao',
'lirao',
'lizao',
'licao',
'lisao',
'lipou',
'limou',
'lifou',
'lidou',
'litou',
'linou',
'lilou',
'ligou',
'likou',
'lihou',
'lirou',
'lizou',
'licou',
'lisou',
'liban',
'lipan',
'liman',
'lifan',
'lidan',
'litan',
'linan',
'lilan',
'ligan',
'likan',
'lihan',
'liran',
'lizan',
'lican',
'lisan',
'liben',
'lipen',
'limen',
'lifen',
'liden',
'linen',
'ligen',
'liken',
'lihen',
'liren',
'lizen',
'licen',
'lisen',
'liang',
'lieng',
'lidia',
'linia',
'lilia',
'lijia',
'liqia',
'lixia',
'libie',
'lipie',
'limie',
'lidie',
'litie',
'linie',
'lilie',
'lijie',
'liqie',
'lixie',
'liyai',
'liyao',
'liyou',
'limiu',
'lidiu',
'liniu',
'liliu',
'lijiu',
'liqiu',
'lixiu',
'liyan',
'liyin',
'libin',
'lipin',
'limin',
'lidin',
'linin',
'lilin',
'lijin',
'liqin',
'lixin',
'lizhu',
'lichu',
'lishu',
'ligua',
'likua',
'lihua',
'lirua',
'liduo',
'lituo',
'linuo',
'liluo',
'liguo',
'likuo',
'lihuo',
'liruo',
'lizuo',
'licuo',
'lisuo',
'liwai',
'liwei',
'lidui',
'litui',
'ligui',
'likui',
'lihui',
'lirui',
'lizui',
'licui',
'lisui',
'liwan',
'liwen',
'lidun',
'litun',
'linun',
'lilun',
'ligun',
'likun',
'lihun',
'lirun',
'lizun',
'licun',
'lisun',
'liyue',
'linüe',
'lilüe',
'lijue',
'lique',
'lixue',
'liyun',
'lilün',
'lijun',
'liqun',
'lixun',
'jizhi',
'jichi',
'jishi',
'jizha',
'jicha',
'jisha',
'jizhe',
'jiche',
'jishe',
'jibai',
'jipai',
'jimai',
'jidai',
'jitai',
'jinai',
'jilai',
'jigai',
'jikai',
'jihai',
'jizai',
'jicai',
'jisai',
'jibei',
'jipei',
'jimei',
'jifei',
'jidei',
'jitei',
'jinei',
'jilei',
'jigei',
'jikei',
'jihei',
'jizei',
'jisei',
'jibao',
'jipao',
'jimao',
'jidao',
'jitao',
'jinao',
'jilao',
'jigao',
'jikao',
'jihao',
'jirao',
'jizao',
'jicao',
'jisao',
'jipou',
'jimou',
'jifou',
'jidou',
'jitou',
'jinou',
'jilou',
'jigou',
'jikou',
'jihou',
'jirou',
'jizou',
'jicou',
'jisou',
'jiban',
'jipan',
'jiman',
'jifan',
'jidan',
'jitan',
'jinan',
'jilan',
'jigan',
'jikan',
'jihan',
'jiran',
'jizan',
'jican',
'jisan',
'jiben',
'jipen',
'jimen',
'jifen',
'jiden',
'jinen',
'jigen',
'jiken',
'jihen',
'jiren',
'jizen',
'jicen',
'jisen',
'jiang',
'jieng',
'jidia',
'jinia',
'jilia',
'jijia',
'jiqia',
'jixia',
'jibie',
'jipie',
'jimie',
'jidie',
'jitie',
'jinie',
'jilie',
'jijie',
'jiqie',
'jixie',
'jiyai',
'jiyao',
'jiyou',
'jimiu',
'jidiu',
'jiniu',
'jiliu',
'jijiu',
'jiqiu',
'jixiu',
'jiyan',
'jiyin',
'jibin',
'jipin',
'jimin',
'jidin',
'jinin',
'jilin',
'jijin',
'jiqin',
'jixin',
'jizhu',
'jichu',
'jishu',
'jigua',
'jikua',
'jihua',
'jirua',
'jiduo',
'jituo',
'jinuo',
'jiluo',
'jiguo',
'jikuo',
'jihuo',
'jiruo',
'jizuo',
'jicuo',
'jisuo',
'jiwai',
'jiwei',
'jidui',
'jitui',
'jigui',
'jikui',
'jihui',
'jirui',
'jizui',
'jicui',
'jisui',
'jiwan',
'jiwen',
'jidun',
'jitun',
'jinun',
'jilun',
'jigun',
'jikun',
'jihun',
'jirun',
'jizun',
'jicun',
'jisun',
'jiyue',
'jinüe',
'jilüe',
'jijue',
'jique',
'jixue',
'jiyun',
'jilün',
'jijun',
'jiqun',
'jixun',
'qizhi',
'qichi',
'qishi',
'qizha',
'qicha',
'qisha',
'qizhe',
'qiche',
'qishe',
'qibai',
'qipai',
'qimai',
'qidai',
'qitai',
'qinai',
'qilai',
'qigai',
'qikai',
'qihai',
'qizai',
'qicai',
'qisai',
'qibei',
'qipei',
'qimei',
'qifei',
'qidei',
'qitei',
'qinei',
'qilei',
'qigei',
'qikei',
'qihei',
'qizei',
'qisei',
'qibao',
'qipao',
'qimao',
'qidao',
'qitao',
'qinao',
'qilao',
'qigao',
'qikao',
'qihao',
'qirao',
'qizao',
'qicao',
'qisao',
'qipou',
'qimou',
'qifou',
'qidou',
'qitou',
'qinou',
'qilou',
'qigou',
'qikou',
'qihou',
'qirou',
'qizou',
'qicou',
'qisou',
'qiban',
'qipan',
'qiman',
'qifan',
'qidan',
'qitan',
'qinan',
'qilan',
'qigan',
'qikan',
'qihan',
'qiran',
'qizan',
'qican',
'qisan',
'qiben',
'qipen',
'qimen',
'qifen',
'qiden',
'qinen',
'qigen',
'qiken',
'qihen',
'qiren',
'qizen',
'qicen',
'qisen',
'qiang',
'qieng',
'qidia',
'qinia',
'qilia',
'qijia',
'qiqia',
'qixia',
'qibie',
'qipie',
'qimie',
'qidie',
'qitie',
'qinie',
'qilie',
'qijie',
'qiqie',
'qixie',
'qiyai',
'qiyao',
'qiyou',
'qimiu',
'qidiu',
'qiniu',
'qiliu',
'qijiu',
'qiqiu',
'qixiu',
'qiyan',
'qiyin',
'qibin',
'qipin',
'qimin',
'qidin',
'qinin',
'qilin',
'qijin',
'qiqin',
'qixin',
'qizhu',
'qichu',
'qishu',
'qigua',
'qikua',
'qihua',
'qirua',
'qiduo',
'qituo',
'qinuo',
'qiluo',
'qiguo',
'qikuo',
'qihuo',
'qiruo',
'qizuo',
'qicuo',
'qisuo',
'qiwai',
'qiwei',
'qidui',
'qitui',
'qigui',
'qikui',
'qihui',
'qirui',
'qizui',
'qicui',
'qisui',
'qiwan',
'qiwen',
'qidun',
'qitun',
'qinun',
'qilun',
'qigun',
'qikun',
'qihun',
'qirun',
'qizun',
'qicun',
'qisun',
'qiyue',
'qinüe',
'qilüe',
'qijue',
'qique',
'qixue',
'qiyun',
'qilün',
'qijun',
'qiqun',
'qixun',
'xizhi',
'xichi',
'xishi',
'xizha',
'xicha',
'xisha',
'xizhe',
'xiche',
'xishe',
'xibai',
'xipai',
'ximai',
'xidai',
'xitai',
'xinai',
'xilai',
'xigai',
'xikai',
'xihai',
'xizai',
'xicai',
'xisai',
'xibei',
'xipei',
'ximei',
'xifei',
'xidei',
'xitei',
'xinei',
'xilei',
'xigei',
'xikei',
'xihei',
'xizei',
'xisei',
'xibao',
'xipao',
'ximao',
'xidao',
'xitao',
'xinao',
'xilao',
'xigao',
'xikao',
'xihao',
'xirao',
'xizao',
'xicao',
'xisao',
'xipou',
'ximou',
'xifou',
'xidou',
'xitou',
'xinou',
'xilou',
'xigou',
'xikou',
'xihou',
'xirou',
'xizou',
'xicou',
'xisou',
'xiban',
'xipan',
'ximan',
'xifan',
'xidan',
'xitan',
'xinan',
'xilan',
'xigan',
'xikan',
'xihan',
'xiran',
'xizan',
'xican',
'xisan',
'xiben',
'xipen',
'ximen',
'xifen',
'xiden',
'xinen',
'xigen',
'xiken',
'xihen',
'xiren',
'xizen',
'xicen',
'xisen',
'xiang',
'xieng',
'xidia',
'xinia',
'xilia',
'xijia',
'xiqia',
'xixia',
'xibie',
'xipie',
'ximie',
'xidie',
'xitie',
'xinie',
'xilie',
'xijie',
'xiqie',
'xixie',
'xiyai',
'xiyao',
'xiyou',
'ximiu',
'xidiu',
'xiniu',
'xiliu',
'xijiu',
'xiqiu',
'xixiu',
'xiyan',
'xiyin',
'xibin',
'xipin',
'ximin',
'xidin',
'xinin',
'xilin',
'xijin',
'xiqin',
'xixin',
'xizhu',
'xichu',
'xishu',
'xigua',
'xikua',
'xihua',
'xirua',
'xiduo',
'xituo',
'xinuo',
'xiluo',
'xiguo',
'xikuo',
'xihuo',
'xiruo',
'xizuo',
'xicuo',
'xisuo',
'xiwai',
'xiwei',
'xidui',
'xitui',
'xigui',
'xikui',
'xihui',
'xirui',
'xizui',
'xicui',
'xisui',
'xiwan',
'xiwen',
'xidun',
'xitun',
'xinun',
'xilun',
'xigun',
'xikun',
'xihun',
'xirun',
'xizun',
'xicun',
'xisun',
'xiyue',
'xinüe',
'xilüe',
'xijue',
'xique',
'xixue',
'xiyun',
'xilün',
'xijun',
'xiqun',
'xixun',
'yazhi',
'yachi',
'yashi',
'yazha',
'yacha',
'yasha',
'yazhe',
'yache',
'yashe',
'yabai',
'yapai',
'yamai',
'yadai',
'yatai',
'yanai',
'yalai',
'yagai',
'yakai',
'yahai',
'yazai',
'yacai',
'yasai',
'yabei',
'yapei',
'yamei',
'yafei',
'yadei',
'yatei',
'yanei',
'yalei',
'yagei',
'yakei',
'yahei',
'yazei',
'yasei',
'yabao',
'yapao',
'yamao',
'yadao',
'yatao',
'yanao',
'yalao',
'yagao',
'yakao',
'yahao',
'yarao',
'yazao',
'yacao',
'yasao',
'yapou',
'yamou',
'yafou',
'yadou',
'yatou',
'yanou',
'yalou',
'yagou',
'yakou',
'yahou',
'yarou',
'yazou',
'yacou',
'yasou',
'yaban',
'yapan',
'yaman',
'yafan',
'yadan',
'yatan',
'yanan',
'yalan',
'yagan',
'yakan',
'yahan',
'yaran',
'yazan',
'yacan',
'yasan',
'yaben',
'yapen',
'yamen',
'yafen',
'yaden',
'yanen',
'yagen',
'yaken',
'yahen',
'yaren',
'yazen',
'yacen',
'yasen',
'yaang',
'yaeng',
'yadia',
'yania',
'yalia',
'yajia',
'yaqia',
'yaxia',
'yabie',
'yapie',
'yamie',
'yadie',
'yatie',
'yanie',
'yalie',
'yajie',
'yaqie',
'yaxie',
'yayai',
'yayao',
'yayou',
'yamiu',
'yadiu',
'yaniu',
'yaliu',
'yajiu',
'yaqiu',
'yaxiu',
'yayan',
'yayin',
'yabin',
'yapin',
'yamin',
'yadin',
'yanin',
'yalin',
'yajin',
'yaqin',
'yaxin',
'yazhu',
'yachu',
'yashu',
'yagua',
'yakua',
'yahua',
'yarua',
'yaduo',
'yatuo',
'yanuo',
'yaluo',
'yaguo',
'yakuo',
'yahuo',
'yaruo',
'yazuo',
'yacuo',
'yasuo',
'yawai',
'yawei',
'yadui',
'yatui',
'yagui',
'yakui',
'yahui',
'yarui',
'yazui',
'yacui',
'yasui',
'yawan',
'yawen',
'yadun',
'yatun',
'yanun',
'yalun',
'yagun',
'yakun',
'yahun',
'yarun',
'yazun',
'yacun',
'yasun',
'yayue',
'yanüe',
'yalüe',
'yajue',
'yaque',
'yaxue',
'yayun',
'yalün',
'yajun',
'yaqun',
'yaxun',
'diari',
'diazi',
'diaci',
'diasi',
'diaba',
'diapa',
'diama',
'diafa',
'diada',
'diata',
'diana',
'diala',
'diaga',
'diaka',
'diaha',
'diaza',
'diaca',
'diasa',
'diabo',
'diapo',
'diamo',
'diafo',
'dialo',
'diame',
'diade',
'diate',
'diane',
'diale',
'diage',
'diake',
'diahe',
'diare',
'diaze',
'diace',
'diase',
'diaai',
'diaei',
'diaao',
'diaou',
'diaan',
'diaen',
'diaer',
'diayi',
'diabi',
'diapi',
'diami',
'diadi',
'diati',
'diani',
'diali',
'diaji',
'diaqi',
'diaxi',
'diaya',
'diayo',
'diaye',
'diawu',
'diabu',
'diapu',
'diamu',
'diafu',
'diadu',
'diatu',
'dianu',
'dialu',
'diagu',
'diaku',
'diahu',
'diaru',
'diazu',
'diacu',
'diasu',
'diawa',
'diawo',
'diayu',
'dianü',
'dialü',
'diaju',
'diaqu',
'diaxu',
'niari',
'niazi',
'niaci',
'niasi',
'niaba',
'niapa',
'niama',
'niafa',
'niada',
'niata',
'niana',
'niala',
'niaga',
'niaka',
'niaha',
'niaza',
'niaca',
'niasa',
'niabo',
'niapo',
'niamo',
'niafo',
'nialo',
'niame',
'niade',
'niate',
'niane',
'niale',
'niage',
'niake',
'niahe',
'niare',
'niaze',
'niace',
'niase',
'niaai',
'niaei',
'niaao',
'niaou',
'niaan',
'niaen',
'niaer',
'niayi',
'niabi',
'niapi',
'niami',
'niadi',
'niati',
'niani',
'niali',
'niaji',
'niaqi',
'niaxi',
'niaya',
'niayo',
'niaye',
'niawu',
'niabu',
'niapu',
'niamu',
'niafu',
'niadu',
'niatu',
'nianu',
'nialu',
'niagu',
'niaku',
'niahu',
'niaru',
'niazu',
'niacu',
'niasu',
'niawa',
'niawo',
'niayu',
'nianü',
'nialü',
'niaju',
'niaqu',
'niaxu',
'liari',
'liazi',
'liaci',
'liasi',
'liaba',
'liapa',
'liama',
'liafa',
'liada',
'liata',
'liana',
'liala',
'liaga',
'liaka',
'liaha',
'liaza',
'liaca',
'liasa',
'liabo',
'liapo',
'liamo',
'liafo',
'lialo',
'liame',
'liade',
'liate',
'liane',
'liale',
'liage',
'liake',
'liahe',
'liare',
'liaze',
'liace',
'liase',
'liaai',
'liaei',
'liaao',
'liaou',
'liaan',
'liaen',
'liaer',
'liayi',
'liabi',
'liapi',
'liami',
'liadi',
'liati',
'liani',
'liali',
'liaji',
'liaqi',
'liaxi',
'liaya',
'liayo',
'liaye',
'liawu',
'liabu',
'liapu',
'liamu',
'liafu',
'liadu',
'liatu',
'lianu',
'lialu',
'liagu',
'liaku',
'liahu',
'liaru',
'liazu',
'liacu',
'liasu',
'liawa',
'liawo',
'liayu',
'lianü',
'lialü',
'liaju',
'liaqu',
'liaxu',
'jiari',
'jiazi',
'jiaci',
'jiasi',
'jiaba',
'jiapa',
'jiama',
'jiafa',
'jiada',
'jiata',
'jiana',
'jiala',
'jiaga',
'jiaka',
'jiaha',
'jiaza',
'jiaca',
'jiasa',
'jiabo',
'jiapo',
'jiamo',
'jiafo',
'jialo',
'jiame',
'jiade',
'jiate',
'jiane',
'jiale',
'jiage',
'jiake',
'jiahe',
'jiare',
'jiaze',
'jiace',
'jiase',
'jiaai',
'jiaei',
'jiaao',
'jiaou',
'jiaan',
'jiaen',
'jiaer',
'jiayi',
'jiabi',
'jiapi',
'jiami',
'jiadi',
'jiati',
'jiani',
'jiali',
'jiaji',
'jiaqi',
'jiaxi',
'jiaya',
'jiayo',
'jiaye',
'jiawu',
'jiabu',
'jiapu',
'jiamu',
'jiafu',
'jiadu',
'jiatu',
'jianu',
'jialu',
'jiagu',
'jiaku',
'jiahu',
'jiaru',
'jiazu',
'jiacu',
'jiasu',
'jiawa',
'jiawo',
'jiayu',
'jianü',
'jialü',
'jiaju',
'jiaqu',
'jiaxu',
'qiari',
'qiazi',
'qiaci',
'qiasi',
'qiaba',
'qiapa',
'qiama',
'qiafa',
'qiada',
'qiata',
'qiana',
'qiala',
'qiaga',
'qiaka',
'qiaha',
'qiaza',
'qiaca',
'qiasa',
'qiabo',
'qiapo',
'qiamo',
'qiafo',
'qialo',
'qiame',
'qiade',
'qiate',
'qiane',
'qiale',
'qiage',
'qiake',
'qiahe',
'qiare',
'qiaze',
'qiace',
'qiase',
'qiaai',
'qiaei',
'qiaao',
'qiaou',
'qiaan',
'qiaen',
'qiaer',
'qiayi',
'qiabi',
'qiapi',
'qiami',
'qiadi',
'qiati',
'qiani',
'qiali',
'qiaji',
'qiaqi',
'qiaxi',
'qiaya',
'qiayo',
'qiaye',
'qiawu',
'qiabu',
'qiapu',
'qiamu',
'qiafu',
'qiadu',
'qiatu',
'qianu',
'qialu',
'qiagu',
'qiaku',
'qiahu',
'qiaru',
'qiazu',
'qiacu',
'qiasu',
'qiawa',
'qiawo',
'qiayu',
'qianü',
'qialü',
'qiaju',
'qiaqu',
'qiaxu',
'xiari',
'xiazi',
'xiaci',
'xiasi',
'xiaba',
'xiapa',
'xiama',
'xiafa',
'xiada',
'xiata',
'xiana',
'xiala',
'xiaga',
'xiaka',
'xiaha',
'xiaza',
'xiaca',
'xiasa',
'xiabo',
'xiapo',
'xiamo',
'xiafo',
'xialo',
'xiame',
'xiade',
'xiate',
'xiane',
'xiale',
'xiage',
'xiake',
'xiahe',
'xiare',
'xiaze',
'xiace',
'xiase',
'xiaai',
'xiaei',
'xiaao',
'xiaou',
'xiaan',
'xiaen',
'xiaer',
'xiayi',
'xiabi',
'xiapi',
'xiami',
'xiadi',
'xiati',
'xiani',
'xiali',
'xiaji',
'xiaqi',
'xiaxi',
'xiaya',
'xiayo',
'xiaye',
'xiawu',
'xiabu',
'xiapu',
'xiamu',
'xiafu',
'xiadu',
'xiatu',
'xianu',
'xialu',
'xiagu',
'xiaku',
'xiahu',
'xiaru',
'xiazu',
'xiacu',
'xiasu',
'xiawa',
'xiawo',
'xiayu',
'xianü',
'xialü',
'xiaju',
'xiaqu',
'xiaxu',
'yozhi',
'yochi',
'yoshi',
'yozha',
'yocha',
'yosha',
'yozhe',
'yoche',
'yoshe',
'yobai',
'yopai',
'yomai',
'yodai',
'yotai',
'yonai',
'yolai',
'yogai',
'yokai',
'yohai',
'yozai',
'yocai',
'yosai',
'yobei',
'yopei',
'yomei',
'yofei',
'yodei',
'yotei',
'yonei',
'yolei',
'yogei',
'yokei',
'yohei',
'yozei',
'yosei',
'yobao',
'yopao',
'yomao',
'yodao',
'yotao',
'yonao',
'yolao',
'yogao',
'yokao',
'yohao',
'yorao',
'yozao',
'yocao',
'yosao',
'yopou',
'yomou',
'yofou',
'yodou',
'yotou',
'yonou',
'yolou',
'yogou',
'yokou',
'yohou',
'yorou',
'yozou',
'yocou',
'yosou',
'yoban',
'yopan',
'yoman',
'yofan',
'yodan',
'yotan',
'yonan',
'yolan',
'yogan',
'yokan',
'yohan',
'yoran',
'yozan',
'yocan',
'yosan',
'yoben',
'yopen',
'yomen',
'yofen',
'yoden',
'yonen',
'yogen',
'yoken',
'yohen',
'yoren',
'yozen',
'yocen',
'yosen',
'yoang',
'yoeng',
'yodia',
'yonia',
'yolia',
'yojia',
'yoqia',
'yoxia',
'yobie',
'yopie',
'yomie',
'yodie',
'yotie',
'yonie',
'yolie',
'yojie',
'yoqie',
'yoxie',
'yoyai',
'yoyao',
'yoyou',
'yomiu',
'yodiu',
'yoniu',
'yoliu',
'yojiu',
'yoqiu',
'yoxiu',
'yoyan',
'yoyin',
'yobin',
'yopin',
'yomin',
'yodin',
'yonin',
'yolin',
'yojin',
'yoqin',
'yoxin',
'yozhu',
'yochu',
'yoshu',
'yogua',
'yokua',
'yohua',
'yorua',
'yoduo',
'yotuo',
'yonuo',
'yoluo',
'yoguo',
'yokuo',
'yohuo',
'yoruo',
'yozuo',
'yocuo',
'yosuo',
'yowai',
'yowei',
'yodui',
'yotui',
'yogui',
'yokui',
'yohui',
'yorui',
'yozui',
'yocui',
'yosui',
'yowan',
'yowen',
'yodun',
'yotun',
'yonun',
'yolun',
'yogun',
'yokun',
'yohun',
'yorun',
'yozun',
'yocun',
'yosun',
'yoyue',
'yonüe',
'yolüe',
'yojue',
'yoque',
'yoxue',
'yoyun',
'yolün',
'yojun',
'yoqun',
'yoxun',
'yezhi',
'yechi',
'yeshi',
'yezha',
'yecha',
'yesha',
'yezhe',
'yeche',
'yeshe',
'yebai',
'yepai',
'yemai',
'yedai',
'yetai',
'yenai',
'yelai',
'yegai',
'yekai',
'yehai',
'yezai',
'yecai',
'yesai',
'yebei',
'yepei',
'yemei',
'yefei',
'yedei',
'yetei',
'yenei',
'yelei',
'yegei',
'yekei',
'yehei',
'yezei',
'yesei',
'yebao',
'yepao',
'yemao',
'yedao',
'yetao',
'yenao',
'yelao',
'yegao',
'yekao',
'yehao',
'yerao',
'yezao',
'yecao',
'yesao',
'yepou',
'yemou',
'yefou',
'yedou',
'yetou',
'yenou',
'yelou',
'yegou',
'yekou',
'yehou',
'yerou',
'yezou',
'yecou',
'yesou',
'yeban',
'yepan',
'yeman',
'yefan',
'yedan',
'yetan',
'yenan',
'yelan',
'yegan',
'yekan',
'yehan',
'yeran',
'yezan',
'yecan',
'yesan',
'yeben',
'yepen',
'yemen',
'yefen',
'yeden',
'yenen',
'yegen',
'yeken',
'yehen',
'yeren',
'yezen',
'yecen',
'yesen',
'yeang',
'yeeng',
'yedia',
'yenia',
'yelia',
'yejia',
'yeqia',
'yexia',
'yebie',
'yepie',
'yemie',
'yedie',
'yetie',
'yenie',
'yelie',
'yejie',
'yeqie',
'yexie',
'yeyai',
'yeyao',
'yeyou',
'yemiu',
'yediu',
'yeniu',
'yeliu',
'yejiu',
'yeqiu',
'yexiu',
'yeyan',
'yeyin',
'yebin',
'yepin',
'yemin',
'yedin',
'yenin',
'yelin',
'yejin',
'yeqin',
'yexin',
'yezhu',
'yechu',
'yeshu',
'yegua',
'yekua',
'yehua',
'yerua',
'yeduo',
'yetuo',
'yenuo',
'yeluo',
'yeguo',
'yekuo',
'yehuo',
'yeruo',
'yezuo',
'yecuo',
'yesuo',
'yewai',
'yewei',
'yedui',
'yetui',
'yegui',
'yekui',
'yehui',
'yerui',
'yezui',
'yecui',
'yesui',
'yewan',
'yewen',
'yedun',
'yetun',
'yenun',
'yelun',
'yegun',
'yekun',
'yehun',
'yerun',
'yezun',
'yecun',
'yesun',
'yeyue',
'yenüe',
'yelüe',
'yejue',
'yeque',
'yexue',
'yeyun',
'yelün',
'yejun',
'yequn',
'yexun',
'bieri',
'biezi',
'bieci',
'biesi',
'bieba',
'biepa',
'biema',
'biefa',
'bieda',
'bieta',
'biena',
'biela',
'biega',
'bieka',
'bieha',
'bieza',
'bieca',
'biesa',
'biebo',
'biepo',
'biemo',
'biefo',
'bielo',
'bieme',
'biede',
'biete',
'biene',
'biele',
'biege',
'bieke',
'biehe',
'biere',
'bieze',
'biece',
'biese',
'bieai',
'bieei',
'bieao',
'bieou',
'biean',
'bieen',
'bieer',
'bieyi',
'biebi',
'biepi',
'biemi',
'biedi',
'bieti',
'bieni',
'bieli',
'bieji',
'bieqi',
'biexi',
'bieya',
'bieyo',
'bieye',
'biewu',
'biebu',
'biepu',
'biemu',
'biefu',
'biedu',
'bietu',
'bienu',
'bielu',
'biegu',
'bieku',
'biehu',
'bieru',
'biezu',
'biecu',
'biesu',
'biewa',
'biewo',
'bieyu',
'bienü',
'bielü',
'bieju',
'biequ',
'biexu',
'pieri',
'piezi',
'pieci',
'piesi',
'pieba',
'piepa',
'piema',
'piefa',
'pieda',
'pieta',
'piena',
'piela',
'piega',
'pieka',
'pieha',
'pieza',
'pieca',
'piesa',
'piebo',
'piepo',
'piemo',
'piefo',
'pielo',
'pieme',
'piede',
'piete',
'piene',
'piele',
'piege',
'pieke',
'piehe',
'piere',
'pieze',
'piece',
'piese',
'pieai',
'pieei',
'pieao',
'pieou',
'piean',
'pieen',
'pieer',
'pieyi',
'piebi',
'piepi',
'piemi',
'piedi',
'pieti',
'pieni',
'pieli',
'pieji',
'pieqi',
'piexi',
'pieya',
'pieyo',
'pieye',
'piewu',
'piebu',
'piepu',
'piemu',
'piefu',
'piedu',
'pietu',
'pienu',
'pielu',
'piegu',
'pieku',
'piehu',
'pieru',
'piezu',
'piecu',
'piesu',
'piewa',
'piewo',
'pieyu',
'pienü',
'pielü',
'pieju',
'piequ',
'piexu',
'mieri',
'miezi',
'mieci',
'miesi',
'mieba',
'miepa',
'miema',
'miefa',
'mieda',
'mieta',
'miena',
'miela',
'miega',
'mieka',
'mieha',
'mieza',
'mieca',
'miesa',
'miebo',
'miepo',
'miemo',
'miefo',
'mielo',
'mieme',
'miede',
'miete',
'miene',
'miele',
'miege',
'mieke',
'miehe',
'miere',
'mieze',
'miece',
'miese',
'mieai',
'mieei',
'mieao',
'mieou',
'miean',
'mieen',
'mieer',
'mieyi',
'miebi',
'miepi',
'miemi',
'miedi',
'mieti',
'mieni',
'mieli',
'mieji',
'mieqi',
'miexi',
'mieya',
'mieyo',
'mieye',
'miewu',
'miebu',
'miepu',
'miemu',
'miefu',
'miedu',
'mietu',
'mienu',
'mielu',
'miegu',
'mieku',
'miehu',
'mieru',
'miezu',
'miecu',
'miesu',
'miewa',
'miewo',
'mieyu',
'mienü',
'mielü',
'mieju',
'miequ',
'miexu',
'dieri',
'diezi',
'dieci',
'diesi',
'dieba',
'diepa',
'diema',
'diefa',
'dieda',
'dieta',
'diena',
'diela',
'diega',
'dieka',
'dieha',
'dieza',
'dieca',
'diesa',
'diebo',
'diepo',
'diemo',
'diefo',
'dielo',
'dieme',
'diede',
'diete',
'diene',
'diele',
'diege',
'dieke',
'diehe',
'diere',
'dieze',
'diece',
'diese',
'dieai',
'dieei',
'dieao',
'dieou',
'diean',
'dieen',
'dieer',
'dieyi',
'diebi',
'diepi',
'diemi',
'diedi',
'dieti',
'dieni',
'dieli',
'dieji',
'dieqi',
'diexi',
'dieya',
'dieyo',
'dieye',
'diewu',
'diebu',
'diepu',
'diemu',
'diefu',
'diedu',
'dietu',
'dienu',
'dielu',
'diegu',
'dieku',
'diehu',
'dieru',
'diezu',
'diecu',
'diesu',
'diewa',
'diewo',
'dieyu',
'dienü',
'dielü',
'dieju',
'diequ',
'diexu',
'tieri',
'tiezi',
'tieci',
'tiesi',
'tieba',
'tiepa',
'tiema',
'tiefa',
'tieda',
'tieta',
'tiena',
'tiela',
'tiega',
'tieka',
'tieha',
'tieza',
'tieca',
'tiesa',
'tiebo',
'tiepo',
'tiemo',
'tiefo',
'tielo',
'tieme',
'tiede',
'tiete',
'tiene',
'tiele',
'tiege',
'tieke',
'tiehe',
'tiere',
'tieze',
'tiece',
'tiese',
'tieai',
'tieei',
'tieao',
'tieou',
'tiean',
'tieen',
'tieer',
'tieyi',
'tiebi',
'tiepi',
'tiemi',
'tiedi',
'tieti',
'tieni',
'tieli',
'tieji',
'tieqi',
'tiexi',
'tieya',
'tieyo',
'tieye',
'tiewu',
'tiebu',
'tiepu',
'tiemu',
'tiefu',
'tiedu',
'tietu',
'tienu',
'tielu',
'tiegu',
'tieku',
'tiehu',
'tieru',
'tiezu',
'tiecu',
'tiesu',
'tiewa',
'tiewo',
'tieyu',
'tienü',
'tielü',
'tieju',
'tiequ',
'tiexu',
'nieri',
'niezi',
'nieci',
'niesi',
'nieba',
'niepa',
'niema',
'niefa',
'nieda',
'nieta',
'niena',
'niela',
'niega',
'nieka',
'nieha',
'nieza',
'nieca',
'niesa',
'niebo',
'niepo',
'niemo',
'niefo',
'nielo',
'nieme',
'niede',
'niete',
'niene',
'niele',
'niege',
'nieke',
'niehe',
'niere',
'nieze',
'niece',
'niese',
'nieai',
'nieei',
'nieao',
'nieou',
'niean',
'nieen',
'nieer',
'nieyi',
'niebi',
'niepi',
'niemi',
'niedi',
'nieti',
'nieni',
'nieli',
'nieji',
'nieqi',
'niexi',
'nieya',
'nieyo',
'nieye',
'niewu',
'niebu',
'niepu',
'niemu',
'niefu',
'niedu',
'nietu',
'nienu',
'nielu',
'niegu',
'nieku',
'niehu',
'nieru',
'niezu',
'niecu',
'niesu',
'niewa',
'niewo',
'nieyu',
'nienü',
'nielü',
'nieju',
'niequ',
'niexu',
'lieri',
'liezi',
'lieci',
'liesi',
'lieba',
'liepa',
'liema',
'liefa',
'lieda',
'lieta',
'liena',
'liela',
'liega',
'lieka',
'lieha',
'lieza',
'lieca',
'liesa',
'liebo',
'liepo',
'liemo',
'liefo',
'lielo',
'lieme',
'liede',
'liete',
'liene',
'liele',
'liege',
'lieke',
'liehe',
'liere',
'lieze',
'liece',
'liese',
'lieai',
'lieei',
'lieao',
'lieou',
'liean',
'lieen',
'lieer',
'lieyi',
'liebi',
'liepi',
'liemi',
'liedi',
'lieti',
'lieni',
'lieli',
'lieji',
'lieqi',
'liexi',
'lieya',
'lieyo',
'lieye',
'liewu',
'liebu',
'liepu',
'liemu',
'liefu',
'liedu',
'lietu',
'lienu',
'lielu',
'liegu',
'lieku',
'liehu',
'lieru',
'liezu',
'liecu',
'liesu',
'liewa',
'liewo',
'lieyu',
'lienü',
'lielü',
'lieju',
'liequ',
'liexu',
'jieri',
'jiezi',
'jieci',
'jiesi',
'jieba',
'jiepa',
'jiema',
'jiefa',
'jieda',
'jieta',
'jiena',
'jiela',
'jiega',
'jieka',
'jieha',
'jieza',
'jieca',
'jiesa',
'jiebo',
'jiepo',
'jiemo',
'jiefo',
'jielo',
'jieme',
'jiede',
'jiete',
'jiene',
'jiele',
'jiege',
'jieke',
'jiehe',
'jiere',
'jieze',
'jiece',
'jiese',
'jieai',
'jieei',
'jieao',
'jieou',
'jiean',
'jieen',
'jieer',
'jieyi',
'jiebi',
'jiepi',
'jiemi',
'jiedi',
'jieti',
'jieni',
'jieli',
'jieji',
'jieqi',
'jiexi',
'jieya',
'jieyo',
'jieye',
'jiewu',
'jiebu',
'jiepu',
'jiemu',
'jiefu',
'jiedu',
'jietu',
'jienu',
'jielu',
'jiegu',
'jieku',
'jiehu',
'jieru',
'jiezu',
'jiecu',
'jiesu',
'jiewa',
'jiewo',
'jieyu',
'jienü',
'jielü',
'jieju',
'jiequ',
'jiexu',
'qieri',
'qiezi',
'qieci',
'qiesi',
'qieba',
'qiepa',
'qiema',
'qiefa',
'qieda',
'qieta',
'qiena',
'qiela',
'qiega',
'qieka',
'qieha',
'qieza',
'qieca',
'qiesa',
'qiebo',
'qiepo',
'qiemo',
'qiefo',
'qielo',
'qieme',
'qiede',
'qiete',
'qiene',
'qiele',
'qiege',
'qieke',
'qiehe',
'qiere',
'qieze',
'qiece',
'qiese',
'qieai',
'qieei',
'qieao',
'qieou',
'qiean',
'qieen',
'qieer',
'qieyi',
'qiebi',
'qiepi',
'qiemi',
'qiedi',
'qieti',
'qieni',
'qieli',
'qieji',
'qieqi',
'qiexi',
'qieya',
'qieyo',
'qieye',
'qiewu',
'qiebu',
'qiepu',
'qiemu',
'qiefu',
'qiedu',
'qietu',
'qienu',
'qielu',
'qiegu',
'qieku',
'qiehu',
'qieru',
'qiezu',
'qiecu',
'qiesu',
'qiewa',
'qiewo',
'qieyu',
'qienü',
'qielü',
'qieju',
'qiequ',
'qiexu',
'xieri',
'xiezi',
'xieci',
'xiesi',
'xieba',
'xiepa',
'xiema',
'xiefa',
'xieda',
'xieta',
'xiena',
'xiela',
'xiega',
'xieka',
'xieha',
'xieza',
'xieca',
'xiesa',
'xiebo',
'xiepo',
'xiemo',
'xiefo',
'xielo',
'xieme',
'xiede',
'xiete',
'xiene',
'xiele',
'xiege',
'xieke',
'xiehe',
'xiere',
'xieze',
'xiece',
'xiese',
'xieai',
'xieei',
'xieao',
'xieou',
'xiean',
'xieen',
'xieer',
'xieyi',
'xiebi',
'xiepi',
'xiemi',
'xiedi',
'xieti',
'xieni',
'xieli',
'xieji',
'xieqi',
'xiexi',
'xieya',
'xieyo',
'xieye',
'xiewu',
'xiebu',
'xiepu',
'xiemu',
'xiefu',
'xiedu',
'xietu',
'xienu',
'xielu',
'xiegu',
'xieku',
'xiehu',
'xieru',
'xiezu',
'xiecu',
'xiesu',
'xiewa',
'xiewo',
'xieyu',
'xienü',
'xielü',
'xieju',
'xiequ',
'xiexu',
'yairi',
'yaizi',
'yaici',
'yaisi',
'yaiba',
'yaipa',
'yaima',
'yaifa',
'yaida',
'yaita',
'yaina',
'yaila',
'yaiga',
'yaika',
'yaiha',
'yaiza',
'yaica',
'yaisa',
'yaibo',
'yaipo',
'yaimo',
'yaifo',
'yailo',
'yaime',
'yaide',
'yaite',
'yaine',
'yaile',
'yaige',
'yaike',
'yaihe',
'yaire',
'yaize',
'yaice',
'yaise',
'yaiai',
'yaiei',
'yaiao',
'yaiou',
'yaian',
'yaien',
'yaier',
'yaiyi',
'yaibi',
'yaipi',
'yaimi',
'yaidi',
'yaiti',
'yaini',
'yaili',
'yaiji',
'yaiqi',
'yaixi',
'yaiya',
'yaiyo',
'yaiye',
'yaiwu',
'yaibu',
'yaipu',
'yaimu',
'yaifu',
'yaidu',
'yaitu',
'yainu',
'yailu',
'yaigu',
'yaiku',
'yaihu',
'yairu',
'yaizu',
'yaicu',
'yaisu',
'yaiwa',
'yaiwo',
'yaiyu',
'yainü',
'yailü',
'yaiju',
'yaiqu',
'yaixu',
'yaori',
'yaozi',
'yaoci',
'yaosi',
'yaoba',
'yaopa',
'yaoma',
'yaofa',
'yaoda',
'yaota',
'yaona',
'yaola',
'yaoga',
'yaoka',
'yaoha',
'yaoza',
'yaoca',
'yaosa',
'yaobo',
'yaopo',
'yaomo',
'yaofo',
'yaolo',
'yaome',
'yaode',
'yaote',
'yaone',
'yaole',
'yaoge',
'yaoke',
'yaohe',
'yaore',
'yaoze',
'yaoce',
'yaose',
'yaoai',
'yaoei',
'yaoao',
'yaoou',
'yaoan',
'yaoen',
'yaoer',
'yaoyi',
'yaobi',
'yaopi',
'yaomi',
'yaodi',
'yaoti',
'yaoni',
'yaoli',
'yaoji',
'yaoqi',
'yaoxi',
'yaoya',
'yaoyo',
'yaoye',
'yaowu',
'yaobu',
'yaopu',
'yaomu',
'yaofu',
'yaodu',
'yaotu',
'yaonu',
'yaolu',
'yaogu',
'yaoku',
'yaohu',
'yaoru',
'yaozu',
'yaocu',
'yaosu',
'yaowa',
'yaowo',
'yaoyu',
'yaonü',
'yaolü',
'yaoju',
'yaoqu',
'yaoxu',
'youri',
'youzi',
'youci',
'yousi',
'youba',
'youpa',
'youma',
'youfa',
'youda',
'youta',
'youna',
'youla',
'youga',
'youka',
'youha',
'youza',
'youca',
'yousa',
'youbo',
'youpo',
'youmo',
'youfo',
'youlo',
'youme',
'youde',
'youte',
'youne',
'youle',
'youge',
'youke',
'youhe',
'youre',
'youze',
'youce',
'youse',
'youai',
'youei',
'youao',
'youou',
'youan',
'youen',
'youer',
'youyi',
'youbi',
'youpi',
'youmi',
'youdi',
'youti',
'youni',
'youli',
'youji',
'youqi',
'youxi',
'youya',
'youyo',
'youye',
'youwu',
'youbu',
'youpu',
'youmu',
'youfu',
'youdu',
'youtu',
'younu',
'youlu',
'yougu',
'youku',
'youhu',
'youru',
'youzu',
'youcu',
'yousu',
'youwa',
'youwo',
'youyu',
'younü',
'youlü',
'youju',
'youqu',
'youxu',
'miuri',
'miuzi',
'miuci',
'miusi',
'miuba',
'miupa',
'miuma',
'miufa',
'miuda',
'miuta',
'miuna',
'miula',
'miuga',
'miuka',
'miuha',
'miuza',
'miuca',
'miusa',
'miubo',
'miupo',
'miumo',
'miufo',
'miulo',
'miume',
'miude',
'miute',
'miune',
'miule',
'miuge',
'miuke',
'miuhe',
'miure',
'miuze',
'miuce',
'miuse',
'miuai',
'miuei',
'miuao',
'miuou',
'miuan',
'miuen',
'miuer',
'miuyi',
'miubi',
'miupi',
'miumi',
'miudi',
'miuti',
'miuni',
'miuli',
'miuji',
'miuqi',
'miuxi',
'miuya',
'miuyo',
'miuye',
'miuwu',
'miubu',
'miupu',
'miumu',
'miufu',
'miudu',
'miutu',
'miunu',
'miulu',
'miugu',
'miuku',
'miuhu',
'miuru',
'miuzu',
'miucu',
'miusu',
'miuwa',
'miuwo',
'miuyu',
'miunü',
'miulü',
'miuju',
'miuqu',
'miuxu',
'diuri',
'diuzi',
'diuci',
'diusi',
'diuba',
'diupa',
'diuma',
'diufa',
'diuda',
'diuta',
'diuna',
'diula',
'diuga',
'diuka',
'diuha',
'diuza',
'diuca',
'diusa',
'diubo',
'diupo',
'diumo',
'diufo',
'diulo',
'diume',
'diude',
'diute',
'diune',
'diule',
'diuge',
'diuke',
'diuhe',
'diure',
'diuze',
'diuce',
'diuse',
'diuai',
'diuei',
'diuao',
'diuou',
'diuan',
'diuen',
'diuer',
'diuyi',
'diubi',
'diupi',
'diumi',
'diudi',
'diuti',
'diuni',
'diuli',
'diuji',
'diuqi',
'diuxi',
'diuya',
'diuyo',
'diuye',
'diuwu',
'diubu',
'diupu',
'diumu',
'diufu',
'diudu',
'diutu',
'diunu',
'diulu',
'diugu',
'diuku',
'diuhu',
'diuru',
'diuzu',
'diucu',
'diusu',
'diuwa',
'diuwo',
'diuyu',
'diunü',
'diulü',
'diuju',
'diuqu',
'diuxu',
'niuri',
'niuzi',
'niuci',
'niusi',
'niuba',
'niupa',
'niuma',
'niufa',
'niuda',
'niuta',
'niuna',
'niula',
'niuga',
'niuka',
'niuha',
'niuza',
'niuca',
'niusa',
'niubo',
'niupo',
'niumo',
'niufo',
'niulo',
'niume',
'niude',
'niute',
'niune',
'niule',
'niuge',
'niuke',
'niuhe',
'niure',
'niuze',
'niuce',
'niuse',
'niuai',
'niuei',
'niuao',
'niuou',
'niuan',
'niuen',
'niuer',
'niuyi',
'niubi',
'niupi',
'niumi',
'niudi',
'niuti',
'niuni',
'niuli',
'niuji',
'niuqi',
'niuxi',
'niuya',
'niuyo',
'niuye',
'niuwu',
'niubu',
'niupu',
'niumu',
'niufu',
'niudu',
'niutu',
'niunu',
'niulu',
'niugu',
'niuku',
'niuhu',
'niuru',
'niuzu',
'niucu',
'niusu',
'niuwa',
'niuwo',
'niuyu',
'niunü',
'niulü',
'niuju',
'niuqu',
'niuxu',
'liuri',
'liuzi',
'liuci',
'liusi',
'liuba',
'liupa',
'liuma',
'liufa',
'liuda',
'liuta',
'liuna',
'liula',
'liuga',
'liuka',
'liuha',
'liuza',
'liuca',
'liusa',
'liubo',
'liupo',
'liumo',
'liufo',
'liulo',
'liume',
'liude',
'liute',
'liune',
'liule',
'liuge',
'liuke',
'liuhe',
'liure',
'liuze',
'liuce',
'liuse',
'liuai',
'liuei',
'liuao',
'liuou',
'liuan',
'liuen',
'liuer',
'liuyi',
'liubi',
'liupi',
'liumi',
'liudi',
'liuti',
'liuni',
'liuli',
'liuji',
'liuqi',
'liuxi',
'liuya',
'liuyo',
'liuye',
'liuwu',
'liubu',
'liupu',
'liumu',
'liufu',
'liudu',
'liutu',
'liunu',
'liulu',
'liugu',
'liuku',
'liuhu',
'liuru',
'liuzu',
'liucu',
'liusu',
'liuwa',
'liuwo',
'liuyu',
'liunü',
'liulü',
'liuju',
'liuqu',
'liuxu',
'jiuri',
'jiuzi',
'jiuci',
'jiusi',
'jiuba',
'jiupa',
'jiuma',
'jiufa',
'jiuda',
'jiuta',
'jiuna',
'jiula',
'jiuga',
'jiuka',
'jiuha',
'jiuza',
'jiuca',
'jiusa',
'jiubo',
'jiupo',
'jiumo',
'jiufo',
'jiulo',
'jiume',
'jiude',
'jiute',
'jiune',
'jiule',
'jiuge',
'jiuke',
'jiuhe',
'jiure',
'jiuze',
'jiuce',
'jiuse',
'jiuai',
'jiuei',
'jiuao',
'jiuou',
'jiuan',
'jiuen',
'jiuer',
'jiuyi',
'jiubi',
'jiupi',
'jiumi',
'jiudi',
'jiuti',
'jiuni',
'jiuli',
'jiuji',
'jiuqi',
'jiuxi',
'jiuya',
'jiuyo',
'jiuye',
'jiuwu',
'jiubu',
'jiupu',
'jiumu',
'jiufu',
'jiudu',
'jiutu',
'jiunu',
'jiulu',
'jiugu',
'jiuku',
'jiuhu',
'jiuru',
'jiuzu',
'jiucu',
'jiusu',
'jiuwa',
'jiuwo',
'jiuyu',
'jiunü',
'jiulü',
'jiuju',
'jiuqu',
'jiuxu',
'qiuri',
'qiuzi',
'qiuci',
'qiusi',
'qiuba',
'qiupa',
'qiuma',
'qiufa',
'qiuda',
'qiuta',
'qiuna',
'qiula',
'qiuga',
'qiuka',
'qiuha',
'qiuza',
'qiuca',
'qiusa',
'qiubo',
'qiupo',
'qiumo',
'qiufo',
'qiulo',
'qiume',
'qiude',
'qiute',
'qiune',
'qiule',
'qiuge',
'qiuke',
'qiuhe',
'qiure',
'qiuze',
'qiuce',
'qiuse',
'qiuai',
'qiuei',
'qiuao',
'qiuou',
'qiuan',
'qiuen',
'qiuer',
'qiuyi',
'qiubi',
'qiupi',
'qiumi',
'qiudi',
'qiuti',
'qiuni',
'qiuli',
'qiuji',
'qiuqi',
'qiuxi',
'qiuya',
'qiuyo',
'qiuye',
'qiuwu',
'qiubu',
'qiupu',
'qiumu',
'qiufu',
'qiudu',
'qiutu',
'qiunu',
'qiulu',
'qiugu',
'qiuku',
'qiuhu',
'qiuru',
'qiuzu',
'qiucu',
'qiusu',
'qiuwa',
'qiuwo',
'qiuyu',
'qiunü',
'qiulü',
'qiuju',
'qiuqu',
'qiuxu',
'xiuri',
'xiuzi',
'xiuci',
'xiusi',
'xiuba',
'xiupa',
'xiuma',
'xiufa',
'xiuda',
'xiuta',
'xiuna',
'xiula',
'xiuga',
'xiuka',
'xiuha',
'xiuza',
'xiuca',
'xiusa',
'xiubo',
'xiupo',
'xiumo',
'xiufo',
'xiulo',
'xiume',
'xiude',
'xiute',
'xiune',
'xiule',
'xiuge',
'xiuke',
'xiuhe',
'xiure',
'xiuze',
'xiuce',
'xiuse',
'xiuai',
'xiuei',
'xiuao',
'xiuou',
'xiuan',
'xiuen',
'xiuer',
'xiuyi',
'xiubi',
'xiupi',
'xiumi',
'xiudi',
'xiuti',
'xiuni',
'xiuli',
'xiuji',
'xiuqi',
'xiuxi',
'xiuya',
'xiuyo',
'xiuye',
'xiuwu',
'xiubu',
'xiupu',
'xiumu',
'xiufu',
'xiudu',
'xiutu',
'xiunu',
'xiulu',
'xiugu',
'xiuku',
'xiuhu',
'xiuru',
'xiuzu',
'xiucu',
'xiusu',
'xiuwa',
'xiuwo',
'xiuyu',
'xiunü',
'xiulü',
'xiuju',
'xiuqu',
'xiuxu',
'yanri',
'yanzi',
'yanci',
'yansi',
'yanba',
'yanpa',
'yanma',
'yanfa',
'yanda',
'yanta',
'yanna',
'yanla',
'yanga',
'yanka',
'yanha',
'yanza',
'yanca',
'yansa',
'yanbo',
'yanpo',
'yanmo',
'yanfo',
'yanlo',
'yanme',
'yande',
'yante',
'yanne',
'yanle',
'yange',
'yanke',
'yanhe',
'yanre',
'yanze',
'yance',
'yanse',
'yanai',
'yanei',
'yanao',
'yanou',
'yanan',
'yanen',
'yaner',
'yanyi',
'yanbi',
'yanpi',
'yanmi',
'yandi',
'yanti',
'yanni',
'yanli',
'yanji',
'yanqi',
'yanxi',
'yanya',
'yanyo',
'yanye',
'yanwu',
'yanbu',
'yanpu',
'yanmu',
'yanfu',
'yandu',
'yantu',
'yannu',
'yanlu',
'yangu',
'yanku',
'yanhu',
'yanru',
'yanzu',
'yancu',
'yansu',
'yanwa',
'yanwo',
'yanyu',
'yannü',
'yanlü',
'yanju',
'yanqu',
'yanxu',
'yinri',
'yinzi',
'yinci',
'yinsi',
'yinba',
'yinpa',
'yinma',
'yinfa',
'yinda',
'yinta',
'yinna',
'yinla',
'yinga',
'yinka',
'yinha',
'yinza',
'yinca',
'yinsa',
'yinbo',
'yinpo',
'yinmo',
'yinfo',
'yinlo',
'yinme',
'yinde',
'yinte',
'yinne',
'yinle',
'yinge',
'yinke',
'yinhe',
'yinre',
'yinze',
'yince',
'yinse',
'yinai',
'yinei',
'yinao',
'yinou',
'yinan',
'yinen',
'yiner',
'yinyi',
'yinbi',
'yinpi',
'yinmi',
'yindi',
'yinti',
'yinni',
'yinli',
'yinji',
'yinqi',
'yinxi',
'yinya',
'yinyo',
'yinye',
'yinwu',
'yinbu',
'yinpu',
'yinmu',
'yinfu',
'yindu',
'yintu',
'yinnu',
'yinlu',
'yingu',
'yinku',
'yinhu',
'yinru',
'yinzu',
'yincu',
'yinsu',
'yinwa',
'yinwo',
'yinyu',
'yinnü',
'yinlü',
'yinju',
'yinqu',
'yinxu',
'binri',
'binzi',
'binci',
'binsi',
'binba',
'binpa',
'binma',
'binfa',
'binda',
'binta',
'binna',
'binla',
'binga',
'binka',
'binha',
'binza',
'binca',
'binsa',
'binbo',
'binpo',
'binmo',
'binfo',
'binlo',
'binme',
'binde',
'binte',
'binne',
'binle',
'binge',
'binke',
'binhe',
'binre',
'binze',
'bince',
'binse',
'binai',
'binei',
'binao',
'binou',
'binan',
'binen',
'biner',
'binyi',
'binbi',
'binpi',
'binmi',
'bindi',
'binti',
'binni',
'binli',
'binji',
'binqi',
'binxi',
'binya',
'binyo',
'binye',
'binwu',
'binbu',
'binpu',
'binmu',
'binfu',
'bindu',
'bintu',
'binnu',
'binlu',
'bingu',
'binku',
'binhu',
'binru',
'binzu',
'bincu',
'binsu',
'binwa',
'binwo',
'binyu',
'binnü',
'binlü',
'binju',
'binqu',
'binxu',
'pinri',
'pinzi',
'pinci',
'pinsi',
'pinba',
'pinpa',
'pinma',
'pinfa',
'pinda',
'pinta',
'pinna',
'pinla',
'pinga',
'pinka',
'pinha',
'pinza',
'pinca',
'pinsa',
'pinbo',
'pinpo',
'pinmo',
'pinfo',
'pinlo',
'pinme',
'pinde',
'pinte',
'pinne',
'pinle',
'pinge',
'pinke',
'pinhe',
'pinre',
'pinze',
'pince',
'pinse',
'pinai',
'pinei',
'pinao',
'pinou',
'pinan',
'pinen',
'piner',
'pinyi',
'pinbi',
'pinpi',
'pinmi',
'pindi',
'pinti',
'pinni',
'pinli',
'pinji',
'pinqi',
'pinxi',
'pinya',
'pinyo',
'pinye',
'pinwu',
'pinbu',
'pinpu',
'pinmu',
'pinfu',
'pindu',
'pintu',
'pinnu',
'pinlu',
'pingu',
'pinku',
'pinhu',
'pinru',
'pinzu',
'pincu',
'pinsu',
'pinwa',
'pinwo',
'pinyu',
'pinnü',
'pinlü',
'pinju',
'pinqu',
'pinxu',
'minri',
'minzi',
'minci',
'minsi',
'minba',
'minpa',
'minma',
'minfa',
'minda',
'minta',
'minna',
'minla',
'minga',
'minka',
'minha',
'minza',
'minca',
'minsa',
'minbo',
'minpo',
'minmo',
'minfo',
'minlo',
'minme',
'minde',
'minte',
'minne',
'minle',
'minge',
'minke',
'minhe',
'minre',
'minze',
'mince',
'minse',
'minai',
'minei',
'minao',
'minou',
'minan',
'minen',
'miner',
'minyi',
'minbi',
'minpi',
'minmi',
'mindi',
'minti',
'minni',
'minli',
'minji',
'minqi',
'minxi',
'minya',
'minyo',
'minye',
'minwu',
'minbu',
'minpu',
'minmu',
'minfu',
'mindu',
'mintu',
'minnu',
'minlu',
'mingu',
'minku',
'minhu',
'minru',
'minzu',
'mincu',
'minsu',
'minwa',
'minwo',
'minyu',
'minnü',
'minlü',
'minju',
'minqu',
'minxu',
'dinri',
'dinzi',
'dinci',
'dinsi',
'dinba',
'dinpa',
'dinma',
'dinfa',
'dinda',
'dinta',
'dinna',
'dinla',
'dinga',
'dinka',
'dinha',
'dinza',
'dinca',
'dinsa',
'dinbo',
'dinpo',
'dinmo',
'dinfo',
'dinlo',
'dinme',
'dinde',
'dinte',
'dinne',
'dinle',
'dinge',
'dinke',
'dinhe',
'dinre',
'dinze',
'dince',
'dinse',
'dinai',
'dinei',
'dinao',
'dinou',
'dinan',
'dinen',
'diner',
'dinyi',
'dinbi',
'dinpi',
'dinmi',
'dindi',
'dinti',
'dinni',
'dinli',
'dinji',
'dinqi',
'dinxi',
'dinya',
'dinyo',
'dinye',
'dinwu',
'dinbu',
'dinpu',
'dinmu',
'dinfu',
'dindu',
'dintu',
'dinnu',
'dinlu',
'dingu',
'dinku',
'dinhu',
'dinru',
'dinzu',
'dincu',
'dinsu',
'dinwa',
'dinwo',
'dinyu',
'dinnü',
'dinlü',
'dinju',
'dinqu',
'dinxu',
'ninri',
'ninzi',
'ninci',
'ninsi',
'ninba',
'ninpa',
'ninma',
'ninfa',
'ninda',
'ninta',
'ninna',
'ninla',
'ninga',
'ninka',
'ninha',
'ninza',
'ninca',
'ninsa',
'ninbo',
'ninpo',
'ninmo',
'ninfo',
'ninlo',
'ninme',
'ninde',
'ninte',
'ninne',
'ninle',
'ninge',
'ninke',
'ninhe',
'ninre',
'ninze',
'nince',
'ninse',
'ninai',
'ninei',
'ninao',
'ninou',
'ninan',
'ninen',
'niner',
'ninyi',
'ninbi',
'ninpi',
'ninmi',
'nindi',
'ninti',
'ninni',
'ninli',
'ninji',
'ninqi',
'ninxi',
'ninya',
'ninyo',
'ninye',
'ninwu',
'ninbu',
'ninpu',
'ninmu',
'ninfu',
'nindu',
'nintu',
'ninnu',
'ninlu',
'ningu',
'ninku',
'ninhu',
'ninru',
'ninzu',
'nincu',
'ninsu',
'ninwa',
'ninwo',
'ninyu',
'ninnü',
'ninlü',
'ninju',
'ninqu',
'ninxu',
'linri',
'linzi',
'linci',
'linsi',
'linba',
'linpa',
'linma',
'linfa',
'linda',
'linta',
'linna',
'linla',
'linga',
'linka',
'linha',
'linza',
'linca',
'linsa',
'linbo',
'linpo',
'linmo',
'linfo',
'linlo',
'linme',
'linde',
'linte',
'linne',
'linle',
'linge',
'linke',
'linhe',
'linre',
'linze',
'lince',
'linse',
'linai',
'linei',
'linao',
'linou',
'linan',
'linen',
'liner',
'linyi',
'linbi',
'linpi',
'linmi',
'lindi',
'linti',
'linni',
'linli',
'linji',
'linqi',
'linxi',
'linya',
'linyo',
'linye',
'linwu',
'linbu',
'linpu',
'linmu',
'linfu',
'lindu',
'lintu',
'linnu',
'linlu',
'lingu',
'linku',
'linhu',
'linru',
'linzu',
'lincu',
'linsu',
'linwa',
'linwo',
'linyu',
'linnü',
'linlü',
'linju',
'linqu',
'linxu',
'jinri',
'jinzi',
'jinci',
'jinsi',
'jinba',
'jinpa',
'jinma',
'jinfa',
'jinda',
'jinta',
'jinna',
'jinla',
'jinga',
'jinka',
'jinha',
'jinza',
'jinca',
'jinsa',
'jinbo',
'jinpo',
'jinmo',
'jinfo',
'jinlo',
'jinme',
'jinde',
'jinte',
'jinne',
'jinle',
'jinge',
'jinke',
'jinhe',
'jinre',
'jinze',
'jince',
'jinse',
'jinai',
'jinei',
'jinao',
'jinou',
'jinan',
'jinen',
'jiner',
'jinyi',
'jinbi',
'jinpi',
'jinmi',
'jindi',
'jinti',
'jinni',
'jinli',
'jinji',
'jinqi',
'jinxi',
'jinya',
'jinyo',
'jinye',
'jinwu',
'jinbu',
'jinpu',
'jinmu',
'jinfu',
'jindu',
'jintu',
'jinnu',
'jinlu',
'jingu',
'jinku',
'jinhu',
'jinru',
'jinzu',
'jincu',
'jinsu',
'jinwa',
'jinwo',
'jinyu',
'jinnü',
'jinlü',
'jinju',
'jinqu',
'jinxu',
'qinri',
'qinzi',
'qinci',
'qinsi',
'qinba',
'qinpa',
'qinma',
'qinfa',
'qinda',
'qinta',
'qinna',
'qinla',
'qinga',
'qinka',
'qinha',
'qinza',
'qinca',
'qinsa',
'qinbo',
'qinpo',
'qinmo',
'qinfo',
'qinlo',
'qinme',
'qinde',
'qinte',
'qinne',
'qinle',
'qinge',
'qinke',
'qinhe',
'qinre',
'qinze',
'qince',
'qinse',
'qinai',
'qinei',
'qinao',
'qinou',
'qinan',
'qinen',
'qiner',
'qinyi',
'qinbi',
'qinpi',
'qinmi',
'qindi',
'qinti',
'qinni',
'qinli',
'qinji',
'qinqi',
'qinxi',
'qinya',
'qinyo',
'qinye',
'qinwu',
'qinbu',
'qinpu',
'qinmu',
'qinfu',
'qindu',
'qintu',
'qinnu',
'qinlu',
'qingu',
'qinku',
'qinhu',
'qinru',
'qinzu',
'qincu',
'qinsu',
'qinwa',
'qinwo',
'qinyu',
'qinnü',
'qinlü',
'qinju',
'qinqu',
'qinxu',
'xinri',
'xinzi',
'xinci',
'xinsi',
'xinba',
'xinpa',
'xinma',
'xinfa',
'xinda',
'xinta',
'xinna',
'xinla',
'xinga',
'xinka',
'xinha',
'xinza',
'xinca',
'xinsa',
'xinbo',
'xinpo',
'xinmo',
'xinfo',
'xinlo',
'xinme',
'xinde',
'xinte',
'xinne',
'xinle',
'xinge',
'xinke',
'xinhe',
'xinre',
'xinze',
'xince',
'xinse',
'xinai',
'xinei',
'xinao',
'xinou',
'xinan',
'xinen',
'xiner',
'xinyi',
'xinbi',
'xinpi',
'xinmi',
'xindi',
'xinti',
'xinni',
'xinli',
'xinji',
'xinqi',
'xinxi',
'xinya',
'xinyo',
'xinye',
'xinwu',
'xinbu',
'xinpu',
'xinmu',
'xinfu',
'xindu',
'xintu',
'xinnu',
'xinlu',
'xingu',
'xinku',
'xinhu',
'xinru',
'xinzu',
'xincu',
'xinsu',
'xinwa',
'xinwo',
'xinyu',
'xinnü',
'xinlü',
'xinju',
'xinqu',
'xinxu',
'biang',
'diang',
'niang',
'liang',
'jiang',
'qiang',
'xiang',
'jiong',
'qiong',
'xiong',
'wuzhi',
'wuchi',
'wushi',
'wuzha',
'wucha',
'wusha',
'wuzhe',
'wuche',
'wushe',
'wubai',
'wupai',
'wumai',
'wudai',
'wutai',
'wunai',
'wulai',
'wugai',
'wukai',
'wuhai',
'wuzai',
'wucai',
'wusai',
'wubei',
'wupei',
'wumei',
'wufei',
'wudei',
'wutei',
'wunei',
'wulei',
'wugei',
'wukei',
'wuhei',
'wuzei',
'wusei',
'wubao',
'wupao',
'wumao',
'wudao',
'wutao',
'wunao',
'wulao',
'wugao',
'wukao',
'wuhao',
'wurao',
'wuzao',
'wucao',
'wusao',
'wupou',
'wumou',
'wufou',
'wudou',
'wutou',
'wunou',
'wulou',
'wugou',
'wukou',
'wuhou',
'wurou',
'wuzou',
'wucou',
'wusou',
'wuban',
'wupan',
'wuman',
'wufan',
'wudan',
'wutan',
'wunan',
'wulan',
'wugan',
'wukan',
'wuhan',
'wuran',
'wuzan',
'wucan',
'wusan',
'wuben',
'wupen',
'wumen',
'wufen',
'wuden',
'wunen',
'wugen',
'wuken',
'wuhen',
'wuren',
'wuzen',
'wucen',
'wusen',
'wuang',
'wueng',
'wudia',
'wunia',
'wulia',
'wujia',
'wuqia',
'wuxia',
'wubie',
'wupie',
'wumie',
'wudie',
'wutie',
'wunie',
'wulie',
'wujie',
'wuqie',
'wuxie',
'wuyai',
'wuyao',
'wuyou',
'wumiu',
'wudiu',
'wuniu',
'wuliu',
'wujiu',
'wuqiu',
'wuxiu',
'wuyan',
'wuyin',
'wubin',
'wupin',
'wumin',
'wudin',
'wunin',
'wulin',
'wujin',
'wuqin',
'wuxin',
'wuzhu',
'wuchu',
'wushu',
'wugua',
'wukua',
'wuhua',
'wurua',
'wuduo',
'wutuo',
'wunuo',
'wuluo',
'wuguo',
'wukuo',
'wuhuo',
'wuruo',
'wuzuo',
'wucuo',
'wusuo',
'wuwai',
'wuwei',
'wudui',
'wutui',
'wugui',
'wukui',
'wuhui',
'wurui',
'wuzui',
'wucui',
'wusui',
'wuwan',
'wuwen',
'wudun',
'wutun',
'wunun',
'wulun',
'wugun',
'wukun',
'wuhun',
'wurun',
'wuzun',
'wucun',
'wusun',
'wuyue',
'wunüe',
'wulüe',
'wujue',
'wuque',
'wuxue',
'wuyun',
'wulün',
'wujun',
'wuqun',
'wuxun',
'buzhi',
'buchi',
'bushi',
'buzha',
'bucha',
'busha',
'buzhe',
'buche',
'bushe',
'bubai',
'bupai',
'bumai',
'budai',
'butai',
'bunai',
'bulai',
'bugai',
'bukai',
'buhai',
'buzai',
'bucai',
'busai',
'bubei',
'bupei',
'bumei',
'bufei',
'budei',
'butei',
'bunei',
'bulei',
'bugei',
'bukei',
'buhei',
'buzei',
'busei',
'bubao',
'bupao',
'bumao',
'budao',
'butao',
'bunao',
'bulao',
'bugao',
'bukao',
'buhao',
'burao',
'buzao',
'bucao',
'busao',
'bupou',
'bumou',
'bufou',
'budou',
'butou',
'bunou',
'bulou',
'bugou',
'bukou',
'buhou',
'burou',
'buzou',
'bucou',
'busou',
'buban',
'bupan',
'buman',
'bufan',
'budan',
'butan',
'bunan',
'bulan',
'bugan',
'bukan',
'buhan',
'buran',
'buzan',
'bucan',
'busan',
'buben',
'bupen',
'bumen',
'bufen',
'buden',
'bunen',
'bugen',
'buken',
'buhen',
'buren',
'buzen',
'bucen',
'busen',
'buang',
'bueng',
'budia',
'bunia',
'bulia',
'bujia',
'buqia',
'buxia',
'bubie',
'bupie',
'bumie',
'budie',
'butie',
'bunie',
'bulie',
'bujie',
'buqie',
'buxie',
'buyai',
'buyao',
'buyou',
'bumiu',
'budiu',
'buniu',
'buliu',
'bujiu',
'buqiu',
'buxiu',
'buyan',
'buyin',
'bubin',
'bupin',
'bumin',
'budin',
'bunin',
'bulin',
'bujin',
'buqin',
'buxin',
'buzhu',
'buchu',
'bushu',
'bugua',
'bukua',
'buhua',
'burua',
'buduo',
'butuo',
'bunuo',
'buluo',
'buguo',
'bukuo',
'buhuo',
'buruo',
'buzuo',
'bucuo',
'busuo',
'buwai',
'buwei',
'budui',
'butui',
'bugui',
'bukui',
'buhui',
'burui',
'buzui',
'bucui',
'busui',
'buwan',
'buwen',
'budun',
'butun',
'bunun',
'bulun',
'bugun',
'bukun',
'buhun',
'burun',
'buzun',
'bucun',
'busun',
'buyue',
'bunüe',
'bulüe',
'bujue',
'buque',
'buxue',
'buyun',
'bulün',
'bujun',
'buqun',
'buxun',
'puzhi',
'puchi',
'pushi',
'puzha',
'pucha',
'pusha',
'puzhe',
'puche',
'pushe',
'pubai',
'pupai',
'pumai',
'pudai',
'putai',
'punai',
'pulai',
'pugai',
'pukai',
'puhai',
'puzai',
'pucai',
'pusai',
'pubei',
'pupei',
'pumei',
'pufei',
'pudei',
'putei',
'punei',
'pulei',
'pugei',
'pukei',
'puhei',
'puzei',
'pusei',
'pubao',
'pupao',
'pumao',
'pudao',
'putao',
'punao',
'pulao',
'pugao',
'pukao',
'puhao',
'purao',
'puzao',
'pucao',
'pusao',
'pupou',
'pumou',
'pufou',
'pudou',
'putou',
'punou',
'pulou',
'pugou',
'pukou',
'puhou',
'purou',
'puzou',
'pucou',
'pusou',
'puban',
'pupan',
'puman',
'pufan',
'pudan',
'putan',
'punan',
'pulan',
'pugan',
'pukan',
'puhan',
'puran',
'puzan',
'pucan',
'pusan',
'puben',
'pupen',
'pumen',
'pufen',
'puden',
'punen',
'pugen',
'puken',
'puhen',
'puren',
'puzen',
'pucen',
'pusen',
'puang',
'pueng',
'pudia',
'punia',
'pulia',
'pujia',
'puqia',
'puxia',
'pubie',
'pupie',
'pumie',
'pudie',
'putie',
'punie',
'pulie',
'pujie',
'puqie',
'puxie',
'puyai',
'puyao',
'puyou',
'pumiu',
'pudiu',
'puniu',
'puliu',
'pujiu',
'puqiu',
'puxiu',
'puyan',
'puyin',
'pubin',
'pupin',
'pumin',
'pudin',
'punin',
'pulin',
'pujin',
'puqin',
'puxin',
'puzhu',
'puchu',
'pushu',
'pugua',
'pukua',
'puhua',
'purua',
'puduo',
'putuo',
'punuo',
'puluo',
'puguo',
'pukuo',
'puhuo',
'puruo',
'puzuo',
'pucuo',
'pusuo',
'puwai',
'puwei',
'pudui',
'putui',
'pugui',
'pukui',
'puhui',
'purui',
'puzui',
'pucui',
'pusui',
'puwan',
'puwen',
'pudun',
'putun',
'punun',
'pulun',
'pugun',
'pukun',
'puhun',
'purun',
'puzun',
'pucun',
'pusun',
'puyue',
'punüe',
'pulüe',
'pujue',
'puque',
'puxue',
'puyun',
'pulün',
'pujun',
'puqun',
'puxun',
'muzhi',
'muchi',
'mushi',
'muzha',
'mucha',
'musha',
'muzhe',
'muche',
'mushe',
'mubai',
'mupai',
'mumai',
'mudai',
'mutai',
'munai',
'mulai',
'mugai',
'mukai',
'muhai',
'muzai',
'mucai',
'musai',
'mubei',
'mupei',
'mumei',
'mufei',
'mudei',
'mutei',
'munei',
'mulei',
'mugei',
'mukei',
'muhei',
'muzei',
'musei',
'mubao',
'mupao',
'mumao',
'mudao',
'mutao',
'munao',
'mulao',
'mugao',
'mukao',
'muhao',
'murao',
'muzao',
'mucao',
'musao',
'mupou',
'mumou',
'mufou',
'mudou',
'mutou',
'munou',
'mulou',
'mugou',
'mukou',
'muhou',
'murou',
'muzou',
'mucou',
'musou',
'muban',
'mupan',
'muman',
'mufan',
'mudan',
'mutan',
'munan',
'mulan',
'mugan',
'mukan',
'muhan',
'muran',
'muzan',
'mucan',
'musan',
'muben',
'mupen',
'mumen',
'mufen',
'muden',
'munen',
'mugen',
'muken',
'muhen',
'muren',
'muzen',
'mucen',
'musen',
'muang',
'mueng',
'mudia',
'munia',
'mulia',
'mujia',
'muqia',
'muxia',
'mubie',
'mupie',
'mumie',
'mudie',
'mutie',
'munie',
'mulie',
'mujie',
'muqie',
'muxie',
'muyai',
'muyao',
'muyou',
'mumiu',
'mudiu',
'muniu',
'muliu',
'mujiu',
'muqiu',
'muxiu',
'muyan',
'muyin',
'mubin',
'mupin',
'mumin',
'mudin',
'munin',
'mulin',
'mujin',
'muqin',
'muxin',
'muzhu',
'muchu',
'mushu',
'mugua',
'mukua',
'muhua',
'murua',
'muduo',
'mutuo',
'munuo',
'muluo',
'muguo',
'mukuo',
'muhuo',
'muruo',
'muzuo',
'mucuo',
'musuo',
'muwai',
'muwei',
'mudui',
'mutui',
'mugui',
'mukui',
'muhui',
'murui',
'muzui',
'mucui',
'musui',
'muwan',
'muwen',
'mudun',
'mutun',
'munun',
'mulun',
'mugun',
'mukun',
'muhun',
'murun',
'muzun',
'mucun',
'musun',
'muyue',
'munüe',
'mulüe',
'mujue',
'muque',
'muxue',
'muyun',
'mulün',
'mujun',
'muqun',
'muxun',
'fuzhi',
'fuchi',
'fushi',
'fuzha',
'fucha',
'fusha',
'fuzhe',
'fuche',
'fushe',
'fubai',
'fupai',
'fumai',
'fudai',
'futai',
'funai',
'fulai',
'fugai',
'fukai',
'fuhai',
'fuzai',
'fucai',
'fusai',
'fubei',
'fupei',
'fumei',
'fufei',
'fudei',
'futei',
'funei',
'fulei',
'fugei',
'fukei',
'fuhei',
'fuzei',
'fusei',
'fubao',
'fupao',
'fumao',
'fudao',
'futao',
'funao',
'fulao',
'fugao',
'fukao',
'fuhao',
'furao',
'fuzao',
'fucao',
'fusao',
'fupou',
'fumou',
'fufou',
'fudou',
'futou',
'funou',
'fulou',
'fugou',
'fukou',
'fuhou',
'furou',
'fuzou',
'fucou',
'fusou',
'fuban',
'fupan',
'fuman',
'fufan',
'fudan',
'futan',
'funan',
'fulan',
'fugan',
'fukan',
'fuhan',
'furan',
'fuzan',
'fucan',
'fusan',
'fuben',
'fupen',
'fumen',
'fufen',
'fuden',
'funen',
'fugen',
'fuken',
'fuhen',
'furen',
'fuzen',
'fucen',
'fusen',
'fuang',
'fueng',
'fudia',
'funia',
'fulia',
'fujia',
'fuqia',
'fuxia',
'fubie',
'fupie',
'fumie',
'fudie',
'futie',
'funie',
'fulie',
'fujie',
'fuqie',
'fuxie',
'fuyai',
'fuyao',
'fuyou',
'fumiu',
'fudiu',
'funiu',
'fuliu',
'fujiu',
'fuqiu',
'fuxiu',
'fuyan',
'fuyin',
'fubin',
'fupin',
'fumin',
'fudin',
'funin',
'fulin',
'fujin',
'fuqin',
'fuxin',
'fuzhu',
'fuchu',
'fushu',
'fugua',
'fukua',
'fuhua',
'furua',
'fuduo',
'futuo',
'funuo',
'fuluo',
'fuguo',
'fukuo',
'fuhuo',
'furuo',
'fuzuo',
'fucuo',
'fusuo',
'fuwai',
'fuwei',
'fudui',
'futui',
'fugui',
'fukui',
'fuhui',
'furui',
'fuzui',
'fucui',
'fusui',
'fuwan',
'fuwen',
'fudun',
'futun',
'funun',
'fulun',
'fugun',
'fukun',
'fuhun',
'furun',
'fuzun',
'fucun',
'fusun',
'fuyue',
'funüe',
'fulüe',
'fujue',
'fuque',
'fuxue',
'fuyun',
'fulün',
'fujun',
'fuqun',
'fuxun',
'duzhi',
'duchi',
'dushi',
'duzha',
'ducha',
'dusha',
'duzhe',
'duche',
'dushe',
'dubai',
'dupai',
'dumai',
'dudai',
'dutai',
'dunai',
'dulai',
'dugai',
'dukai',
'duhai',
'duzai',
'ducai',
'dusai',
'dubei',
'dupei',
'dumei',
'dufei',
'dudei',
'dutei',
'dunei',
'dulei',
'dugei',
'dukei',
'duhei',
'duzei',
'dusei',
'dubao',
'dupao',
'dumao',
'dudao',
'dutao',
'dunao',
'dulao',
'dugao',
'dukao',
'duhao',
'durao',
'duzao',
'ducao',
'dusao',
'dupou',
'dumou',
'dufou',
'dudou',
'dutou',
'dunou',
'dulou',
'dugou',
'dukou',
'duhou',
'durou',
'duzou',
'ducou',
'dusou',
'duban',
'dupan',
'duman',
'dufan',
'dudan',
'dutan',
'dunan',
'dulan',
'dugan',
'dukan',
'duhan',
'duran',
'duzan',
'ducan',
'dusan',
'duben',
'dupen',
'dumen',
'dufen',
'duden',
'dunen',
'dugen',
'duken',
'duhen',
'duren',
'duzen',
'ducen',
'dusen',
'duang',
'dueng',
'dudia',
'dunia',
'dulia',
'dujia',
'duqia',
'duxia',
'dubie',
'dupie',
'dumie',
'dudie',
'dutie',
'dunie',
'dulie',
'dujie',
'duqie',
'duxie',
'duyai',
'duyao',
'duyou',
'dumiu',
'dudiu',
'duniu',
'duliu',
'dujiu',
'duqiu',
'duxiu',
'duyan',
'duyin',
'dubin',
'dupin',
'dumin',
'dudin',
'dunin',
'dulin',
'dujin',
'duqin',
'duxin',
'duzhu',
'duchu',
'dushu',
'dugua',
'dukua',
'duhua',
'durua',
'duduo',
'dutuo',
'dunuo',
'duluo',
'duguo',
'dukuo',
'duhuo',
'duruo',
'duzuo',
'ducuo',
'dusuo',
'duwai',
'duwei',
'dudui',
'dutui',
'dugui',
'dukui',
'duhui',
'durui',
'duzui',
'ducui',
'dusui',
'duwan',
'duwen',
'dudun',
'dutun',
'dunun',
'dulun',
'dugun',
'dukun',
'duhun',
'durun',
'duzun',
'ducun',
'dusun',
'duyue',
'dunüe',
'dulüe',
'dujue',
'duque',
'duxue',
'duyun',
'dulün',
'dujun',
'duqun',
'duxun',
'tuzhi',
'tuchi',
'tushi',
'tuzha',
'tucha',
'tusha',
'tuzhe',
'tuche',
'tushe',
'tubai',
'tupai',
'tumai',
'tudai',
'tutai',
'tunai',
'tulai',
'tugai',
'tukai',
'tuhai',
'tuzai',
'tucai',
'tusai',
'tubei',
'tupei',
'tumei',
'tufei',
'tudei',
'tutei',
'tunei',
'tulei',
'tugei',
'tukei',
'tuhei',
'tuzei',
'tusei',
'tubao',
'tupao',
'tumao',
'tudao',
'tutao',
'tunao',
'tulao',
'tugao',
'tukao',
'tuhao',
'turao',
'tuzao',
'tucao',
'tusao',
'tupou',
'tumou',
'tufou',
'tudou',
'tutou',
'tunou',
'tulou',
'tugou',
'tukou',
'tuhou',
'turou',
'tuzou',
'tucou',
'tusou',
'tuban',
'tupan',
'tuman',
'tufan',
'tudan',
'tutan',
'tunan',
'tulan',
'tugan',
'tukan',
'tuhan',
'turan',
'tuzan',
'tucan',
'tusan',
'tuben',
'tupen',
'tumen',
'tufen',
'tuden',
'tunen',
'tugen',
'tuken',
'tuhen',
'turen',
'tuzen',
'tucen',
'tusen',
'tuang',
'tueng',
'tudia',
'tunia',
'tulia',
'tujia',
'tuqia',
'tuxia',
'tubie',
'tupie',
'tumie',
'tudie',
'tutie',
'tunie',
'tulie',
'tujie',
'tuqie',
'tuxie',
'tuyai',
'tuyao',
'tuyou',
'tumiu',
'tudiu',
'tuniu',
'tuliu',
'tujiu',
'tuqiu',
'tuxiu',
'tuyan',
'tuyin',
'tubin',
'tupin',
'tumin',
'tudin',
'tunin',
'tulin',
'tujin',
'tuqin',
'tuxin',
'tuzhu',
'tuchu',
'tushu',
'tugua',
'tukua',
'tuhua',
'turua',
'tuduo',
'tutuo',
'tunuo',
'tuluo',
'tuguo',
'tukuo',
'tuhuo',
'turuo',
'tuzuo',
'tucuo',
'tusuo',
'tuwai',
'tuwei',
'tudui',
'tutui',
'tugui',
'tukui',
'tuhui',
'turui',
'tuzui',
'tucui',
'tusui',
'tuwan',
'tuwen',
'tudun',
'tutun',
'tunun',
'tulun',
'tugun',
'tukun',
'tuhun',
'turun',
'tuzun',
'tucun',
'tusun',
'tuyue',
'tunüe',
'tulüe',
'tujue',
'tuque',
'tuxue',
'tuyun',
'tulün',
'tujun',
'tuqun',
'tuxun',
'nuzhi',
'nuchi',
'nushi',
'nuzha',
'nucha',
'nusha',
'nuzhe',
'nuche',
'nushe',
'nubai',
'nupai',
'numai',
'nudai',
'nutai',
'nunai',
'nulai',
'nugai',
'nukai',
'nuhai',
'nuzai',
'nucai',
'nusai',
'nubei',
'nupei',
'numei',
'nufei',
'nudei',
'nutei',
'nunei',
'nulei',
'nugei',
'nukei',
'nuhei',
'nuzei',
'nusei',
'nubao',
'nupao',
'numao',
'nudao',
'nutao',
'nunao',
'nulao',
'nugao',
'nukao',
'nuhao',
'nurao',
'nuzao',
'nucao',
'nusao',
'nupou',
'numou',
'nufou',
'nudou',
'nutou',
'nunou',
'nulou',
'nugou',
'nukou',
'nuhou',
'nurou',
'nuzou',
'nucou',
'nusou',
'nuban',
'nupan',
'numan',
'nufan',
'nudan',
'nutan',
'nunan',
'nulan',
'nugan',
'nukan',
'nuhan',
'nuran',
'nuzan',
'nucan',
'nusan',
'nuben',
'nupen',
'numen',
'nufen',
'nuden',
'nunen',
'nugen',
'nuken',
'nuhen',
'nuren',
'nuzen',
'nucen',
'nusen',
'nuang',
'nueng',
'nudia',
'nunia',
'nulia',
'nujia',
'nuqia',
'nuxia',
'nubie',
'nupie',
'numie',
'nudie',
'nutie',
'nunie',
'nulie',
'nujie',
'nuqie',
'nuxie',
'nuyai',
'nuyao',
'nuyou',
'numiu',
'nudiu',
'nuniu',
'nuliu',
'nujiu',
'nuqiu',
'nuxiu',
'nuyan',
'nuyin',
'nubin',
'nupin',
'numin',
'nudin',
'nunin',
'nulin',
'nujin',
'nuqin',
'nuxin',
'nuzhu',
'nuchu',
'nushu',
'nugua',
'nukua',
'nuhua',
'nurua',
'nuduo',
'nutuo',
'nunuo',
'nuluo',
'nuguo',
'nukuo',
'nuhuo',
'nuruo',
'nuzuo',
'nucuo',
'nusuo',
'nuwai',
'nuwei',
'nudui',
'nutui',
'nugui',
'nukui',
'nuhui',
'nurui',
'nuzui',
'nucui',
'nusui',
'nuwan',
'nuwen',
'nudun',
'nutun',
'nunun',
'nulun',
'nugun',
'nukun',
'nuhun',
'nurun',
'nuzun',
'nucun',
'nusun',
'nuyue',
'nunüe',
'nulüe',
'nujue',
'nuque',
'nuxue',
'nuyun',
'nulün',
'nujun',
'nuqun',
'nuxun',
'luzhi',
'luchi',
'lushi',
'luzha',
'lucha',
'lusha',
'luzhe',
'luche',
'lushe',
'lubai',
'lupai',
'lumai',
'ludai',
'lutai',
'lunai',
'lulai',
'lugai',
'lukai',
'luhai',
'luzai',
'lucai',
'lusai',
'lubei',
'lupei',
'lumei',
'lufei',
'ludei',
'lutei',
'lunei',
'lulei',
'lugei',
'lukei',
'luhei',
'luzei',
'lusei',
'lubao',
'lupao',
'lumao',
'ludao',
'lutao',
'lunao',
'lulao',
'lugao',
'lukao',
'luhao',
'lurao',
'luzao',
'lucao',
'lusao',
'lupou',
'lumou',
'lufou',
'ludou',
'lutou',
'lunou',
'lulou',
'lugou',
'lukou',
'luhou',
'lurou',
'luzou',
'lucou',
'lusou',
'luban',
'lupan',
'luman',
'lufan',
'ludan',
'lutan',
'lunan',
'lulan',
'lugan',
'lukan',
'luhan',
'luran',
'luzan',
'lucan',
'lusan',
'luben',
'lupen',
'lumen',
'lufen',
'luden',
'lunen',
'lugen',
'luken',
'luhen',
'luren',
'luzen',
'lucen',
'lusen',
'luang',
'lueng',
'ludia',
'lunia',
'lulia',
'lujia',
'luqia',
'luxia',
'lubie',
'lupie',
'lumie',
'ludie',
'lutie',
'lunie',
'lulie',
'lujie',
'luqie',
'luxie',
'luyai',
'luyao',
'luyou',
'lumiu',
'ludiu',
'luniu',
'luliu',
'lujiu',
'luqiu',
'luxiu',
'luyan',
'luyin',
'lubin',
'lupin',
'lumin',
'ludin',
'lunin',
'lulin',
'lujin',
'luqin',
'luxin',
'luzhu',
'luchu',
'lushu',
'lugua',
'lukua',
'luhua',
'lurua',
'luduo',
'lutuo',
'lunuo',
'luluo',
'luguo',
'lukuo',
'luhuo',
'luruo',
'luzuo',
'lucuo',
'lusuo',
'luwai',
'luwei',
'ludui',
'lutui',
'lugui',
'lukui',
'luhui',
'lurui',
'luzui',
'lucui',
'lusui',
'luwan',
'luwen',
'ludun',
'lutun',
'lunun',
'lulun',
'lugun',
'lukun',
'luhun',
'lurun',
'luzun',
'lucun',
'lusun',
'luyue',
'lunüe',
'lulüe',
'lujue',
'luque',
'luxue',
'luyun',
'lulün',
'lujun',
'luqun',
'luxun',
'guzhi',
'guchi',
'gushi',
'guzha',
'gucha',
'gusha',
'guzhe',
'guche',
'gushe',
'gubai',
'gupai',
'gumai',
'gudai',
'gutai',
'gunai',
'gulai',
'gugai',
'gukai',
'guhai',
'guzai',
'gucai',
'gusai',
'gubei',
'gupei',
'gumei',
'gufei',
'gudei',
'gutei',
'gunei',
'gulei',
'gugei',
'gukei',
'guhei',
'guzei',
'gusei',
'gubao',
'gupao',
'gumao',
'gudao',
'gutao',
'gunao',
'gulao',
'gugao',
'gukao',
'guhao',
'gurao',
'guzao',
'gucao',
'gusao',
'gupou',
'gumou',
'gufou',
'gudou',
'gutou',
'gunou',
'gulou',
'gugou',
'gukou',
'guhou',
'gurou',
'guzou',
'gucou',
'gusou',
'guban',
'gupan',
'guman',
'gufan',
'gudan',
'gutan',
'gunan',
'gulan',
'gugan',
'gukan',
'guhan',
'guran',
'guzan',
'gucan',
'gusan',
'guben',
'gupen',
'gumen',
'gufen',
'guden',
'gunen',
'gugen',
'guken',
'guhen',
'guren',
'guzen',
'gucen',
'gusen',
'guang',
'gueng',
'gudia',
'gunia',
'gulia',
'gujia',
'guqia',
'guxia',
'gubie',
'gupie',
'gumie',
'gudie',
'gutie',
'gunie',
'gulie',
'gujie',
'guqie',
'guxie',
'guyai',
'guyao',
'guyou',
'gumiu',
'gudiu',
'guniu',
'guliu',
'gujiu',
'guqiu',
'guxiu',
'guyan',
'guyin',
'gubin',
'gupin',
'gumin',
'gudin',
'gunin',
'gulin',
'gujin',
'guqin',
'guxin',
'guzhu',
'guchu',
'gushu',
'gugua',
'gukua',
'guhua',
'gurua',
'guduo',
'gutuo',
'gunuo',
'guluo',
'guguo',
'gukuo',
'guhuo',
'guruo',
'guzuo',
'gucuo',
'gusuo',
'guwai',
'guwei',
'gudui',
'gutui',
'gugui',
'gukui',
'guhui',
'gurui',
'guzui',
'gucui',
'gusui',
'guwan',
'guwen',
'gudun',
'gutun',
'gunun',
'gulun',
'gugun',
'gukun',
'guhun',
'gurun',
'guzun',
'gucun',
'gusun',
'guyue',
'gunüe',
'gulüe',
'gujue',
'guque',
'guxue',
'guyun',
'gulün',
'gujun',
'guqun',
'guxun',
'kuzhi',
'kuchi',
'kushi',
'kuzha',
'kucha',
'kusha',
'kuzhe',
'kuche',
'kushe',
'kubai',
'kupai',
'kumai',
'kudai',
'kutai',
'kunai',
'kulai',
'kugai',
'kukai',
'kuhai',
'kuzai',
'kucai',
'kusai',
'kubei',
'kupei',
'kumei',
'kufei',
'kudei',
'kutei',
'kunei',
'kulei',
'kugei',
'kukei',
'kuhei',
'kuzei',
'kusei',
'kubao',
'kupao',
'kumao',
'kudao',
'kutao',
'kunao',
'kulao',
'kugao',
'kukao',
'kuhao',
'kurao',
'kuzao',
'kucao',
'kusao',
'kupou',
'kumou',
'kufou',
'kudou',
'kutou',
'kunou',
'kulou',
'kugou',
'kukou',
'kuhou',
'kurou',
'kuzou',
'kucou',
'kusou',
'kuban',
'kupan',
'kuman',
'kufan',
'kudan',
'kutan',
'kunan',
'kulan',
'kugan',
'kukan',
'kuhan',
'kuran',
'kuzan',
'kucan',
'kusan',
'kuben',
'kupen',
'kumen',
'kufen',
'kuden',
'kunen',
'kugen',
'kuken',
'kuhen',
'kuren',
'kuzen',
'kucen',
'kusen',
'kuang',
'kueng',
'kudia',
'kunia',
'kulia',
'kujia',
'kuqia',
'kuxia',
'kubie',
'kupie',
'kumie',
'kudie',
'kutie',
'kunie',
'kulie',
'kujie',
'kuqie',
'kuxie',
'kuyai',
'kuyao',
'kuyou',
'kumiu',
'kudiu',
'kuniu',
'kuliu',
'kujiu',
'kuqiu',
'kuxiu',
'kuyan',
'kuyin',
'kubin',
'kupin',
'kumin',
'kudin',
'kunin',
'kulin',
'kujin',
'kuqin',
'kuxin',
'kuzhu',
'kuchu',
'kushu',
'kugua',
'kukua',
'kuhua',
'kurua',
'kuduo',
'kutuo',
'kunuo',
'kuluo',
'kuguo',
'kukuo',
'kuhuo',
'kuruo',
'kuzuo',
'kucuo',
'kusuo',
'kuwai',
'kuwei',
'kudui',
'kutui',
'kugui',
'kukui',
'kuhui',
'kurui',
'kuzui',
'kucui',
'kusui',
'kuwan',
'kuwen',
'kudun',
'kutun',
'kunun',
'kulun',
'kugun',
'kukun',
'kuhun',
'kurun',
'kuzun',
'kucun',
'kusun',
'kuyue',
'kunüe',
'kulüe',
'kujue',
'kuque',
'kuxue',
'kuyun',
'kulün',
'kujun',
'kuqun',
'kuxun',
'huzhi',
'huchi',
'hushi',
'huzha',
'hucha',
'husha',
'huzhe',
'huche',
'hushe',
'hubai',
'hupai',
'humai',
'hudai',
'hutai',
'hunai',
'hulai',
'hugai',
'hukai',
'huhai',
'huzai',
'hucai',
'husai',
'hubei',
'hupei',
'humei',
'hufei',
'hudei',
'hutei',
'hunei',
'hulei',
'hugei',
'hukei',
'huhei',
'huzei',
'husei',
'hubao',
'hupao',
'humao',
'hudao',
'hutao',
'hunao',
'hulao',
'hugao',
'hukao',
'huhao',
'hurao',
'huzao',
'hucao',
'husao',
'hupou',
'humou',
'hufou',
'hudou',
'hutou',
'hunou',
'hulou',
'hugou',
'hukou',
'huhou',
'hurou',
'huzou',
'hucou',
'husou',
'huban',
'hupan',
'human',
'hufan',
'hudan',
'hutan',
'hunan',
'hulan',
'hugan',
'hukan',
'huhan',
'huran',
'huzan',
'hucan',
'husan',
'huben',
'hupen',
'humen',
'hufen',
'huden',
'hunen',
'hugen',
'huken',
'huhen',
'huren',
'huzen',
'hucen',
'husen',
'huang',
'hueng',
'hudia',
'hunia',
'hulia',
'hujia',
'huqia',
'huxia',
'hubie',
'hupie',
'humie',
'hudie',
'hutie',
'hunie',
'hulie',
'hujie',
'huqie',
'huxie',
'huyai',
'huyao',
'huyou',
'humiu',
'hudiu',
'huniu',
'huliu',
'hujiu',
'huqiu',
'huxiu',
'huyan',
'huyin',
'hubin',
'hupin',
'humin',
'hudin',
'hunin',
'hulin',
'hujin',
'huqin',
'huxin',
'huzhu',
'huchu',
'hushu',
'hugua',
'hukua',
'huhua',
'hurua',
'huduo',
'hutuo',
'hunuo',
'huluo',
'huguo',
'hukuo',
'huhuo',
'huruo',
'huzuo',
'hucuo',
'husuo',
'huwai',
'huwei',
'hudui',
'hutui',
'hugui',
'hukui',
'huhui',
'hurui',
'huzui',
'hucui',
'husui',
'huwan',
'huwen',
'hudun',
'hutun',
'hunun',
'hulun',
'hugun',
'hukun',
'huhun',
'hurun',
'huzun',
'hucun',
'husun',
'huyue',
'hunüe',
'hulüe',
'hujue',
'huque',
'huxue',
'huyun',
'hulün',
'hujun',
'huqun',
'huxun',
'zhuri',
'zhuzi',
'zhuci',
'zhusi',
'zhuba',
'zhupa',
'zhuma',
'zhufa',
'zhuda',
'zhuta',
'zhuna',
'zhula',
'zhuga',
'zhuka',
'zhuha',
'zhuza',
'zhuca',
'zhusa',
'zhubo',
'zhupo',
'zhumo',
'zhufo',
'zhulo',
'zhume',
'zhude',
'zhute',
'zhune',
'zhule',
'zhuge',
'zhuke',
'zhuhe',
'zhure',
'zhuze',
'zhuce',
'zhuse',
'zhuai',
'zhuei',
'zhuao',
'zhuou',
'zhuan',
'zhuen',
'zhuer',
'zhuyi',
'zhubi',
'zhupi',
'zhumi',
'zhudi',
'zhuti',
'zhuni',
'zhuli',
'zhuji',
'zhuqi',
'zhuxi',
'zhuya',
'zhuyo',
'zhuye',
'zhuwu',
'zhubu',
'zhupu',
'zhumu',
'zhufu',
'zhudu',
'zhutu',
'zhunu',
'zhulu',
'zhugu',
'zhuku',
'zhuhu',
'zhuru',
'zhuzu',
'zhucu',
'zhusu',
'zhuwa',
'zhuwo',
'zhuyu',
'zhunü',
'zhulü',
'zhuju',
'zhuqu',
'zhuxu',
'churi',
'chuzi',
'chuci',
'chusi',
'chuba',
'chupa',
'chuma',
'chufa',
'chuda',
'chuta',
'chuna',
'chula',
'chuga',
'chuka',
'chuha',
'chuza',
'chuca',
'chusa',
'chubo',
'chupo',
'chumo',
'chufo',
'chulo',
'chume',
'chude',
'chute',
'chune',
'chule',
'chuge',
'chuke',
'chuhe',
'chure',
'chuze',
'chuce',
'chuse',
'chuai',
'chuei',
'chuao',
'chuou',
'chuan',
'chuen',
'chuer',
'chuyi',
'chubi',
'chupi',
'chumi',
'chudi',
'chuti',
'chuni',
'chuli',
'chuji',
'chuqi',
'chuxi',
'chuya',
'chuyo',
'chuye',
'chuwu',
'chubu',
'chupu',
'chumu',
'chufu',
'chudu',
'chutu',
'chunu',
'chulu',
'chugu',
'chuku',
'chuhu',
'churu',
'chuzu',
'chucu',
'chusu',
'chuwa',
'chuwo',
'chuyu',
'chunü',
'chulü',
'chuju',
'chuqu',
'chuxu',
'shuri',
'shuzi',
'shuci',
'shusi',
'shuba',
'shupa',
'shuma',
'shufa',
'shuda',
'shuta',
'shuna',
'shula',
'shuga',
'shuka',
'shuha',
'shuza',
'shuca',
'shusa',
'shubo',
'shupo',
'shumo',
'shufo',
'shulo',
'shume',
'shude',
'shute',
'shune',
'shule',
'shuge',
'shuke',
'shuhe',
'shure',
'shuze',
'shuce',
'shuse',
'shuai',
'shuei',
'shuao',
'shuou',
'shuan',
'shuen',
'shuer',
'shuyi',
'shubi',
'shupi',
'shumi',
'shudi',
'shuti',
'shuni',
'shuli',
'shuji',
'shuqi',
'shuxi',
'shuya',
'shuyo',
'shuye',
'shuwu',
'shubu',
'shupu',
'shumu',
'shufu',
'shudu',
'shutu',
'shunu',
'shulu',
'shugu',
'shuku',
'shuhu',
'shuru',
'shuzu',
'shucu',
'shusu',
'shuwa',
'shuwo',
'shuyu',
'shunü',
'shulü',
'shuju',
'shuqu',
'shuxu',
'ruzhi',
'ruchi',
'rushi',
'ruzha',
'rucha',
'rusha',
'ruzhe',
'ruche',
'rushe',
'rubai',
'rupai',
'rumai',
'rudai',
'rutai',
'runai',
'rulai',
'rugai',
'rukai',
'ruhai',
'ruzai',
'rucai',
'rusai',
'rubei',
'rupei',
'rumei',
'rufei',
'rudei',
'rutei',
'runei',
'rulei',
'rugei',
'rukei',
'ruhei',
'ruzei',
'rusei',
'rubao',
'rupao',
'rumao',
'rudao',
'rutao',
'runao',
'rulao',
'rugao',
'rukao',
'ruhao',
'rurao',
'ruzao',
'rucao',
'rusao',
'rupou',
'rumou',
'rufou',
'rudou',
'rutou',
'runou',
'rulou',
'rugou',
'rukou',
'ruhou',
'rurou',
'ruzou',
'rucou',
'rusou',
'ruban',
'rupan',
'ruman',
'rufan',
'rudan',
'rutan',
'runan',
'rulan',
'rugan',
'rukan',
'ruhan',
'ruran',
'ruzan',
'rucan',
'rusan',
'ruben',
'rupen',
'rumen',
'rufen',
'ruden',
'runen',
'rugen',
'ruken',
'ruhen',
'ruren',
'ruzen',
'rucen',
'rusen',
'ruang',
'rueng',
'rudia',
'runia',
'rulia',
'rujia',
'ruqia',
'ruxia',
'rubie',
'rupie',
'rumie',
'rudie',
'rutie',
'runie',
'rulie',
'rujie',
'ruqie',
'ruxie',
'ruyai',
'ruyao',
'ruyou',
'rumiu',
'rudiu',
'runiu',
'ruliu',
'rujiu',
'ruqiu',
'ruxiu',
'ruyan',
'ruyin',
'rubin',
'rupin',
'rumin',
'rudin',
'runin',
'rulin',
'rujin',
'ruqin',
'ruxin',
'ruzhu',
'ruchu',
'rushu',
'rugua',
'rukua',
'ruhua',
'rurua',
'ruduo',
'rutuo',
'runuo',
'ruluo',
'ruguo',
'rukuo',
'ruhuo',
'ruruo',
'ruzuo',
'rucuo',
'rusuo',
'ruwai',
'ruwei',
'rudui',
'rutui',
'rugui',
'rukui',
'ruhui',
'rurui',
'ruzui',
'rucui',
'rusui',
'ruwan',
'ruwen',
'rudun',
'rutun',
'runun',
'rulun',
'rugun',
'rukun',
'ruhun',
'rurun',
'ruzun',
'rucun',
'rusun',
'ruyue',
'runüe',
'rulüe',
'rujue',
'ruque',
'ruxue',
'ruyun',
'rulün',
'rujun',
'ruqun',
'ruxun',
'zuzhi',
'zuchi',
'zushi',
'zuzha',
'zucha',
'zusha',
'zuzhe',
'zuche',
'zushe',
'zubai',
'zupai',
'zumai',
'zudai',
'zutai',
'zunai',
'zulai',
'zugai',
'zukai',
'zuhai',
'zuzai',
'zucai',
'zusai',
'zubei',
'zupei',
'zumei',
'zufei',
'zudei',
'zutei',
'zunei',
'zulei',
'zugei',
'zukei',
'zuhei',
'zuzei',
'zusei',
'zubao',
'zupao',
'zumao',
'zudao',
'zutao',
'zunao',
'zulao',
'zugao',
'zukao',
'zuhao',
'zurao',
'zuzao',
'zucao',
'zusao',
'zupou',
'zumou',
'zufou',
'zudou',
'zutou',
'zunou',
'zulou',
'zugou',
'zukou',
'zuhou',
'zurou',
'zuzou',
'zucou',
'zusou',
'zuban',
'zupan',
'zuman',
'zufan',
'zudan',
'zutan',
'zunan',
'zulan',
'zugan',
'zukan',
'zuhan',
'zuran',
'zuzan',
'zucan',
'zusan',
'zuben',
'zupen',
'zumen',
'zufen',
'zuden',
'zunen',
'zugen',
'zuken',
'zuhen',
'zuren',
'zuzen',
'zucen',
'zusen',
'zuang',
'zueng',
'zudia',
'zunia',
'zulia',
'zujia',
'zuqia',
'zuxia',
'zubie',
'zupie',
'zumie',
'zudie',
'zutie',
'zunie',
'zulie',
'zujie',
'zuqie',
'zuxie',
'zuyai',
'zuyao',
'zuyou',
'zumiu',
'zudiu',
'zuniu',
'zuliu',
'zujiu',
'zuqiu',
'zuxiu',
'zuyan',
'zuyin',
'zubin',
'zupin',
'zumin',
'zudin',
'zunin',
'zulin',
'zujin',
'zuqin',
'zuxin',
'zuzhu',
'zuchu',
'zushu',
'zugua',
'zukua',
'zuhua',
'zurua',
'zuduo',
'zutuo',
'zunuo',
'zuluo',
'zuguo',
'zukuo',
'zuhuo',
'zuruo',
'zuzuo',
'zucuo',
'zusuo',
'zuwai',
'zuwei',
'zudui',
'zutui',
'zugui',
'zukui',
'zuhui',
'zurui',
'zuzui',
'zucui',
'zusui',
'zuwan',
'zuwen',
'zudun',
'zutun',
'zunun',
'zulun',
'zugun',
'zukun',
'zuhun',
'zurun',
'zuzun',
'zucun',
'zusun',
'zuyue',
'zunüe',
'zulüe',
'zujue',
'zuque',
'zuxue',
'zuyun',
'zulün',
'zujun',
'zuqun',
'zuxun',
'cuzhi',
'cuchi',
'cushi',
'cuzha',
'cucha',
'cusha',
'cuzhe',
'cuche',
'cushe',
'cubai',
'cupai',
'cumai',
'cudai',
'cutai',
'cunai',
'culai',
'cugai',
'cukai',
'cuhai',
'cuzai',
'cucai',
'cusai',
'cubei',
'cupei',
'cumei',
'cufei',
'cudei',
'cutei',
'cunei',
'culei',
'cugei',
'cukei',
'cuhei',
'cuzei',
'cusei',
'cubao',
'cupao',
'cumao',
'cudao',
'cutao',
'cunao',
'culao',
'cugao',
'cukao',
'cuhao',
'curao',
'cuzao',
'cucao',
'cusao',
'cupou',
'cumou',
'cufou',
'cudou',
'cutou',
'cunou',
'culou',
'cugou',
'cukou',
'cuhou',
'curou',
'cuzou',
'cucou',
'cusou',
'cuban',
'cupan',
'cuman',
'cufan',
'cudan',
'cutan',
'cunan',
'culan',
'cugan',
'cukan',
'cuhan',
'curan',
'cuzan',
'cucan',
'cusan',
'cuben',
'cupen',
'cumen',
'cufen',
'cuden',
'cunen',
'cugen',
'cuken',
'cuhen',
'curen',
'cuzen',
'cucen',
'cusen',
'cuang',
'cueng',
'cudia',
'cunia',
'culia',
'cujia',
'cuqia',
'cuxia',
'cubie',
'cupie',
'cumie',
'cudie',
'cutie',
'cunie',
'culie',
'cujie',
'cuqie',
'cuxie',
'cuyai',
'cuyao',
'cuyou',
'cumiu',
'cudiu',
'cuniu',
'culiu',
'cujiu',
'cuqiu',
'cuxiu',
'cuyan',
'cuyin',
'cubin',
'cupin',
'cumin',
'cudin',
'cunin',
'culin',
'cujin',
'cuqin',
'cuxin',
'cuzhu',
'cuchu',
'cushu',
'cugua',
'cukua',
'cuhua',
'curua',
'cuduo',
'cutuo',
'cunuo',
'culuo',
'cuguo',
'cukuo',
'cuhuo',
'curuo',
'cuzuo',
'cucuo',
'cusuo',
'cuwai',
'cuwei',
'cudui',
'cutui',
'cugui',
'cukui',
'cuhui',
'curui',
'cuzui',
'cucui',
'cusui',
'cuwan',
'cuwen',
'cudun',
'cutun',
'cunun',
'culun',
'cugun',
'cukun',
'cuhun',
'curun',
'cuzun',
'cucun',
'cusun',
'cuyue',
'cunüe',
'culüe',
'cujue',
'cuque',
'cuxue',
'cuyun',
'culün',
'cujun',
'cuqun',
'cuxun',
'suzhi',
'suchi',
'sushi',
'suzha',
'sucha',
'susha',
'suzhe',
'suche',
'sushe',
'subai',
'supai',
'sumai',
'sudai',
'sutai',
'sunai',
'sulai',
'sugai',
'sukai',
'suhai',
'suzai',
'sucai',
'susai',
'subei',
'supei',
'sumei',
'sufei',
'sudei',
'sutei',
'sunei',
'sulei',
'sugei',
'sukei',
'suhei',
'suzei',
'susei',
'subao',
'supao',
'sumao',
'sudao',
'sutao',
'sunao',
'sulao',
'sugao',
'sukao',
'suhao',
'surao',
'suzao',
'sucao',
'susao',
'supou',
'sumou',
'sufou',
'sudou',
'sutou',
'sunou',
'sulou',
'sugou',
'sukou',
'suhou',
'surou',
'suzou',
'sucou',
'susou',
'suban',
'supan',
'suman',
'sufan',
'sudan',
'sutan',
'sunan',
'sulan',
'sugan',
'sukan',
'suhan',
'suran',
'suzan',
'sucan',
'susan',
'suben',
'supen',
'sumen',
'sufen',
'suden',
'sunen',
'sugen',
'suken',
'suhen',
'suren',
'suzen',
'sucen',
'susen',
'suang',
'sueng',
'sudia',
'sunia',
'sulia',
'sujia',
'suqia',
'suxia',
'subie',
'supie',
'sumie',
'sudie',
'sutie',
'sunie',
'sulie',
'sujie',
'suqie',
'suxie',
'suyai',
'suyao',
'suyou',
'sumiu',
'sudiu',
'suniu',
'suliu',
'sujiu',
'suqiu',
'suxiu',
'suyan',
'suyin',
'subin',
'supin',
'sumin',
'sudin',
'sunin',
'sulin',
'sujin',
'suqin',
'suxin',
'suzhu',
'suchu',
'sushu',
'sugua',
'sukua',
'suhua',
'surua',
'suduo',
'sutuo',
'sunuo',
'suluo',
'suguo',
'sukuo',
'suhuo',
'suruo',
'suzuo',
'sucuo',
'susuo',
'suwai',
'suwei',
'sudui',
'sutui',
'sugui',
'sukui',
'suhui',
'surui',
'suzui',
'sucui',
'susui',
'suwan',
'suwen',
'sudun',
'sutun',
'sunun',
'sulun',
'sugun',
'sukun',
'suhun',
'surun',
'suzun',
'sucun',
'susun',
'suyue',
'sunüe',
'sulüe',
'sujue',
'suque',
'suxue',
'suyun',
'sulün',
'sujun',
'suqun',
'suxun',
'wazhi',
'wachi',
'washi',
'wazha',
'wacha',
'washa',
'wazhe',
'wache',
'washe',
'wabai',
'wapai',
'wamai',
'wadai',
'watai',
'wanai',
'walai',
'wagai',
'wakai',
'wahai',
'wazai',
'wacai',
'wasai',
'wabei',
'wapei',
'wamei',
'wafei',
'wadei',
'watei',
'wanei',
'walei',
'wagei',
'wakei',
'wahei',
'wazei',
'wasei',
'wabao',
'wapao',
'wamao',
'wadao',
'watao',
'wanao',
'walao',
'wagao',
'wakao',
'wahao',
'warao',
'wazao',
'wacao',
'wasao',
'wapou',
'wamou',
'wafou',
'wadou',
'watou',
'wanou',
'walou',
'wagou',
'wakou',
'wahou',
'warou',
'wazou',
'wacou',
'wasou',
'waban',
'wapan',
'waman',
'wafan',
'wadan',
'watan',
'wanan',
'walan',
'wagan',
'wakan',
'wahan',
'waran',
'wazan',
'wacan',
'wasan',
'waben',
'wapen',
'wamen',
'wafen',
'waden',
'wanen',
'wagen',
'waken',
'wahen',
'waren',
'wazen',
'wacen',
'wasen',
'waang',
'waeng',
'wadia',
'wania',
'walia',
'wajia',
'waqia',
'waxia',
'wabie',
'wapie',
'wamie',
'wadie',
'watie',
'wanie',
'walie',
'wajie',
'waqie',
'waxie',
'wayai',
'wayao',
'wayou',
'wamiu',
'wadiu',
'waniu',
'waliu',
'wajiu',
'waqiu',
'waxiu',
'wayan',
'wayin',
'wabin',
'wapin',
'wamin',
'wadin',
'wanin',
'walin',
'wajin',
'waqin',
'waxin',
'wazhu',
'wachu',
'washu',
'wagua',
'wakua',
'wahua',
'warua',
'waduo',
'watuo',
'wanuo',
'waluo',
'waguo',
'wakuo',
'wahuo',
'waruo',
'wazuo',
'wacuo',
'wasuo',
'wawai',
'wawei',
'wadui',
'watui',
'wagui',
'wakui',
'wahui',
'warui',
'wazui',
'wacui',
'wasui',
'wawan',
'wawen',
'wadun',
'watun',
'wanun',
'walun',
'wagun',
'wakun',
'wahun',
'warun',
'wazun',
'wacun',
'wasun',
'wayue',
'wanüe',
'walüe',
'wajue',
'waque',
'waxue',
'wayun',
'walün',
'wajun',
'waqun',
'waxun',
'guari',
'guazi',
'guaci',
'guasi',
'guaba',
'guapa',
'guama',
'guafa',
'guada',
'guata',
'guana',
'guala',
'guaga',
'guaka',
'guaha',
'guaza',
'guaca',
'guasa',
'guabo',
'guapo',
'guamo',
'guafo',
'gualo',
'guame',
'guade',
'guate',
'guane',
'guale',
'guage',
'guake',
'guahe',
'guare',
'guaze',
'guace',
'guase',
'guaai',
'guaei',
'guaao',
'guaou',
'guaan',
'guaen',
'guaer',
'guayi',
'guabi',
'guapi',
'guami',
'guadi',
'guati',
'guani',
'guali',
'guaji',
'guaqi',
'guaxi',
'guaya',
'guayo',
'guaye',
'guawu',
'guabu',
'guapu',
'guamu',
'guafu',
'guadu',
'guatu',
'guanu',
'gualu',
'guagu',
'guaku',
'guahu',
'guaru',
'guazu',
'guacu',
'guasu',
'guawa',
'guawo',
'guayu',
'guanü',
'gualü',
'guaju',
'guaqu',
'guaxu',
'kuari',
'kuazi',
'kuaci',
'kuasi',
'kuaba',
'kuapa',
'kuama',
'kuafa',
'kuada',
'kuata',
'kuana',
'kuala',
'kuaga',
'kuaka',
'kuaha',
'kuaza',
'kuaca',
'kuasa',
'kuabo',
'kuapo',
'kuamo',
'kuafo',
'kualo',
'kuame',
'kuade',
'kuate',
'kuane',
'kuale',
'kuage',
'kuake',
'kuahe',
'kuare',
'kuaze',
'kuace',
'kuase',
'kuaai',
'kuaei',
'kuaao',
'kuaou',
'kuaan',
'kuaen',
'kuaer',
'kuayi',
'kuabi',
'kuapi',
'kuami',
'kuadi',
'kuati',
'kuani',
'kuali',
'kuaji',
'kuaqi',
'kuaxi',
'kuaya',
'kuayo',
'kuaye',
'kuawu',
'kuabu',
'kuapu',
'kuamu',
'kuafu',
'kuadu',
'kuatu',
'kuanu',
'kualu',
'kuagu',
'kuaku',
'kuahu',
'kuaru',
'kuazu',
'kuacu',
'kuasu',
'kuawa',
'kuawo',
'kuayu',
'kuanü',
'kualü',
'kuaju',
'kuaqu',
'kuaxu',
'huari',
'huazi',
'huaci',
'huasi',
'huaba',
'huapa',
'huama',
'huafa',
'huada',
'huata',
'huana',
'huala',
'huaga',
'huaka',
'huaha',
'huaza',
'huaca',
'huasa',
'huabo',
'huapo',
'huamo',
'huafo',
'hualo',
'huame',
'huade',
'huate',
'huane',
'huale',
'huage',
'huake',
'huahe',
'huare',
'huaze',
'huace',
'huase',
'huaai',
'huaei',
'huaao',
'huaou',
'huaan',
'huaen',
'huaer',
'huayi',
'huabi',
'huapi',
'huami',
'huadi',
'huati',
'huani',
'huali',
'huaji',
'huaqi',
'huaxi',
'huaya',
'huayo',
'huaye',
'huawu',
'huabu',
'huapu',
'huamu',
'huafu',
'huadu',
'huatu',
'huanu',
'hualu',
'huagu',
'huaku',
'huahu',
'huaru',
'huazu',
'huacu',
'huasu',
'huawa',
'huawo',
'huayu',
'huanü',
'hualü',
'huaju',
'huaqu',
'huaxu',
'ruari',
'ruazi',
'ruaci',
'ruasi',
'ruaba',
'ruapa',
'ruama',
'ruafa',
'ruada',
'ruata',
'ruana',
'ruala',
'ruaga',
'ruaka',
'ruaha',
'ruaza',
'ruaca',
'ruasa',
'ruabo',
'ruapo',
'ruamo',
'ruafo',
'rualo',
'ruame',
'ruade',
'ruate',
'ruane',
'ruale',
'ruage',
'ruake',
'ruahe',
'ruare',
'ruaze',
'ruace',
'ruase',
'ruaai',
'ruaei',
'ruaao',
'ruaou',
'ruaan',
'ruaen',
'ruaer',
'ruayi',
'ruabi',
'ruapi',
'ruami',
'ruadi',
'ruati',
'ruani',
'ruali',
'ruaji',
'ruaqi',
'ruaxi',
'ruaya',
'ruayo',
'ruaye',
'ruawu',
'ruabu',
'ruapu',
'ruamu',
'ruafu',
'ruadu',
'ruatu',
'ruanu',
'rualu',
'ruagu',
'ruaku',
'ruahu',
'ruaru',
'ruazu',
'ruacu',
'ruasu',
'ruawa',
'ruawo',
'ruayu',
'ruanü',
'rualü',
'ruaju',
'ruaqu',
'ruaxu',
'wozhi',
'wochi',
'woshi',
'wozha',
'wocha',
'wosha',
'wozhe',
'woche',
'woshe',
'wobai',
'wopai',
'womai',
'wodai',
'wotai',
'wonai',
'wolai',
'wogai',
'wokai',
'wohai',
'wozai',
'wocai',
'wosai',
'wobei',
'wopei',
'womei',
'wofei',
'wodei',
'wotei',
'wonei',
'wolei',
'wogei',
'wokei',
'wohei',
'wozei',
'wosei',
'wobao',
'wopao',
'womao',
'wodao',
'wotao',
'wonao',
'wolao',
'wogao',
'wokao',
'wohao',
'worao',
'wozao',
'wocao',
'wosao',
'wopou',
'womou',
'wofou',
'wodou',
'wotou',
'wonou',
'wolou',
'wogou',
'wokou',
'wohou',
'worou',
'wozou',
'wocou',
'wosou',
'woban',
'wopan',
'woman',
'wofan',
'wodan',
'wotan',
'wonan',
'wolan',
'wogan',
'wokan',
'wohan',
'woran',
'wozan',
'wocan',
'wosan',
'woben',
'wopen',
'women',
'wofen',
'woden',
'wonen',
'wogen',
'woken',
'wohen',
'woren',
'wozen',
'wocen',
'wosen',
'woang',
'woeng',
'wodia',
'wonia',
'wolia',
'wojia',
'woqia',
'woxia',
'wobie',
'wopie',
'womie',
'wodie',
'wotie',
'wonie',
'wolie',
'wojie',
'woqie',
'woxie',
'woyai',
'woyao',
'woyou',
'womiu',
'wodiu',
'woniu',
'woliu',
'wojiu',
'woqiu',
'woxiu',
'woyan',
'woyin',
'wobin',
'wopin',
'womin',
'wodin',
'wonin',
'wolin',
'wojin',
'woqin',
'woxin',
'wozhu',
'wochu',
'woshu',
'wogua',
'wokua',
'wohua',
'worua',
'woduo',
'wotuo',
'wonuo',
'woluo',
'woguo',
'wokuo',
'wohuo',
'woruo',
'wozuo',
'wocuo',
'wosuo',
'wowai',
'wowei',
'wodui',
'wotui',
'wogui',
'wokui',
'wohui',
'worui',
'wozui',
'wocui',
'wosui',
'wowan',
'wowen',
'wodun',
'wotun',
'wonun',
'wolun',
'wogun',
'wokun',
'wohun',
'worun',
'wozun',
'wocun',
'wosun',
'woyue',
'wonüe',
'wolüe',
'wojue',
'woque',
'woxue',
'woyun',
'wolün',
'wojun',
'woqun',
'woxun',
'duori',
'duozi',
'duoci',
'duosi',
'duoba',
'duopa',
'duoma',
'duofa',
'duoda',
'duota',
'duona',
'duola',
'duoga',
'duoka',
'duoha',
'duoza',
'duoca',
'duosa',
'duobo',
'duopo',
'duomo',
'duofo',
'duolo',
'duome',
'duode',
'duote',
'duone',
'duole',
'duoge',
'duoke',
'duohe',
'duore',
'duoze',
'duoce',
'duose',
'duoai',
'duoei',
'duoao',
'duoou',
'duoan',
'duoen',
'duoer',
'duoyi',
'duobi',
'duopi',
'duomi',
'duodi',
'duoti',
'duoni',
'duoli',
'duoji',
'duoqi',
'duoxi',
'duoya',
'duoyo',
'duoye',
'duowu',
'duobu',
'duopu',
'duomu',
'duofu',
'duodu',
'duotu',
'duonu',
'duolu',
'duogu',
'duoku',
'duohu',
'duoru',
'duozu',
'duocu',
'duosu',
'duowa',
'duowo',
'duoyu',
'duonü',
'duolü',
'duoju',
'duoqu',
'duoxu',
'tuori',
'tuozi',
'tuoci',
'tuosi',
'tuoba',
'tuopa',
'tuoma',
'tuofa',
'tuoda',
'tuota',
'tuona',
'tuola',
'tuoga',
'tuoka',
'tuoha',
'tuoza',
'tuoca',
'tuosa',
'tuobo',
'tuopo',
'tuomo',
'tuofo',
'tuolo',
'tuome',
'tuode',
'tuote',
'tuone',
'tuole',
'tuoge',
'tuoke',
'tuohe',
'tuore',
'tuoze',
'tuoce',
'tuose',
'tuoai',
'tuoei',
'tuoao',
'tuoou',
'tuoan',
'tuoen',
'tuoer',
'tuoyi',
'tuobi',
'tuopi',
'tuomi',
'tuodi',
'tuoti',
'tuoni',
'tuoli',
'tuoji',
'tuoqi',
'tuoxi',
'tuoya',
'tuoyo',
'tuoye',
'tuowu',
'tuobu',
'tuopu',
'tuomu',
'tuofu',
'tuodu',
'tuotu',
'tuonu',
'tuolu',
'tuogu',
'tuoku',
'tuohu',
'tuoru',
'tuozu',
'tuocu',
'tuosu',
'tuowa',
'tuowo',
'tuoyu',
'tuonü',
'tuolü',
'tuoju',
'tuoqu',
'tuoxu',
'nuori',
'nuozi',
'nuoci',
'nuosi',
'nuoba',
'nuopa',
'nuoma',
'nuofa',
'nuoda',
'nuota',
'nuona',
'nuola',
'nuoga',
'nuoka',
'nuoha',
'nuoza',
'nuoca',
'nuosa',
'nuobo',
'nuopo',
'nuomo',
'nuofo',
'nuolo',
'nuome',
'nuode',
'nuote',
'nuone',
'nuole',
'nuoge',
'nuoke',
'nuohe',
'nuore',
'nuoze',
'nuoce',
'nuose',
'nuoai',
'nuoei',
'nuoao',
'nuoou',
'nuoan',
'nuoen',
'nuoer',
'nuoyi',
'nuobi',
'nuopi',
'nuomi',
'nuodi',
'nuoti',
'nuoni',
'nuoli',
'nuoji',
'nuoqi',
'nuoxi',
'nuoya',
'nuoyo',
'nuoye',
'nuowu',
'nuobu',
'nuopu',
'nuomu',
'nuofu',
'nuodu',
'nuotu',
'nuonu',
'nuolu',
'nuogu',
'nuoku',
'nuohu',
'nuoru',
'nuozu',
'nuocu',
'nuosu',
'nuowa',
'nuowo',
'nuoyu',
'nuonü',
'nuolü',
'nuoju',
'nuoqu',
'nuoxu',
'luori',
'luozi',
'luoci',
'luosi',
'luoba',
'luopa',
'luoma',
'luofa',
'luoda',
'luota',
'luona',
'luola',
'luoga',
'luoka',
'luoha',
'luoza',
'luoca',
'luosa',
'luobo',
'luopo',
'luomo',
'luofo',
'luolo',
'luome',
'luode',
'luote',
'luone',
'luole',
'luoge',
'luoke',
'luohe',
'luore',
'luoze',
'luoce',
'luose',
'luoai',
'luoei',
'luoao',
'luoou',
'luoan',
'luoen',
'luoer',
'luoyi',
'luobi',
'luopi',
'luomi',
'luodi',
'luoti',
'luoni',
'luoli',
'luoji',
'luoqi',
'luoxi',
'luoya',
'luoyo',
'luoye',
'luowu',
'luobu',
'luopu',
'luomu',
'luofu',
'luodu',
'luotu',
'luonu',
'luolu',
'luogu',
'luoku',
'luohu',
'luoru',
'luozu',
'luocu',
'luosu',
'luowa',
'luowo',
'luoyu',
'luonü',
'luolü',
'luoju',
'luoqu',
'luoxu',
'guori',
'guozi',
'guoci',
'guosi',
'guoba',
'guopa',
'guoma',
'guofa',
'guoda',
'guota',
'guona',
'guola',
'guoga',
'guoka',
'guoha',
'guoza',
'guoca',
'guosa',
'guobo',
'guopo',
'guomo',
'guofo',
'guolo',
'guome',
'guode',
'guote',
'guone',
'guole',
'guoge',
'guoke',
'guohe',
'guore',
'guoze',
'guoce',
'guose',
'guoai',
'guoei',
'guoao',
'guoou',
'guoan',
'guoen',
'guoer',
'guoyi',
'guobi',
'guopi',
'guomi',
'guodi',
'guoti',
'guoni',
'guoli',
'guoji',
'guoqi',
'guoxi',
'guoya',
'guoyo',
'guoye',
'guowu',
'guobu',
'guopu',
'guomu',
'guofu',
'guodu',
'guotu',
'guonu',
'guolu',
'guogu',
'guoku',
'guohu',
'guoru',
'guozu',
'guocu',
'guosu',
'guowa',
'guowo',
'guoyu',
'guonü',
'guolü',
'guoju',
'guoqu',
'guoxu',
'kuori',
'kuozi',
'kuoci',
'kuosi',
'kuoba',
'kuopa',
'kuoma',
'kuofa',
'kuoda',
'kuota',
'kuona',
'kuola',
'kuoga',
'kuoka',
'kuoha',
'kuoza',
'kuoca',
'kuosa',
'kuobo',
'kuopo',
'kuomo',
'kuofo',
'kuolo',
'kuome',
'kuode',
'kuote',
'kuone',
'kuole',
'kuoge',
'kuoke',
'kuohe',
'kuore',
'kuoze',
'kuoce',
'kuose',
'kuoai',
'kuoei',
'kuoao',
'kuoou',
'kuoan',
'kuoen',
'kuoer',
'kuoyi',
'kuobi',
'kuopi',
'kuomi',
'kuodi',
'kuoti',
'kuoni',
'kuoli',
'kuoji',
'kuoqi',
'kuoxi',
'kuoya',
'kuoyo',
'kuoye',
'kuowu',
'kuobu',
'kuopu',
'kuomu',
'kuofu',
'kuodu',
'kuotu',
'kuonu',
'kuolu',
'kuogu',
'kuoku',
'kuohu',
'kuoru',
'kuozu',
'kuocu',
'kuosu',
'kuowa',
'kuowo',
'kuoyu',
'kuonü',
'kuolü',
'kuoju',
'kuoqu',
'kuoxu',
'huori',
'huozi',
'huoci',
'huosi',
'huoba',
'huopa',
'huoma',
'huofa',
'huoda',
'huota',
'huona',
'huola',
'huoga',
'huoka',
'huoha',
'huoza',
'huoca',
'huosa',
'huobo',
'huopo',
'huomo',
'huofo',
'huolo',
'huome',
'huode',
'huote',
'huone',
'huole',
'huoge',
'huoke',
'huohe',
'huore',
'huoze',
'huoce',
'huose',
'huoai',
'huoei',
'huoao',
'huoou',
'huoan',
'huoen',
'huoer',
'huoyi',
'huobi',
'huopi',
'huomi',
'huodi',
'huoti',
'huoni',
'huoli',
'huoji',
'huoqi',
'huoxi',
'huoya',
'huoyo',
'huoye',
'huowu',
'huobu',
'huopu',
'huomu',
'huofu',
'huodu',
'huotu',
'huonu',
'huolu',
'huogu',
'huoku',
'huohu',
'huoru',
'huozu',
'huocu',
'huosu',
'huowa',
'huowo',
'huoyu',
'huonü',
'huolü',
'huoju',
'huoqu',
'huoxu',
'ruori',
'ruozi',
'ruoci',
'ruosi',
'ruoba',
'ruopa',
'ruoma',
'ruofa',
'ruoda',
'ruota',
'ruona',
'ruola',
'ruoga',
'ruoka',
'ruoha',
'ruoza',
'ruoca',
'ruosa',
'ruobo',
'ruopo',
'ruomo',
'ruofo',
'ruolo',
'ruome',
'ruode',
'ruote',
'ruone',
'ruole',
'ruoge',
'ruoke',
'ruohe',
'ruore',
'ruoze',
'ruoce',
'ruose',
'ruoai',
'ruoei',
'ruoao',
'ruoou',
'ruoan',
'ruoen',
'ruoer',
'ruoyi',
'ruobi',
'ruopi',
'ruomi',
'ruodi',
'ruoti',
'ruoni',
'ruoli',
'ruoji',
'ruoqi',
'ruoxi',
'ruoya',
'ruoyo',
'ruoye',
'ruowu',
'ruobu',
'ruopu',
'ruomu',
'ruofu',
'ruodu',
'ruotu',
'ruonu',
'ruolu',
'ruogu',
'ruoku',
'ruohu',
'ruoru',
'ruozu',
'ruocu',
'ruosu',
'ruowa',
'ruowo',
'ruoyu',
'ruonü',
'ruolü',
'ruoju',
'ruoqu',
'ruoxu',
'zuori',
'zuozi',
'zuoci',
'zuosi',
'zuoba',
'zuopa',
'zuoma',
'zuofa',
'zuoda',
'zuota',
'zuona',
'zuola',
'zuoga',
'zuoka',
'zuoha',
'zuoza',
'zuoca',
'zuosa',
'zuobo',
'zuopo',
'zuomo',
'zuofo',
'zuolo',
'zuome',
'zuode',
'zuote',
'zuone',
'zuole',
'zuoge',
'zuoke',
'zuohe',
'zuore',
'zuoze',
'zuoce',
'zuose',
'zuoai',
'zuoei',
'zuoao',
'zuoou',
'zuoan',
'zuoen',
'zuoer',
'zuoyi',
'zuobi',
'zuopi',
'zuomi',
'zuodi',
'zuoti',
'zuoni',
'zuoli',
'zuoji',
'zuoqi',
'zuoxi',
'zuoya',
'zuoyo',
'zuoye',
'zuowu',
'zuobu',
'zuopu',
'zuomu',
'zuofu',
'zuodu',
'zuotu',
'zuonu',
'zuolu',
'zuogu',
'zuoku',
'zuohu',
'zuoru',
'zuozu',
'zuocu',
'zuosu',
'zuowa',
'zuowo',
'zuoyu',
'zuonü',
'zuolü',
'zuoju',
'zuoqu',
'zuoxu',
'cuori',
'cuozi',
'cuoci',
'cuosi',
'cuoba',
'cuopa',
'cuoma',
'cuofa',
'cuoda',
'cuota',
'cuona',
'cuola',
'cuoga',
'cuoka',
'cuoha',
'cuoza',
'cuoca',
'cuosa',
'cuobo',
'cuopo',
'cuomo',
'cuofo',
'cuolo',
'cuome',
'cuode',
'cuote',
'cuone',
'cuole',
'cuoge',
'cuoke',
'cuohe',
'cuore',
'cuoze',
'cuoce',
'cuose',
'cuoai',
'cuoei',
'cuoao',
'cuoou',
'cuoan',
'cuoen',
'cuoer',
'cuoyi',
'cuobi',
'cuopi',
'cuomi',
'cuodi',
'cuoti',
'cuoni',
'cuoli',
'cuoji',
'cuoqi',
'cuoxi',
'cuoya',
'cuoyo',
'cuoye',
'cuowu',
'cuobu',
'cuopu',
'cuomu',
'cuofu',
'cuodu',
'cuotu',
'cuonu',
'cuolu',
'cuogu',
'cuoku',
'cuohu',
'cuoru',
'cuozu',
'cuocu',
'cuosu',
'cuowa',
'cuowo',
'cuoyu',
'cuonü',
'cuolü',
'cuoju',
'cuoqu',
'cuoxu',
'suori',
'suozi',
'suoci',
'suosi',
'suoba',
'suopa',
'suoma',
'suofa',
'suoda',
'suota',
'suona',
'suola',
'suoga',
'suoka',
'suoha',
'suoza',
'suoca',
'suosa',
'suobo',
'suopo',
'suomo',
'suofo',
'suolo',
'suome',
'suode',
'suote',
'suone',
'suole',
'suoge',
'suoke',
'suohe',
'suore',
'suoze',
'suoce',
'suose',
'suoai',
'suoei',
'suoao',
'suoou',
'suoan',
'suoen',
'suoer',
'suoyi',
'suobi',
'suopi',
'suomi',
'suodi',
'suoti',
'suoni',
'suoli',
'suoji',
'suoqi',
'suoxi',
'suoya',
'suoyo',
'suoye',
'suowu',
'suobu',
'suopu',
'suomu',
'suofu',
'suodu',
'suotu',
'suonu',
'suolu',
'suogu',
'suoku',
'suohu',
'suoru',
'suozu',
'suocu',
'suosu',
'suowa',
'suowo',
'suoyu',
'suonü',
'suolü',
'suoju',
'suoqu',
'suoxu',
'wairi',
'waizi',
'waici',
'waisi',
'waiba',
'waipa',
'waima',
'waifa',
'waida',
'waita',
'waina',
'waila',
'waiga',
'waika',
'waiha',
'waiza',
'waica',
'waisa',
'waibo',
'waipo',
'waimo',
'waifo',
'wailo',
'waime',
'waide',
'waite',
'waine',
'waile',
'waige',
'waike',
'waihe',
'waire',
'waize',
'waice',
'waise',
'waiai',
'waiei',
'waiao',
'waiou',
'waian',
'waien',
'waier',
'waiyi',
'waibi',
'waipi',
'waimi',
'waidi',
'waiti',
'waini',
'waili',
'waiji',
'waiqi',
'waixi',
'waiya',
'waiyo',
'waiye',
'waiwu',
'waibu',
'waipu',
'waimu',
'waifu',
'waidu',
'waitu',
'wainu',
'wailu',
'waigu',
'waiku',
'waihu',
'wairu',
'waizu',
'waicu',
'waisu',
'waiwa',
'waiwo',
'waiyu',
'wainü',
'wailü',
'waiju',
'waiqu',
'waixu',
'zhuai',
'chuai',
'shuai',
'weiri',
'weizi',
'weici',
'weisi',
'weiba',
'weipa',
'weima',
'weifa',
'weida',
'weita',
'weina',
'weila',
'weiga',
'weika',
'weiha',
'weiza',
'weica',
'weisa',
'weibo',
'weipo',
'weimo',
'weifo',
'weilo',
'weime',
'weide',
'weite',
'weine',
'weile',
'weige',
'weike',
'weihe',
'weire',
'weize',
'weice',
'weise',
'weiai',
'weiei',
'weiao',
'weiou',
'weian',
'weien',
'weier',
'weiyi',
'weibi',
'weipi',
'weimi',
'weidi',
'weiti',
'weini',
'weili',
'weiji',
'weiqi',
'weixi',
'weiya',
'weiyo',
'weiye',
'weiwu',
'weibu',
'weipu',
'weimu',
'weifu',
'weidu',
'weitu',
'weinu',
'weilu',
'weigu',
'weiku',
'weihu',
'weiru',
'weizu',
'weicu',
'weisu',
'weiwa',
'weiwo',
'weiyu',
'weinü',
'weilü',
'weiju',
'weiqu',
'weixu',
'duiri',
'duizi',
'duici',
'duisi',
'duiba',
'duipa',
'duima',
'duifa',
'duida',
'duita',
'duina',
'duila',
'duiga',
'duika',
'duiha',
'duiza',
'duica',
'duisa',
'duibo',
'duipo',
'duimo',
'duifo',
'duilo',
'duime',
'duide',
'duite',
'duine',
'duile',
'duige',
'duike',
'duihe',
'duire',
'duize',
'duice',
'duise',
'duiai',
'duiei',
'duiao',
'duiou',
'duian',
'duien',
'duier',
'duiyi',
'duibi',
'duipi',
'duimi',
'duidi',
'duiti',
'duini',
'duili',
'duiji',
'duiqi',
'duixi',
'duiya',
'duiyo',
'duiye',
'duiwu',
'duibu',
'duipu',
'duimu',
'duifu',
'duidu',
'duitu',
'duinu',
'duilu',
'duigu',
'duiku',
'duihu',
'duiru',
'duizu',
'duicu',
'duisu',
'duiwa',
'duiwo',
'duiyu',
'duinü',
'duilü',
'duiju',
'duiqu',
'duixu',
'tuiri',
'tuizi',
'tuici',
'tuisi',
'tuiba',
'tuipa',
'tuima',
'tuifa',
'tuida',
'tuita',
'tuina',
'tuila',
'tuiga',
'tuika',
'tuiha',
'tuiza',
'tuica',
'tuisa',
'tuibo',
'tuipo',
'tuimo',
'tuifo',
'tuilo',
'tuime',
'tuide',
'tuite',
'tuine',
'tuile',
'tuige',
'tuike',
'tuihe',
'tuire',
'tuize',
'tuice',
'tuise',
'tuiai',
'tuiei',
'tuiao',
'tuiou',
'tuian',
'tuien',
'tuier',
'tuiyi',
'tuibi',
'tuipi',
'tuimi',
'tuidi',
'tuiti',
'tuini',
'tuili',
'tuiji',
'tuiqi',
'tuixi',
'tuiya',
'tuiyo',
'tuiye',
'tuiwu',
'tuibu',
'tuipu',
'tuimu',
'tuifu',
'tuidu',
'tuitu',
'tuinu',
'tuilu',
'tuigu',
'tuiku',
'tuihu',
'tuiru',
'tuizu',
'tuicu',
'tuisu',
'tuiwa',
'tuiwo',
'tuiyu',
'tuinü',
'tuilü',
'tuiju',
'tuiqu',
'tuixu',
'guiri',
'guizi',
'guici',
'guisi',
'guiba',
'guipa',
'guima',
'guifa',
'guida',
'guita',
'guina',
'guila',
'guiga',
'guika',
'guiha',
'guiza',
'guica',
'guisa',
'guibo',
'guipo',
'guimo',
'guifo',
'guilo',
'guime',
'guide',
'guite',
'guine',
'guile',
'guige',
'guike',
'guihe',
'guire',
'guize',
'guice',
'guise',
'guiai',
'guiei',
'guiao',
'guiou',
'guian',
'guien',
'guier',
'guiyi',
'guibi',
'guipi',
'guimi',
'guidi',
'guiti',
'guini',
'guili',
'guiji',
'guiqi',
'guixi',
'guiya',
'guiyo',
'guiye',
'guiwu',
'guibu',
'guipu',
'guimu',
'guifu',
'guidu',
'guitu',
'guinu',
'guilu',
'guigu',
'guiku',
'guihu',
'guiru',
'guizu',
'guicu',
'guisu',
'guiwa',
'guiwo',
'guiyu',
'guinü',
'guilü',
'guiju',
'guiqu',
'guixu',
'kuiri',
'kuizi',
'kuici',
'kuisi',
'kuiba',
'kuipa',
'kuima',
'kuifa',
'kuida',
'kuita',
'kuina',
'kuila',
'kuiga',
'kuika',
'kuiha',
'kuiza',
'kuica',
'kuisa',
'kuibo',
'kuipo',
'kuimo',
'kuifo',
'kuilo',
'kuime',
'kuide',
'kuite',
'kuine',
'kuile',
'kuige',
'kuike',
'kuihe',
'kuire',
'kuize',
'kuice',
'kuise',
'kuiai',
'kuiei',
'kuiao',
'kuiou',
'kuian',
'kuien',
'kuier',
'kuiyi',
'kuibi',
'kuipi',
'kuimi',
'kuidi',
'kuiti',
'kuini',
'kuili',
'kuiji',
'kuiqi',
'kuixi',
'kuiya',
'kuiyo',
'kuiye',
'kuiwu',
'kuibu',
'kuipu',
'kuimu',
'kuifu',
'kuidu',
'kuitu',
'kuinu',
'kuilu',
'kuigu',
'kuiku',
'kuihu',
'kuiru',
'kuizu',
'kuicu',
'kuisu',
'kuiwa',
'kuiwo',
'kuiyu',
'kuinü',
'kuilü',
'kuiju',
'kuiqu',
'kuixu',
'huiri',
'huizi',
'huici',
'huisi',
'huiba',
'huipa',
'huima',
'huifa',
'huida',
'huita',
'huina',
'huila',
'huiga',
'huika',
'huiha',
'huiza',
'huica',
'huisa',
'huibo',
'huipo',
'huimo',
'huifo',
'huilo',
'huime',
'huide',
'huite',
'huine',
'huile',
'huige',
'huike',
'huihe',
'huire',
'huize',
'huice',
'huise',
'huiai',
'huiei',
'huiao',
'huiou',
'huian',
'huien',
'huier',
'huiyi',
'huibi',
'huipi',
'huimi',
'huidi',
'huiti',
'huini',
'huili',
'huiji',
'huiqi',
'huixi',
'huiya',
'huiyo',
'huiye',
'huiwu',
'huibu',
'huipu',
'huimu',
'huifu',
'huidu',
'huitu',
'huinu',
'huilu',
'huigu',
'huiku',
'huihu',
'huiru',
'huizu',
'huicu',
'huisu',
'huiwa',
'huiwo',
'huiyu',
'huinü',
'huilü',
'huiju',
'huiqu',
'huixu',
'ruiri',
'ruizi',
'ruici',
'ruisi',
'ruiba',
'ruipa',
'ruima',
'ruifa',
'ruida',
'ruita',
'ruina',
'ruila',
'ruiga',
'ruika',
'ruiha',
'ruiza',
'ruica',
'ruisa',
'ruibo',
'ruipo',
'ruimo',
'ruifo',
'ruilo',
'ruime',
'ruide',
'ruite',
'ruine',
'ruile',
'ruige',
'ruike',
'ruihe',
'ruire',
'ruize',
'ruice',
'ruise',
'ruiai',
'ruiei',
'ruiao',
'ruiou',
'ruian',
'ruien',
'ruier',
'ruiyi',
'ruibi',
'ruipi',
'ruimi',
'ruidi',
'ruiti',
'ruini',
'ruili',
'ruiji',
'ruiqi',
'ruixi',
'ruiya',
'ruiyo',
'ruiye',
'ruiwu',
'ruibu',
'ruipu',
'ruimu',
'ruifu',
'ruidu',
'ruitu',
'ruinu',
'ruilu',
'ruigu',
'ruiku',
'ruihu',
'ruiru',
'ruizu',
'ruicu',
'ruisu',
'ruiwa',
'ruiwo',
'ruiyu',
'ruinü',
'ruilü',
'ruiju',
'ruiqu',
'ruixu',
'zuiri',
'zuizi',
'zuici',
'zuisi',
'zuiba',
'zuipa',
'zuima',
'zuifa',
'zuida',
'zuita',
'zuina',
'zuila',
'zuiga',
'zuika',
'zuiha',
'zuiza',
'zuica',
'zuisa',
'zuibo',
'zuipo',
'zuimo',
'zuifo',
'zuilo',
'zuime',
'zuide',
'zuite',
'zuine',
'zuile',
'zuige',
'zuike',
'zuihe',
'zuire',
'zuize',
'zuice',
'zuise',
'zuiai',
'zuiei',
'zuiao',
'zuiou',
'zuian',
'zuien',
'zuier',
'zuiyi',
'zuibi',
'zuipi',
'zuimi',
'zuidi',
'zuiti',
'zuini',
'zuili',
'zuiji',
'zuiqi',
'zuixi',
'zuiya',
'zuiyo',
'zuiye',
'zuiwu',
'zuibu',
'zuipu',
'zuimu',
'zuifu',
'zuidu',
'zuitu',
'zuinu',
'zuilu',
'zuigu',
'zuiku',
'zuihu',
'zuiru',
'zuizu',
'zuicu',
'zuisu',
'zuiwa',
'zuiwo',
'zuiyu',
'zuinü',
'zuilü',
'zuiju',
'zuiqu',
'zuixu',
'cuiri',
'cuizi',
'cuici',
'cuisi',
'cuiba',
'cuipa',
'cuima',
'cuifa',
'cuida',
'cuita',
'cuina',
'cuila',
'cuiga',
'cuika',
'cuiha',
'cuiza',
'cuica',
'cuisa',
'cuibo',
'cuipo',
'cuimo',
'cuifo',
'cuilo',
'cuime',
'cuide',
'cuite',
'cuine',
'cuile',
'cuige',
'cuike',
'cuihe',
'cuire',
'cuize',
'cuice',
'cuise',
'cuiai',
'cuiei',
'cuiao',
'cuiou',
'cuian',
'cuien',
'cuier',
'cuiyi',
'cuibi',
'cuipi',
'cuimi',
'cuidi',
'cuiti',
'cuini',
'cuili',
'cuiji',
'cuiqi',
'cuixi',
'cuiya',
'cuiyo',
'cuiye',
'cuiwu',
'cuibu',
'cuipu',
'cuimu',
'cuifu',
'cuidu',
'cuitu',
'cuinu',
'cuilu',
'cuigu',
'cuiku',
'cuihu',
'cuiru',
'cuizu',
'cuicu',
'cuisu',
'cuiwa',
'cuiwo',
'cuiyu',
'cuinü',
'cuilü',
'cuiju',
'cuiqu',
'cuixu',
'suiri',
'suizi',
'suici',
'suisi',
'suiba',
'suipa',
'suima',
'suifa',
'suida',
'suita',
'suina',
'suila',
'suiga',
'suika',
'suiha',
'suiza',
'suica',
'suisa',
'suibo',
'suipo',
'suimo',
'suifo',
'suilo',
'suime',
'suide',
'suite',
'suine',
'suile',
'suige',
'suike',
'suihe',
'suire',
'suize',
'suice',
'suise',
'suiai',
'suiei',
'suiao',
'suiou',
'suian',
'suien',
'suier',
'suiyi',
'suibi',
'suipi',
'suimi',
'suidi',
'suiti',
'suini',
'suili',
'suiji',
'suiqi',
'suixi',
'suiya',
'suiyo',
'suiye',
'suiwu',
'suibu',
'suipu',
'suimu',
'suifu',
'suidu',
'suitu',
'suinu',
'suilu',
'suigu',
'suiku',
'suihu',
'suiru',
'suizu',
'suicu',
'suisu',
'suiwa',
'suiwo',
'suiyu',
'suinü',
'suilü',
'suiju',
'suiqu',
'suixu',
'wanri',
'wanzi',
'wanci',
'wansi',
'wanba',
'wanpa',
'wanma',
'wanfa',
'wanda',
'wanta',
'wanna',
'wanla',
'wanga',
'wanka',
'wanha',
'wanza',
'wanca',
'wansa',
'wanbo',
'wanpo',
'wanmo',
'wanfo',
'wanlo',
'wanme',
'wande',
'wante',
'wanne',
'wanle',
'wange',
'wanke',
'wanhe',
'wanre',
'wanze',
'wance',
'wanse',
'wanai',
'wanei',
'wanao',
'wanou',
'wanan',
'wanen',
'waner',
'wanyi',
'wanbi',
'wanpi',
'wanmi',
'wandi',
'wanti',
'wanni',
'wanli',
'wanji',
'wanqi',
'wanxi',
'wanya',
'wanyo',
'wanye',
'wanwu',
'wanbu',
'wanpu',
'wanmu',
'wanfu',
'wandu',
'wantu',
'wannu',
'wanlu',
'wangu',
'wanku',
'wanhu',
'wanru',
'wanzu',
'wancu',
'wansu',
'wanwa',
'wanwo',
'wanyu',
'wannü',
'wanlü',
'wanju',
'wanqu',
'wanxu',
'zhuan',
'chuan',
'shuan',
'wenri',
'wenzi',
'wenci',
'wensi',
'wenba',
'wenpa',
'wenma',
'wenfa',
'wenda',
'wenta',
'wenna',
'wenla',
'wenga',
'wenka',
'wenha',
'wenza',
'wenca',
'wensa',
'wenbo',
'wenpo',
'wenmo',
'wenfo',
'wenlo',
'wenme',
'wende',
'wente',
'wenne',
'wenle',
'wenge',
'wenke',
'wenhe',
'wenre',
'wenze',
'wence',
'wense',
'wenai',
'wenei',
'wenao',
'wenou',
'wenan',
'wenen',
'wener',
'wenyi',
'wenbi',
'wenpi',
'wenmi',
'wendi',
'wenti',
'wenni',
'wenli',
'wenji',
'wenqi',
'wenxi',
'wenya',
'wenyo',
'wenye',
'wenwu',
'wenbu',
'wenpu',
'wenmu',
'wenfu',
'wendu',
'wentu',
'wennu',
'wenlu',
'wengu',
'wenku',
'wenhu',
'wenru',
'wenzu',
'wencu',
'wensu',
'wenwa',
'wenwo',
'wenyu',
'wennü',
'wenlü',
'wenju',
'wenqu',
'wenxu',
'dunri',
'dunzi',
'dunci',
'dunsi',
'dunba',
'dunpa',
'dunma',
'dunfa',
'dunda',
'dunta',
'dunna',
'dunla',
'dunga',
'dunka',
'dunha',
'dunza',
'dunca',
'dunsa',
'dunbo',
'dunpo',
'dunmo',
'dunfo',
'dunlo',
'dunme',
'dunde',
'dunte',
'dunne',
'dunle',
'dunge',
'dunke',
'dunhe',
'dunre',
'dunze',
'dunce',
'dunse',
'dunai',
'dunei',
'dunao',
'dunou',
'dunan',
'dunen',
'duner',
'dunyi',
'dunbi',
'dunpi',
'dunmi',
'dundi',
'dunti',
'dunni',
'dunli',
'dunji',
'dunqi',
'dunxi',
'dunya',
'dunyo',
'dunye',
'dunwu',
'dunbu',
'dunpu',
'dunmu',
'dunfu',
'dundu',
'duntu',
'dunnu',
'dunlu',
'dungu',
'dunku',
'dunhu',
'dunru',
'dunzu',
'duncu',
'dunsu',
'dunwa',
'dunwo',
'dunyu',
'dunnü',
'dunlü',
'dunju',
'dunqu',
'dunxu',
'tunri',
'tunzi',
'tunci',
'tunsi',
'tunba',
'tunpa',
'tunma',
'tunfa',
'tunda',
'tunta',
'tunna',
'tunla',
'tunga',
'tunka',
'tunha',
'tunza',
'tunca',
'tunsa',
'tunbo',
'tunpo',
'tunmo',
'tunfo',
'tunlo',
'tunme',
'tunde',
'tunte',
'tunne',
'tunle',
'tunge',
'tunke',
'tunhe',
'tunre',
'tunze',
'tunce',
'tunse',
'tunai',
'tunei',
'tunao',
'tunou',
'tunan',
'tunen',
'tuner',
'tunyi',
'tunbi',
'tunpi',
'tunmi',
'tundi',
'tunti',
'tunni',
'tunli',
'tunji',
'tunqi',
'tunxi',
'tunya',
'tunyo',
'tunye',
'tunwu',
'tunbu',
'tunpu',
'tunmu',
'tunfu',
'tundu',
'tuntu',
'tunnu',
'tunlu',
'tungu',
'tunku',
'tunhu',
'tunru',
'tunzu',
'tuncu',
'tunsu',
'tunwa',
'tunwo',
'tunyu',
'tunnü',
'tunlü',
'tunju',
'tunqu',
'tunxu',
'nunri',
'nunzi',
'nunci',
'nunsi',
'nunba',
'nunpa',
'nunma',
'nunfa',
'nunda',
'nunta',
'nunna',
'nunla',
'nunga',
'nunka',
'nunha',
'nunza',
'nunca',
'nunsa',
'nunbo',
'nunpo',
'nunmo',
'nunfo',
'nunlo',
'nunme',
'nunde',
'nunte',
'nunne',
'nunle',
'nunge',
'nunke',
'nunhe',
'nunre',
'nunze',
'nunce',
'nunse',
'nunai',
'nunei',
'nunao',
'nunou',
'nunan',
'nunen',
'nuner',
'nunyi',
'nunbi',
'nunpi',
'nunmi',
'nundi',
'nunti',
'nunni',
'nunli',
'nunji',
'nunqi',
'nunxi',
'nunya',
'nunyo',
'nunye',
'nunwu',
'nunbu',
'nunpu',
'nunmu',
'nunfu',
'nundu',
'nuntu',
'nunnu',
'nunlu',
'nungu',
'nunku',
'nunhu',
'nunru',
'nunzu',
'nuncu',
'nunsu',
'nunwa',
'nunwo',
'nunyu',
'nunnü',
'nunlü',
'nunju',
'nunqu',
'nunxu',
'lunri',
'lunzi',
'lunci',
'lunsi',
'lunba',
'lunpa',
'lunma',
'lunfa',
'lunda',
'lunta',
'lunna',
'lunla',
'lunga',
'lunka',
'lunha',
'lunza',
'lunca',
'lunsa',
'lunbo',
'lunpo',
'lunmo',
'lunfo',
'lunlo',
'lunme',
'lunde',
'lunte',
'lunne',
'lunle',
'lunge',
'lunke',
'lunhe',
'lunre',
'lunze',
'lunce',
'lunse',
'lunai',
'lunei',
'lunao',
'lunou',
'lunan',
'lunen',
'luner',
'lunyi',
'lunbi',
'lunpi',
'lunmi',
'lundi',
'lunti',
'lunni',
'lunli',
'lunji',
'lunqi',
'lunxi',
'lunya',
'lunyo',
'lunye',
'lunwu',
'lunbu',
'lunpu',
'lunmu',
'lunfu',
'lundu',
'luntu',
'lunnu',
'lunlu',
'lungu',
'lunku',
'lunhu',
'lunru',
'lunzu',
'luncu',
'lunsu',
'lunwa',
'lunwo',
'lunyu',
'lunnü',
'lunlü',
'lunju',
'lunqu',
'lunxu',
'gunri',
'gunzi',
'gunci',
'gunsi',
'gunba',
'gunpa',
'gunma',
'gunfa',
'gunda',
'gunta',
'gunna',
'gunla',
'gunga',
'gunka',
'gunha',
'gunza',
'gunca',
'gunsa',
'gunbo',
'gunpo',
'gunmo',
'gunfo',
'gunlo',
'gunme',
'gunde',
'gunte',
'gunne',
'gunle',
'gunge',
'gunke',
'gunhe',
'gunre',
'gunze',
'gunce',
'gunse',
'gunai',
'gunei',
'gunao',
'gunou',
'gunan',
'gunen',
'guner',
'gunyi',
'gunbi',
'gunpi',
'gunmi',
'gundi',
'gunti',
'gunni',
'gunli',
'gunji',
'gunqi',
'gunxi',
'gunya',
'gunyo',
'gunye',
'gunwu',
'gunbu',
'gunpu',
'gunmu',
'gunfu',
'gundu',
'guntu',
'gunnu',
'gunlu',
'gungu',
'gunku',
'gunhu',
'gunru',
'gunzu',
'guncu',
'gunsu',
'gunwa',
'gunwo',
'gunyu',
'gunnü',
'gunlü',
'gunju',
'gunqu',
'gunxu',
'kunri',
'kunzi',
'kunci',
'kunsi',
'kunba',
'kunpa',
'kunma',
'kunfa',
'kunda',
'kunta',
'kunna',
'kunla',
'kunga',
'kunka',
'kunha',
'kunza',
'kunca',
'kunsa',
'kunbo',
'kunpo',
'kunmo',
'kunfo',
'kunlo',
'kunme',
'kunde',
'kunte',
'kunne',
'kunle',
'kunge',
'kunke',
'kunhe',
'kunre',
'kunze',
'kunce',
'kunse',
'kunai',
'kunei',
'kunao',
'kunou',
'kunan',
'kunen',
'kuner',
'kunyi',
'kunbi',
'kunpi',
'kunmi',
'kundi',
'kunti',
'kunni',
'kunli',
'kunji',
'kunqi',
'kunxi',
'kunya',
'kunyo',
'kunye',
'kunwu',
'kunbu',
'kunpu',
'kunmu',
'kunfu',
'kundu',
'kuntu',
'kunnu',
'kunlu',
'kungu',
'kunku',
'kunhu',
'kunru',
'kunzu',
'kuncu',
'kunsu',
'kunwa',
'kunwo',
'kunyu',
'kunnü',
'kunlü',
'kunju',
'kunqu',
'kunxu',
'hunri',
'hunzi',
'hunci',
'hunsi',
'hunba',
'hunpa',
'hunma',
'hunfa',
'hunda',
'hunta',
'hunna',
'hunla',
'hunga',
'hunka',
'hunha',
'hunza',
'hunca',
'hunsa',
'hunbo',
'hunpo',
'hunmo',
'hunfo',
'hunlo',
'hunme',
'hunde',
'hunte',
'hunne',
'hunle',
'hunge',
'hunke',
'hunhe',
'hunre',
'hunze',
'hunce',
'hunse',
'hunai',
'hunei',
'hunao',
'hunou',
'hunan',
'hunen',
'huner',
'hunyi',
'hunbi',
'hunpi',
'hunmi',
'hundi',
'hunti',
'hunni',
'hunli',
'hunji',
'hunqi',
'hunxi',
'hunya',
'hunyo',
'hunye',
'hunwu',
'hunbu',
'hunpu',
'hunmu',
'hunfu',
'hundu',
'huntu',
'hunnu',
'hunlu',
'hungu',
'hunku',
'hunhu',
'hunru',
'hunzu',
'huncu',
'hunsu',
'hunwa',
'hunwo',
'hunyu',
'hunnü',
'hunlü',
'hunju',
'hunqu',
'hunxu',
'runri',
'runzi',
'runci',
'runsi',
'runba',
'runpa',
'runma',
'runfa',
'runda',
'runta',
'runna',
'runla',
'runga',
'runka',
'runha',
'runza',
'runca',
'runsa',
'runbo',
'runpo',
'runmo',
'runfo',
'runlo',
'runme',
'runde',
'runte',
'runne',
'runle',
'runge',
'runke',
'runhe',
'runre',
'runze',
'runce',
'runse',
'runai',
'runei',
'runao',
'runou',
'runan',
'runen',
'runer',
'runyi',
'runbi',
'runpi',
'runmi',
'rundi',
'runti',
'runni',
'runli',
'runji',
'runqi',
'runxi',
'runya',
'runyo',
'runye',
'runwu',
'runbu',
'runpu',
'runmu',
'runfu',
'rundu',
'runtu',
'runnu',
'runlu',
'rungu',
'runku',
'runhu',
'runru',
'runzu',
'runcu',
'runsu',
'runwa',
'runwo',
'runyu',
'runnü',
'runlü',
'runju',
'runqu',
'runxu',
'zunri',
'zunzi',
'zunci',
'zunsi',
'zunba',
'zunpa',
'zunma',
'zunfa',
'zunda',
'zunta',
'zunna',
'zunla',
'zunga',
'zunka',
'zunha',
'zunza',
'zunca',
'zunsa',
'zunbo',
'zunpo',
'zunmo',
'zunfo',
'zunlo',
'zunme',
'zunde',
'zunte',
'zunne',
'zunle',
'zunge',
'zunke',
'zunhe',
'zunre',
'zunze',
'zunce',
'zunse',
'zunai',
'zunei',
'zunao',
'zunou',
'zunan',
'zunen',
'zuner',
'zunyi',
'zunbi',
'zunpi',
'zunmi',
'zundi',
'zunti',
'zunni',
'zunli',
'zunji',
'zunqi',
'zunxi',
'zunya',
'zunyo',
'zunye',
'zunwu',
'zunbu',
'zunpu',
'zunmu',
'zunfu',
'zundu',
'zuntu',
'zunnu',
'zunlu',
'zungu',
'zunku',
'zunhu',
'zunru',
'zunzu',
'zuncu',
'zunsu',
'zunwa',
'zunwo',
'zunyu',
'zunnü',
'zunlü',
'zunju',
'zunqu',
'zunxu',
'cunri',
'cunzi',
'cunci',
'cunsi',
'cunba',
'cunpa',
'cunma',
'cunfa',
'cunda',
'cunta',
'cunna',
'cunla',
'cunga',
'cunka',
'cunha',
'cunza',
'cunca',
'cunsa',
'cunbo',
'cunpo',
'cunmo',
'cunfo',
'cunlo',
'cunme',
'cunde',
'cunte',
'cunne',
'cunle',
'cunge',
'cunke',
'cunhe',
'cunre',
'cunze',
'cunce',
'cunse',
'cunai',
'cunei',
'cunao',
'cunou',
'cunan',
'cunen',
'cuner',
'cunyi',
'cunbi',
'cunpi',
'cunmi',
'cundi',
'cunti',
'cunni',
'cunli',
'cunji',
'cunqi',
'cunxi',
'cunya',
'cunyo',
'cunye',
'cunwu',
'cunbu',
'cunpu',
'cunmu',
'cunfu',
'cundu',
'cuntu',
'cunnu',
'cunlu',
'cungu',
'cunku',
'cunhu',
'cunru',
'cunzu',
'cuncu',
'cunsu',
'cunwa',
'cunwo',
'cunyu',
'cunnü',
'cunlü',
'cunju',
'cunqu',
'cunxu',
'sunri',
'sunzi',
'sunci',
'sunsi',
'sunba',
'sunpa',
'sunma',
'sunfa',
'sunda',
'sunta',
'sunna',
'sunla',
'sunga',
'sunka',
'sunha',
'sunza',
'sunca',
'sunsa',
'sunbo',
'sunpo',
'sunmo',
'sunfo',
'sunlo',
'sunme',
'sunde',
'sunte',
'sunne',
'sunle',
'sunge',
'sunke',
'sunhe',
'sunre',
'sunze',
'sunce',
'sunse',
'sunai',
'sunei',
'sunao',
'sunou',
'sunan',
'sunen',
'suner',
'sunyi',
'sunbi',
'sunpi',
'sunmi',
'sundi',
'sunti',
'sunni',
'sunli',
'sunji',
'sunqi',
'sunxi',
'sunya',
'sunyo',
'sunye',
'sunwu',
'sunbu',
'sunpu',
'sunmu',
'sunfu',
'sundu',
'suntu',
'sunnu',
'sunlu',
'sungu',
'sunku',
'sunhu',
'sunru',
'sunzu',
'suncu',
'sunsu',
'sunwa',
'sunwo',
'sunyu',
'sunnü',
'sunlü',
'sunju',
'sunqu',
'sunxu',
'guang',
'kuang',
'huang',
'yuzhi',
'yuchi',
'yushi',
'yuzha',
'yucha',
'yusha',
'yuzhe',
'yuche',
'yushe',
'yubai',
'yupai',
'yumai',
'yudai',
'yutai',
'yunai',
'yulai',
'yugai',
'yukai',
'yuhai',
'yuzai',
'yucai',
'yusai',
'yubei',
'yupei',
'yumei',
'yufei',
'yudei',
'yutei',
'yunei',
'yulei',
'yugei',
'yukei',
'yuhei',
'yuzei',
'yusei',
'yubao',
'yupao',
'yumao',
'yudao',
'yutao',
'yunao',
'yulao',
'yugao',
'yukao',
'yuhao',
'yurao',
'yuzao',
'yucao',
'yusao',
'yupou',
'yumou',
'yufou',
'yudou',
'yutou',
'yunou',
'yulou',
'yugou',
'yukou',
'yuhou',
'yurou',
'yuzou',
'yucou',
'yusou',
'yuban',
'yupan',
'yuman',
'yufan',
'yudan',
'yutan',
'yunan',
'yulan',
'yugan',
'yukan',
'yuhan',
'yuran',
'yuzan',
'yucan',
'yusan',
'yuben',
'yupen',
'yumen',
'yufen',
'yuden',
'yunen',
'yugen',
'yuken',
'yuhen',
'yuren',
'yuzen',
'yucen',
'yusen',
'yuang',
'yueng',
'yudia',
'yunia',
'yulia',
'yujia',
'yuqia',
'yuxia',
'yubie',
'yupie',
'yumie',
'yudie',
'yutie',
'yunie',
'yulie',
'yujie',
'yuqie',
'yuxie',
'yuyai',
'yuyao',
'yuyou',
'yumiu',
'yudiu',
'yuniu',
'yuliu',
'yujiu',
'yuqiu',
'yuxiu',
'yuyan',
'yuyin',
'yubin',
'yupin',
'yumin',
'yudin',
'yunin',
'yulin',
'yujin',
'yuqin',
'yuxin',
'yuzhu',
'yuchu',
'yushu',
'yugua',
'yukua',
'yuhua',
'yurua',
'yuduo',
'yutuo',
'yunuo',
'yuluo',
'yuguo',
'yukuo',
'yuhuo',
'yuruo',
'yuzuo',
'yucuo',
'yusuo',
'yuwai',
'yuwei',
'yudui',
'yutui',
'yugui',
'yukui',
'yuhui',
'yurui',
'yuzui',
'yucui',
'yusui',
'yuwan',
'yuwen',
'yudun',
'yutun',
'yunun',
'yulun',
'yugun',
'yukun',
'yuhun',
'yurun',
'yuzun',
'yucun',
'yusun',
'yuyue',
'yunüe',
'yulüe',
'yujue',
'yuque',
'yuxue',
'yuyun',
'yulün',
'yujun',
'yuqun',
'yuxun',
'nüzhi',
'nüchi',
'nüshi',
'nüzha',
'nücha',
'nüsha',
'nüzhe',
'nüche',
'nüshe',
'nübai',
'nüpai',
'nümai',
'nüdai',
'nütai',
'nünai',
'nülai',
'nügai',
'nükai',
'nühai',
'nüzai',
'nücai',
'nüsai',
'nübei',
'nüpei',
'nümei',
'nüfei',
'nüdei',
'nütei',
'nünei',
'nülei',
'nügei',
'nükei',
'nühei',
'nüzei',
'nüsei',
'nübao',
'nüpao',
'nümao',
'nüdao',
'nütao',
'nünao',
'nülao',
'nügao',
'nükao',
'nühao',
'nürao',
'nüzao',
'nücao',
'nüsao',
'nüpou',
'nümou',
'nüfou',
'nüdou',
'nütou',
'nünou',
'nülou',
'nügou',
'nükou',
'nühou',
'nürou',
'nüzou',
'nücou',
'nüsou',
'nüban',
'nüpan',
'nüman',
'nüfan',
'nüdan',
'nütan',
'nünan',
'nülan',
'nügan',
'nükan',
'nühan',
'nüran',
'nüzan',
'nücan',
'nüsan',
'nüben',
'nüpen',
'nümen',
'nüfen',
'nüden',
'nünen',
'nügen',
'nüken',
'nühen',
'nüren',
'nüzen',
'nücen',
'nüsen',
'nüang',
'nüeng',
'nüdia',
'nünia',
'nülia',
'nüjia',
'nüqia',
'nüxia',
'nübie',
'nüpie',
'nümie',
'nüdie',
'nütie',
'nünie',
'nülie',
'nüjie',
'nüqie',
'nüxie',
'nüyai',
'nüyao',
'nüyou',
'nümiu',
'nüdiu',
'nüniu',
'nüliu',
'nüjiu',
'nüqiu',
'nüxiu',
'nüyan',
'nüyin',
'nübin',
'nüpin',
'nümin',
'nüdin',
'nünin',
'nülin',
'nüjin',
'nüqin',
'nüxin',
'nüzhu',
'nüchu',
'nüshu',
'nügua',
'nükua',
'nühua',
'nürua',
'nüduo',
'nütuo',
'nünuo',
'nüluo',
'nüguo',
'nükuo',
'nühuo',
'nüruo',
'nüzuo',
'nücuo',
'nüsuo',
'nüwai',
'nüwei',
'nüdui',
'nütui',
'nügui',
'nükui',
'nühui',
'nürui',
'nüzui',
'nücui',
'nüsui',
'nüwan',
'nüwen',
'nüdun',
'nütun',
'nünun',
'nülun',
'nügun',
'nükun',
'nühun',
'nürun',
'nüzun',
'nücun',
'nüsun',
'nüyue',
'nünüe',
'nülüe',
'nüjue',
'nüque',
'nüxue',
'nüyun',
'nülün',
'nüjun',
'nüqun',
'nüxun',
'lüzhi',
'lüchi',
'lüshi',
'lüzha',
'lücha',
'lüsha',
'lüzhe',
'lüche',
'lüshe',
'lübai',
'lüpai',
'lümai',
'lüdai',
'lütai',
'lünai',
'lülai',
'lügai',
'lükai',
'lühai',
'lüzai',
'lücai',
'lüsai',
'lübei',
'lüpei',
'lümei',
'lüfei',
'lüdei',
'lütei',
'lünei',
'lülei',
'lügei',
'lükei',
'lühei',
'lüzei',
'lüsei',
'lübao',
'lüpao',
'lümao',
'lüdao',
'lütao',
'lünao',
'lülao',
'lügao',
'lükao',
'lühao',
'lürao',
'lüzao',
'lücao',
'lüsao',
'lüpou',
'lümou',
'lüfou',
'lüdou',
'lütou',
'lünou',
'lülou',
'lügou',
'lükou',
'lühou',
'lürou',
'lüzou',
'lücou',
'lüsou',
'lüban',
'lüpan',
'lüman',
'lüfan',
'lüdan',
'lütan',
'lünan',
'lülan',
'lügan',
'lükan',
'lühan',
'lüran',
'lüzan',
'lücan',
'lüsan',
'lüben',
'lüpen',
'lümen',
'lüfen',
'lüden',
'lünen',
'lügen',
'lüken',
'lühen',
'lüren',
'lüzen',
'lücen',
'lüsen',
'lüang',
'lüeng',
'lüdia',
'lünia',
'lülia',
'lüjia',
'lüqia',
'lüxia',
'lübie',
'lüpie',
'lümie',
'lüdie',
'lütie',
'lünie',
'lülie',
'lüjie',
'lüqie',
'lüxie',
'lüyai',
'lüyao',
'lüyou',
'lümiu',
'lüdiu',
'lüniu',
'lüliu',
'lüjiu',
'lüqiu',
'lüxiu',
'lüyan',
'lüyin',
'lübin',
'lüpin',
'lümin',
'lüdin',
'lünin',
'lülin',
'lüjin',
'lüqin',
'lüxin',
'lüzhu',
'lüchu',
'lüshu',
'lügua',
'lükua',
'lühua',
'lürua',
'lüduo',
'lütuo',
'lünuo',
'lüluo',
'lüguo',
'lükuo',
'lühuo',
'lüruo',
'lüzuo',
'lücuo',
'lüsuo',
'lüwai',
'lüwei',
'lüdui',
'lütui',
'lügui',
'lükui',
'lühui',
'lürui',
'lüzui',
'lücui',
'lüsui',
'lüwan',
'lüwen',
'lüdun',
'lütun',
'lünun',
'lülun',
'lügun',
'lükun',
'lühun',
'lürun',
'lüzun',
'lücun',
'lüsun',
'lüyue',
'lünüe',
'lülüe',
'lüjue',
'lüque',
'lüxue',
'lüyun',
'lülün',
'lüjun',
'lüqun',
'lüxun',
'juzhi',
'juchi',
'jushi',
'juzha',
'jucha',
'jusha',
'juzhe',
'juche',
'jushe',
'jubai',
'jupai',
'jumai',
'judai',
'jutai',
'junai',
'julai',
'jugai',
'jukai',
'juhai',
'juzai',
'jucai',
'jusai',
'jubei',
'jupei',
'jumei',
'jufei',
'judei',
'jutei',
'junei',
'julei',
'jugei',
'jukei',
'juhei',
'juzei',
'jusei',
'jubao',
'jupao',
'jumao',
'judao',
'jutao',
'junao',
'julao',
'jugao',
'jukao',
'juhao',
'jurao',
'juzao',
'jucao',
'jusao',
'jupou',
'jumou',
'jufou',
'judou',
'jutou',
'junou',
'julou',
'jugou',
'jukou',
'juhou',
'jurou',
'juzou',
'jucou',
'jusou',
'juban',
'jupan',
'juman',
'jufan',
'judan',
'jutan',
'junan',
'julan',
'jugan',
'jukan',
'juhan',
'juran',
'juzan',
'jucan',
'jusan',
'juben',
'jupen',
'jumen',
'jufen',
'juden',
'junen',
'jugen',
'juken',
'juhen',
'juren',
'juzen',
'jucen',
'jusen',
'juang',
'jueng',
'judia',
'junia',
'julia',
'jujia',
'juqia',
'juxia',
'jubie',
'jupie',
'jumie',
'judie',
'jutie',
'junie',
'julie',
'jujie',
'juqie',
'juxie',
'juyai',
'juyao',
'juyou',
'jumiu',
'judiu',
'juniu',
'juliu',
'jujiu',
'juqiu',
'juxiu',
'juyan',
'juyin',
'jubin',
'jupin',
'jumin',
'judin',
'junin',
'julin',
'jujin',
'juqin',
'juxin',
'juzhu',
'juchu',
'jushu',
'jugua',
'jukua',
'juhua',
'jurua',
'juduo',
'jutuo',
'junuo',
'juluo',
'juguo',
'jukuo',
'juhuo',
'juruo',
'juzuo',
'jucuo',
'jusuo',
'juwai',
'juwei',
'judui',
'jutui',
'jugui',
'jukui',
'juhui',
'jurui',
'juzui',
'jucui',
'jusui',
'juwan',
'juwen',
'judun',
'jutun',
'junun',
'julun',
'jugun',
'jukun',
'juhun',
'jurun',
'juzun',
'jucun',
'jusun',
'juyue',
'junüe',
'julüe',
'jujue',
'juque',
'juxue',
'juyun',
'julün',
'jujun',
'juqun',
'juxun',
'quzhi',
'quchi',
'qushi',
'quzha',
'qucha',
'qusha',
'quzhe',
'quche',
'qushe',
'qubai',
'qupai',
'qumai',
'qudai',
'qutai',
'qunai',
'qulai',
'qugai',
'qukai',
'quhai',
'quzai',
'qucai',
'qusai',
'qubei',
'qupei',
'qumei',
'qufei',
'qudei',
'qutei',
'qunei',
'qulei',
'qugei',
'qukei',
'quhei',
'quzei',
'qusei',
'qubao',
'qupao',
'qumao',
'qudao',
'qutao',
'qunao',
'qulao',
'qugao',
'qukao',
'quhao',
'qurao',
'quzao',
'qucao',
'qusao',
'qupou',
'qumou',
'qufou',
'qudou',
'qutou',
'qunou',
'qulou',
'qugou',
'qukou',
'quhou',
'qurou',
'quzou',
'qucou',
'qusou',
'quban',
'qupan',
'quman',
'qufan',
'qudan',
'qutan',
'qunan',
'qulan',
'qugan',
'qukan',
'quhan',
'quran',
'quzan',
'qucan',
'qusan',
'quben',
'qupen',
'qumen',
'qufen',
'quden',
'qunen',
'qugen',
'quken',
'quhen',
'quren',
'quzen',
'qucen',
'qusen',
'quang',
'queng',
'qudia',
'qunia',
'qulia',
'qujia',
'quqia',
'quxia',
'qubie',
'qupie',
'qumie',
'qudie',
'qutie',
'qunie',
'qulie',
'qujie',
'quqie',
'quxie',
'quyai',
'quyao',
'quyou',
'qumiu',
'qudiu',
'quniu',
'quliu',
'qujiu',
'quqiu',
'quxiu',
'quyan',
'quyin',
'qubin',
'qupin',
'qumin',
'qudin',
'qunin',
'qulin',
'qujin',
'quqin',
'quxin',
'quzhu',
'quchu',
'qushu',
'qugua',
'qukua',
'quhua',
'qurua',
'quduo',
'qutuo',
'qunuo',
'quluo',
'quguo',
'qukuo',
'quhuo',
'quruo',
'quzuo',
'qucuo',
'qusuo',
'quwai',
'quwei',
'qudui',
'qutui',
'qugui',
'qukui',
'quhui',
'qurui',
'quzui',
'qucui',
'qusui',
'quwan',
'quwen',
'qudun',
'qutun',
'qunun',
'qulun',
'qugun',
'qukun',
'quhun',
'qurun',
'quzun',
'qucun',
'qusun',
'quyue',
'qunüe',
'qulüe',
'qujue',
'quque',
'quxue',
'quyun',
'qulün',
'qujun',
'ququn',
'quxun',
'xuzhi',
'xuchi',
'xushi',
'xuzha',
'xucha',
'xusha',
'xuzhe',
'xuche',
'xushe',
'xubai',
'xupai',
'xumai',
'xudai',
'xutai',
'xunai',
'xulai',
'xugai',
'xukai',
'xuhai',
'xuzai',
'xucai',
'xusai',
'xubei',
'xupei',
'xumei',
'xufei',
'xudei',
'xutei',
'xunei',
'xulei',
'xugei',
'xukei',
'xuhei',
'xuzei',
'xusei',
'xubao',
'xupao',
'xumao',
'xudao',
'xutao',
'xunao',
'xulao',
'xugao',
'xukao',
'xuhao',
'xurao',
'xuzao',
'xucao',
'xusao',
'xupou',
'xumou',
'xufou',
'xudou',
'xutou',
'xunou',
'xulou',
'xugou',
'xukou',
'xuhou',
'xurou',
'xuzou',
'xucou',
'xusou',
'xuban',
'xupan',
'xuman',
'xufan',
'xudan',
'xutan',
'xunan',
'xulan',
'xugan',
'xukan',
'xuhan',
'xuran',
'xuzan',
'xucan',
'xusan',
'xuben',
'xupen',
'xumen',
'xufen',
'xuden',
'xunen',
'xugen',
'xuken',
'xuhen',
'xuren',
'xuzen',
'xucen',
'xusen',
'xuang',
'xueng',
'xudia',
'xunia',
'xulia',
'xujia',
'xuqia',
'xuxia',
'xubie',
'xupie',
'xumie',
'xudie',
'xutie',
'xunie',
'xulie',
'xujie',
'xuqie',
'xuxie',
'xuyai',
'xuyao',
'xuyou',
'xumiu',
'xudiu',
'xuniu',
'xuliu',
'xujiu',
'xuqiu',
'xuxiu',
'xuyan',
'xuyin',
'xubin',
'xupin',
'xumin',
'xudin',
'xunin',
'xulin',
'xujin',
'xuqin',
'xuxin',
'xuzhu',
'xuchu',
'xushu',
'xugua',
'xukua',
'xuhua',
'xurua',
'xuduo',
'xutuo',
'xunuo',
'xuluo',
'xuguo',
'xukuo',
'xuhuo',
'xuruo',
'xuzuo',
'xucuo',
'xusuo',
'xuwai',
'xuwei',
'xudui',
'xutui',
'xugui',
'xukui',
'xuhui',
'xurui',
'xuzui',
'xucui',
'xusui',
'xuwan',
'xuwen',
'xudun',
'xutun',
'xunun',
'xulun',
'xugun',
'xukun',
'xuhun',
'xurun',
'xuzun',
'xucun',
'xusun',
'xuyue',
'xunüe',
'xulüe',
'xujue',
'xuque',
'xuxue',
'xuyun',
'xulün',
'xujun',
'xuqun',
'xuxun',
'yueri',
'yuezi',
'yueci',
'yuesi',
'yueba',
'yuepa',
'yuema',
'yuefa',
'yueda',
'yueta',
'yuena',
'yuela',
'yuega',
'yueka',
'yueha',
'yueza',
'yueca',
'yuesa',
'yuebo',
'yuepo',
'yuemo',
'yuefo',
'yuelo',
'yueme',
'yuede',
'yuete',
'yuene',
'yuele',
'yuege',
'yueke',
'yuehe',
'yuere',
'yueze',
'yuece',
'yuese',
'yueai',
'yueei',
'yueao',
'yueou',
'yuean',
'yueen',
'yueer',
'yueyi',
'yuebi',
'yuepi',
'yuemi',
'yuedi',
'yueti',
'yueni',
'yueli',
'yueji',
'yueqi',
'yuexi',
'yueya',
'yueyo',
'yueye',
'yuewu',
'yuebu',
'yuepu',
'yuemu',
'yuefu',
'yuedu',
'yuetu',
'yuenu',
'yuelu',
'yuegu',
'yueku',
'yuehu',
'yueru',
'yuezu',
'yuecu',
'yuesu',
'yuewa',
'yuewo',
'yueyu',
'yuenü',
'yuelü',
'yueju',
'yuequ',
'yuexu',
'nüeri',
'nüezi',
'nüeci',
'nüesi',
'nüeba',
'nüepa',
'nüema',
'nüefa',
'nüeda',
'nüeta',
'nüena',
'nüela',
'nüega',
'nüeka',
'nüeha',
'nüeza',
'nüeca',
'nüesa',
'nüebo',
'nüepo',
'nüemo',
'nüefo',
'nüelo',
'nüeme',
'nüede',
'nüete',
'nüene',
'nüele',
'nüege',
'nüeke',
'nüehe',
'nüere',
'nüeze',
'nüece',
'nüese',
'nüeai',
'nüeei',
'nüeao',
'nüeou',
'nüean',
'nüeen',
'nüeer',
'nüeyi',
'nüebi',
'nüepi',
'nüemi',
'nüedi',
'nüeti',
'nüeni',
'nüeli',
'nüeji',
'nüeqi',
'nüexi',
'nüeya',
'nüeyo',
'nüeye',
'nüewu',
'nüebu',
'nüepu',
'nüemu',
'nüefu',
'nüedu',
'nüetu',
'nüenu',
'nüelu',
'nüegu',
'nüeku',
'nüehu',
'nüeru',
'nüezu',
'nüecu',
'nüesu',
'nüewa',
'nüewo',
'nüeyu',
'nüenü',
'nüelü',
'nüeju',
'nüequ',
'nüexu',
'lüeri',
'lüezi',
'lüeci',
'lüesi',
'lüeba',
'lüepa',
'lüema',
'lüefa',
'lüeda',
'lüeta',
'lüena',
'lüela',
'lüega',
'lüeka',
'lüeha',
'lüeza',
'lüeca',
'lüesa',
'lüebo',
'lüepo',
'lüemo',
'lüefo',
'lüelo',
'lüeme',
'lüede',
'lüete',
'lüene',
'lüele',
'lüege',
'lüeke',
'lüehe',
'lüere',
'lüeze',
'lüece',
'lüese',
'lüeai',
'lüeei',
'lüeao',
'lüeou',
'lüean',
'lüeen',
'lüeer',
'lüeyi',
'lüebi',
'lüepi',
'lüemi',
'lüedi',
'lüeti',
'lüeni',
'lüeli',
'lüeji',
'lüeqi',
'lüexi',
'lüeya',
'lüeyo',
'lüeye',
'lüewu',
'lüebu',
'lüepu',
'lüemu',
'lüefu',
'lüedu',
'lüetu',
'lüenu',
'lüelu',
'lüegu',
'lüeku',
'lüehu',
'lüeru',
'lüezu',
'lüecu',
'lüesu',
'lüewa',
'lüewo',
'lüeyu',
'lüenü',
'lüelü',
'lüeju',
'lüequ',
'lüexu',
'jueri',
'juezi',
'jueci',
'juesi',
'jueba',
'juepa',
'juema',
'juefa',
'jueda',
'jueta',
'juena',
'juela',
'juega',
'jueka',
'jueha',
'jueza',
'jueca',
'juesa',
'juebo',
'juepo',
'juemo',
'juefo',
'juelo',
'jueme',
'juede',
'juete',
'juene',
'juele',
'juege',
'jueke',
'juehe',
'juere',
'jueze',
'juece',
'juese',
'jueai',
'jueei',
'jueao',
'jueou',
'juean',
'jueen',
'jueer',
'jueyi',
'juebi',
'juepi',
'juemi',
'juedi',
'jueti',
'jueni',
'jueli',
'jueji',
'jueqi',
'juexi',
'jueya',
'jueyo',
'jueye',
'juewu',
'juebu',
'juepu',
'juemu',
'juefu',
'juedu',
'juetu',
'juenu',
'juelu',
'juegu',
'jueku',
'juehu',
'jueru',
'juezu',
'juecu',
'juesu',
'juewa',
'juewo',
'jueyu',
'juenü',
'juelü',
'jueju',
'juequ',
'juexu',
'queri',
'quezi',
'queci',
'quesi',
'queba',
'quepa',
'quema',
'quefa',
'queda',
'queta',
'quena',
'quela',
'quega',
'queka',
'queha',
'queza',
'queca',
'quesa',
'quebo',
'quepo',
'quemo',
'quefo',
'quelo',
'queme',
'quede',
'quete',
'quene',
'quele',
'quege',
'queke',
'quehe',
'quere',
'queze',
'quece',
'quese',
'queai',
'queei',
'queao',
'queou',
'quean',
'queen',
'queer',
'queyi',
'quebi',
'quepi',
'quemi',
'quedi',
'queti',
'queni',
'queli',
'queji',
'queqi',
'quexi',
'queya',
'queyo',
'queye',
'quewu',
'quebu',
'quepu',
'quemu',
'quefu',
'quedu',
'quetu',
'quenu',
'quelu',
'quegu',
'queku',
'quehu',
'queru',
'quezu',
'quecu',
'quesu',
'quewa',
'quewo',
'queyu',
'quenü',
'quelü',
'queju',
'quequ',
'quexu',
'xueri',
'xuezi',
'xueci',
'xuesi',
'xueba',
'xuepa',
'xuema',
'xuefa',
'xueda',
'xueta',
'xuena',
'xuela',
'xuega',
'xueka',
'xueha',
'xueza',
'xueca',
'xuesa',
'xuebo',
'xuepo',
'xuemo',
'xuefo',
'xuelo',
'xueme',
'xuede',
'xuete',
'xuene',
'xuele',
'xuege',
'xueke',
'xuehe',
'xuere',
'xueze',
'xuece',
'xuese',
'xueai',
'xueei',
'xueao',
'xueou',
'xuean',
'xueen',
'xueer',
'xueyi',
'xuebi',
'xuepi',
'xuemi',
'xuedi',
'xueti',
'xueni',
'xueli',
'xueji',
'xueqi',
'xuexi',
'xueya',
'xueyo',
'xueye',
'xuewu',
'xuebu',
'xuepu',
'xuemu',
'xuefu',
'xuedu',
'xuetu',
'xuenu',
'xuelu',
'xuegu',
'xueku',
'xuehu',
'xueru',
'xuezu',
'xuecu',
'xuesu',
'xuewa',
'xuewo',
'xueyu',
'xuenü',
'xuelü',
'xueju',
'xuequ',
'xuexu',
'yunri',
'yunzi',
'yunci',
'yunsi',
'yunba',
'yunpa',
'yunma',
'yunfa',
'yunda',
'yunta',
'yunna',
'yunla',
'yunga',
'yunka',
'yunha',
'yunza',
'yunca',
'yunsa',
'yunbo',
'yunpo',
'yunmo',
'yunfo',
'yunlo',
'yunme',
'yunde',
'yunte',
'yunne',
'yunle',
'yunge',
'yunke',
'yunhe',
'yunre',
'yunze',
'yunce',
'yunse',
'yunai',
'yunei',
'yunao',
'yunou',
'yunan',
'yunen',
'yuner',
'yunyi',
'yunbi',
'yunpi',
'yunmi',
'yundi',
'yunti',
'yunni',
'yunli',
'yunji',
'yunqi',
'yunxi',
'yunya',
'yunyo',
'yunye',
'yunwu',
'yunbu',
'yunpu',
'yunmu',
'yunfu',
'yundu',
'yuntu',
'yunnu',
'yunlu',
'yungu',
'yunku',
'yunhu',
'yunru',
'yunzu',
'yuncu',
'yunsu',
'yunwa',
'yunwo',
'yunyu',
'yunnü',
'yunlü',
'yunju',
'yunqu',
'yunxu',
'lünri',
'lünzi',
'lünci',
'lünsi',
'lünba',
'lünpa',
'lünma',
'lünfa',
'lünda',
'lünta',
'lünna',
'lünla',
'lünga',
'lünka',
'lünha',
'lünza',
'lünca',
'lünsa',
'lünbo',
'lünpo',
'lünmo',
'lünfo',
'lünlo',
'lünme',
'lünde',
'lünte',
'lünne',
'lünle',
'lünge',
'lünke',
'lünhe',
'lünre',
'lünze',
'lünce',
'lünse',
'lünai',
'lünei',
'lünao',
'lünou',
'lünan',
'lünen',
'lüner',
'lünyi',
'lünbi',
'lünpi',
'lünmi',
'lündi',
'lünti',
'lünni',
'lünli',
'lünji',
'lünqi',
'lünxi',
'lünya',
'lünyo',
'lünye',
'lünwu',
'lünbu',
'lünpu',
'lünmu',
'lünfu',
'lündu',
'lüntu',
'lünnu',
'lünlu',
'lüngu',
'lünku',
'lünhu',
'lünru',
'lünzu',
'lüncu',
'lünsu',
'lünwa',
'lünwo',
'lünyu',
'lünnü',
'lünlü',
'lünju',
'lünqu',
'lünxu',
'junri',
'junzi',
'junci',
'junsi',
'junba',
'junpa',
'junma',
'junfa',
'junda',
'junta',
'junna',
'junla',
'junga',
'junka',
'junha',
'junza',
'junca',
'junsa',
'junbo',
'junpo',
'junmo',
'junfo',
'junlo',
'junme',
'junde',
'junte',
'junne',
'junle',
'junge',
'junke',
'junhe',
'junre',
'junze',
'junce',
'junse',
'junai',
'junei',
'junao',
'junou',
'junan',
'junen',
'juner',
'junyi',
'junbi',
'junpi',
'junmi',
'jundi',
'junti',
'junni',
'junli',
'junji',
'junqi',
'junxi',
'junya',
'junyo',
'junye',
'junwu',
'junbu',
'junpu',
'junmu',
'junfu',
'jundu',
'juntu',
'junnu',
'junlu',
'jungu',
'junku',
'junhu',
'junru',
'junzu',
'juncu',
'junsu',
'junwa',
'junwo',
'junyu',
'junnü',
'junlü',
'junju',
'junqu',
'junxu',
'qunri',
'qunzi',
'qunci',
'qunsi',
'qunba',
'qunpa',
'qunma',
'qunfa',
'qunda',
'qunta',
'qunna',
'qunla',
'qunga',
'qunka',
'qunha',
'qunza',
'qunca',
'qunsa',
'qunbo',
'qunpo',
'qunmo',
'qunfo',
'qunlo',
'qunme',
'qunde',
'qunte',
'qunne',
'qunle',
'qunge',
'qunke',
'qunhe',
'qunre',
'qunze',
'qunce',
'qunse',
'qunai',
'qunei',
'qunao',
'qunou',
'qunan',
'qunen',
'quner',
'qunyi',
'qunbi',
'qunpi',
'qunmi',
'qundi',
'qunti',
'qunni',
'qunli',
'qunji',
'qunqi',
'qunxi',
'qunya',
'qunyo',
'qunye',
'qunwu',
'qunbu',
'qunpu',
'qunmu',
'qunfu',
'qundu',
'quntu',
'qunnu',
'qunlu',
'qungu',
'qunku',
'qunhu',
'qunru',
'qunzu',
'quncu',
'qunsu',
'qunwa',
'qunwo',
'qunyu',
'qunnü',
'qunlü',
'qunju',
'qunqu',
'qunxu',
'xunri',
'xunzi',
'xunci',
'xunsi',
'xunba',
'xunpa',
'xunma',
'xunfa',
'xunda',
'xunta',
'xunna',
'xunla',
'xunga',
'xunka',
'xunha',
'xunza',
'xunca',
'xunsa',
'xunbo',
'xunpo',
'xunmo',
'xunfo',
'xunlo',
'xunme',
'xunde',
'xunte',
'xunne',
'xunle',
'xunge',
'xunke',
'xunhe',
'xunre',
'xunze',
'xunce',
'xunse',
'xunai',
'xunei',
'xunao',
'xunou',
'xunan',
'xunen',
'xuner',
'xunyi',
'xunbi',
'xunpi',
'xunmi',
'xundi',
'xunti',
'xunni',
'xunli',
'xunji',
'xunqi',
'xunxi',
'xunya',
'xunyo',
'xunye',
'xunwu',
'xunbu',
'xunpu',
'xunmu',
'xunfu',
'xundu',
'xuntu',
'xunnu',
'xunlu',
'xungu',
'xunku',
'xunhu',
'xunru',
'xunzu',
'xuncu',
'xunsu',
'xunwa',
'xunwo',
'xunyu',
'xunnü',
'xunlü',
'xunju',
'xunqu',
'xunxu'
]
